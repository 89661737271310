import {
	READ_INTERESTS_FULFILLED,
	READ_INTERESTS,
	ReadTargetingInterests,
	ReadTargetingInterestsFulfilled,
	ReadTargetingInterestsFulfilledPayload,
	ReadTargetingInterestsPayload,
} from './types';

export const readTargetingInterests: (payload: ReadTargetingInterestsPayload) => ReadTargetingInterests = (
	payload
) => ({
	type: READ_INTERESTS,
	payload,
});

export const readTargetingInterestsFulfilled: (
	payload: ReadTargetingInterestsFulfilledPayload
) => ReadTargetingInterestsFulfilled = (payload) => ({
	type: READ_INTERESTS_FULFILLED,
	payload,
});
