import axios from '@utils/axios';
import cloneDeep from 'lodash.clonedeep';
import { select } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { PatchQuesOptions } from '../types';

export function* patchChoiceOptionsSaga(action: PatchQuesOptions) {
	const { questionId, updates, moderated } = action.payload;
	const { questions, moderatedQuestion } = yield select((s) => s.surveyBuilder);

	try {
		const body = updates.map((option) => Object.assign({}, option, { questionId }));
		yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl), body);

		const questionState = moderated ? moderatedQuestion[questionId] : cloneDeep(questions[questionId]);
		updates.forEach((partialOption) => {
			const { id, ...changes } = partialOption;
			questionState.options[id] = Object.assign({}, questionState.options[id], changes);
		});
		yield putEffect(setQuestion(questionId, questionState, undefined, moderated));
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
