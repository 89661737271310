import { useQuery } from 'react-query';
import { environment } from '../../environment';
import { Workspace } from '../../store/workspace/types';
import { quickFetch } from '../../utils/fetch';

const WORKSPACE_POLL_INTERVAL = 30000;

export const getWorkspaceUrlById = (workspaceId: string) => {
	const apiUrl = environment.apiUrl;
	return `${apiUrl}workspaces/${workspaceId}`;
};
export const getWorkspaceQueryKey = (workspaceId?: string | null) => ['workspaces', workspaceId];
export const useReadWorkspace = (workspaceId?: string | null, enablePolling = false) => {
	return useQuery(
		getWorkspaceQueryKey(workspaceId),
		() => quickFetch<Workspace>(`${environment.apiUrl}workspaces/${workspaceId}`),
		{ enabled: !!workspaceId, refetchInterval: enablePolling ? WORKSPACE_POLL_INTERVAL : false }
	);
};
