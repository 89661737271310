import { takeLeading } from 'redux-saga/effects';
import { GET_SURVEY_DATA } from './types';
import axios from '@utils/axios';
import { GetSurveyPreviewDataAction } from './types';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { putEffect } from '../../../shared/utils/putEffect';
import {
	getSurveyPreviewDataAction,
	setActiveSurveyPreviewAction,
	setSurveyPreviewDataAction,
	setSurveyPreviewDataErrorAction,
} from './actions';
import { mapQuestionnaireResponseToSurvey } from '../surveyBuilder/surveyInfo/sagas/utils';
import { getApiError } from '../../../shared/utils/getApiError';
import { questionnairesApiEndpoint } from '../surveyBuilder/surveyInfo/sagas/consts';
import { assign } from '../../../shared/utils/assign';
import { pickAll } from 'ramda';
import { get } from 'shades';
import { fulfill, pend, reject } from '../../promise/actions';

export function* getSurveyPreviewData(action: GetSurveyPreviewDataAction) {
	const { id } = action.payload;
	yield putEffect(pend(getSurveyPreviewDataAction));
	try {
		const { data } = yield axios.get(getApiEndPointUrl(questionnairesApiEndpoint, id));
		yield putEffect(
			setSurveyPreviewDataAction(
				assign(
					mapQuestionnaireResponseToSurvey(data),
					pickAll(['questionNumber', 'startTime', 'endTime', 'status', 'brandId', 'workspaceId', 'paymentStatus'], data)
				)
			)
		);

		yield putEffect(setActiveSurveyPreviewAction(get('surveys', 0, 'id')(data) || ''));
		yield putEffect(fulfill(getSurveyPreviewDataAction));
	} catch (e: any) {
		yield putEffect(setSurveyPreviewDataErrorAction(getApiError(e)));
		yield putEffect(reject(getSurveyPreviewDataAction, getApiError(e)));
	}
}

function* getSurveyDataPreviewWatcher() {
	yield takeLeading(GET_SURVEY_DATA, getSurveyPreviewData);
}

export const getSurveyDataWatchers = [getSurveyDataPreviewWatcher];
