import axios from '@utils/axios';

import { Questionnaire, UpdateSurveyAction } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { setSurveyError } from '../../shared/actions';
import { questionnairesApiEndpoint } from './consts';
import { setSurveyAction, setSurveyInfoAction } from '../actions';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { setCreditsInfo } from '../../../surveyPreview/actions';

export function* updateSurvey(action: UpdateSurveyAction) {
	const {
		info: { surveyId, ...surveyData },
	} = action.payload;
	yield putEffect(pend(setSurveyInfoAction));
	try {
		const {
			data: {
				name,
				description,
				surveys: [survey],
			},
		} = yield axios.patch(getApiEndPointUrl(questionnairesApiEndpoint, surveyId), surveyData);
		yield putEffect(
			setSurveyAction({ name, description, isModerated: action.payload.info.isModerated } as Questionnaire)
		);
		if (survey) {
			yield putEffect(
				setCreditsInfo({
					credits: survey.credits,
					totalRespondents: survey.totalRespondents,
					surveyCost: survey.surveyCost,
				})
			);
		}
		yield putEffect(fulfill(setSurveyInfoAction));
	} catch (e: any) {
		yield putEffect(reject(setSurveyInfoAction, getApiError(e)));
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
