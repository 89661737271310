// Only do this heere as testing framework mocha cannot use babel

import { stringify } from '@lytejs/query-string';

const isFlatObject = (object) => !Object.values(object).find((v) => typeof v === 'object' && v !== null);
const hasFile = (object) => !Object.values(object).find((v) => v instanceof File);
function getFormData(object) {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
}
export const customFetch = async (url: string, options: any = {}) => {
	const { headers = {}, method = 'get', body, ...remaininOptions } = options;
	const processedOptions: any = {
		headers,
		method,
	};
	if (body && typeof body === 'object') {
		processedOptions.method = method === 'get' ? 'post' : method;
		if (isFlatObject(body)) {
			if (hasFile(body)) {
				processedOptions.body = getFormData(body);
				processedOptions.headers['Content-Type'] = processedOptions.headers['Content-Type'] || 'multipart/form-data';
			} else {
				processedOptions.body = stringify(body);
				processedOptions.headers['Content-Type'] =
					processedOptions.headers['Content-Type'] || 'application/x-www-form-urlencoded';
			}
		} else {
			processedOptions.body = JSON.stringify(body);
			processedOptions.headers['Content-Type'] = processedOptions.headers['Content-Type'] || 'application/json';
		}
	}
	// @ts-ignore
	const res: any = await fetch(url, { ...remaininOptions, processedOptions });
	if (res.ok) {
		try {
			return await res.json();
		} catch (e) {}
	}

	try {
		res.message = await res.text();
	} catch (e) {
		res.message = 'Some Error';
	}
	throw res;
};
