import { ResearchTemplate } from '../../../shared/types/ResearchTemplate';
import { ResearchModuleT } from '../../../shared/types/ResearchModule';
import { ResearchModule } from '../../../shared/enums/ResearchModule';
import { map, pickAll, sortBy } from 'ramda';
import { Research } from '../../../shared/enums/Research';
import { assign } from '../../../shared/utils/assign';
import BrandHealthImg from '../../../assets/img/img-brand-health.svg';
import BrandInnovationImg from '../../../assets/img/img-brand-innovation.svg';
import WebsiteTestingImg from '../../../assets/icons/ResearchIcons/icon-uiux-testing.svg';
import AdRecalIcon from '../../../assets/icons/ResearchIcons/icon-ux-feedback.svg';
import IntentAnalysisIcon from '../../../assets/icons/ResearchIcons/icon-market-intent-analysis.svg';
import BrandUsageIcon from '../../../assets/icons/ResearchIcons/icon-brand-similarity-mapping.svg';
import CustomerLoyaltyIcon from '../../../assets/icons/ResearchIcons/icon-customer-loyalty.svg';
import BrandAssociationIcon from '../../../assets/icons/ResearchIcons/icon-brand-association.svg';
import BrandFunnelIcon from '../../../assets/icons/ResearchIcons/icon-brand-funnel.svg';
import NPSIcon from '../../../assets/icons/ResearchIcons/icon-nps-analysis.svg';
import PricePerceptionIcon from '../../../assets/icons/ResearchIcons/icon-price-perception.svg';
import PrototypeTestingIcon from '../../../assets/icons/ResearchIcons/icon-prototype-testing.svg';
import ApplicationTestingIcon from '../../../assets/icons/ResearchIcons/icon-application-testing.svg';
import ApplicationComparisonIcon from '../../../assets/icons/ResearchIcons/icon-application-comparison.svg';
import ApplicationEvaluationIcon from '../../../assets/icons/ResearchIcons/icon-application-evaluation.svg';
import ConceptValidationIcon from '../../../assets/icons/ResearchIcons/icon-concept-validation.svg';
import ProductAdoptionIcon from '../../../assets/icons/ResearchIcons/icon-product-adoption.svg';
import PrototypeEvaluationIcon from '../../../assets/icons/ResearchIcons/icon-prototype-evaluation.svg';
import UserJourneyIcon from '../../../assets/icons/ResearchIcons/icon-user-journey.svg';
import WebsiteComparisonIcon from '../../../assets/icons/ResearchIcons/icon-website-comparison.svg';
import WebsiteConversionIcon from '../../../assets/icons/ResearchIcons/icon-website-conversion.svg';
import WebsiteEvaluationIcon from '../../../assets/icons/ResearchIcons/icon-website-evaluation.svg';
import SystemUsabilityIcon from '../../../assets/icons/ResearchIcons/icon-system-usability-test.svg';
import TestingFromScratchIcon from '../../../assets/icons/ResearchIcons/icon-testing-from-scratch.svg';
import ABTestingIcon from '../../../assets/icons/ResearchIcons/icon-ab-testing.svg';
import LandingPageIcon from '../../../assets/icons/ResearchIcons/icon-landing-page.svg';
import CreativeDesignIcon from '../../../assets/icons/ResearchIcons/icon-creative-design.svg';
import CopyTestingIcon from '../../../assets/icons/ResearchIcons/icon-copy-testing.svg';
import EmailIcon from '../../../assets/icons/ResearchIcons/icon-email.svg';
import CallToActionIcon from '../../../assets/icons/ResearchIcons/icon-call-to-action.svg';

type TemplateResponse = Omit<ResearchTemplate, 'title' | 'description' | 'videoUrl' | 'research'> & {
	researchType: Research;
};

const mockDescription =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ';
const mockVideo = '/assets/images/video.png';

export const getResearchName = (research: Research): any => {
	switch (research) {
		case Research.AdRecall:
			return 'Ad Recall & Effectiveness';
		case Research.None:
			return 'Create From Scratch';
		case Research.IntentAnalysis:
			return 'Market Intent Analysis';
		case Research.BrandUsage:
			return 'Brand Usage';
		case Research.CustomerLoyalty:
			return 'Customer Loyalty';
		case Research.BrandAssociation:
			return 'Brand Association';
		case Research.BrandFunnel:
			return 'Brand Funnel';
		case Research.FunnelAnalyzer:
			return 'Funnel Analyser';
		case Research.CohortDeepDive:
			return 'Cohort DeepDive';
		case Research.NPS:
			return 'NPS Analysis';
		case Research.PricePerception:
			return 'Price Perception';
		case Research.WebsiteEvaluation:
			return 'Website Evaluation';
		case Research.WebsiteConversion:
			return 'Website Conversion';
		case Research.WebsiteComparison:
			return 'Website Comparison';
		case Research.SystemUsabilityTest:
			return 'System Usability Test';
		case Research.PrototypeEvaluation:
			return 'Prototype Evaluation';
		case Research.ConceptValidation:
			return 'Concept Validation';
		case Research.UserJourney:
			return 'User Journey';
		case Research.ProductAdoption:
			return 'Product Adoption';
		case Research.ApplicationEvaluation:
			return 'Application Evaluation';
		case Research.ApplicationComparison:
			return 'Application Comparison';
		case Research.WebsiteTesting:
			return 'Create From Scratch';
		case Research.PrototypeTesting:
			return 'Create From Scratch';
		case Research.ApplicationTesting:
			return 'Create From Scratch';
		case Research.ABTesting:
			return 'Create From Scratch';
		case Research.LandingPage:
			return 'Landing page';
		case Research.CreativeDesign:
			return 'Creative design';
		case Research.Email:
			return 'Email';
		case Research.CallToAction:
			return 'Call to action (CTA)';
		case Research.CopyTesting:
			return 'Copy Testing';
	}
};

const getResearchModuleInfoByType = (type: ResearchModule, templates: TemplateResponse[]): ResearchModuleT => {
	switch (type) {
		case ResearchModule.BrandHealth:
			return {
				title: 'Brand Health Research',
				description: 'Understand your comprehensive brand health and manage your brand reputation.',
				imageUrl: BrandHealthImg,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 1,
			};
		case ResearchModule.BrandInnovation:
			return {
				title: 'Innovation Research',
				description: 'Test new ideas amongst your target and compare them against industry benchmarks.',
				imageUrl: BrandInnovationImg,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 2,
			};
		case ResearchModule.ABTesting:
			return {
				title: 'A/B Testing',
				description: 'Uncover potential flaws and discover opportunities to improve your application.',
				imageUrl: ABTestingIcon,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 3,
			};
		case ResearchModule.WebsiteTesting:
			return {
				title: 'Website Testing',
				description: 'Get feedback from your target audience on your website and uncover potential flaws.',
				imageUrl: WebsiteTestingImg,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 4,
			};
		case ResearchModule.PrototypeTesting:
			return {
				title: 'Prototype Testing',
				description: 'Gain invaluable insights about your prototype from your users to improve the final product.',
				imageUrl: PrototypeTestingIcon,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 5,
			};
		case ResearchModule.ApplicationTesting:
			return {
				title: 'Application Testing',
				description: 'Uncover potential flaws and discover opportunities to improve your application.',
				imageUrl: ApplicationTestingIcon,
				type,
				numberOfResearches: getNumberOfResearches(templates, type),
				order: 6,
			};
		default:
			return {
				title: 'Create From Scratch',
				description: 'Custom-make survey questionnaires based on your research need.',
				type,
				order: 7,
			};
	}
};

export const getResearchTemplateInfoByType = (
	research: Research
): Pick<ResearchTemplate, 'title' | 'description' | 'videoUrl' | 'research' | 'image'> | any => {
	switch (research) {
		case Research.AdRecall:
			return {
				title: getResearchName(research),
				description:
					'Measure the overall adequacy of your ads based on how memorable your ad is to the target audience.',
				videoUrl: mockVideo,
				research,
				image: AdRecalIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-innovation/Ad_Recall_Effectiveness-v1_dp4gud.pdf',
			};
		case Research.None:
			return {
				title: getResearchName(research),
				description: mockDescription,
				videoUrl: mockVideo,
				research,
				image: IntentAnalysisIcon,
			};
		case Research.IntentAnalysis:
			return {
				title: getResearchName(research),
				description:
					'Understand consumers’ intent of buying your product. Predict future usage and channelize your resources effectively.',
				videoUrl: mockVideo,
				research,
				image: IntentAnalysisIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-innovation/Intent_Analysis_2_psvuj8.pdf',
			};
		case Research.BrandUsage:
			return {
				title: getResearchName(research),
				description:
					'Get granular insights on the principal drivers of your brand usage and increase non-user activation.',
				videoUrl: mockVideo,
				research,
				image: BrandUsageIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-health/Brand_Usage_Attitude_x0n3at.pdf',
			};
		case Research.CustomerLoyalty:
			return {
				title: getResearchName(research),
				description: 'Analyze the trust between your customer and your brand to sense and gain committed customers.',
				videoUrl: mockVideo,
				research,
				image: CustomerLoyaltyIcon,
			};
		case Research.BrandAssociation:
			return {
				title: getResearchName(research),
				description:
					'Identify the different associations that are deep-seated in consumers’ minds and build strategies around them.',
				videoUrl: mockVideo,
				research,
				image: BrandAssociationIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-health/Brand_Associatioon_tsqiaa.pdf',
			};
		case Research.BrandFunnel:
			return {
				title: getResearchName(research),
				description: 'Gain detailed insights into your consumers’ progression along the brand funnel.',
				videoUrl: mockVideo,
				research,
				image: BrandFunnelIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-health/Brand_Funnel_usi78o.pdf',
			};
		case Research.NPS:
			return {
				title: getResearchName(research),
				description: 'Understand your brand’s popularity among the target audience and measure customer loyalty.',
				videoUrl: mockVideo,
				research,
				image: NPSIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-health/NPS_-_Net_Promoter_Score_-1_w9nnqq.pdf',
			};
		case Research.PricePerception:
			return {
				title: getResearchName(research),
				description:
					'Discover the optimal price for your product that your audience deems fit. Create pricing strategies around it.',
				videoUrl: mockVideo,
				research,
				image: PricePerceptionIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/brand-innovation/Price_Perception_cgxtc9.pdf',
			};
		case Research.ApplicationEvaluation:
			return {
				title: getResearchName(research),
				description: 'Determine the strengths and weaknesses of your application from the perspective of your users.',
				videoUrl: mockVideo,
				research,
				image: ApplicationEvaluationIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/Application_testing/Application_Evaluation_qs20zv.pdf',
			};
		case Research.ApplicationComparison:
			return {
				title: getResearchName(research),
				description: 'Recognize the distinguishing points which can make your app better than your competitors.',
				videoUrl: mockVideo,
				research,
				image: ApplicationComparisonIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/Application_testing/App_Comparison_fgpo1g.pdf',
			};
		case Research.WebsiteEvaluation:
			return {
				title: getResearchName(research),
				description: 'Understand the overall value driven by your website based on its design and functionality.',
				videoUrl: mockVideo,
				research,
				image: WebsiteEvaluationIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Website_Evaluation_Test_tz5vwf.pdf',
			};
		case Research.WebsiteConversion:
			return {
				title: getResearchName(research),
				description: 'Identify the design and functional aspects of your website that can increase conversion rate.',
				videoUrl: mockVideo,
				research,
				image: WebsiteConversionIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Website_Conversion_Test_cngaog.pdf',
			};
		case Research.WebsiteComparison:
			return {
				title: getResearchName(research),
				description:
					'Benchmark your website against your competitors and uncover effective ways to improve website performance.',
				videoUrl: mockVideo,
				research,
				image: WebsiteComparisonIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Website_Comparison_Test_rtyomj.pdf',
			};
		case Research.SystemUsabilityTest:
			return {
				title: getResearchName(research),
				description: 'Evaluate the overall system performance to see if it meets your usability objectives.',
				videoUrl: mockVideo,
				research,
				image: SystemUsabilityIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/System_Useability_Test_oe0uix.pdf',
			};
		case Research.PrototypeEvaluation:
			return {
				title: getResearchName(research),
				description: 'Perform an overall assessment of your prototype from your consumer’s perspective.',
				videoUrl: mockVideo,
				research,
				image: PrototypeEvaluationIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/Application_testing/Prototype_Evaluation_ondzka.pdf',
			};
		case Research.ConceptValidation:
			return {
				title: getResearchName(research),
				description:
					'Assess ideas and develop the best ones into successful products by gaining insights from your users.',
				videoUrl: mockVideo,
				research,
				image: ConceptValidationIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/Application_testing/Concept_Validation_oc8oln.pdf',
			};
		case Research.UserJourney:
			return {
				title: getResearchName(research),
				description:
					'Predict and improve the users’ engagement by gaining insights from various stages of the user journey.',
				videoUrl: mockVideo,
				research,
				image: UserJourneyIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/User_Journey_rbuvpc.pdf',
			};
		case Research.ProductAdoption:
			return {
				title: getResearchName(research),
				description:
					'Measure the overall adoption rate of your product and assess users’ journey from awareness to purchase stage.',
				videoUrl: mockVideo,
				research,
				image: ProductAdoptionIcon,
				researchPdfUrl: 'https://d3gwdsy0ns7b47.cloudfront.net/Application_testing/Product_Adoption_oobjip.pdf',
			};
		case Research.WebsiteTesting:
			return {
				title: getResearchName(research),
				description: 'Generate a customized survey that best suits your survey purpose by creating your own questions.',
				videoUrl: mockVideo,
				research,
				image: TestingFromScratchIcon,
			};
		case Research.PrototypeTesting:
			return {
				title: getResearchName(research),
				description: 'Generate a customized survey that best suits your survey purpose by creating your own questions.',
				videoUrl: mockVideo,
				research,
				image: TestingFromScratchIcon,
			};
		case Research.ApplicationTesting:
			return {
				title: getResearchName(research),
				description: 'Generate a customized survey that best suits your survey purpose by creating your own questions.',
				videoUrl: mockVideo,
				research,
				image: TestingFromScratchIcon,
			};
		case Research.ABTesting:
			return {
				title: getResearchName(research),
				description: 'Generate a customized survey that best suits your survey purpose by creating your own questions.',
				videoUrl: mockVideo,
				research,
				image: TestingFromScratchIcon,
			};
		case Research.LandingPage:
			return {
				title: getResearchName(research),
				description: 'Analyze the design that can creates better engagement and drives conversions.',
				videoUrl: mockVideo,
				research,
				image: LandingPageIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Landing_Page_A-B_Testing_1_d7h7xy.pdf',
			};
		case Research.CreativeDesign:
			return {
				title: getResearchName(research),
				description:
					'Optimize creative designs based on real, actionable feedback to increase conversions and user satisfaction.',
				videoUrl: mockVideo,
				research,
				image: CreativeDesignIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Creative_design_A-B_Testing_1_tvuzv5.pdf',
			};
		case Research.CopyTesting:
			return {
				title: getResearchName(research),
				description:
					'Craft unforgettable content to stay on your customers’ top of mind with respect to your product, business, and industry.',
				videoUrl: mockVideo,
				research,
				image: CopyTestingIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Copy_A-B_Testing_1_qz2ris.pdf',
			};
		case Research.Email:
			return {
				title: getResearchName(research),
				description: 'Discover which lines hold a strong chance of getting users to open and digest your emails.',
				videoUrl: mockVideo,
				research,
				image: EmailIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Email_A-B_testing_1_mvv9zq.pdf',
			};
		case Research.CallToAction:
			return {
				title: getResearchName(research),
				description:
					'Figure out whether your CTA should be above the fold, at the bottom of your page, or somewhere completely different.',
				videoUrl: mockVideo,
				research,
				image: CallToActionIcon,
				researchPdfUrl:
					'https://d3gwdsy0ns7b47.cloudfront.net/research_pixis/uiux-research/Call_To_Action_A-B_Testing_1_wygkxn.pdf',
			};
	}
};

export const mapTemplatesResponseToResearchModules = (templates: TemplateResponse[]): ResearchModuleT[] => {
	return sortBy(
		({ order }) => order,
		templates.reduce((modules = [], { researchModule }) => {
			if (modules.some((m) => m.type.includes(researchModule))) {
				return modules;
			}
			return [...modules, getResearchModuleInfoByType(researchModule, templates)];
		}, [] as ResearchModuleT[])
	);
};

export const mapTemplatesResponseToResearchTemplates = (templates: TemplateResponse[]): ResearchTemplate[] => {
	return map(
		(template) =>
			assign(
				getResearchTemplateInfoByType(template.researchType),
				pickAll(['questions', 'id', 'researchModule', 'subResearch'], template)
			),
		templates
	) as ResearchTemplate[];
};

export const putCreateFromScratchToTop = (researchTemplates: ResearchTemplate[]) => {
	const [createFromScratchTemplates, othersTemplates]: [ResearchTemplate[], ResearchTemplate[]] = [[], []];
	researchTemplates.forEach((template) => {
		// @ts-ignore
		if (template.research === Research.None || template.research === template.researchModule) {
			return createFromScratchTemplates.push(template);
		}
		othersTemplates.push(template);
	});
	return createFromScratchTemplates.concat(othersTemplates);
};

export const getNumberOfResearches = (templates: TemplateResponse[], type: ResearchModule): number => {
	return templates.filter((t) => t.researchModule === type).length;
};

export const isUiUxUserTestingResearch = (type: ResearchModule) => {
	return [ResearchModule.WebsiteTesting, ResearchModule.PrototypeTesting, ResearchModule.ApplicationTesting].includes(
		type
	);
};
