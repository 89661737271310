import { SurveyAction, SurveyBuilderState } from '../../shared/types';
import { all, put, putResolve, select } from 'redux-saga/effects';
import { StoreState } from '../../../../index';
import { range, without } from 'ramda';
import {
	changeQuestionInfo,
	changeSectionSettingsAndLogics,
	moveQues,
	removeQuestionPipe as deleteQuestionPipe,
	setDisplayLogic as updateDisplayLogic,
	setPopup,
	setSkipLogic,
} from '../actions';
import { getCleanupActions } from '../../utils';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { MoveQues } from '../types';
import axios from '@utils/axios';
import { surveyQuestionsApiUrl, surveySectionOptionAssetsApiUrl } from './consts';
import { MENTION_PREFIX_REGEX, QUESTION_UUID_REGEX } from '../../../../../shared/utils/mentionUtils';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getModifiedQuestions, updateQuestionsActionAndMoved, updateSectionActionAndMoved } from './utils';
import { getQuestionsOrderWithoutSections, getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { fulfill, pend } from 'store/promise/actions';

export function* handleReorder(action: MoveQues) {
	const {
		questions: quess,
		quesOrder: orders,
		surveys,
		surveyId,
	}: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
	let questions = quess;
	let quesOrder = orders;
	const { src, dest, type } = action.payload;
	let srcIndex = src.index;
	let destIndex = dest.index;
	let affected = without(
		[destIndex],
		srcIndex < destIndex ? range(srcIndex, destIndex) : range(destIndex, srcIndex + 1)
	).map((i) => quesOrder[i]);
	if (type === 'droppable-items') {
		const sourceCategoryId = src.droppableId;
		const destinationCategoryId = dest.droppableId;
		questions = getQuestionsWithoutFunctions(quess);
		quesOrder = getQuestionsOrderWithoutSections(quess);
		if (sourceCategoryId === destinationCategoryId) {
			const orderPosition = quess[sourceCategoryId]?.orderPosition;
			srcIndex = orderPosition + src.index - 1;
			destIndex = orderPosition + dest.index - 1;
			affected = without(
				[destIndex],
				srcIndex < destIndex ? range(srcIndex, destIndex) : range(destIndex, srcIndex + 1)
			).map((i) => quesOrder[i]);
		}
		if (quess[destinationCategoryId]?.metaData) {
			const orderPosition = quess[sourceCategoryId]?.orderPosition;
			srcIndex = orderPosition + src.index - 1;
			destIndex = quess[destinationCategoryId]?.orderPosition + dest.index - 1;
			affected = without(
				[destIndex],
				srcIndex < destIndex ? range(srcIndex, destIndex) : range(destIndex, srcIndex + 1)
			).map((i) => quesOrder[i]);
		} else {
			const orderPosition = quess[sourceCategoryId]?.orderPosition;
			srcIndex = orderPosition + src.index - 1;
			destIndex = quess[destinationCategoryId]?.orderPosition;
			affected = without(
				[destIndex],
				srcIndex < destIndex ? range(srcIndex, destIndex) : range(destIndex, srcIndex + 1)
			).map((i) => quesOrder[i]);
		}
	}
	// const moved = questions[quesOrder[destIndex]].questions?.[0]?.[quesOrder[destIndex]];
	const moved = questions[quesOrder[destIndex]];

	let actions: SurveyAction[] = [];
	if (!!moved?.metaData) {
		updateSectionActionAndMoved(moved, actions, affected, changeSectionSettingsAndLogics, questions);
		if (Object.values(moved?.questions).length) {
			Object.values(moved?.questions).forEach((question: any) => {
				updateQuestionsActionAndMoved(
					question,
					actions,
					affected,
					setSkipLogic,
					updateDisplayLogic,
					changeQuestionInfo,
					deleteQuestionPipe,
					questions
				);
			});
		}
	}
	if (!moved?.metaData) {
		updateQuestionsActionAndMoved(
			moved,
			actions,
			affected,
			setSkipLogic,
			updateDisplayLogic,
			changeQuestionInfo,
			deleteQuestionPipe,
			questions
		);
	}
	actions.push(
		...getCleanupActions(
			moved.id,
			affected.map((id) => questions[id]),
			questions
		)
	);

	let updates = {
		questionId: moved.id,
		questionType: moved.type,
		orderPosition: moved.orderPosition,
		pageBreak: moved.pageBreak,
	};

	if (moved.orderPosition === 1) {
		updates.pageBreak = true;
	}
	const findPrevQuestion = questions[quesOrder[dest.index - 1]];
	if (findPrevQuestion?.metaData) {
		updates.pageBreak = true;
	}

	try {
		if (!!moved?.metaData || !!quess[src.droppableId]?.metaData) {
			const questionnaireId = surveys[surveyId]?.questionnaireId;
			// yield axios.patch(getApiEndPointUrl(`${surveySectionOptionAssetsApiUrl}/move?questionaireId=${questionnaireId}`), {
			// 	...moved,
			// 	questions: Object.values(moved.questions || {}),
			// });
			const modifyQuestions = getModifiedQuestions(quess);
			yield putEffect(pend(moveQues));
			yield axios.patch(
				getApiEndPointUrl(`${surveySectionOptionAssetsApiUrl}/move?questionaireId=${questionnaireId}`),
				{
					questions: modifyQuestions,
					surveyId,
				}
			);
			yield putEffect(fulfill(moveQues));
		} else {
			yield putEffect(pend(moveQues));
			yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, moved.id), updates);
			yield putEffect(fulfill(moveQues));
		}
		for (const action of actions) {
			yield putEffect(action);
		}
		if (actions.length) {
			yield putEffect(setPopup('Question piping has been modified after reorder.'));
		}
	} catch (e) {
		console.log('e', e);
	}
}
