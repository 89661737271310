const ROOT = '';

export const routePaths = {
	home: {
		root: `${ROOT}` || '/',
	},
	auth: {
		root: `${ROOT}/auth`,
		sub: {
			login: '/sign-in',
			register: '/sign-up',
			resetPassword: '/reset-password',
			token: '/token',
		},
	},
	creatorAuth: {
		root: `${ROOT}/creators/auth`,
		sub: {
			login: '/sign-in',
			register: '/sign-up',
			confirmEmail: '/confirm-email',
			// resetPassword: '/reset-password',
			// token: '/token',
		},
	},
	creator: {
		root: `${ROOT}/creators`,
		sub: {
			details: '/details',
			dashboard: '/',
			pastCampaigns: '/past-campaigns',
			pastCampaignView: '/past-campaigns/:campaignCreatorId',
			helpDesk: '/help-desk',
			campaignDetails: '/campaigns/:campaignCreatorId',
			login: '/auth/sign-in',
			register: '/auth/sign-up',
			// resetPassword: '/auth/reset-password',
		},
	},
	users: {
		root: `${ROOT}/users`,
		sub: {
			dashboard: '/',
			onboard: '/onboard',
			invite: '/add-user',
			inviteSuccess: '/invite-success',
			thankYou: '/thankyou',
		},
	},
	rolesPermission: {
		root: `${ROOT}/roles-permission`,
		sub: {
			dashboard: '/',
		},
	},
	transaction: {
		root: `${ROOT}/transaction`,
		sub: {
			dashboard: '/',
		},
	},
	billing: {
		root: `${ROOT}/billing`,
		sub: {
			dashboard: '/',
			payment: '/payment/:surveyId',
		},
	},
	analytics: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/survey/:surveyId/dashboard`,
		sub: {
			generalAnalytics: '/general-analytics/reports/:reportId',
			researchAnalytics: '/research-analytics',
			videoRecordings: '/video-recordings',
			cohortDeepDive: '/cohort-deep-dive',
			funnelAnalyzer: '/funnel-analyzer',
			moderateTesting: '/moderate-testing',
		},
	},
	survey: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/survey`,
		sub: {
			list: '/',
			create: '/new',
			research: '/:surveyId/research',
			design: '/:surveyId/design',
			// TODO: Design Revert
			// designUI: '/:surveyId/design-ui',
			moderate: '/:surveyId/moderate',
			targeting: '/:surveyId/targeting',
			submit: '/:surveyId/submit',
			success: '/:surveyId/success',
			preview: '/',
		},
	},
	approval: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/approval`,
		sub: {
			list: '/',
			preview: '/:surveyId',
			approve: '/:surveyId/approve',
		},
	},
	library: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/library`,
	},
	brands: {
		root: `${ROOT}/workspace/:workspaceId/brand`,
		sub: {
			create: '/new',
			brandSetup: '/:brandId',
			macroTargeting: '/:brandId/targeting',
			profileSetup: '/:brandId',
			profileCompetitor: '/competitor',
			profileTargeting: '/targeting',
		},
	},
	lite: {
		root: `${ROOT}/workspace/:workspaceId/lite-brand`,
		sub: {
			setUp: '/setup',
			surveyCover: '/:brandId/cover',
			allTemplates: '/:brandId/survey/:surveyId/all-templates',
		},
	},
	brandSpace: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/brand-space`,
	},
	campaign: {
		root: `${ROOT}/workspace/:workspaceId/brand/:brandId/campaigns`,
		sub: {
			getStarted: '/get-started',
			newSetup: '/new/setup',
			setup: '/:campaignId/setup',
			productSecifications: '/:campaignId/product-specifications',
			targeting: '/:campaignId/targeting',
			references: '/:campaignId/references',
			create: '/cover',
			newCampaignOld: '/new',
			campaignSetup: '/:campaignId',
			campaignPreview: '/:campaignId/videos',
			creatorSelection: '/:campaignId/selection',
			viewDetails: '/:campaignId/view',
			creatorSelectionOld: '/selection',
			campaignList: '/',
			veiwDetailsOld: '/view',
		},
	},
	workspaces: {
		root: `${ROOT}/workspace`,
		sub: {
			dashboard: '/',
			welcome: '/onboard',
			createStepTwo: '/create-step-two',
			createStepOne: '/create-step-one',
			success: '/success/:workspaceId',
		},
	},
	profile: {
		root: `${ROOT}/profile`,
		sub: {
			personalInformation: '/',
		},
	},
	questionnaire: {
		root: `${ROOT}/questionnaire/:id/:nicheId`,
	},
	moderateTesting: {
		root: `${ROOT}/moderateTesting/:id/:nicheId`,
	},
	moderateTestingPreview: {
		root: `${ROOT}/moderateTesting/:id/preview`,
	},
	surveyDummy: {
		root: `${ROOT}/surveyDummy/:surveyId`,
	},
	policies: {
		root: '/policies',
		sub: {
			privacy: '/privacy',
			dataCollection: '/data-collection',
		},
	},
} as const;
