const ALLOW_PAYMENT_PAGE_VIEW_SESSION_KEY = '_a';
export const allowPaymentPageView = () => sessionStorage.setItem(ALLOW_PAYMENT_PAGE_VIEW_SESSION_KEY, 'true');
export const disallowPaymentPageView = () => sessionStorage.removeItem(ALLOW_PAYMENT_PAGE_VIEW_SESSION_KEY);

export const checkIfPaymentPageViewAllowed = () => {
	return (
		sessionStorage.hasOwnProperty(ALLOW_PAYMENT_PAGE_VIEW_SESSION_KEY) &&
		!!sessionStorage.getItem(ALLOW_PAYMENT_PAGE_VIEW_SESSION_KEY)
	);
};
