import axios from '@utils/axios';

import { CreateModerateSlotAction } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { setSurveyError } from '../../shared/actions';
import { questionnairesApiEndpoint } from './consts';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { createModerateSlotAction, setSurveyAction } from '../actions';
import { mapQuestionnaireResponseToSurvey } from './utils';
import { pend, fulfill, reject } from '../../../../promise/actions';

export function* createModerateSlot(action: CreateModerateSlotAction) {
	const { surveyId, slotId, slotDetail } = action.payload.info;
	try {
		yield putEffect(pend(createModerateSlotAction)); //loader
		const { data } = yield axios.patch(
			getApiEndPointUrl(questionnairesApiEndpoint, surveyId, 'moderated-testing/slots', slotId),
			slotDetail
		);
		yield putEffect(setSurveyAction(mapQuestionnaireResponseToSurvey(data)));
		yield putEffect(fulfill(createModerateSlotAction, data));
	} catch (e: any) {
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(createModerateSlotAction, getApiError(e)));
	}
}
