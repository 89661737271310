// import axios from '@utils/axios';;
import { takeLeading } from 'redux-saga/effects';

import { putEffect } from '../../../shared/utils/putEffect';
// import { surveyEndPoint } from './const';

import { getApiError } from '../../../shared/utils/getApiError';

// import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { fulfill, pend, reject } from '../../promise/actions';
import { ReadSurveysCount, ReadSurveysCountPayload, READ_SURVEYS_COUNT, SurveyCountSchema } from './types';
import { readSurveysCount, readSurveysCountFulfilled } from './actions';
import { surveyEndPoint } from './const';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import axios from '@utils/axios';
import { validate } from '../../../shared/utils/jsonschema';

function readSurveysCountAPI(brand: ReadSurveysCountPayload) {
	return axios.get(getApiEndPointUrl(surveyEndPoint), {
		params: brand,
	});
}
function* readSurveysCountSaga(action: ReadSurveysCount) {
	try {
		yield putEffect(pend(readSurveysCount));
		const response = yield readSurveysCountAPI(action.payload);
		yield putEffect(readSurveysCountFulfilled(validate(response.data.count, SurveyCountSchema)));
		yield putEffect(fulfill(readSurveysCount));
	} catch (e) {
		yield putEffect(reject(readSurveysCount, getApiError(e)));
	}
}

function* getReadSurveysCountWatcher() {
	yield takeLeading(READ_SURVEYS_COUNT, readSurveysCountSaga);
}

export const brandSurveySagas = [getReadSurveysCountWatcher];
