import { QuesState } from '../types/QuesTypes';

export const MENTION_PREFIX = '~*~';
// export const QUESTION_UUID_REGEX = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}?(:[0-9a-fA-F]*)/g;
export const QUESTION_UUID_REGEX =
	/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(:[ A-Za-z0-9_@./#&+-]*)?/g;
export const MENTION_PREFIX_REGEX = /~\*~/g;

export const replaceMentionText = (text: string, questions: { [k: string]: QuesState }, isForPDF?: boolean): string => {
	// isForPDF is used only when we download the pdf of surveys
	if (!text || !text.includes(MENTION_PREFIX)) return text || '';
	return [...(text.matchAll(QUESTION_UUID_REGEX) as any)].reduce((p, [mentionId]) => {
		let id = mentionId.split(':')[0];
		let changedText = !!isForPDF
			? `[#Pipe Answers(Q${questions[id]?.orderPosition}.)] `
			: `#Pipe Answers(Q${questions[id]?.orderPosition}.) `;
		return p.replace(mentionId, changedText).replace(MENTION_PREFIX_REGEX, '');
	}, text) as string;
};

export const removeMentionTextMarkers = (text: string, mentions: any) => {
	// let t;
	// t = text.replace(/~\*~/g, '');
	// t = t.replaceAll(QUESTION_UUID_REGEX, '');
	return [...(text.matchAll(QUESTION_UUID_REGEX) as any)].reduce((p, [mentionId]) => {
		return p.replace(mentionId, '').replace(MENTION_PREFIX_REGEX, '');
	}, text) as string;
};
export const extractMentionIds = (text: string) => {
	return [...(text.matchAll(QUESTION_UUID_REGEX) as any)].map((mention) => {
		return {
			mention: mention[0].split(':')[0],
			column: mention[0].split(':')[1],
			index: mention.index,
		};
	});
};
