import { QuesState } from '../../../../shared/types/QuesTypes';
import { ReduxReducer } from '../../../../shared/types/ReduxReducer';
import { MatrixAction, MatrixActionType } from '../matrixQues/types';
import { MultipleChoiceAction, MultipleChoiceActionType } from '../questionOptions/types';
import { MultipleOpinionScaleAction, MultipleOpinionScaleActionType } from '../questionSections/types';
import {
	CREATE_SURVEY,
	CREATE_SURVEY_FROM_RESEARCH,
	CreateSurveyAction,
	CreateSurveyFromResearchesAction,
	GET_SURVEY,
	GetSurveyAction,
	SET_ACTIVE_SURVEY,
	SET_SURVEY,
	SET_SURVEY_INFO,
	SET_SURVEY_GAME,
	SetActiveSurveyAction,
	SetSurveyAction,
	SetSurveyInfoAction,
	SetSurveyGameAction,
	Survey,
	UpdateSurveyGamesAction,
	UPDATE_SURVEY,
	UPDATE_SURVEY_DATA,
	UpdateSurveyAction,
	UpdateGlobalSettingsAction,
	UPDATE_GLOBAL_SETTINGS,
	UPDATE_GLOBAL_SETTINGS_SUCCESS,
	SCHEDULE_SURVEY,
	ScheduleSurveyAction,
	GetPublicSurveyAction,
	GET_PUBLIC_SURVEY,
	CREATE_MODERATE_SLOT,
	CreateModerateSlotAction,
	SetSurveyDesignThemeInfoAction,
	SET_SURVEY_DESIGN_THEME,
	ThemeDesign,
	SurveyThemeDesign,
} from '../surveyInfo/types';
import {
	ADD_QUES,
	ADD_QUES_AT_INDEX,
	ADD_BULK_QUES,
	ADD_QUESTION_PIPE,
	AddQues,
	AddQuesAtIndex,
	AddQuestionPipe,
	CHANGE_QUESTION_INFO,
	CHANGE_QUESTION_SETTINGS,
	CHANGE_SURVEY_SECTION_SETTINGS,
	ChangeQuestionInfoAction,
	ChangeQuestionSettingsAction,
	COPY_QUES,
	CopyQues,
	DELETE_QUES,
	DeleteQues,
	INSERT_QUESTION,
	INSERT_MODERATED_QUESTION,
	INSERT_QUESTIONS,
	InsertQuestion,
	InsertModeratedQuestion,
	InsertQuestions,
	MOVE_QUES,
	MoveQues,
	REMOVE_QUESTION_PIPE,
	REMOVE_SKIP_LOGIC,
	RemoveQuestionPipe,
	RemoveSkipLogic,
	SELECT_QUES,
	SelectQues,
	SET_DISPLAY_LOGIC,
	SET_POPUP,
	SET_QUESTION,
	SET_SKIP_LOGIC,
	SetDisplayLogic,
	SetMandateLogic,
	SetPopup,
	SetQuestion,
	SetSkipLogic,
	SET_MANDATE_LOGIC,
	APPROVE_EDIT,
	ApproveEdit,
	CREATE_NEW_SECTION_TO_SURVEY,
	AddSection,
	ADDED_SECTION_SUCCESS,
	DELETE_SURVEY_SECTION,
	DeleteSurveySection,
	CHANGE_SECTION_INFO,
	ChangeSectionInfoAction,
	SET_SECTION,
	SetSection,
	SET_SECTION_QUESTION,
	SetSectionQuestion,
	UPDATE_INFO,
	UpdateInfo,
	ChangeSectionSettingsDisplayAction,
	CopySurveySection,
	COPY_SURVEY_SECTION,
	AddBulkQues,
} from '../sharedQuestions/types';
import { ReduxAction } from '../../../../shared/types/ReduxAction';
import { GET_SURVEY_BRAND, GetSurveyBrand, SET_SURVEY_BRAND, SetSurveyBrand } from '../../surveyBrand/types';
import { Research } from '../../../../shared/enums/Research';
import { ResearchModule } from '../../../../shared/enums/ResearchModule';
import { InsertSection } from '../sharedQuestions/types';
import { QuestionsStates, SectionsInSurvey } from 'shared/types/SurveyResponses';
export const SET_SURVEY_ERROR = 'SET_ERROR';
export const RELOAD_STATE = 'RELOAD_STATE';

export type SetSurveyErrorAction = ReduxAction<typeof SET_SURVEY_ERROR, { error: string }>;
export type ReloadStateAction = ReduxAction<typeof RELOAD_STATE, null>;

export type SurveyQuestions<T = QuesState> = {
	[id: string]: T;
};

export type ScreenerQuestion<T> = {
	[id: string]: T;
};

// type definitions used in state
export type SurveyBuilderState<T = QuestionsStates> = {
	surveyId: string;
	name: string;
	description: string;
	howToPlay: string;
	questions: SectionsInSurvey<T>;
	moderatedQuestion: SectionsInSurvey<T>;
	quesOrder: string[];
	currQuesId: string;
	hasWelcomeScreen: boolean;
	hasVideoUploadQues: boolean;
	requestInProgress: boolean;
	research: Research;
	error: string;
	popup: string;
	allowEditAndResubmit: boolean;
	usePyxisLogo: boolean;
	isRightOriented: boolean;

	gamified: boolean;
	theme: string;
	surveys: {
		[id: string]: Survey;
	};
	testingGoal?: string;
	testingTargetUrls: string[];
	researchModule: ResearchModule | undefined;
	creator: { id: string; firstName: string; lastName: string; [x: string]: any } | undefined;
	isModerated: boolean;
	surveyTemplateSetting: any;
};

export type SurveyAction =
	| AddQues
	| AddSection
	| InsertSection
	| DeleteSurveySection
	| SetSection
	| AddQuesAtIndex
	| ApproveEdit
	| SelectQues
	| CopyQues
	| DeleteQues
	| MoveQues
	| MultipleChoiceAction
	| MultipleOpinionScaleAction
	| MatrixAction
	| SetDisplayLogic
	| SetMandateLogic
	| RemoveQuestionPipe
	| AddQuestionPipe
	| RemoveSkipLogic
	| SetSkipLogic
	| SetQuestion
	| SetPopup
	| CopySurveySection
	| InsertQuestion
	| InsertModeratedQuestion
	| InsertQuestions
	| UpdateSurveyGamesAction
	| CreateSurveyAction
	| UpdateSurveyAction
	| CreateSurveyFromResearchesAction
	| SetActiveSurveyAction
	| SetSurveyErrorAction
	| SetSurveyInfoAction
	| GetSurveyAction
	| GetPublicSurveyAction
	| SetSurveyAction
	| SetSurveyGameAction
	| ChangeQuestionInfoAction
	| ChangeQuestionSettingsAction
	| GetSurveyBrand
	| SetSurveyBrand
	| ReloadStateAction
	| UpdateGlobalSettingsAction
	| CreateModerateSlotAction
	| ChangeSectionInfoAction
	| SetSectionQuestion
	| UpdateInfo
	| ChangeSectionSettingsDisplayAction
	| SetSurveyDesignThemeInfoAction
	| ScheduleSurveyAction
	| AddBulkQues;

export type SurveyActionType =
	| typeof ADD_QUES
	| typeof CREATE_NEW_SECTION_TO_SURVEY
	| typeof ADDED_SECTION_SUCCESS
	| typeof DELETE_SURVEY_SECTION
	| typeof COPY_SURVEY_SECTION
	| typeof SET_SECTION
	| typeof ADD_QUES_AT_INDEX
	| typeof ADD_BULK_QUES
	| typeof APPROVE_EDIT
	| typeof SELECT_QUES
	| typeof COPY_QUES
	| typeof DELETE_QUES
	| typeof MOVE_QUES
	| typeof SET_SKIP_LOGIC
	| typeof SET_MANDATE_LOGIC
	| typeof REMOVE_SKIP_LOGIC
	| typeof ADD_QUESTION_PIPE
	| typeof REMOVE_QUESTION_PIPE
	| typeof SET_DISPLAY_LOGIC
	| typeof SET_QUESTION
	| typeof SET_POPUP
	| typeof CREATE_SURVEY
	| typeof UPDATE_SURVEY
	| typeof UPDATE_SURVEY_DATA
	| typeof SET_SURVEY_ERROR
	| typeof SET_SURVEY_INFO
	| typeof SET_SURVEY_GAME
	| typeof GET_SURVEY
	| typeof GET_PUBLIC_SURVEY
	| typeof SET_SURVEY
	| typeof INSERT_QUESTION
	| typeof INSERT_MODERATED_QUESTION
	| typeof INSERT_QUESTIONS
	| typeof CHANGE_QUESTION_SETTINGS
	| typeof CHANGE_SURVEY_SECTION_SETTINGS
	| typeof CHANGE_QUESTION_INFO
	| typeof GET_SURVEY_BRAND
	| typeof SET_SURVEY_BRAND
	| typeof CREATE_SURVEY_FROM_RESEARCH
	| typeof SET_ACTIVE_SURVEY
	| typeof RELOAD_STATE
	| typeof UPDATE_GLOBAL_SETTINGS
	| typeof CREATE_MODERATE_SLOT
	| typeof UPDATE_GLOBAL_SETTINGS_SUCCESS
	| typeof SCHEDULE_SURVEY
	| typeof CHANGE_SECTION_INFO
	| typeof SET_SECTION_QUESTION
	| typeof UPDATE_INFO
	| typeof SET_SURVEY_DESIGN_THEME
	| MultipleChoiceActionType
	| MultipleOpinionScaleActionType
	| MatrixActionType;

export type SurveyReducer = ReduxReducer<SurveyBuilderState, SurveyAction>;
