import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export type Niche = {
	id: string;
	country: string;
	state: string;
	region?: string;
	city?: string;
	ageFrom: number;
	ageTo: number;
	gender: string;
	incomeFrom: number;
	incomeTo: number;
	interests: string[];
	name: string;
	relationshipStatuses: string[];
	macroTargetingGroupId: string;
	numberOfRespondents: number;
	isUsingPyxisSmartPanel: boolean;
	socialNetwork: string[];
	isSelected?: boolean;
	deviceType: string;
	employmentStatuses: string[];
	expertiseLevel: string;
	industries: string[];
	totalExperts: number;
	totalParticipants: number;
	languages: string[];
	jobRoles: string[];
	audiencePanel: string[];
};
const NicheSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
	},
};
export const NichesSchema: JSONSchema = {
	type: 'array',
	items: NicheSchema,
};
export const CREATE_NICHES = 'CREATE_NICHES';
export const CREATE_NICHES_FULFILLED = 'CREATE_NICHES_FULFILLED';

export type CreateNichePayload = Niche & { id?: string; region?: string; city?: string };

export type CreateNiches = ReduxAction<typeof CREATE_NICHES, CreateNichePayload[]>;
export type CreateNichesFulfilled = ReduxAction<typeof CREATE_NICHES_FULFILLED, Niche[]>;

export const PUT_NICHES = 'PUT_NICHES';
export const PUT_NICHES_FULFILLED = 'PUT_NICHES_FULFILLED';

export type PutNichePayload = Niche & { id?: string; region?: string; city?: string };

export type PutNiches = ReduxAction<typeof PUT_NICHES, CreateNichePayload[]>;
export type PutNichesFulfilled = ReduxAction<typeof PUT_NICHES_FULFILLED, Niche[]>;

export const UPDATE_NICHEs = 'UPDATE_NICHES';
export const UPDATE_NICHES_FULFILLED = 'UPDATE_NICHES_FULFILLED';

export type UpdateNichePayload = Omit<Niche, 'macroTargetingGroupId'>;

export type UpdateNiches = ReduxAction<typeof UPDATE_NICHEs, UpdateNichePayload[]>;
export type UpdateNichesFulfilled = ReduxAction<typeof UPDATE_NICHES_FULFILLED, Niche[]>;

export const DELETE_NICHES = 'DELETE_NICHES';
export const DELETE_NICHES_FULFILLED = 'DELETE_NICHES_FULFILLED';

export type DeleteNiches = ReduxAction<typeof DELETE_NICHES, Niche[]>;
export type DeleteNichesFulfilled = ReduxAction<typeof DELETE_NICHES_FULFILLED, Niche[]>;

export const READ_NICHES = 'READ_NICHES';
export const READ_NICHES_FULFILLED = 'READ_NICHES_FULFILLED';

export type ReadNichesPayload = { macroTargetingGroupId: string };

export type ReadNiches = ReduxAction<typeof READ_NICHES, ReadNichesPayload>;
export type ReadNichesFulfilled = ReduxAction<typeof READ_NICHES_FULFILLED, Niche[]>;

export type NicheAction =
	| CreateNiches
	| CreateNichesFulfilled
	| ReadNiches
	| ReadNichesFulfilled
	| UpdateNiches
	| UpdateNichesFulfilled
	| DeleteNiches
	| DeleteNichesFulfilled
	| PutNiches
	| PutNichesFulfilled;

export type NicheReducer = ReduxReducer<BrandState, NicheAction>;
