import { MoveQuesOption } from '../types';
import { select } from 'redux-saga/effects';
import { MultipleChoiceQuesState } from '../../../../../shared/types/QuesTypes';
import axios from '@utils/axios';
import { surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { processMultipleChoiceQuestion } from '../../surveyInfo/sagas/utils';

export function* handleOptionReorder(action: MoveQuesOption) {
	const { quesId, destIndex, isBulkUpdation = false, updatedOptions = [] } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	const question = questions[quesId] as MultipleChoiceQuesState;
	if (!question) return;

	const optionId = question.settings.optionOrder[destIndex];
	try {
		if (!!isBulkUpdation) {
			const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl), updatedOptions);
			yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
		} else {
			const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId), {
				questionId: question.id,
				orderPosition: destIndex + 1,
			});
			yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
		}
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
