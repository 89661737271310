import { SurveyBuilderState } from '../../shared/types';
import { select } from 'redux-saga/effects';
import { StoreState } from '../../../../index';
import { filter, set } from 'shades';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { changeQuestionInfo, changeQuestionSettings, setPopup, setQuestion } from '../actions';
import { QUES_TYPE } from '../../../../../shared/consts/quesType';
import { clearOptionsByIds, deleteQuesOption } from '../../questionOptions/actions';
import { QuestionPipe } from '../../../../../shared/types/QuestionLogic';
import { AddQuestionPipe, RemoveQuestionPipe } from '../types';
import { removeOptionsForMatrixType } from 'components/SurveyBuilder/QuestionLogic/PipeAnswer/utils';
import { notification } from 'antd';
import { fulfill, pend, reject } from 'store/promise/actions';
import { getApiEndPointUrl } from 'shared/utils/getApiEndpointUrl';
import { surveyQuestionOptionsApiUrl } from './consts';
import { mapPipeAnswerTypeToValue } from '../../utils';

import { processMultipleChoiceQuestion } from '../../surveyInfo/sagas/utils';
import { getApiError } from 'shared/utils/getApiError';
import axios from '@utils/axios';
import { OptionType } from 'shared/types/QuesTypes';
import { quickFetch } from '@utils/fetch';
import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';

export function* addQuestionPipe(action: AddQuestionPipe) {
	const { currQuesId, questions: ques }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
	const { id, pipe } = action.payload;
	const questions = getQuestionsWithoutFunctions(ques);
	const question = questions[id];
	const isSectionQuestion: boolean = !ques[currQuesId];
	if (!question) return;
	if (Object.values(questions).find((q: any) => q.type === QUES_TYPE.MATRIX_MATCH_QUES && currQuesId === q.id)) {
		const filterMatrixType: any = Object.values(questions).find(
			(question: any) => question.type === QUES_TYPE.MATRIX_MATCH_QUES && currQuesId === question?.id
		);
		const options = Object.values(filterMatrixType['options']) as any;
		if (!Object.keys(options).length) return notification.warn({ message: 'At least 1 row is required' });
		if (pipe.pipeType === 'rows') {
			// yield removeOptionsForMatrixType(currQuesId, options, putEffect);
			const filteredOptions = options.filter(({ optionType }) => optionType !== 'pipe');
			yield Promise.all(
				filteredOptions?.map((option) =>
					quickFetch<any>(getApiEndPointUrl('question-options', option.id), { method: 'DELETE' })
				)
			).then(() => {
				putEffect(
					clearOptionsByIds(
						currQuesId,
						options.map((o) => o.id)
					)
				);
			});
			try {
				yield putEffect(pend(addQuestionPipe));
				const { data } = yield axios.post(getApiEndPointUrl(surveyQuestionOptionsApiUrl), {
					questionId: id,
					pipeId: pipe?.pipeId,
					optionType: pipe ? OptionType.Pipe : OptionType.Normal,
					pipeValue: mapPipeAnswerTypeToValue(pipe?.pipeAnswer),
					pipeType: pipe?.pipeType,
				});
				// incase of section's question need to set question first to section than set it as normal question
				// if (isSectionQuestion) {
				// 	ques[question?.questionnaireSectionId].ques[question.id] = processMultipleChoiceQuestion(data, question);
				// 	yield putEffect(setQuestion(question?.questionnaireSectionId, ques[question?.questionnaireSectionId]));
				// } else {
				// 	yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
				// }
				// question.settings.noneOption = false;
				yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
				yield putEffect(fulfill(addQuestionPipe));
			} catch (e) {
				yield putEffect(reject(addQuestionPipe, getApiError(e)));
				yield putEffect(setPopup(getApiError(e)));
			}
		}
	}
	if (pipe.pipeType !== 'rows') {
		try {
			yield putEffect(pend(addQuestionPipe));
			const { data } = yield axios.post(getApiEndPointUrl(surveyQuestionOptionsApiUrl), {
				questionId: id,
				pipeId: pipe?.pipeId,
				optionType: pipe ? OptionType.Pipe : OptionType.Normal,
				pipeValue: mapPipeAnswerTypeToValue(pipe?.pipeAnswer),
				pipeType: pipe?.pipeType,
			});
			if (question.type === QUES_TYPE.MATRIX_MATCH_QUES && pipe.pipeType === 'columns') {
				yield putEffect(changeQuestionSettings(id, question.type, { addIcon: false }));
				if (question?.column.some(({ creativeUrl }) => creativeUrl?.length)) {
					yield putEffect(
						changeQuestionInfo(id, question.type, {
							column: question?.column?.map((col) => ({ ...col, creativeUrl: '' })),
						})
					);
				}
			}
			// incase of section's question need to set question first to section than set it as normal question
			// if (isSectionQuestion) {
			// 	ques[question?.questionnaireSectionId].questions[question?.id] = processMultipleChoiceQuestion(data, question);
			// 	yield putEffect(setQuestion(question?.questionnaireSectionId, ques[question?.questionnaireSectionId]));
			// 	yield putEffect(fulfill(addQuestionPipe));
			// } else {
			// 	yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
			// 	yield putEffect(fulfill(addQuestionPipe));
			// }
			yield putEffect(setQuestion(question.id, processMultipleChoiceQuestion(data, question)));
			yield putEffect(fulfill(addQuestionPipe));
		} catch (e) {
			yield putEffect(reject(addQuestionPipe, getApiError(e)));
			yield putEffect(setPopup(getApiError(e)));
		}
	}
}

export function* removeQuestionPipe(action: RemoveQuestionPipe) {
	const { id, pipeId } = action.payload;
	const { questions: ques }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
	const questions = getQuestionsWithoutFunctions(ques);
	const isSectionQuestion: boolean = !ques[id];
	const question = set(
		'logic',
		'pipeAnswer'
	)(filter((a: QuestionPipe) => a.pipeId !== pipeId)(questions[id].logic?.pipeAnswer || []))(questions[id]);

	// if (isSectionQuestion) {
	// 	ques[question?.questionnaireSectionId].questions[question?.id] = question;
	// 	yield putEffect(setQuestion(question?.questionnaireSectionId, ques[question?.questionnaireSectionId]));
	// } else {
	// 	yield putEffect(setQuestion(id, question));
	// }
	yield putEffect(setQuestion(id, question));
	if (
		question.type === QUES_TYPE.MULTIPLE_CHOICE_QUES ||
		question.type === QUES_TYPE.CREATIVE_QUES ||
		question.type === QUES_TYPE.MATRIX_MATCH_QUES ||
		question.type === QUES_TYPE.RANKING_QUES
	) {
		let updatedOptions: any = Object.entries(question.options);
		if (question.type === QUES_TYPE.MATRIX_MATCH_QUES) {
			updatedOptions = Object.entries({ ...question.options, ...question.columnOptions });
		}
		const option = updatedOptions?.find(([, o]) => o.pipe && o.pipe.pipeId === pipeId);
		// TODO Send API Request
		if (option) {
			yield putEffect(deleteQuesOption(id, option[0], false, pipeId));
		}
	}
}
