import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandAsset, BrandAssetsSchema } from '../assets/types';
import { BrandColor, BrandColorsSchema } from '../colors/types';
import { BrandCompetitor, BrandCompetitorsSchema } from '../competitors/types';
import { BrandKeyword, BrandKeywordsSchema } from '../keywords/types';
import { BrandProductsSchema, BrandProductType } from '../products/types';
import { BrandState } from '../types';

export type Brand = {
	id: string;
	name: string;
	website: string;
	age: number;
	country: string;
	industry: string;
	emotionalAssociation?: number;
	functionalAssociation?: number;
	strongAssociation?: number;
	weakAssociation?: number;
	brandKeywords: BrandKeyword[];
	workspaceId: string;
	brandAssets: BrandAsset[];
	brandFonts: any[];
	brandColors: BrandColor[];
	products: BrandProductType[];
	competitors: BrandCompetitor[];
	macroTargetingGroupId?: string;
} & { [key: string]: any };

export const BrandSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: {
			anyOf: [{ type: 'string' }, { type: 'object', value: null }],
		},
		website: { type: 'string' },
		age: {
			anyOf: [{ type: 'number' }, { type: 'object', value: null }],
		},
		country: {
			anyOf: [{ type: 'string' }, { type: 'object', value: null }],
		},
		industry: {
			anyOf: [{ type: 'string' }, { type: 'object', value: null }],
		},
		emotionalAssociation: { type: 'number' },
		functionalAssociation: { type: 'number' },
		strongAssociation: { type: 'number' },
		weakAssociation: { type: 'number' },
		workspaceId: { type: 'string' },
		macroTargetingGroupId: {
			anyOf: [{ type: 'string' }, { type: 'object', value: null }],
		},
		brandAssets: BrandAssetsSchema,
		brandColors: BrandColorsSchema,
		brandKeywords: BrandKeywordsSchema,
		competitors: BrandCompetitorsSchema,
		products: BrandProductsSchema,
	},
};
export const BrandsSchema: JSONSchema = {
	type: 'array',
	items: BrandSchema,
};
export const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND';
export type SetCurrentBrandPayload = string;
export type SetCurrentBrand = ReduxAction<typeof SET_CURRENT_BRAND, SetCurrentBrandPayload>;

export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRAND_FULFILLED = 'CREATE_BRAND_FULFILLED';

export type CreateBrandPayload = Omit<Brand, 'id'>;

export type CreateBrand = ReduxAction<typeof CREATE_BRAND, CreateBrandPayload>;
export type CreateBrandFulfilled = ReduxAction<typeof CREATE_BRAND_FULFILLED, Brand>;

export const READ_BRANDS = 'READ_BRANDS';
export const READ_BRANDS_FULFILLED = 'READ_BRANDS_FULFILLED';

export type ReadBrandsPayload = Omit<Brand, 'id'>;

export type ReadBrands = ReduxAction<typeof READ_BRANDS, ReadBrandsPayload>;
export type ReadBrandsFulfilled = ReduxAction<typeof READ_BRANDS_FULFILLED, Brand[]>;

export const READ_BRAND = 'READ_BRAND';
export const READ_BRAND_FULFILLED = 'READ_BRAND_FULFILLED';

export type ReadBrandPayload = Omit<Brand, 'workspaceId'>;

export type ReadBrand = ReduxAction<typeof READ_BRAND, ReadBrandPayload>;
export type ReadBrandFulfilled = ReduxAction<typeof READ_BRAND_FULFILLED, Brand>;

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_FULFILLED = 'UPDATE_BRAND_FULFILLED';
export type UpdateBrand = ReduxAction<typeof UPDATE_BRAND, Brand>;
export type UpdateBrandFulfilled = ReduxAction<typeof UPDATE_BRAND_FULFILLED, Brand>;

export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_FULFILLED = 'DELETE_BRAND_FULFILLED';
export type DeleteBrand = ReduxAction<typeof DELETE_BRAND, string>;
export type DeleteBrandFulfilled = ReduxAction<typeof DELETE_BRAND_FULFILLED, string>;

export type BrandDetailAction =
	| CreateBrand
	| CreateBrandFulfilled
	| ReadBrands
	| ReadBrandsFulfilled
	| ReadBrand
	| ReadBrandFulfilled
	| DeleteBrand
	| DeleteBrandFulfilled
	| UpdateBrand
	| UpdateBrandFulfilled
	| SetCurrentBrand;

export type BrandDetailReducer = ReduxReducer<BrandState, BrandDetailAction>;
