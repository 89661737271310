import { PaymentStatus } from '../../../shared/enums/PaymentStatus';
import { ApprovalStatus } from '../../approval/enums';
import { OrderByEnum } from '../../questionnaire/types';
import {
	ARCHIVE_SURVEY,
	ArchiveSurveyAction,
	DELETE_SURVEY,
	DeleteSurveyAction,
	DUPLICATE_SURVEY,
	DuplicateSurveyAction,
	GET_SURVEY_LIST,
	GetSurveyListAction,
	RELOAD_SURVEY_LIST_STATE,
	ReloadSurveyListStateAction,
	SET_SURVEY_LIST,
	SET_SURVEY_LIST_ERROR,
	SetSurveyListAction,
	SetSurveyListErrorAction,
	SurveyListItem,
	ChangeSurveyStatusToDraftAction,
	CHANGE_SERVEY_STATUS_TO_DRAFT,
	ChangeSurveyStatusToDraftFulFilled,
	CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED,
	ChangeSurveyStatusToDraftRejected,
	CHANGE_SERVEY_STATUS_TO_DRAFT_REJECTED,
	UPDATE_SURVEY_PAYMENT_STATUS,
	UpdateSurveyPaymentStatus,
	GetSurveyListSearchAction,
	GET_SURVEY_LIST_SEARCH,
	SET_SURVEY_LIST_TO_EXPORT,
	SetSurveyListActionToExport,
	GetImportSurveyListAction,
	GET_IMPORT_SURVEY_LIST,
	SetSurveyListFromISFAction,
	SET_SURVEY_LIST_FROM_ISF,
	GetSurveysFromImportIsfTableAction,
	GET_SURVEYS_FROM_IMPORT_ISF_TABLE,
} from './types';

export const getSurveyListAction: (
	brandId: string,
	status?: ApprovalStatus,
	offset?: number,
	limit?: number,
	isForExportSurvey?: boolean
) => GetSurveyListAction = (brandId, status, offset = 0, limit = 10, isForExportSurvey) => ({
	type: GET_SURVEY_LIST,
	payload: { brandId, status, limit, offset, isForExportSurvey },
});

export const getImportSurveyList: (assetId: string) => GetImportSurveyListAction = (assetId) => ({
	type: GET_IMPORT_SURVEY_LIST,
	payload: { assetId },
});

export const setSurveyListFromIsf: (data: any, isFileCorrupted: boolean) => SetSurveyListFromISFAction = (
	data,
	isFileCorrupted
) => ({
	type: SET_SURVEY_LIST_FROM_ISF,
	payload: { data, isFileCorrupted },
});

export const getSurveysFromImportIsfTableAction: (
	questionnaireIds: [],
	assetId: string
) => GetSurveysFromImportIsfTableAction = (questionnaireIds, assetId) => ({
	type: GET_SURVEYS_FROM_IMPORT_ISF_TABLE,
	payload: { questionnaireIds, assetId },
});

export const getSurveyListSearchAction: (
	workspaceId: string,
	search: string,
	offset?: number,
	limit?: number,
	order?: OrderByEnum,
	status?: ApprovalStatus
) => GetSurveyListSearchAction = (workspaceId, search, offset, limit, order, status) => ({
	type: GET_SURVEY_LIST_SEARCH,
	payload: { workspaceId, search, offset, limit, order, status },
});

export const setSurveyListAction: (items: SurveyListItem[], count: number) => SetSurveyListAction = (items, count) => {
	return {
		type: SET_SURVEY_LIST,
		payload: { items, count },
	};
};

export const setSurveyListToExport: (items: any, count: number, offset: any) => SetSurveyListActionToExport = (
	items,
	count,
	offset
) => {
	return {
		type: SET_SURVEY_LIST_TO_EXPORT,
		payload: { items, count, offset },
	};
};

export const setSurveyListErrorAction: (error: string) => SetSurveyListErrorAction = (error) => ({
	type: SET_SURVEY_LIST_ERROR,
	payload: { error },
});

export const deleteSurveyAction: (id: string, index?: number, brandId?: string) => DeleteSurveyAction = (
	id,
	index = 0,
	brandId = ''
) => ({
	type: DELETE_SURVEY,
	payload: { id, index, brandId },
});

export const duplicateSurveyAction: (id: string, name: string, index: number) => DuplicateSurveyAction = (
	id,
	name,
	index
) => ({
	type: DUPLICATE_SURVEY,
	payload: { id, name, index },
});

export const archiveSurveyAction: (id: string) => ArchiveSurveyAction = (id) => ({
	type: ARCHIVE_SURVEY,
	payload: { id },
});

export const reloadSurveyListStateAction: () => ReloadSurveyListStateAction = () => ({
	type: RELOAD_SURVEY_LIST_STATE,
	payload: null,
});

export const changeSurveyStatusToDraft: (surveyId: string) => ChangeSurveyStatusToDraftAction = (surveyId) => ({
	type: CHANGE_SERVEY_STATUS_TO_DRAFT,
	payload: { surveyId },
});

export const changeSurveyStatusToDraftFulfilled: (surveyId: string) => ChangeSurveyStatusToDraftFulFilled = (
	surveyId
) => ({
	type: CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED,
	payload: { surveyId },
});

export const changeSurveyStatusToDraftRejected: (error: string) => ChangeSurveyStatusToDraftRejected = (error) => ({
	type: CHANGE_SERVEY_STATUS_TO_DRAFT_REJECTED,
	payload: { error },
});

export const updateSurveyPaymentStatus: (surveyId: string, paymentStatus: PaymentStatus) => UpdateSurveyPaymentStatus =
	(surveyId, paymentStatus) => ({
		type: UPDATE_SURVEY_PAYMENT_STATUS,
		payload: { surveyId, paymentStatus },
	});
