import {
	ReadBrandKeywordsPayload,
	ReadBrandKeywords,
	READ_BRAND_KEYWORDS,
	BrandKeyword,
	ReadBrandKeywordsFulfilled,
	READ_BRAND_KEYWORDS_FULFILLED,
	CreateBrandKeywordsPayload,
	CreateBrandKeywords,
	CREATE_BRAND_KEYWORDS,
	CreateBrandKeywordsFulfilled,
	CREATE_BRAND_KEYWORDS_FULFILLED,
} from './types';

export const readBrandKeywords = (payload: ReadBrandKeywordsPayload): ReadBrandKeywords => ({
	type: READ_BRAND_KEYWORDS,
	payload,
});

export const readBrandKeywordsFulfilled = (payload: BrandKeyword[]): ReadBrandKeywordsFulfilled => ({
	type: READ_BRAND_KEYWORDS_FULFILLED,
	payload: payload,
});

export const createBrandKeywords = (payload: CreateBrandKeywordsPayload): CreateBrandKeywords => ({
	type: CREATE_BRAND_KEYWORDS,
	payload,
});

export const createBrandKeywordsFulfilled = (payload: BrandKeyword[]): CreateBrandKeywordsFulfilled => ({
	type: CREATE_BRAND_KEYWORDS_FULFILLED,
	payload: payload,
});
