import { SetSectionImage } from '../types';
import { call, select } from 'redux-saga/effects';
import { MultipleOpinionScaleQuesState } from '../../../../../shared/types/QuesTypes';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { setQuestion } from '../../sharedQuestions/actions';
import { set, get } from 'shades';
import { pickAll } from 'ramda';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { questionSectionAssetsEndPoint } from '../consts';

export function* uploadSectionImage(action: SetSectionImage) {
	const { quesId, sectionId, file } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId]) {
		const question: MultipleOpinionScaleQuesState = questions[quesId];
		if (!file) return;

		try {
			// Upload process is long this will set URL of file before the upload is completed (for better UX)
			yield putEffect(
				setQuestion(
					question.id,
					set('sections', sectionId, 'asset', 'url')(window.URL.createObjectURL(file))(question),
					true
				)
			);

			if (get('sections', sectionId, 'asset', 'id')(question)) {
				yield call(
					axios.delete,
					getApiEndPointUrl(questionSectionAssetsEndPoint, get('sections', sectionId, 'asset', 'id')(question))
				);
			}

			const formData = new FormData();
			formData.append('assets', file);
			formData.append('sectionId', sectionId);

			const { data } = yield axios.post(getApiEndPointUrl(questionSectionAssetsEndPoint, 'batch'), formData);

			yield putEffect(
				setQuestion(question.id, set('sections', sectionId, 'asset')(pickAll(['url', 'id'], data[0]))(question))
			);
		} catch (e) {
			// Revert if request fails
			yield putEffect(setQuestion(question.id, question));
			yield putEffect(setSurveyError(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			// Upload process is long this will set URL of file before the upload is completed (for better UX)
			questions[question?.questionnaireSectionId].questions[question.id] = set(
				'sections',
				sectionId,
				'asset',
				'url'
			)(window.URL.createObjectURL(file))(question);
			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], true));
			const getSection = get('sections', sectionId, 'asset', 'id')(question);
			if (getSection) {
				yield call(axios.delete, getApiEndPointUrl(questionSectionAssetsEndPoint, getSection));
			}

			const formData = new FormData();
			formData.append('assets', file);
			formData.append('sectionId', sectionId);

			const { data } = yield axios.post(getApiEndPointUrl(questionSectionAssetsEndPoint, 'batch'), formData);

			questions[question?.questionnaireSectionId].questions[question.id] = set(
				'sections',
				sectionId,
				'asset'
			)(pickAll(['url', 'id'], data[0]))(question);

			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			// Revert if request fails
			yield putEffect(setQuestion(question?.questionnaireSectionId, question));
			yield putEffect(setSurveyError(getApiError(e)));
		}
	}
}
