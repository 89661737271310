import { SurveyReducer } from '../shared/types';
import {
	ADD_QUES,
	ADD_QUES_AT_INDEX,
	ADD_QUESTION_PIPE,
	CHANGE_QUESTION_INFO,
	CHANGE_QUESTION_SETTINGS,
	CHANGE_SURVEY_SECTION_SETTINGS,
	COPY_QUES,
	DELETE_QUES,
	APPROVE_EDIT,
	INSERT_QUESTION,
	MOVE_QUES,
	REMOVE_QUESTION_PIPE,
	REMOVE_SKIP_LOGIC,
	SELECT_QUES,
	SET_DISPLAY_LOGIC,
	SET_POPUP,
	SET_QUESTION,
	SET_SKIP_LOGIC,
	INSERT_QUESTIONS,
	SET_MANDATE_LOGIC,
	INSERT_MODERATED_QUESTION,
	ADDED_SECTION_SUCCESS,
	CREATE_NEW_SECTION_TO_SURVEY,
	DELETE_SURVEY_SECTION,
	CHANGE_SECTION_INFO,
	SET_SECTION,
	UPDATE_INFO,
	COPY_SURVEY_SECTION,
	ADD_BULK_QUES,
} from './types';
import { applyNewPositionOrderToAllQuestions, applyPositonByCategoryType } from '../utils';
import { QUES_TYPE } from '../../../../shared/consts/quesType';
import { set } from 'shades';
import { insert, move, omit } from 'ramda';
import { assign } from '../../../../shared/utils/assign';

export const sharedQuestionsReducer: SurveyReducer = (state, action) => {
	switch (action.type) {
		case INSERT_QUESTION: {
			const { question } = action.payload;
			const { questions, quesOrder, hasWelcomeScreen, hasVideoUploadQues } = state;
			const isSectionQuestion = Object.keys(questions).includes(question?.questionnaireSectionId as string);
			let newOrderState;
			let newQuestionsState;
			if (isSectionQuestion) {
				newOrderState = quesOrder;
				if (Object.keys(questions[question?.questionnaireSectionId as string].questions).length !== 0) {
					questions[question?.questionnaireSectionId as string].questionnaireId = [
						...(questions[question?.questionnaireSectionId as string].questionnaireId as string[]),
						question.id,
					];
					questions[question?.questionnaireSectionId as string].questions[
						Object.keys(questions[question?.questionnaireSectionId as string].questions).length
					] = {
						[question.id]: question,
					};
				} else {
					questions[question?.questionnaireSectionId as string].questionnaireId = [question.id];
					questions[question?.questionnaireSectionId as string].questions = { [question.id]: question };
				}
			} else {
				newOrderState = insert(Number(question.orderPosition) - 1, question.id, quesOrder);
				newQuestionsState = applyNewPositionOrderToAllQuestions(
					set(question.id)(question)(questions as any) as any,
					newOrderState
				);
			}

			return assign(
				{
					questions: isSectionQuestion ? questions : newQuestionsState,
					quesOrder: newOrderState,
					hasWelcomeScreen: hasWelcomeScreen,
					hasVideoUploadQues: hasVideoUploadQues,
					requestInProgress: false,
				},
				state
			);
		}
		case INSERT_MODERATED_QUESTION: {
			const { question } = action.payload;
			const { moderatedQuestion } = state;
			const newModeratedQuestionState = set(question.id)(question)(moderatedQuestion as any);
			return assign(
				{
					moderatedQuestion: newModeratedQuestionState,
					requestInProgress: false,
				},
				state
			);
		}
		case INSERT_QUESTIONS: {
			const { questions: newQuestions, moderated } = action.payload;
			const { questions, moderatedQuestion, quesOrder, hasWelcomeScreen, hasVideoUploadQues } = state;
			const { orderState, questionsState } = (newQuestions as any[]).reduce(
				({ orderState, questionsState }, question) => {
					const newOrderState = insert(question.orderPosition - 1, question.id, orderState);
					return {
						orderState: newOrderState,
						questionsState: applyNewPositionOrderToAllQuestions(
							set(question.id)(question)(questionsState),
							newOrderState
						),
					};
				},
				{
					orderState: moderated ? Object.keys(moderatedQuestion).map((i) => i) : quesOrder,
					questionsState: moderated ? moderatedQuestion : questions,
				}
			);
			if (moderated) {
				return assign(
					{
						moderatedQuestion: questionsState,
						hasWelcomeScreen:
							hasWelcomeScreen || newQuestions.some((question) => question.type === QUES_TYPE.WELCOME_SCREEN),
						hasVideoUploadQues:
							hasVideoUploadQues || newQuestions.some((question) => question.type === QUES_TYPE.VIDEO_UPLOAD_QUES),
						requestInProgress: false,
					},
					state
				);
			} else {
				return assign(
					{
						questions: questionsState,
						quesOrder: orderState,
						hasWelcomeScreen:
							hasWelcomeScreen || newQuestions.some((question) => question.type === QUES_TYPE.WELCOME_SCREEN),
						hasVideoUploadQues:
							hasVideoUploadQues || newQuestions.some((question) => question.type === QUES_TYPE.VIDEO_UPLOAD_QUES),
						requestInProgress: false,
					},
					state
				);
			}
		}
		case SELECT_QUES: {
			const { quesId, moderated } = action.payload;
			const sectionQuestions =
				Object.values(state['questions'])
					.map((question) => question?.questionnaireId)
					.flat() || [];
			if (sectionQuestions.includes(quesId)) {
				return set('currQuesId')(quesId)(state);
			} else {
				return set('currQuesId')(state[moderated ? 'moderatedQuestion' : 'questions'][quesId] ? quesId : '')(state);
			}
		}

		case DELETE_QUES: {
			const { quesId: quesDelId, moderated } = action.payload;
			if (!!moderated) {
				//NOTE:moderated has only SCREENER Q.
				const remQuestions = omit([quesDelId], state.moderatedQuestion) || {};
				return {
					...state,
					moderatedQuestion: { ...remQuestions },
				};
			} else {
				let isSectionQuestion: boolean = false;
				let sectionId;
				if (state.questions[quesDelId] === undefined) {
					isSectionQuestion = true;
					const sections = Object.keys(state.questions).filter((id) => state.questions[id]?.metaData);
					sections.forEach((id) => {
						const section = state.questions[id];
						for (let i = 0; i < section.questionnaireId.length; i++) {
							if (section?.questionnaireId?.[i] === quesDelId) {
								sectionId = section.id;
							}
						}
					});
				}

				let updatedSection = {};
				if (isSectionQuestion) {
					const questions = Object.values(state.questions[sectionId].questions);
					questions.sort((a: any, b: any) => a.orderPosition - b.orderPosition);
					for (const sectionIdToUpdate in state.questions) {
						if (sectionIdToUpdate === sectionId) {
							const section = state.questions[sectionIdToUpdate];
							const updatedQuestionnaireId = section.questionnaireId.filter((id) => id !== quesDelId);
							const updatedQuestions = { ...section.questions };
							let decreaseQuestionOrdering = {};
							let questionToDelteOrderPosition = updatedQuestions[quesDelId].orderPosition;

							for (const questionId in updatedQuestions) {
								const question = updatedQuestions[questionId];
								if (question.orderPosition > questionToDelteOrderPosition) {
									decreaseQuestionOrdering[questionId] = question;
								}
							}

							for (const questionId in decreaseQuestionOrdering) {
								const questionToDecreaseOrder = decreaseQuestionOrdering?.[questionId];

								const newOrderPosition = questionToDecreaseOrder.orderPosition - 1;
								questionToDecreaseOrder.orderPosition = newOrderPosition;
							}

							delete updatedQuestions[quesDelId];
							updatedSection = { ...section, questionnaireId: updatedQuestionnaireId, questions: updatedQuestions };
						}
					}
				}
				// if welcome screen deleted, toggle hasWelcomeScreen in state
				const hasWelcomeScreen: boolean =
					state.questions[quesDelId]?.type === QUES_TYPE.WELCOME_SCREEN ? false : state.hasWelcomeScreen;

				// if video upload question deleted, toggle hasVideoUploadQues in state
				const hasVideoUploadQues: boolean =
					state.questions[quesDelId]?.type === QUES_TYPE.VIDEO_UPLOAD_QUES ? false : state.hasVideoUploadQues;

				let remQuestions = { ...state.questions };
				const currValues = Object.values(state.questions)
					.sort((a, b) => a?.orderPosition - b?.orderPosition)
					.filter(
						(ques) =>
							!ques?.parentQuestionId &&
							ques?.orderPosition > state.questions[!!isSectionQuestion ? sectionId : quesDelId].orderPosition
					);

				currValues.forEach((item) => {
					// if its normal questions
					if (!item?.metaData) {
						remQuestions[item.id] = { ...item, orderPosition: item.orderPosition - 1 };
					} else {
						// if its section
						let sectionQuestions = {};
						if (remQuestions[item?.id]?.questionnaireId?.length > 0) {
							remQuestions[item?.id]?.questionnaireId.forEach((quesId) => {
								sectionQuestions[quesId] = {
									...remQuestions[item?.id].questions[quesId],
									orderPosition: remQuestions[item?.id].questions[quesId]?.orderPosition - 1,
								};
							});
							remQuestions[item?.id] = {
								...item,
								orderPosition: item.orderPosition - 1,
								questions: sectionQuestions,
							};
						} else {
							remQuestions[item?.id] = {
								...item,
								orderPosition: item.orderPosition - 1,
								questions: item?.questions,
							};
						}
					}
				});
				if (isSectionQuestion) {
					remQuestions[sectionId] = updatedSection;
				} else {
					remQuestions = omit([quesDelId], remQuestions);
				}

				// const remQuestionsFiltered = Object.entries(remQuestions).reduce((acc: any, curr: any): any => {
				// 	//@ts-ignore
				// 	let filteredOptions = Object.entries(!!curr[1]?.options ? curr[1]?.options : {}).reduce(
				// 		(acc: any, curr: any) => {
				// 			//@ts-ignore
				// 			if (curr[1]?.pipe?.pipeId !== quesDelId) {
				// 				//@ts-ignore
				// 				acc = { ...acc, [curr[0]]: { ...curr[1] } };
				// 			}
				// 			return acc;
				// 		},
				// 		{}
				// 	);

				// 	//@ts-ignore
				// 	acc = {
				// 		...acc,
				// 		[curr[0]]: {
				// 			...curr[1],
				// 			options: filteredOptions,
				// 			settings: { ...curr[1].settings, optionOrder: Object.keys(filteredOptions) },
				// 		},
				// 	};
				// 	return acc;
				// }, {});
				const newQuesOrder: string[] = Object.values(remQuestions)
					?.sort((a, b) => a?.orderPosition - b?.orderPosition)
					?.map((qId) => qId?.id);

				return {
					...state,
					questions: remQuestions,
					quesOrder: newQuesOrder,
					currQuesId: state.currQuesId === quesDelId ? '' : state.currQuesId,
					hasWelcomeScreen,
					hasVideoUploadQues,
				};
			}
		}

		case MOVE_QUES: {
			const { src, dest, type } = action.payload;

			let newQuesOrder = state.quesOrder;
			const isScreener = state.quesOrder.filter((id) => state.questions[id].type === 'Screener');
			if (isScreener?.length) {
				newQuesOrder = state.quesOrder
					.filter((id) => state.questions[id]?.type !== 'Screener')
					//@ts-ignore
					.toSpliced(1, 0, ...isScreener);
				const subScreener = state.quesOrder.filter((id) => state.questions[id].parentQuestionId?.length);
				if (subScreener) {
					newQuesOrder = state.quesOrder.filter((id) => !state.questions[id]?.parentQuestionId).concat(subScreener);
				}
			}
			//to exclude screener question with parent id from  drag and frop movement
			// let newQuesOrderFiltered = state.quesOrder.filter((id) => !state.questions[id]?.parentQuestionId?.length);
			if (type === 'droppable-category') {
				newQuesOrder = move(src?.index)(dest?.index)(newQuesOrder);
			}
			if (type === 'droppable-items') {
				if (!state?.questions[dest?.droppableId]?.metaData) {
					//@ts-ignore
					newQuesOrder = newQuesOrder?.toSpliced(
						newQuesOrder.indexOf(dest?.droppableId) + 1,
						0,
						state?.questions[src?.droppableId]?.questionnaireId[src?.index]
					);
				}
			}

			return {
				...state,
				quesOrder: newQuesOrder,
				questions: applyPositonByCategoryType(state.questions, newQuesOrder, type, src, dest),
			};
		}
		case SET_SKIP_LOGIC:
		case REMOVE_QUESTION_PIPE:
		case ADD_QUESTION_PIPE:
		case REMOVE_SKIP_LOGIC:
		case SET_DISPLAY_LOGIC:
		case ADD_QUES:
		case ADD_QUES_AT_INDEX:
		case ADD_BULK_QUES:
		case APPROVE_EDIT:
		case COPY_QUES:
		case COPY_SURVEY_SECTION:
		case CHANGE_QUESTION_INFO:
		case CHANGE_QUESTION_SETTINGS:
		case CHANGE_SURVEY_SECTION_SETTINGS:
			return set('requestInProgress')(false)(state);
		case SET_MANDATE_LOGIC:
			return set('requestInProgress')(false)(state);
		case SET_QUESTION:
		case SET_SECTION: {
			const sectionQuestions =
				Object.values(state['questions'])
					.map((question) => question?.questionnaireId)
					.flat() || [];
			if (sectionQuestions.includes(action.payload.id)) {
				const currentQuestion: any = action.payload.state;
				// state['questions'][currentQuestion?.questionnaireSectionId]['questions'][action.payload.id] = currentQuestion;
				// state['requestInProgress'] = false;
				return set('requestInProgress')(false)(
					set('questions', currentQuestion?.questionnaireSectionId, 'questions', action.payload.id)(currentQuestion)(
						state as any
					) as any
				);
			} else {
				if (action.payload.moderated) {
					return set('requestInProgress')(false)(
						set('moderatedQuestion', action.payload.id)(action.payload.state)(state as any) as any
					);
				} else {
					return set('requestInProgress')(false)(
						set('questions', action.payload.id)(action.payload.state)(state as any) as any
					);
				}
			}
		}
		case SET_POPUP: {
			return set('requestInProgress')(false)(set('popup')(action.payload.message)(state));
		}
		case CREATE_NEW_SECTION_TO_SURVEY: {
			return set('requestInProgress')(false)(state);
		}
		case CHANGE_SECTION_INFO: {
			return set('requestInProgress')(false)(state);
		}
		case ADDED_SECTION_SUCCESS: {
			const { section } = action.payload;
			const { questions, quesOrder } = state;
			const newOrderState = insert(Number(section.orderPosition) - 1, section.id, quesOrder);
			const newQuestionsState = applyNewPositionOrderToAllQuestions(set(section.id)(section)(questions), newOrderState);

			return assign(
				{
					questions: newQuestionsState,
					quesOrder: newOrderState,
					requestInProgress: false,
					currQuesId: section.id,
				},
				state
			);
		}

		case DELETE_SURVEY_SECTION: {
			const { sectionId, moderated } = action.payload;
			let remQuestions = { ...state.questions };
			const currValues = Object.values(state.questions)
				.sort((a, b) => a?.orderPosition - b?.orderPosition)
				.filter((ques) => !ques?.parentQuestionId && ques?.orderPosition > state.questions[sectionId].orderPosition);
			const currSecLength = remQuestions[sectionId]?.questionnaireId?.length || 1;
			currValues.forEach((item) => {
				// if its normal questions
				if (!item?.metaData) {
					remQuestions[item.id] = { ...item, orderPosition: item.orderPosition - currSecLength };
				} else {
					// if its section
					let sectionQuestions = {};
					if (remQuestions[item?.id]?.questionnaireId?.length > 0) {
						remQuestions[item?.id]?.questionnaireId.forEach((quesId) => {
							sectionQuestions[quesId] = {
								...remQuestions[item?.id].questions[quesId],
								orderPosition: remQuestions[item?.id].questions[quesId]?.orderPosition - currSecLength,
							};
						});
						remQuestions[item?.id] = {
							...item,
							orderPosition: item.orderPosition - currSecLength,
							questions: sectionQuestions,
						};
					} else {
						remQuestions[item?.id] = {
							...item,
							orderPosition: item.orderPosition - currSecLength,
							questions: item?.questions,
						};
					}
				}
			});
			if (!!moderated) {
				return {
					...state,
					moderatedQuestion: omit([sectionId], remQuestions) || {},
				};
			} else {
				/*
				 * sorting the sections by order-position
				 */
				const sectionsSetByOrderPosition = Object.values(omit([sectionId], remQuestions) || {})
					?.sort((a, b) => a?.orderPosition - b?.orderPosition)
					?.map((qId) => qId?.id);
				const newSectionOrder: string[] = sectionsSetByOrderPosition.map(({ id }) => id);

				return {
					...state,
					questions: omit([sectionId], remQuestions) || {},
					quesOrder: newSectionOrder,
					currQuesId: state.currQuesId === sectionId ? '' : state.currQuesId,
				};
			}
		}
		case UPDATE_INFO: {
			const { currentSelectedId, response, moderated } = action.payload;
			const sectionsSetByOrderPosition = Object.values(response)
				?.filter((ques) => !ques.parentQuestionId)
				?.sort((a, b) => a?.orderPosition - b?.orderPosition);
			const newSectionOrder: string[] = sectionsSetByOrderPosition.map(({ id }) => id);
			const hasWelcomeScreen: boolean = Object.values(response).some((ques) => ques.type === QUES_TYPE.WELCOME_SCREEN);
			const hasVideoUploadQues: boolean = Object.values(response).some(
				(ques) => ques.type === QUES_TYPE.VIDEO_UPLOAD_QUES
			);
			if (!!moderated) {
				return assign(
					{
						moderatedQuestion: response,
						requestInProgress: false,
					},
					state
				);
			}

			return {
				...state,
				questions: response,
				quesOrder: newSectionOrder,
				currQuesId: state.currQuesId === currentSelectedId ? currentSelectedId : state.currQuesId,
				hasWelcomeScreen,
				hasVideoUploadQues,
			};
		}
		default:
			return state;
	}
};
