const DYNAMIC_PARAM_SYMBOL = ':';
export const isActiveRoute =
	(root: string) =>
	(sub: string) =>
	(url: string): boolean => {
		const urlParts = url.split('/');
		return `${root}${sub}`.split('/').reduce((p: boolean, c: string, index: number) => {
			if (c.startsWith(DYNAMIC_PARAM_SYMBOL)) {
				return p && !!urlParts[index];
			}

			return p && c === urlParts[index];
		}, true);
	};

export const createRouteFromBlueprint =
	(...chunks: string[]) =>
	(params: { [k: string]: string | number }) => {
		return chunks
			.join('')
			.split('/')
			.reduce((p, c) => {
				if (c.startsWith(DYNAMIC_PARAM_SYMBOL)) {
					return `${p}/${params[c.replace(DYNAMIC_PARAM_SYMBOL, '')] || ''}`;
				}

				return `${p}/${c}`;
			}, '')
			.replace(/\/\//g, '/');
	};
