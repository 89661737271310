import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { BrandState } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const CREATE_BRAND_KEYWORDS = 'CREATE_BRAND_KEYWORDS';
export const CREATE_BRAND_KEYWORDS_FULFILLED = 'CREATE_BRAND_KEYWORDS_FULFILLED';

export type CreateBrandKeywordsPayload = {
	keywords: string[];
	brandId: string;
};
export type BrandKeyword = {
	id: string;
	keyword: string;
	brandId: string;
};
const BrandKeywordSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		keyword: { type: 'string' },
		brandId: { type: 'string' },
	},
};
export const BrandKeywordsSchema: JSONSchema = {
	anyOf: [
		{ type: 'array', items: BrandKeywordSchema },
		{ type: 'object', value: null },
	],
};

export type CreateBrandKeywords = ReduxAction<typeof CREATE_BRAND_KEYWORDS, CreateBrandKeywordsPayload>;
export type CreateBrandKeywordsFulfilled = ReduxAction<typeof CREATE_BRAND_KEYWORDS_FULFILLED, BrandKeyword[]>;

export const READ_BRAND_KEYWORDS = 'READ_BRAND_KEYWORDS';
export const READ_BRAND_KEYWORDS_FULFILLED = 'READ_BRAND_KEYWORDS_FULFILLED';

export type ReadBrandKeywordsPayload = {
	brandId: string;
};

export type ReadBrandKeywords = ReduxAction<typeof READ_BRAND_KEYWORDS, ReadBrandKeywordsPayload>;
export type ReadBrandKeywordsFulfilled = ReduxAction<typeof READ_BRAND_KEYWORDS_FULFILLED, BrandKeyword[]>;

export type BrandKeywordAction =
	| CreateBrandKeywords
	| CreateBrandKeywordsFulfilled
	| ReadBrandKeywords
	| ReadBrandKeywordsFulfilled;

export type BrandKeywordReducer = ReduxReducer<BrandState, BrandKeywordAction>;
