import {
	SetCurrentBrandPayload,
	SetCurrentBrand,
	SET_CURRENT_BRAND,
	CreateBrandPayload,
	CreateBrand,
	CREATE_BRAND,
	Brand,
	CreateBrandFulfilled,
	CREATE_BRAND_FULFILLED,
	ReadBrandsPayload,
	ReadBrands,
	READ_BRANDS,
	ReadBrandsFulfilled,
	READ_BRANDS_FULFILLED,
	ReadBrandPayload,
	ReadBrand,
	READ_BRAND,
	ReadBrandFulfilled,
	READ_BRAND_FULFILLED,
	UpdateBrand,
	UPDATE_BRAND,
	UpdateBrandFulfilled,
	UPDATE_BRAND_FULFILLED,
	DeleteBrand,
	DELETE_BRAND,
	DeleteBrandFulfilled,
	DELETE_BRAND_FULFILLED,
} from './types';

export const setCurrentBrand = (payload: SetCurrentBrandPayload): SetCurrentBrand => ({
	type: SET_CURRENT_BRAND,
	payload,
});

export const createBrand = (payload: CreateBrandPayload): CreateBrand => ({
	type: CREATE_BRAND,
	payload,
});

export const createBrandFulfilled = (payload: Brand): CreateBrandFulfilled => ({
	type: CREATE_BRAND_FULFILLED,
	payload: payload,
});

export const readBrands = (payload: ReadBrandsPayload): ReadBrands => ({
	type: READ_BRANDS,
	payload,
});

export const readBrandsFulfilled = (payload: Brand[]): ReadBrandsFulfilled => ({
	type: READ_BRANDS_FULFILLED,
	payload: payload,
});

export const readBrand = (payload: ReadBrandPayload): ReadBrand => ({
	type: READ_BRAND,
	payload,
});
export const readBrandFulfilled = (payload: Brand): ReadBrandFulfilled => ({
	type: READ_BRAND_FULFILLED,
	payload: payload,
});

export const updateBrand = (payload: Brand): UpdateBrand => ({
	type: UPDATE_BRAND,
	payload,
});

export const updateBrandFulfilled = (payload: Brand): UpdateBrandFulfilled => ({
	type: UPDATE_BRAND_FULFILLED,
	payload: payload,
});

export const deleteBrand = (payload: string): DeleteBrand => ({
	type: DELETE_BRAND,
	payload,
});

export const deleteBrandFulfilled = (payload: string): DeleteBrandFulfilled => ({
	type: DELETE_BRAND_FULFILLED,
	payload: payload,
});
