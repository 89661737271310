import styled from 'styled-components';
import { MixinHaltButton } from '../Button/styled';
import RightOutlined from '@ant-design/icons/RightOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';

export const StyledFooter = styled.footer`
	text-align: right;
	padding: 0.8rem 1.6rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${({ theme }) => theme.colors.surfaceLight};
	box-shadow: 0rem 0rem 0.2rem rgba(30, 21, 83, 0.2);
`;
export const StyledFooterText = styled.span`
	color: ${({ theme }) => theme.colors.primaryButton};
	padding-left: 1.5rem;
`;

export const StyledHaltButton = styled.button`
	${MixinHaltButton}
`;
export const StyledRightOutlined = styled(RightOutlined)`
	padding-left: 1.4rem;
`;
export const StyledLeftOutlined = styled(LeftOutlined)`
	padding-right: 1.4rem;
`;
