import {
	CREATE_BRAND_COMPETITORS_FULFILLED,
	READ_BRAND_COMPETITORS_FULFILLED,
	BrandCompetitorAction,
	BrandCompetitorReducer,
} from './types';

export const brandCompetitorReducer: BrandCompetitorReducer = (state, action: BrandCompetitorAction) => {
	switch (action.type) {
		case READ_BRAND_COMPETITORS_FULFILLED:
		case CREATE_BRAND_COMPETITORS_FULFILLED:
			return {
				...state,
				competitors: action.payload,
			};

		default:
			return state;
	}
};
