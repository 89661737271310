import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	readFunnelAnalyzerStages,
	readFunnelAnalyzerStagesFulfilled,
	updateFunnelAnalyzerStages,
	updateFunnelAnalyzerStagesFulfilled,
} from './actions';
import { questionnaireEndPoint, brandFunnelStagesEndPoint } from './const';
import {
	UpdateFunnelAnalyzerStagesPayload,
	ReadFunnelAnalyzerStagesPayload,
	UpdateFunnelAnalyzerStages,
	ReadFunnelAnalyzerStages,
	FunnelAnalyzerStagesSchema,
	READ_FUNNEL_ANALYZER_STAGES,
	UPDATE_FUNNEL_ANALYZER_STAGES,
} from './types';

function updateFunnelAnalyzerStagesAPI(brand: UpdateFunnelAnalyzerStagesPayload) {
	const { questionnaireId, ...data } = brand;
	return axios.put(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelStagesEndPoint),
		data
	);
}

function readFunnelAnalyzerStagesAPI(brand: ReadFunnelAnalyzerStagesPayload) {
	return axios.get(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelStagesEndPoint),
		{
			params: brand,
		}
	);
}

function* updateFunnelAnalyzerStageSaga(action: UpdateFunnelAnalyzerStages) {
	try {
		yield putEffect(pend(updateFunnelAnalyzerStages));
		yield updateFunnelAnalyzerStagesAPI(action.payload);
		yield putEffect(updateFunnelAnalyzerStagesFulfilled(action.payload));
		yield putEffect(fulfill(updateFunnelAnalyzerStages));
	} catch (e) {
		yield putEffect(reject(updateFunnelAnalyzerStages, getApiError(e)));
	}
}

function* readFunnelAnalyzerStagesSaga(action: ReadFunnelAnalyzerStages) {
	try {
		yield putEffect(pend(readFunnelAnalyzerStages));
		const res = yield readFunnelAnalyzerStagesAPI(action.payload);
		yield putEffect(readFunnelAnalyzerStagesFulfilled(validate(res.data, FunnelAnalyzerStagesSchema)));
		yield putEffect(fulfill(readFunnelAnalyzerStages));
	} catch (e) {
		yield putEffect(reject(readFunnelAnalyzerStages, getApiError(e)));
	}
}

export const funnelAnalyzerStageSagas = [
	function* getUpdateFunnelAnalyzerStageWatcher() {
		yield takeLeading(UPDATE_FUNNEL_ANALYZER_STAGES, updateFunnelAnalyzerStageSaga);
	},
	function* getReadFunnelAnalyzerStagesWatcher() {
		yield takeLeading(READ_FUNNEL_ANALYZER_STAGES, readFunnelAnalyzerStagesSaga);
	},
];
