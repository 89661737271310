import { CopyQues } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, updateInfo } from '../actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { surveyQuestionsApiUrl } from './consts';
import { select } from 'redux-saga/effects';
import { getQuestionsResponse } from './createSection';

export function* duplicateQuestion(action: CopyQues) {
	const { currQuesId, surveyId, surveys } = yield select((s) => s.surveyBuilder);
	const questionnaireId = surveys[surveyId]?.questionnaireId;
	try {
		const { data } = yield axios.post(
			getApiEndPointUrl(surveyQuestionsApiUrl, 'clone', `${action.payload.quesId}?questionnaireId=${questionnaireId}`)
		);
		const responseOfData = getQuestionsResponse(data?.surveys?.[0]?.questions);
		yield putEffect(updateInfo(currQuesId, responseOfData, action?.payload?.moderated));
		// if (data[0]) yield putEffect(selectQues(data[0].id, true, action?.payload?.moderated));
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
