import { Modal, ModalProps } from 'antd';
import React, {
	ComponentType,
	createContext,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useContext,
	useMemo,
	useState,
} from 'react';
import { createPortal } from 'react-dom';

type ModalContextType = {
	push: (data: ModalData) => void;
	pop: () => void;
};

export type ModalData = Omit<ModalProps, 'isOpen'> & {
	children: ReactNode;
};

const ModalContext = createContext({} as ModalContextType);
export const useCustomModal = () => useContext(ModalContext);

type ModalProviderProps = {
	mountNodeRef?: any;
};
const ModalProvider = ({ children, mountNodeRef }: PropsWithChildren<ModalProviderProps>) => {
	const [modals, setModals] = useState<ReactElement[]>([]);

	const value = useMemo(
		() => ({
			push: ({ children, ...rest }: ModalData) => {
				setModals((prev) =>
					prev.concat(
						<Modal key={prev.length + 1} {...rest} visible onCancel={() => setModals((prev) => prev.slice(0, -1))}>
							{children}
						</Modal>
					)
				);
			},
			pop: () => setModals((prev) => prev.slice(0, -1)),
		}),
		[]
	);

	return (
		<ModalContext.Provider value={value}>
			{children}
			{mountNodeRef ? createPortal(<>{modals}</>, mountNodeRef.current) : modals}
		</ModalContext.Provider>
	);
};

export const withModalProvider = function <P = any>(Component: ComponentType<P>) {
	return (props: P) => {
		return (
			<ModalProvider>
				<Component {...props} />
			</ModalProvider>
		);
	};
};

export default ModalProvider;
