import {
	ReadBrandProductsPayload,
	ReadBrandProducts,
	READ_BRAND_PRODUCTS,
	ReadBrandProductsFulfilledPayload,
	ReadBrandProductsFulfilled,
	READ_BRAND_PRODUCTS_FULFILLED,
	CreateBrandProductPayload,
	CreateBrandProducts,
	CREATE_BRAND_PRODUCTS,
	BrandProductType,
	CreateBrandProductsFulfilled,
	CREATE_BRAND_PRODUCTS_FULFILLED,
	UpdateBrandProductPayload,
	UpdateBrandProducts,
	UPDATE_BRAND_PRODUCTS,
	UpdateBrandProductsFulfilled,
	UPDATE_BRAND_PRODUCTS_FULFILLED,
	DeleteBrandProducts,
	DELETE_BRAND_PRODUCTS,
	DeleteBrandProductsFulfilled,
	DELETE_BRAND_PRODUCTS_FULFILLED,
} from './types';

export const readBrandProducts = (payload: ReadBrandProductsPayload): ReadBrandProducts => ({
	type: READ_BRAND_PRODUCTS,
	payload,
});

export const readBrandProductsFulfilled = (payload: ReadBrandProductsFulfilledPayload): ReadBrandProductsFulfilled => ({
	type: READ_BRAND_PRODUCTS_FULFILLED,
	payload: payload,
});

export const createBrandProducts = (payload: CreateBrandProductPayload[]): CreateBrandProducts => ({
	type: CREATE_BRAND_PRODUCTS,
	payload,
});

export const createBrandProductsFulfilled = (payload: BrandProductType[]): CreateBrandProductsFulfilled => ({
	type: CREATE_BRAND_PRODUCTS_FULFILLED,
	payload: payload,
});

export const updateBrandProducts = (payload: UpdateBrandProductPayload[]): UpdateBrandProducts => ({
	type: UPDATE_BRAND_PRODUCTS,
	payload,
});

export const updateBrandProductsFulfilled = (payload: BrandProductType[]): UpdateBrandProductsFulfilled => ({
	type: UPDATE_BRAND_PRODUCTS_FULFILLED,
	payload: payload,
});

export const deleteBrandProducts = (payload: BrandProductType[]): DeleteBrandProducts => ({
	type: DELETE_BRAND_PRODUCTS,
	payload,
});

export const deleteBrandProductsFulfilled = (payload: BrandProductType[]): DeleteBrandProductsFulfilled => ({
	type: DELETE_BRAND_PRODUCTS_FULFILLED,
	payload: payload,
});
