import { takeLeading } from 'redux-saga/effects';
import {
	ADD_SECTION,
	ADD_STROKE,
	DELETE_SECTION,
	DELETE_STROKE,
	EDIT_SECTION_TEXT,
	EDIT_STROKE,
	MOVE_SECTION,
	MOVE_STROKE,
	SET_SECTION_IMAGE,
} from './types';
import { addSection } from './sagas/addSection';
import { addStroke } from './sagas/addStroke';
import { removeStroke } from './sagas/removeStroke';
import { removeSection } from './sagas/removeSection';
import { reorderSections } from './sagas/reorderSections';
import { reorderStrokes } from './sagas/reorderStrokes';
import { updateSectionText } from './sagas/updateSectionText';
import { updateStrokeText } from './sagas/updateStrokeText';
import { uploadSectionImage } from './sagas/uploadSectionImage';

function* addSectionWatcher() {
	yield takeLeading(ADD_SECTION, addSection);
}

function* addStrokeWatcher() {
	yield takeLeading(ADD_STROKE, addStroke);
}

function* removeStrokeWatcher() {
	yield takeLeading(DELETE_STROKE, removeStroke);
}

function* removeSectionWatcher() {
	yield takeLeading(DELETE_SECTION, removeSection);
}

function* reorderSectionWatcher() {
	yield takeLeading(MOVE_SECTION, reorderSections);
}

function* reorderStrokesWatcher() {
	yield takeLeading(MOVE_STROKE, reorderStrokes);
}

function* updateQuestionTextWatcher() {
	yield takeLeading(EDIT_SECTION_TEXT, updateSectionText);
}

function* updateStrokeTextWatcher() {
	yield takeLeading(EDIT_STROKE, updateStrokeText);
}

function* uploadSectionImageWatcher() {
	yield takeLeading(SET_SECTION_IMAGE, uploadSectionImage);
}

export const questionSectionsWatchers = [
	addSectionWatcher,
	addStrokeWatcher,
	removeStrokeWatcher,
	removeSectionWatcher,
	reorderSectionWatcher,
	reorderStrokesWatcher,
	updateQuestionTextWatcher,
	updateStrokeTextWatcher,
	uploadSectionImageWatcher,
];
