import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	readBrandCompetitorsFulfilled,
	createBrandCompetitorsFulfilled,
	createBrandCompetitors,
	readBrandCompetitors,
} from './actions';
import { brandCompetitorEndPoint } from './const';
import {
	BrandCompetitorsSchema,
	CreateBrandCompetitors,
	CreateBrandCompetitorsPayload,
	CREATE_BRAND_COMPETITORS,
	ReadBrandCompetitors,
	ReadBrandCompetitorsPayload,
	READ_BRAND_COMPETITORS,
} from './types';

function createBrandCompetitorsAPI(brandCompetitorObj: CreateBrandCompetitorsPayload) {
	return axios.post(getApiEndPointUrl(brandCompetitorEndPoint, 'batch/save'), brandCompetitorObj);
}

function readBrandCompetitorsAPI(brandObj: ReadBrandCompetitorsPayload) {
	return axios.get(getApiEndPointUrl(brandCompetitorEndPoint), {
		params: brandObj,
	});
}

function* createBrandCompetitorsSaga({ payload }: CreateBrandCompetitors) {
	try {
		yield putEffect(pend(createBrandCompetitors));
		if (payload.competitors.length) {
			const res = yield createBrandCompetitorsAPI(payload);
			yield putEffect(createBrandCompetitorsFulfilled(validate(res.data, BrandCompetitorsSchema)));
		}
		yield putEffect(fulfill(createBrandCompetitors));
	} catch (e) {
		yield putEffect(reject(createBrandCompetitors, getApiError(e)));
	}
}

function* readBrandCompetitorsSaga(action: ReadBrandCompetitors) {
	try {
		yield putEffect(pend(readBrandCompetitors));
		const res = yield readBrandCompetitorsAPI(action.payload);
		yield putEffect(readBrandCompetitorsFulfilled(validate(res.data, BrandCompetitorsSchema)));
		yield putEffect(fulfill(readBrandCompetitors));
	} catch (e) {
		yield putEffect(reject(readBrandCompetitors, getApiError(e)));
	}
}
function* getCreateBrandCompetitorsWatcher() {
	yield takeLeading(CREATE_BRAND_COMPETITORS, createBrandCompetitorsSaga);
}
function* getReadBrandCompetitorsWatcher() {
	yield takeLeading(READ_BRAND_COMPETITORS, readBrandCompetitorsSaga);
}

export const brandCompetitorSagas = [getReadBrandCompetitorsWatcher, getCreateBrandCompetitorsWatcher];
