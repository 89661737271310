import { ReduxAction } from '../../../shared/types/ReduxAction';
import { Survey } from '../surveyBuilder/surveyInfo/types';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyStatus } from '../../../shared/enums/SurveyStatus';
import { Creator } from '../../../shared/types/SurveyResponses';
import { Research } from '../../../shared/enums/Research';
import { Niche } from '../../brand/niche/types';
import { ResearchModule } from '../../../shared/enums/ResearchModule';

export const GET_SURVEY_DATA = 'GET_SURVEY_DATA';
export const SET_SURVEY_DATA = 'SET_SURVEY_DATA';
export const SET_SURVEY_DATA_ERROR = 'SET_SURVEY_DATA_ERROR';
export const SET_ACTIVE_SURVEY_PREVIEW = 'SET_ACTIVE_SURVEY_PREVIEW';
export const RELOAD_SURVEY_PREVIEW_STATE = 'RELOAD_SURVEY_PREVIEW_STATE';
export const SET_CREDITS_INFO = 'SET_CREDITS_INFO';

export type SurveyPreviewDataState = {
	id: string;
	name: string;
	allowMultipleResponse: boolean;
	description: string;
	createdAt: string;
	requestInProgress: boolean;
	questionNumber: number;
	startTime: string | null;
	endTime: string | null;
	pixelId: string | null;
	error: string;
	image: string | null;
	surveys: {
		[id: string]: Survey;
	};
	activeSurvey: string;
	usePyxisLogo: boolean;
	isRightOriented: boolean;
	allowEditAndResubmit: boolean;
	creator: Creator | null;
	status?: SurveyStatus;
	testingTargetUrls: string[];
	testingGoal: string;
	macroTargetingGroupId: string | null;
	credits: number;
	totalRespondents: number;
	surveyCost: number;
	researchType: Research;
	researchModule: ResearchModule;
	macroTargetingGroup: null | { niches: Niche[] };
	workspaceId: string;
	brandId: string;
	isModerated: boolean;
	surveyTemplateSetting: any;
};

export type GetSurveyPreviewDataAction = ReduxAction<typeof GET_SURVEY_DATA, { id: string }>;

export type SetSurveyPreviewDataActionPayload = {
	id: string;
	name: string;
	description: string;
	createdAt: string;
	status?: SurveyStatus;
	surveys: {
		[id: string]: Survey;
	};
};

export type SetSurveyPreviewDataAction = ReduxAction<typeof SET_SURVEY_DATA, SetSurveyPreviewDataActionPayload>;

export type SetSurveyPreviewDataErrorAction = ReduxAction<typeof SET_SURVEY_DATA_ERROR, { error: string }>;

export type SetActiveSurveyPreviewAction = ReduxAction<typeof SET_ACTIVE_SURVEY_PREVIEW, { id: string }>;

export type SetCreditsInfoAction = ReduxAction<
	typeof SET_CREDITS_INFO,
	{ credits: number; totalRespondents: number; surveyCost: number }
>;

export type ReloadSurveyPreviewState = ReduxAction<typeof RELOAD_SURVEY_PREVIEW_STATE, null>;

export type SurveyPreviewDataReducer = ReduxReducer<
	SurveyPreviewDataState,
	| GetSurveyPreviewDataAction
	| SetSurveyPreviewDataAction
	| SetSurveyPreviewDataErrorAction
	| SetActiveSurveyPreviewAction
	| ReloadSurveyPreviewState
	| SetCreditsInfoAction
>;
