import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { Brand } from '../brand/detail/types';

export const SET_WORKSPACES = 'SET_WORKSPACES';
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const SET_WORKSPACE_USER_ERROR = 'SET_WORKSPACE_USER_ERROR';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export type Workspace = {
	id: string;
	name: string;
	brands: Brand[];
};
export type Role = {
	id: string;
	name: string;
	workspace: Workspace;
};

export type WorkspaceObj = {
	id: string;
	name: string;
	roles: Role[];
};

export type WorkspaceUserState = {
	workspaces: WorkspaceObj[];
	error: string | null;
	notification: string | null;
	loading: boolean;
};

export type SetNotificationAction = ReduxAction<typeof SET_NOTIFICATION, string | null>;

export type WorkspacesPayload = Workspace[];

export type GetWorskpacesAction = ReduxAction<typeof GET_WORKSPACES, null>;

export type SetErrorAction = ReduxAction<typeof SET_WORKSPACE_USER_ERROR, string | null>;

export type SetWorskpacesUserAction = ReduxAction<typeof SET_WORKSPACES, WorkspacesPayload>;

export const RESET_WORKSPACE_USER_ERROR = 'RESET_WORKSPACE_USER_ERROR';
export type ResetWorkspaceUserErrorAction = ReduxAction<typeof RESET_WORKSPACE_USER_ERROR, null>;

export type WorkspaceUserAction =
	| GetWorskpacesAction
	| SetWorskpacesUserAction
	| SetErrorAction
	| SetNotificationAction
	| ResetWorkspaceUserErrorAction;

export type WorkspaceUserReducer = ReduxReducer<WorkspaceUserState, WorkspaceUserAction>;
