import { lazy, mount } from 'navi';

import { routePaths } from './shared/consts/routePaths';

const surveyBuilderRoutes = {
	[routePaths.home.root]: lazy(() => import('./pages/Home')),
	[routePaths.auth.root]: lazy(() => import('./pages/Auth')),
	[routePaths.users.root]: lazy(() => import('./pages/Users')),
	[routePaths.rolesPermission.root]: lazy(() => import('./pages/RolesPermission')),
	[routePaths.billing.root]: lazy(() => import('./pages/Billing')),
	[routePaths.transaction.root]: lazy(() => import('./pages/Transaction')),
	[routePaths.survey.root]: lazy(() => import('./pages/Survey')),
	[routePaths.analytics.root]: lazy(() => import('./pages/Analytics')),
	[routePaths.workspaces.root]: lazy(() => import('./pages/Workspace')),
	[routePaths.brands.root]: lazy(() => import('./pages/Brand')),
	[routePaths.lite.root]: lazy(() => import('./pages/Lite')),
	[routePaths.library.root]: lazy(() => import('./pages/Library')),
	[routePaths.profile.root]: lazy(() => import('./pages/Profile')),
	[routePaths.approval.root]: lazy(() => import('./pages/Approval')),
	[routePaths.questionnaire.root]: lazy(() => import('./pages/Questionnaire')),
	[routePaths.moderateTesting.root]: lazy(() => import('./pages/ModerateTesting')),
	[routePaths.moderateTestingPreview.root]: lazy(() => import('./pages/ModerateTesting')),
	[routePaths.surveyDummy.root]: lazy(() => import('./pages/SurveyDummy')),
	[routePaths.policies.root]: lazy(() => import('./pages/Policies')),
	[routePaths.brandSpace.root]: lazy(() => import('./pages/BrandSpace')),
	[routePaths.campaign.root]: lazy(() => import('./pages/Campaign')),
	[routePaths.creatorAuth.root]: lazy(() => import('./pages/CreatorAuth')),
	[routePaths.policies.root]: lazy(() => import('./pages/Policies')),
	[routePaths.creator.root]: lazy(() => import('./pages/Creator')),
};

export const routes = mount(surveyBuilderRoutes);
