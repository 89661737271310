import {
	ReadBrandColorsPayload,
	ReadBrandColors,
	READ_BRAND_COLORS,
	ReadBrandColorsFulfilled,
	READ_BRAND_COLORS_FULFILLED,
	CreateBrandColors,
	CREATE_BRAND_COLORS,
	CreateBrandColorsFulfilled,
	CREATE_BRAND_COLORS_FULFILLED,
	BrandColor,
	CreateBrandColorPayload,
	DeleteBrandColors,
	DeleteBrandColorsFulfilled,
	DELETE_BRAND_COLORS,
	DELETE_BRAND_COLORS_FULFILLED,
} from './types';

export const readBrandColors = (payload: ReadBrandColorsPayload): ReadBrandColors => ({
	type: READ_BRAND_COLORS,
	payload,
});

export const readBrandColorsFulfilled = (payload: BrandColor[]): ReadBrandColorsFulfilled => ({
	type: READ_BRAND_COLORS_FULFILLED,
	payload: payload,
});

export const createBrandColors = (payload: CreateBrandColorPayload[]): CreateBrandColors => ({
	type: CREATE_BRAND_COLORS,
	payload,
});

export const createBrandColorsFulfilled = (payload: BrandColor[]): CreateBrandColorsFulfilled => ({
	type: CREATE_BRAND_COLORS_FULFILLED,
	payload: payload,
});

export const deleteBrandColors = (payload: BrandColor[]): DeleteBrandColors => ({
	type: DELETE_BRAND_COLORS,
	payload,
});

export const deleteBrandColorsFulfilled = (payload: BrandColor[]): DeleteBrandColorsFulfilled => ({
	type: DELETE_BRAND_COLORS_FULFILLED,
	payload: payload,
});
