import { SurveyAnalytics } from '../types';
import { ReduxReducer } from 'shared/types/ReduxReducer';
import {
	deleteGeneralAnalyticsReport,
	deleteGeneralAnalyticsReportFulfilled,
	DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED,
	readCurrentAnalyticsReport,
	readCurrentAnalyticsReportFulfilled,
	readGeneralAnalyticsAllReport,
	readGeneralAnalyticsAllReportFulfilled,
	READ_CURRENT_ANALYTICS_REPORT_FULFILLED,
	READ_GENERAL_ANALYTICS_ALL_REPORT_FULFILLED,
	sendGeneralAnalyticsReportFilters,
	sendGeneralAnalyticsReportFulfilled,
	SEND_REPORT_COMPARISON_FULFILLED,
	SEND_REPORT_FILTERS_FULFILLED,
} from './actions';

export type GeneralAnalyticsReportAction =
	| ReturnType<typeof sendGeneralAnalyticsReportFilters>
	| ReturnType<typeof readGeneralAnalyticsAllReport>
	| ReturnType<typeof readGeneralAnalyticsAllReportFulfilled>
	| ReturnType<typeof deleteGeneralAnalyticsReport>
	| ReturnType<typeof deleteGeneralAnalyticsReportFulfilled>
	| ReturnType<typeof readCurrentAnalyticsReport>
	| ReturnType<typeof readCurrentAnalyticsReportFulfilled>
	| ReturnType<typeof sendGeneralAnalyticsReportFulfilled>;

export type GeneralAnalyticsReducer = ReduxReducer<SurveyAnalytics, GeneralAnalyticsReportAction>;
//@ts-ignore
export const generalAnalyticsReportsReducer: GeneralAnalyticsReducer = (
	state: SurveyAnalytics,
	action: GeneralAnalyticsReportAction
) => {
	switch (action.type) {
		case SEND_REPORT_FILTERS_FULFILLED:
			return {
				...state,
				generalAnalyticsReports: action.payload,
			};
		case DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED:
			return {
				...state,
				generalAnalyticsReports: action.payload,
			};
		case READ_GENERAL_ANALYTICS_ALL_REPORT_FULFILLED:
			return {
				...state,
				generalAnalyticsReports: action.payload,
			};
		case READ_CURRENT_ANALYTICS_REPORT_FULFILLED:
			return {
				...state,
				generalAnalyticsReports: action.payload,
			};
		case SEND_REPORT_COMPARISON_FULFILLED:
			return {
				...state,
				generalAnalyticsReports: action.payload,
			};
		default:
			return state;
	}
};
