import { REDIRECT, RedirectReducer, RedirectState, SET_ROUTE_INFO } from './types';
import { set } from 'shades';

const initialState: RedirectState = {
	url: undefined,
	info: {
		params: {},
		query: {},
		url: '/',
	},
};

export const redirectReducer: RedirectReducer = (state = initialState, action) => {
	switch (action.type) {
		case REDIRECT:
			return set('url')(action.payload.url)(state);
		case SET_ROUTE_INFO:
			return set('info')(action.payload.info)(state);
		default:
			return state;
	}
};
