import axios from '@utils/axios';
import { all } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { setSurveyError } from '../../shared/actions';
import { updateSurveyResearch, updateSurveyResearchFulfilled } from '../actions';
import { UpdateSurveyResearchAction } from '../types';

export function* updateSurveyResearchSaga(action: UpdateSurveyResearchAction) {
	const { researches, surveyEntityId } = action.payload;
	try {
		yield putEffect(pend(updateSurveyResearch));
		const resData = yield all(
			researches.map((researchId) =>
				axios.patch(getApiEndPointUrl('surveys', surveyEntityId), { researchId }).then((res) => res.data)
			)
		);
		yield putEffect(updateSurveyResearchFulfilled(resData));
		yield putEffect(fulfill(updateSurveyResearch, resData));
	} catch (e) {
		yield putEffect(reject(updateSurveyResearch, getApiError(e)));
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
