import React, { ReactNode, useContext, useState } from 'react';
import ConfirmDrawer, { ConfirmDrawerProps } from './ConfirmDrawer';
type drawerContextType = {
	drawers: ConfirmDrawerProps[];
	push: (drawer: ConfirmDrawerProps) => void;
	pop: () => void;
};
const DrawerProvider = ({ children }: { children: ReactNode }) => {
	const [drawers, setDrawers] = useState<ConfirmDrawerProps[]>([]);
	const [isVisible, setIsVisible] = useState(false);
	const drawerUtilities: drawerContextType = {
		drawers,
		push: (drawer) => {
			setDrawers([...drawers, drawer]);
			setIsVisible(true);
		},
		pop: () => {
			setIsVisible(false);
		},
	};
	return (
		<>
			{
				<ConfirmDrawer
					onClose={() => {
						setIsVisible(false);
					}}
					afterVisibleChange={(visible) => {
						if (drawers.length > 1) {
							// show again if more than 1 drawer
							setIsVisible(true);
						}
						if (!visible) {
							setDrawers(drawers.slice(1)); // remove it after animation to not get ugly flash
						}
					}}
					visible={isVisible}
					{...drawers[0]}
				/>
			}
			<DrawerContext.Provider value={drawerUtilities}>{children}</DrawerContext.Provider>
		</>
	);
};

export const DrawerContext = React.createContext<drawerContextType>({
	drawers: [],
	push: () => {},
	pop: () => {},
});
export const useDrawer = () => useContext(DrawerContext);

export default DrawerProvider;
