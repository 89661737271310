import {
	READ_BRAND_FUNNEL_COMPETITORS_FULFILLED,
	BrandFunnelCompetitorAction,
	BrandFunnelCompetitorReducer,
} from './types';

export const brandFunnelCompetitorReducer: BrandFunnelCompetitorReducer = (
	state,
	action: BrandFunnelCompetitorAction
) => {
	switch (action.type) {
		case READ_BRAND_FUNNEL_COMPETITORS_FULFILLED:
			return {
				...state,
				brandFunnelCompetitors: action.payload,
			};
		default:
			return state;
	}
};
