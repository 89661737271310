import React, { ReactElement, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { theme } from '../../shared/consts/theme';
import { StoreState } from '../../store';
import { reset } from '../../store/promise/actions';
import { ReduxQueryContext } from '../../utils/reduxQuery/useReduxMutation';
import { useIsError } from '../../utils/reactQueryPlus/provider';

export const ReduxErrorHandler = (): ReactElement => {
	const rejected = useSelector((state: StoreState) => state.promise.rejected);
	const dictionary = useContext(ReduxQueryContext);

	const effectiveRejected = useMemo(
		() => rejected.filter(({ id }) => !dictionary.globalErrorBlackList[id]),
		[dictionary.globalErrorBlackList, rejected]
	);
	const dispatch = useDispatch();
	return (
		<Modal
			zIndex={theme.layouts.zIndex.modal}
			title="Error"
			visible={!!effectiveRejected.length}
			onCancel={() => dispatch(reset(effectiveRejected[0]?.id))}
			footer={null}
		>
			{!!effectiveRejected.length && effectiveRejected[0].reason}
		</Modal>
	);
};
export const ReactQueryErrorHandler = (): ReactElement => {
	const { errors, reset } = useIsError();
	return (
		<Modal
			zIndex={theme.layouts.zIndex.modal}
			title="Error"
			visible={!!errors.length}
			onCancel={() => reset(errors[0]?.hash)}
			footer={null}
		>
			{!!errors.length && errors[0]?.value}
		</Modal>
	);
};
