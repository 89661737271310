import { SurveyInfo } from '../surveyBuilder/surveyInfo/types';
import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyStatus } from '../../../shared/enums/SurveyStatus';
import { ApprovalStatus } from '../../approval/enums';
import { Research } from '../../../shared/enums/Research';
import { PaymentStatus } from '../../../shared/enums/PaymentStatus';
import { OrderByEnum } from '../../questionnaire/types';

export const GET_SURVEY_LIST = 'GET_SURVEY_LIST';
export const GET_SURVEY_LIST_SEARCH = 'GET_SURVEY_LIST_SEARCH';
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST';
export const SET_SURVEY_LIST_TO_EXPORT = 'SET_SURVEY_LIST_TO_EXPORT';
export const SET_SURVEY_LIST_ERROR = 'SET_SURVEY_LIST_ERROR';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const DUPLICATE_SURVEY = 'DUPLICATE_SURVEY';
export const ARCHIVE_SURVEY = 'ARCHIVE_SURVEY';
export const RELOAD_SURVEY_LIST_STATE = 'RELOAD_SURVEY_LIST_STATE';
export const CHANGE_SERVEY_STATUS_TO_DRAFT = 'CHANGE_SERVEY_STATUS_TO_DRAFT';
export const CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED = 'CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED';
export const CHANGE_SERVEY_STATUS_TO_DRAFT_REJECTED = 'CHANGE_SERVEY_STATUS_TO_DRAFT_REJECTED';
export const UPDATE_SURVEY_PAYMENT_STATUS = 'UPDATE_SURVEY_PAYMENT_STATUS';
export const GET_IMPORT_SURVEY_LIST = 'GET_IMPORT_SURVEY_LIST';
export const SET_SURVEY_LIST_FROM_ISF = 'SET_SURVEY_LIST_FROM_ISF';
export const GET_SURVEYS_FROM_IMPORT_ISF_TABLE = 'GET_SURVEYS_FROM_IMPORT_ISF_TABLE';

export type SurveyListItem = SurveyInfo & {
	questionsNumber: number;
	createdAt: string;
	startTime: string;
	endTime: string;
	pixelId: string | null;
	approvalStatus: string; // TODO define actual statuses after approval flow
	status: SurveyStatus;
	credits: number;
	totalRespondents: number;
	surveyCost: number;
	research: Research;
	paymentStatus: PaymentStatus;
};
export type GetSurveyListAction = ReduxAction<
	typeof GET_SURVEY_LIST,
	{
		brandId: string;
		status?: ApprovalStatus;
		offset?: number;
		limit?: number;
		isForExportSurvey?: boolean;
	}
>;

export type GetSurveysFromImportIsfTableAction = ReduxAction<
	typeof GET_SURVEYS_FROM_IMPORT_ISF_TABLE,
	{
		questionnaireIds: [];
		assetId: string;
	}
>;

export type GetImportSurveyListAction = ReduxAction<
	typeof GET_IMPORT_SURVEY_LIST,
	{
		assetId: string;
	}
>;

export type SetSurveyListFromISFAction = ReduxAction<
	typeof SET_SURVEY_LIST_FROM_ISF,
	{
		data: any;
		isFileCorrupted: boolean;
	}
>;
export type GetSurveyListSearchAction = ReduxAction<
	typeof GET_SURVEY_LIST_SEARCH,
	{
		workspaceId: string;
		search: string;
		offset?: number;
		limit?: number;
		order?: OrderByEnum;
		status?: ApprovalStatus;
	}
>;
export type SetSurveyListAction = ReduxAction<typeof SET_SURVEY_LIST, { items: SurveyListItem[]; count: number }>;
export type SetSurveyListActionToExport = ReduxAction<
	typeof SET_SURVEY_LIST_TO_EXPORT,
	{ items: any; count: number; offset: any }
>;
export type SetSurveyListErrorAction = ReduxAction<typeof SET_SURVEY_LIST_ERROR, { error: string }>;
export type DeleteSurveyAction = ReduxAction<typeof DELETE_SURVEY, { id: string; index?: number; brandId?: string }>;
export type DuplicateSurveyAction = ReduxAction<typeof DUPLICATE_SURVEY, { id: string; name: string; index: number }>;
export type ArchiveSurveyAction = ReduxAction<typeof ARCHIVE_SURVEY, { id: string }>;
export type ReloadSurveyListStateAction = ReduxAction<typeof RELOAD_SURVEY_LIST_STATE, null>;
export type ChangeSurveyStatusToDraftAction = ReduxAction<typeof CHANGE_SERVEY_STATUS_TO_DRAFT, { surveyId: string }>;
export type ChangeSurveyStatusToDraftFulFilled = ReduxAction<
	typeof CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED,
	{ surveyId: string }
>;
export type ChangeSurveyStatusToDraftRejected = ReduxAction<
	typeof CHANGE_SERVEY_STATUS_TO_DRAFT_REJECTED,
	{ error: string }
>;
export type UpdateSurveyPaymentStatus = ReduxAction<
	typeof UPDATE_SURVEY_PAYMENT_STATUS,
	{ surveyId: string; paymentStatus: PaymentStatus }
>;

export type SurveyListState = {
	items: SurveyListItem[];
	fetchingSurveys: boolean;
	error: string;
	count: number;
	surveyToExport: [];
	surveyFromIsf: [];
	isFileCorrupted: boolean;
};

export type SurveyListReducer = ReduxReducer<
	SurveyListState,
	| GetSurveyListAction
	| GetSurveyListSearchAction
	| SetSurveyListAction
	| SetSurveyListErrorAction
	| DeleteSurveyAction
	| DuplicateSurveyAction
	| ArchiveSurveyAction
	| ReloadSurveyListStateAction
	| ChangeSurveyStatusToDraftAction
	| ChangeSurveyStatusToDraftFulFilled
	| ChangeSurveyStatusToDraftRejected
	| UpdateSurveyPaymentStatus
	| SetSurveyListActionToExport
	| GetImportSurveyListAction
	| SetSurveyListFromISFAction
	| GetSurveysFromImportIsfTableAction
>;
