import {
	ReadFunnelAnalyzerStagesPayload,
	ReadFunnelAnalyzerStages,
	READ_FUNNEL_ANALYZER_STAGES,
	ReadFunnelAnalyzerStagesFulfilled,
	READ_FUNNEL_ANALYZER_STAGES_FULFILLED,
	FunnelAnalyzerStages,
	UpdateFunnelAnalyzerStages,
	UpdateFunnelAnalyzerStagesFulfilled,
	UpdateFunnelAnalyzerStagesPayload,
	UPDATE_FUNNEL_ANALYZER_STAGES,
	UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED,
} from './types';

export const readFunnelAnalyzerStages = (payload: ReadFunnelAnalyzerStagesPayload): ReadFunnelAnalyzerStages => ({
	type: READ_FUNNEL_ANALYZER_STAGES,
	payload,
});

export const readFunnelAnalyzerStagesFulfilled = (
	payload: FunnelAnalyzerStages
): ReadFunnelAnalyzerStagesFulfilled => ({
	type: READ_FUNNEL_ANALYZER_STAGES_FULFILLED,
	payload: payload,
});

export const updateFunnelAnalyzerStages = (payload: UpdateFunnelAnalyzerStagesPayload): UpdateFunnelAnalyzerStages => ({
	type: UPDATE_FUNNEL_ANALYZER_STAGES,
	payload,
});

export const updateFunnelAnalyzerStagesFulfilled = (
	payload: FunnelAnalyzerStages
): UpdateFunnelAnalyzerStagesFulfilled => ({
	type: UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED,
	payload,
});
