import axios from '@utils/axios';

import { UpdateGlobalSettingsAction } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { setSurveyError } from '../../shared/actions';
import { questionnairesApiEndpoint } from './consts';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { setSurveyAction, updateGloballSettingsAction } from '../actions';
import { mapQuestionnaireResponseToSurvey } from './utils';
import { pend, fulfill, reject } from '../../../../promise/actions';
import { select } from 'redux-saga/effects';
import { setQuestion } from '../../sharedQuestions/actions';
import { assign } from 'shared/utils/assign';
import { GamifiedTheme, QuestionTheme } from 'components/SurveyBuilder/QuestionSettings/utils';

export function* updateGlobalSettings(action: UpdateGlobalSettingsAction) {
	const { surveyId, ...rest } = action.payload.info;

	const { questions } = yield select((s) => s.surveyBuilder);
	if (rest.gamified) {
		for (let key of Object.keys(questions)) {
			yield putEffect(setQuestion(key, assign({ pageBreak: true }, questions[key])));
		}
	}
	if (rest.theme) {
		for (let key of Object.keys(questions)) {
			yield putEffect(
				setQuestion(
					key,
					assign(
						{
							settings: {
								...questions[key].settings,
								questionTheme:
									rest.theme === GamifiedTheme.STOCK ? QuestionTheme.STOCK_TEMPLATE : QuestionTheme.DENTAL_TEMPLATE1,
							},
						},
						questions[key]
					)
				)
			);
		}
	}

	try {
		yield putEffect(pend(updateGloballSettingsAction));
		const { data } = yield axios.patch(getApiEndPointUrl(questionnairesApiEndpoint, surveyId), rest);
		yield putEffect(setSurveyAction(mapQuestionnaireResponseToSurvey(data)));
		yield putEffect(fulfill(updateGloballSettingsAction, data));
	} catch (e) {
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(updateGloballSettingsAction, getApiError(e)));
	}
}
