import { GET_SURVEY_BRAND, SET_SURVEY_BRAND, SurveyBrandReducer, SurveyBrandState } from './types';
import { set, updateAll } from 'shades';

const initialSurveyBrandState: SurveyBrandState = {
	info: {
		id: '',
		name: '',
		website: '',
		industry: '',
		keywords: [],
		brandAssets: [],
		competitors: [],
		products: [],
	},
	requestInProgress: false,
};

export const surveyBrandReducer: SurveyBrandReducer = (state = initialSurveyBrandState, action) => {
	switch (action.type) {
		case GET_SURVEY_BRAND:
			return set('requestInProgress')(true)(state);
		case SET_SURVEY_BRAND:
			return updateAll<SurveyBrandState>(
				set('requestInProgress')(false as boolean),
				set('info')(action.payload)
			)(state);
		default:
			return state;
	}
};
