import { cons, mod, set, updateAll } from 'shades';
import * as uuid from 'uuid';
import { QUES_TYPE } from '../../../../shared/consts/quesType';
import { MatrixColumnState, MatrixQuesState } from '../../../../shared/types/QuesTypes';
import { SurveyBuilderState, SurveyReducer } from '../shared/types';
import {
	ADD_COLUMN,
	ADD_ROW,
	DELETE_COLUMN,
	DELETE_ROW,
	EDIT_COLUMN_TEXT,
	EDIT_ROW_TEXT,
	MOVE_COLUMN,
	MOVE_ROW,
	SET_COLUMN_URL,
} from './types';

export const matrixReducer: SurveyReducer = (state, action) => {
	switch (action.type) {
		case ADD_COLUMN: {
			const { quesId, url } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const newColumnId: string = uuid.v4();
			const newColumn: MatrixColumnState = {
				columnId: newColumnId,
				text: '',
				url: url,
				rows: {},
				rowOrder: [],
			};

			return updateAll<SurveyBuilderState<MatrixQuesState>>(
				set('questions', quesId, 'columns', newColumnId)(newColumn),
				mod('questions', quesId, 'settings', 'columnOrder')(cons(newColumnId))
			)(state as SurveyBuilderState<MatrixQuesState> | any);
		}

		case DELETE_COLUMN: {
			const { quesId, columnId: delColumnId } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const newColumnOrder: string[] = ques.settings.columnOrder.filter((columnId: string) => columnId !== delColumnId);
			const { [delColumnId]: delColumn, ...remColumns } = ques.columns;

			return updateAll<SurveyBuilderState<MatrixQuesState>>(
				set('questions', quesId, 'columns')(remColumns),
				set('questions', 'settings', 'columnOrder')(newColumnOrder)
			)(state as SurveyBuilderState<MatrixQuesState> | any);
		}

		case MOVE_COLUMN: {
			const { quesId, srcIndex, destIndex } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const newColumnOrder: string[] = Array.from(ques.settings.columnOrder);
			const movedColumnId: string = newColumnOrder.splice(srcIndex, 1)[0];
			newColumnOrder.splice(destIndex, 0, movedColumnId);

			return set('questions', quesId, 'settings', 'columnOrder')(newColumnOrder)(
				state as SurveyBuilderState<MatrixQuesState> | any
			);
		}

		case EDIT_COLUMN_TEXT: {
			const { quesId, columnId, text } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			return set('questions', quesId, 'columns', columnId, 'text')(text)(
				state as SurveyBuilderState<MatrixQuesState> | any
			);
		}

		case SET_COLUMN_URL: {
			const { quesId, columnId, url } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			return set('questions', quesId, 'columns', columnId, 'url')(url)(
				state as SurveyBuilderState<MatrixQuesState> | any
			);
		}

		case ADD_ROW: {
			const { quesId, columnId } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const newRowId: string = uuid.v4();
			const newRow: string = '';

			return updateAll<SurveyBuilderState<MatrixQuesState>>(
				set('questions', quesId, 'columns', columnId, 'rows', newRowId)(newRow),
				mod('questions', quesId, 'columns', columnId, 'rowOrder')(cons(newRowId))
			)(state as SurveyBuilderState<MatrixQuesState> | any);
		}

		case DELETE_ROW: {
			const { quesId, columnId, rowId: delRowId } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const column: MatrixColumnState = ques.columns[columnId];
			const newRowOrder: string[] = column.rowOrder.filter((rowId: string) => rowId !== delRowId);
			const { [delRowId]: delRow, ...remRows } = column.rows;

			return updateAll<SurveyBuilderState<MatrixQuesState>>(
				set('questions', quesId, 'columns', columnId, 'rows')(remRows),
				set('questions', quesId, 'columns', columnId, 'rowOrder')(newRowOrder)
			)(state as SurveyBuilderState<MatrixQuesState> | any);
		}

		case MOVE_ROW: {
			const { quesId, columnId, srcIndex, destIndex } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			const column: MatrixColumnState = ques.columns[columnId];
			const newRowOrder: string[] = Array.from(column.rowOrder);
			const movedRowId: string = newRowOrder.splice(srcIndex, 1)[0];
			newRowOrder.splice(destIndex, 0, movedRowId);

			return set('questions', quesId, 'columns', columnId, 'rowOrder')(newRowOrder)(
				state as SurveyBuilderState<MatrixQuesState> | any
			);
		}

		case EDIT_ROW_TEXT: {
			const { quesId, columnId, rowId, text } = action.payload;

			const ques = state.questions[quesId];
			if (ques.type !== QUES_TYPE.MATRIX_QUES) return state;

			return set('questions', quesId, 'columns', columnId, 'rows', rowId)(text)(
				state as SurveyBuilderState<MatrixQuesState> | any
			);
		}
		default:
			return state;
	}
};
