import { DeleteQuesOption } from '../types';
import { select } from 'redux-saga/effects';
import { MatrixMatchQuesState, MultipleChoiceQuesState, OptionType } from '../../../../../shared/types/QuesTypes';
import axios from '@utils/axios';
import { surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { set, updateAll } from 'shades';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { without, omit, filter } from 'ramda';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { deleteQuesOption } from '../actions';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { QuestionPipe } from 'shared/types/QuestionLogic';

export function* deleteChoiceOption(action: DeleteQuesOption) {
	const { quesId, optionId, moderated, pipeId } = action.payload;
	const { questions, moderatedQuestion } = yield select((s) => s.surveyBuilder);
	const allQuestions = getQuestionsWithoutFunctions(questions);
	const isValidPipingOption =
		allQuestions[quesId]?.options[optionId]?.optionType === OptionType.Response &&
		allQuestions[allQuestions[quesId].options[optionId]?.pipe?.pipeId]?.orderPosition >
			allQuestions[quesId]?.orderPosition;
	const question = moderated
		? moderatedQuestion[quesId]
		: (allQuestions[quesId] as MultipleChoiceQuesState | MatrixMatchQuesState);
	if (!question) return;
	const updatedQuestion = updateAll<any>(
		set('logic', 'pipeAnswer')(filter((a: QuestionPipe) => a.pipeId !== pipeId)(question.logic?.pipeAnswer || [])),
		set('options')(omit([optionId], question.options)),
		set('columnOptions')(omit([optionId], question.columnOptions)),
		set('settings', 'optionOrder')(without([optionId], question.settings.optionOrder))
	)(question);

	// Update the orderPosition for the remaining options
	const updatedOptions = { ...updatedQuestion.options };
	let orderPosition = 1;

	for (const optionId in updatedOptions) {
		if (updatedOptions.hasOwnProperty(optionId)) updatedOptions[optionId].orderPosition = orderPosition;
		orderPosition++;
	}
	yield putEffect(setQuestion(question.id, updatedQuestion, undefined, moderated));
	try {
		yield putEffect(pend(deleteQuesOption));
		if (!!questions[quesId]) {
			// if (!isValidPipingOption) {
			yield axios.delete(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId));
			// }
		}
		if (!questions[quesId]) {
			yield axios.delete(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId));
		}
		yield putEffect(setQuestion(question.id, updatedQuestion, undefined, moderated));

		yield putEffect(fulfill(deleteQuesOption));
	} catch (e) {
		yield putEffect(reject(deleteQuesOption, getApiError(e)));
		yield putEffect(setPopup(getApiError(e)));
	}
}
