import { set } from 'shades';
import { SurveyReducer } from '../shared/types';
import {
	ADD_SECTION,
	ADD_STROKE,
	DELETE_SECTION,
	DELETE_STROKE,
	EDIT_SECTION_TEXT,
	EDIT_STROKE,
	MOVE_SECTION,
	MOVE_STROKE,
	SET_SECTION_IMAGE,
} from './types';

export const multipleOpinionScaleReducer: SurveyReducer = (state, action) => {
	switch (action.type) {
		case ADD_SECTION:
		case DELETE_STROKE:
		case DELETE_SECTION:
		case MOVE_SECTION:
		case ADD_STROKE:
		case MOVE_STROKE:
		case EDIT_STROKE:
		case EDIT_SECTION_TEXT:
		case SET_SECTION_IMAGE:
			return set('requestInProgress')(true)(state);

		default:
			return state;
	}
};
