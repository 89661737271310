import axios from '@utils/axios';
import { select } from 'redux-saga/effects';
import { queryClient } from '../../../../..';
import { allowPaymentPageView } from '../../../../../pages/Billing/Payment/utils';
import { DEFAULT_CALCULATED_SURVEY_COST } from '../../../../../pages/Survey/Targeting/useCalculateSurveyCost';
import { getWorkspaceQueryKey } from '../../../../../services/workspace';
import { routePaths } from '../../../../../shared/consts/routePaths';
import { Entity, PermissionAction } from '../../../../../shared/enums/Permissions';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { createRouteFromBlueprint } from '../../../../../shared/utils/routeMatcher';
import { hasPermissions } from '../../../../../shared/utils/withPermissionsCheck';
import { Action } from '../../../../approval/sagas/actionsSagas';
import { UserState } from '../../../../auth/types';
import { Niche } from '../../../../brand/niche/types';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { redirect } from '../../../../redirect/actions';
import { RedirectState } from '../../../../redirect/types';
import { setSurveyError } from '../../shared/actions';
import { scheduleSurveyAction } from '../actions';
import { ScheduleSurveyAction } from '../types';
import { questionnairesApiEndpoint } from './consts';

function calculateFinalCreditsAPI(surveyEntityId: string, selectedNiches: Niche[]) {
	if (!selectedNiches.length) return DEFAULT_CALCULATED_SURVEY_COST;
	const url = getApiEndPointUrl('surveys', surveyEntityId, 'calculate-final-credit');

	return axios.post(url, selectedNiches);
}

export function* scheduleSurvey(action: ScheduleSurveyAction) {
	const { surveyId, date, selectedNiches, surveyEntityId, userId } = action.payload;
	const { roles }: UserState = yield select((s) => s.user);

	const {
		info: { params },
	}: RedirectState = yield select((s) => s.redirect);

	const body = Object.assign({}, date, {
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		surveyDomain: window.location.origin,
		userId,
	});
	try {
		yield putEffect(pend(scheduleSurveyAction));
		let redirectionUrl = createRouteFromBlueprint(routePaths.survey.root, routePaths.survey.sub.success)(params);
		if (
			hasPermissions(roles, {
				actions: [PermissionAction.Review],
				entity: Entity.QuestionnaireEntity,
				workspaceId: params.workspaceId,
			})
		) {
			const { data: calculatedCredits } = yield calculateFinalCreditsAPI(surveyEntityId, selectedNiches);
			const redirectToPayment = calculatedCredits.surveyCost > 0;
			if (redirectToPayment) {
				allowPaymentPageView();
				redirectionUrl = createRouteFromBlueprint(
					routePaths.billing.root,
					routePaths.billing.sub.payment
				)({ surveyId });
			}
			yield axios.post(getApiEndPointUrl(questionnairesApiEndpoint, surveyId, Action.Approve), body);
		} else {
			yield axios.post(getApiEndPointUrl(questionnairesApiEndpoint, surveyId, 'request-review'), body);
		}
		yield putEffect(fulfill(scheduleSurveyAction));
		yield putEffect(redirect(redirectionUrl));
		yield putEffect(setSurveyError(''));
		queryClient.invalidateQueries(getWorkspaceQueryKey(params.workspaceId));
	} catch (e) {
		yield putEffect(reject(scheduleSurveyAction, getApiError(e)));
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
