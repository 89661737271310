import { SurveyBuilderState } from '../../shared/types';
import { select } from 'redux-saga/effects';
import { StoreState } from '../../../../index';
import { get, set } from 'shades';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setMandateLogic, setPopup, setQuestion } from '../actions';
import { SetMandateLogic } from '../types';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { surveyQuestionsApiUrl } from './consts';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import cloneDeep from 'lodash.clonedeep';
import { fulfill, pend, reject } from 'store/promise/actions';

export function* setMandateLogicSaga(action: SetMandateLogic) {
	const { id: quesId, mandate } = action.payload;
	const { questions }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
	// const mandate = cloneDeep(action.payload.mandate)
	if (!!questions[quesId]) {
		const question = set('mandate')({
			...(get('mandate')(questions[quesId]) as any),
			...mandate,
		})(questions[quesId]);
		try {
			yield putEffect(pend(setMandateLogic));
			yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, question.id), {
				questionId: question.id,
				questionType: question.type,
				mandate: cloneDeep(question.mandate),
				//  display: mapShowLogicToDisplayLogicRequest(question.logic.display)// USED THIS ONCE BACKEND IS CHANGED
			});
			yield putEffect(setQuestion(action.payload.id, question));
			yield putEffect(fulfill(setMandateLogic));
		} catch (e) {
			yield putEffect(reject(setMandateLogic, getApiError(e)));
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		question = set('mandate')({
			...(get('mandate')(question) as any),
			...mandate,
		})(question);

		try {
			yield putEffect(pend(setMandateLogic));
			yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, question.id), {
				questionId: question.id,
				questionType: question.type,
				mandate: cloneDeep(question.mandate),
				//  display: mapShowLogicToDisplayLogicRequest(question.logic.display)// USED THIS ONCE BACKEND IS CHANGED
			});
			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
			yield putEffect(fulfill(setMandateLogic));
		} catch (e) {
			yield putEffect(reject(setMandateLogic, getApiError(e)));
			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
