import { QuesState } from '../../shared/types/QuesTypes';
import { SurveyQuestions } from '../survey/surveyBuilder/shared/types';
import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { Survey } from '../survey/surveyBuilder/surveyInfo/types';
import { SectionsInSurvey, SurveySectionsQuestionStates } from 'shared/types/SurveyResponses';

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
export const SET_QUESTIONNAIRE_ERROR = 'SET_QUESTIONNAIRE_ERROR';
export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
export const READ_QUESTIONNAIRES_COUNT_BY_STATUS = 'READ_QUESTIONNAIRES_COUNT_BY_STATUS';
export const READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED = 'READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED';
export const READ_QUESTIONNAIRES = 'READ_QUESTIONNAIRES';
export const READ_QUESTIONNAIRES_FULFILLED = 'READ_QUESTIONNAIRES_FULFILLED';

export type QuestionnaireState<T = SurveySectionsQuestionStates | any> = {
	name: string;
	description: string;
	allowMultipleResponse: boolean;
	pixelId: string | null;
	questions: SectionsInSurvey<T>;
	quesOrder: string[];
	requestInProgress: boolean;
	howToPlay: string;
	error: string;
	allowEditAndResubmit: boolean;
	usePyxisLogo: boolean;
	isRightOriented: boolean;
	gamified: boolean;
	theme: string;
	surveys: any;
	countByStatus: Record<string, number>;
	questionnaires: any[];
	totalQuestionnaires: number;
	redirectURLs: { url: string; panel: string }[];
	instruction: { id: string; instruction: string[] }[];
	surveyTemplateSetting: any;
};

export type GetQuestionnaireActionPayload = {
	id: string;
	nicheId: string;
	moderated: boolean;
};

export type ReadQuestionnaireCountByStatusPayload = {
	brandId: string;
};

export enum OrderByEnum {
	ASC = 'ASC',
	DESC = 'DESC',
}

export type ReadQuestionnairesPayload = {
	brandId: string;
	limit?: number;
	offset?: number;
	order?: OrderByEnum;
};

export type ReadQuestionnairesFulfilledPayload = {
	items: any[];
	count: number;
};

export type ReadQuestionnaireCountByStatusFulfilledPayload = {
	status: string;
	count: number;
}[];

export type ReadQuestionnaireCountByStatus = ReduxAction<
	typeof READ_QUESTIONNAIRES_COUNT_BY_STATUS,
	ReadQuestionnaireCountByStatusPayload
>;

export type ReadQuestionnaireCountByStatusFulfilled = ReduxAction<
	typeof READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED,
	ReadQuestionnaireCountByStatusFulfilledPayload
>;

export type ReadQuestionnaires = ReduxAction<typeof READ_QUESTIONNAIRES, ReadQuestionnairesPayload>;

export type ReadQuestionnairesFulfilled = ReduxAction<
	typeof READ_QUESTIONNAIRES_FULFILLED,
	ReadQuestionnairesFulfilledPayload
>;

export type GetQuestionnaireAction = ReduxAction<typeof GET_QUESTIONNAIRE, GetQuestionnaireActionPayload>;

export type SetQuestionnaireActionPayload = Survey;

export type SetQuestionnaireAction = ReduxAction<typeof SET_QUESTIONNAIRE, SetQuestionnaireActionPayload>;

export type SetQuestionnaireErrorAction = ReduxAction<typeof SET_QUESTIONNAIRE_ERROR, { error: string }>;

export type SubmitSurveyAction = ReduxAction<
	typeof SUBMIT_SURVEY,
	{ removeResponsesForQuestionIds?: string[]; duration?: any }
>;

export type QuestionnaireReducer = ReduxReducer<
	QuestionnaireState,
	| GetQuestionnaireAction
	| SetQuestionnaireAction
	| SetQuestionnaireErrorAction
	| SubmitSurveyAction
	| ReadQuestionnaireCountByStatus
	| ReadQuestionnaireCountByStatusFulfilled
	| ReadQuestionnaires
	| ReadQuestionnairesFulfilled
>;
