// @ts-nocheck
// TODO FIX TYPES WHEN TIME
import { Survey } from '../../store/survey/surveyBuilder/surveyInfo/types';
import { SurveyQuestions } from '../../store/survey/surveyBuilder/shared/types';
// import { sortBy } from 'ramda';
import { QuesState } from '../types/QuesTypes';
import { set } from 'shades';
import { SectionsInSurvey } from 'shared/types/SurveyResponses';
import { map, prop, sortBy, values } from 'ramda';

type SurveyBundle = { questions: SectionsInSurvey; quesOrder: string[] };
export const bundleSurveyTemplates = (surveys: { [id: string]: Survey }, isModerated?: boolean): SurveyBundle => {
	return sortBy((a) => a.orderPosition, Object.values(surveys)).reduce(
		(allQuestions, { questions: surveyQues, redirectURLs, instruction, moderatedTesting }, surveyIndex, allSurveys) => {
			// pick moderated questions instead of survey questions in case of moderated testing
			const questions = isModerated ? moderatedTesting.questions : surveyQues;
			const quesOrder: any = map(
				prop('id'),
				sortBy(
					prop('orderPosition'),
					values(questions)?.filter((ques) => !ques.parentQuestionId)
				)
			);
			/*
       if some question have a skipIf logic that has action "end"  and current survey is not last in the bundle
       we need to override that to first question of next survey
       */
			const surveyQuestions: SurveyQuestions =
				surveyIndex === allSurveys.length - 1 // final survey
					? questions
					: quesOrder.reduce((questions, id) => {
							const question: QuesState = questions[id].logic.display.skipIf.reduce((question, logic, index) => {
								if (logic?.action?.type === 'end') {
									const firstQuestionOfNextSurvey = Object.values(allSurveys[surveyIndex + 1].questions || {}).find(
										({ orderPosition }) => orderPosition === 1
									) as QuesState;
									return set(
										'logic',
										'display',
										'skipIf',
										index,
										'action'
									)({
										type: 'question',
										value: firstQuestionOfNextSurvey.id,
									})(question);
								}

								return question;
							}, questions[id]);

							return {
								...questions,
								[id]: question,
							};
					  }, questions);

			return {
				questions: { ...allQuestions.questions, ...surveyQuestions },
				quesOrder: [...allQuestions.quesOrder, ...quesOrder],
				redirectURLs,
				instruction,
			};
		},
		{ questions: {}, quesOrder: [] } as SurveyBundle
	);
};
