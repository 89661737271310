import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import {
	MultipleChoiceResponseType,
	LinearRangeResponseType,
	OpinionScaleResponseType,
	ResponseState,
	NumberResponseType,
	RatingResponseType,
	YesNoResponseType,
	VideoUploadResponseType,
	CreativeResponseType,
	TextBoxResponseType,
	MatrixMatchResponseType,
	ScreenerResponseType,
	ImageResponseType,
	TimeZoneResponseType,
	ModerateSlotSelectType,
	ModerateSlotUserType,
	EmailResponseType,
	RankingResponseType,
} from '../../../shared/types/ResponseTypes';
import { MethodologyType, OptionType } from '../../../shared/types/QuesTypes';

export type SurveyResponseState<T = ResponseState> = {
	responses: {
		[id: string]: T;
	};
	quesOrder: string[];
	skipped: string[];
	currQuesIndex: number;
	startTime: string;
};

export const SET_SURVEY_RESPONSE = 'SET_SURVEY_RESPONSE';
export const SETUP_FORM = 'SETUP_FORM';
export const SET_CURR_QUES_INDEX = 'SET_CURR_QUES_INDEX';
export const CREATE_SKIPPED = 'CREATE_SKIPPED';
export const DELETE_SKIPPED = 'DELETE_SKIPPED';
export const SET_MULTIPLE_CHOICE_RESPONSE = 'SET_MULTIPLE_CHOICE_RESPONSE';
export const SET_RANKING_RESPONSE = 'SET_RANKING_RESPONSE';
export const SET_OPINION_SCALE_RESPONSE = 'SET_OPINION_SCALE_RESPONSE';
export const SET_NUMBER_RESPONSE = 'SET_NUMBER_RESPONSE';
export const SET_TEXT_BOX_RESPONSE = 'SET_TEXT_BOX_RESPONSE';
export const SET_EMAIL_RESPONSE = 'SET_EMAIL_RESPONSE';
export const SET_RATING_RESPONSE = 'SET_RATING_RESPONSE';
export const SET_YES_NO_RESPONSE = 'SET_YES_NO_RESPONSE';
export const SET_VIDEO_UPLOAD_RESPONSE = 'SET_VIDEO_UPLOAD_RESPONSE';
export const SET_CREATIVE_RESPONSE = 'SET_CREATIVE_RESPONSE';
export const SET_MULTIPLE_OPINION_SCALE_RESPONSE = 'SET_MULTIPLE_OPINION_SCALE_RESPONSE';
export const SET_MATRIX_RESPONSE = 'SET_MATRIX_RESPONSE';
export const SET_MATRIX_MATCH_RESPONSE = 'SET_MATRIX_MATCH_RESPONSE';
export const SET_SCREENER_RESPONSE = 'SET_SCREENER_RESPONSE';
export const SET_LINEARRANGE_RESPONSE = 'SET_LINEARRANGE_RESPONSE';
export const SET_IMAGE_QUES_RESPONSE = 'SET_IMAGE_QUES_RESPONSE';
export const SET_TIMEZONE_RESPONSE = 'SET_TIMEZONE_RESPONSE';
export const SET_TIMESLOT_MODERATE = 'SET_TIMESLOT_MODERATE';
export const SET_TIMESLOT_USER_DETAIL = 'SET_TIMESLOT_USER_DETAIL';
export type SetupFormPayload = {
	responses: {
		[id: string]: ResponseState;
	};
	quesOrder: string[];
	clearSkipped?: boolean;
};

export type SetResponsePayload<T> = {
	quesId: string;
	response: T;
	settings: any;
};

export type SetTimeZonePayload<T> = {
	quesId: string;
	response: T | undefined;
};

export type SetTimeSlotModeratePayload<T> = {
	quesId: string;
	response: T;
};

export type SetUserTimeSlotPayload<T> = {
	quesId: string;
	response: T;
};

export type OptionResponse = {
	value: string;
	origin: string; // Option that is actual value (for piped responses this might be couple of levels deep)
	text?: string;
	url?: string;
	optionType?: OptionType;
	methodology?: MethodologyType;
	ranking?: number;
	anchorOnRandomization?: boolean;
	parentOptionId?: string;
	groupAnchorOnRandomization?: boolean;
};

export type SetSurveyResponse = ReduxAction<typeof SET_SURVEY_RESPONSE, SurveyResponseState>;
export type SetupForm = ReduxAction<typeof SETUP_FORM, SetupFormPayload>;
export type SetCurrQuesIndex = ReduxAction<typeof SET_CURR_QUES_INDEX, { index: number }>;
export type CreateSkipped = ReduxAction<typeof CREATE_SKIPPED, { quesId: string }>;
export type DeleteSkipped = ReduxAction<typeof DELETE_SKIPPED, { quesId: string }>;
export type SetMultipleChoiceResponse = ReduxAction<
	typeof SET_MULTIPLE_CHOICE_RESPONSE,
	SetResponsePayload<MultipleChoiceResponseType>
>;
export type SetRankingResponse = ReduxAction<typeof SET_RANKING_RESPONSE, SetResponsePayload<RankingResponseType>>;
export type SetOpinionScaleResponse = ReduxAction<
	typeof SET_OPINION_SCALE_RESPONSE,
	SetResponsePayload<OpinionScaleResponseType>
>;
export type SetNumberResponse = ReduxAction<typeof SET_NUMBER_RESPONSE, SetResponsePayload<NumberResponseType>>;
export type SetTextBoxResponse = ReduxAction<typeof SET_TEXT_BOX_RESPONSE, SetResponsePayload<TextBoxResponseType>>;
export type SetEmailResponse = ReduxAction<typeof SET_EMAIL_RESPONSE, SetResponsePayload<EmailResponseType>>;
export type SetRatingResponse = ReduxAction<typeof SET_RATING_RESPONSE, SetResponsePayload<RatingResponseType>>;
export type SetYesNoResponse = ReduxAction<typeof SET_YES_NO_RESPONSE, SetResponsePayload<YesNoResponseType>>;
export type SetVideoUploadResponse = ReduxAction<
	typeof SET_VIDEO_UPLOAD_RESPONSE,
	SetResponsePayload<VideoUploadResponseType>
>;
export type SetCreativeResponse = ReduxAction<typeof SET_CREATIVE_RESPONSE, SetResponsePayload<CreativeResponseType>>;
export type SetMultipleOpinionScaleResponse = ReduxAction<
	typeof SET_MULTIPLE_OPINION_SCALE_RESPONSE,
	SetResponsePayload<OpinionScaleResponseType> & { sectionId: string }
>;
export type SetMatrixResponse = ReduxAction<
	typeof SET_MATRIX_RESPONSE,
	{ columnId: string } & SetResponsePayload<MultipleChoiceResponseType>
>;
export type SetMatrixMatchResponse = ReduxAction<
	typeof SET_MATRIX_MATCH_RESPONSE,
	SetResponsePayload<MatrixMatchResponseType>
>;

export type SetScreenerResponse = ReduxAction<typeof SET_SCREENER_RESPONSE, SetResponsePayload<ScreenerResponseType>>;
export type SetLinearRangeResponse = ReduxAction<
	typeof SET_LINEARRANGE_RESPONSE,
	SetResponsePayload<LinearRangeResponseType>
>;
export type SetImageQuesResponse = ReduxAction<typeof SET_IMAGE_QUES_RESPONSE, SetResponsePayload<ImageResponseType>>;

export type SetTimeZoneResponse = ReduxAction<typeof SET_TIMEZONE_RESPONSE, SetTimeZonePayload<TimeZoneResponseType>>;

export type SetTimeSlotModerateResponse = ReduxAction<
	typeof SET_TIMESLOT_MODERATE,
	SetTimeSlotModeratePayload<ModerateSlotSelectType>
>;

export type SetTimeSlotUserResponse = ReduxAction<
	typeof SET_TIMESLOT_USER_DETAIL,
	SetUserTimeSlotPayload<ModerateSlotUserType>
>;

export type SurveyResponseAction =
	| SetSurveyResponse
	| SetupForm
	| SetCurrQuesIndex
	| CreateSkipped
	| DeleteSkipped
	| SetMultipleChoiceResponse
	| SetRankingResponse
	| SetOpinionScaleResponse
	| SetNumberResponse
	| SetTextBoxResponse
	| SetEmailResponse
	| SetRatingResponse
	| SetYesNoResponse
	| SetCreativeResponse
	| SetMultipleOpinionScaleResponse
	| SetMatrixResponse
	| SetLinearRangeResponse
	| SetVideoUploadResponse
	| SetMatrixMatchResponse
	| SetScreenerResponse
	| SetImageQuesResponse
	| SetTimeZoneResponse
	| SetTimeSlotModerateResponse
	| SetTimeSlotUserResponse;

export type SurveyResponseActionType =
	| typeof SET_SURVEY_RESPONSE
	| typeof SETUP_FORM
	| typeof SET_CURR_QUES_INDEX
	| typeof CREATE_SKIPPED
	| typeof DELETE_SKIPPED
	| typeof SET_MULTIPLE_CHOICE_RESPONSE
	| typeof SET_RANKING_RESPONSE
	| typeof SET_OPINION_SCALE_RESPONSE
	| typeof SET_NUMBER_RESPONSE
	| typeof SET_TEXT_BOX_RESPONSE
	| typeof SET_EMAIL_RESPONSE
	| typeof SET_RATING_RESPONSE
	| typeof SET_YES_NO_RESPONSE
	| typeof SET_VIDEO_UPLOAD_RESPONSE
	| typeof SET_CREATIVE_RESPONSE
	| typeof SET_MULTIPLE_OPINION_SCALE_RESPONSE
	| typeof SET_MATRIX_RESPONSE
	| typeof SET_MATRIX_MATCH_RESPONSE
	| typeof SET_LINEARRANGE_RESPONSE
	| typeof SET_SCREENER_RESPONSE
	| typeof SET_IMAGE_QUES_RESPONSE
	| typeof SET_TIMEZONE_RESPONSE
	| typeof SET_TIMESLOT_MODERATE
	| typeof SET_TIMESLOT_USER_DETAIL;

export type SurveyResponseReducer = ReduxReducer<SurveyResponseState, SurveyResponseAction>;
