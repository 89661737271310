import {
	ReadBrandCompetitorsPayload,
	ReadBrandCompetitors,
	READ_BRAND_COMPETITORS,
	BrandCompetitor,
	ReadBrandCompetitorsFulfilled,
	READ_BRAND_COMPETITORS_FULFILLED,
	CreateBrandCompetitorsPayload,
	CreateBrandCompetitors,
	CREATE_BRAND_COMPETITORS,
	CreateBrandCompetitorsFulfilled,
	CREATE_BRAND_COMPETITORS_FULFILLED,
} from './types';

export const readBrandCompetitors = (payload: ReadBrandCompetitorsPayload): ReadBrandCompetitors => ({
	type: READ_BRAND_COMPETITORS,
	payload,
});

export const readBrandCompetitorsFulfilled = (payload: BrandCompetitor[]): ReadBrandCompetitorsFulfilled => ({
	type: READ_BRAND_COMPETITORS_FULFILLED,
	payload: payload,
});

export const createBrandCompetitors = (payload: CreateBrandCompetitorsPayload): CreateBrandCompetitors => ({
	type: CREATE_BRAND_COMPETITORS,
	payload,
});

export const createBrandCompetitorsFulfilled = (payload: BrandCompetitor[]): CreateBrandCompetitorsFulfilled => ({
	type: CREATE_BRAND_COMPETITORS_FULFILLED,
	payload: payload,
});
