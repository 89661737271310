import {
	ReadVideoRecordingsPayload,
	ReadVideoRecordings,
	READ_VIDEO_RECORDINGS,
	ReadVideoRecordingsFulfilled,
	READ_VIDEO_RECORDINGS_FULFILLED,
	VideoRecordings,
	UPDATE_VIDEO_RECORDINGS,
	UPDATE_VIDEO_RECORDINGS_FULFILLED,
	UPDATE_VIDEO_MARKERS,
	UPDATE_VIDEO_MARKERS_FULFILLED,
	UpdateVideoRecordingPayload,
	UpdateVideoRecordings,
	UpdateVideoRecordingFulfilled,
	UpdateVideoMarkersPayload,
	UpdateVideoMarkers,
	UpdateVideoMarkersFulfilled,
} from './types';

export const readVideoRecordings = (payload: ReadVideoRecordingsPayload): ReadVideoRecordings => ({
	type: READ_VIDEO_RECORDINGS,
	payload,
});

export const readVideoRecordingsFulfilled = (payload: VideoRecordings): ReadVideoRecordingsFulfilled => ({
	type: READ_VIDEO_RECORDINGS_FULFILLED,
	payload: payload,
});

export const updateVideoRecording = (payload: UpdateVideoRecordingPayload): UpdateVideoRecordings => ({
	type: UPDATE_VIDEO_RECORDINGS,
	payload,
});

export const updateVideoRecordingFulfilled = (payload: UpdateVideoRecordingPayload): UpdateVideoRecordingFulfilled => ({
	type: UPDATE_VIDEO_RECORDINGS_FULFILLED,
	payload: payload,
});

export const updateVideoMarkers = (payload: UpdateVideoMarkersPayload): UpdateVideoMarkers => ({
	type: UPDATE_VIDEO_MARKERS,
	payload,
});

export const updateVideoMarkersFulfilled = (payload: UpdateVideoMarkersPayload): UpdateVideoMarkersFulfilled => ({
	type: UPDATE_VIDEO_MARKERS_FULFILLED,
	payload: payload,
});
