import { READ_INTERESTS_FULFILLED, TargetingInterestsAction, TargetingInterestsReducer } from './types';

export const targetingInterestReducer: TargetingInterestsReducer = (state, action: TargetingInterestsAction) => {
	switch (action.type) {
		case READ_INTERESTS_FULFILLED:
			return { ...state, interests: action.payload };
		default:
			return state;
	}
};
