import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { AuthActionError } from '../../shared/types/ResponseError';

export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_SIGN_UP_ERROR = 'SET_SIGN_UP_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGN_UP_USER = 'SIGN_IN_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILED = 'GET_USER_ROLES_FAILED';

type Permission = {
	action: string;
	groupLabel: string;
	id: string;
	label: string;
	entityType: string;
};

type Brand = {
	id: string;
	name: string;
};

type Workspace = {
	brands: Brand[];
	id: string;
	name: string;
};
export type Role = {
	id: string;
	name: string;
	workspace: Workspace;
	permissions: Permission[];
};

export type UserState = {
	authenticated: boolean;
	roles: Role[];
	isUserSignedUp: boolean;
};

export type LoginPayload = {
	email: string;
	password: string;
	rememberUser: boolean;
};

export type ResetPasswordPayload = {
	email: string;
};

export type SignUpPayload = {
	email: string;
	password: string;
	companyName: string;
	firstName: string;
	lastName: string;
	passwordConfirm: string;
	isAgreedToTerms: string;
	rememberUser: boolean;
};

export type LoginUser = ReduxAction<typeof LOGIN_USER, LoginPayload>;
export type SetLoginErrorAction = ReduxAction<typeof SET_LOGIN_ERROR, string | null | AuthActionError>;

export type SetRegistrationStatusAction = ReduxAction<typeof SET_TOKEN, string>;
export type LogoutUserAction = ReduxAction<typeof LOGOUT_USER, null>;
export type SignUpUser = ReduxAction<typeof SIGN_UP_USER, SignUpPayload>;
export type ResetPasswordAction = ReduxAction<typeof RESET_PASSWORD, ResetPasswordPayload>;

export type ReadUserRoles = ReduxAction<typeof GET_USER_ROLES, null>;
export type ReadUserRolesFulfilled = ReduxAction<typeof GET_USER_ROLES_SUCCESS, Role[]>;
export type ReadUserRolesRejected = ReduxAction<typeof GET_USER_ROLES_FAILED, Error>;

export type UserAction =
	| LoginUser
	| SetLoginErrorAction
	| SetRegistrationStatusAction
	| LogoutUserAction
	| SignUpUser
	| ResetPasswordAction
	| ReadUserRoles
	| ReadUserRolesFulfilled
	| ReadUserRolesRejected;
export type UserReducer = ReduxReducer<UserState, UserAction>;
