import axios from '@utils/axios';
import { call, takeLeading } from 'redux-saga/effects';

import { environment } from '../../environment';
import { putEffect } from '../../shared/utils/putEffect';
import {
	readUserRolesFulfilled,
	readUserRolesRejected,
	resetPasswordAction,
	loginUser,
	signUpUser,
	readUserRoles,
	logoutUser,
} from './actions';
import {
	LOGIN_USER,
	LoginUser,
	SignUpUser,
	SIGN_UP_USER,
	ResetPasswordAction,
	RESET_PASSWORD,
	GET_USER_ROLES,
	LOGOUT_USER,
} from './types';
import { routePaths } from '../../shared/consts/routePaths';
import { getApiError } from '../../shared/utils/getApiError';
import { createRouteFromBlueprint } from '../../shared/utils/routeMatcher';
import { toSentance } from '../../shared/utils/camelize';
import { pend, fulfill, reject } from '../promise/actions';
import mixpanel from 'mixpanel-browser';
import { omit } from 'ramda';
export const meEndPoint = `profiles/me`;
export const loginEndpoint = `auth/login`;
export const signoutEndpoint = `auth/signout`;
export const signInEndPoint = `auth/register`;
export const resetPassEndpoint = `auth/reset-password`;
export const rolesEndPoint = `roles/me`;

const getUserRolesRequest = () => {
	var myHeaders = new Headers();
	myHeaders.append('pragma', 'no-cache');
	myHeaders.append('cache-control', 'no-cache');
	return axios.get(`${environment.apiUrl}${rolesEndPoint}`, {
		cache: 'no-store',
		headers: myHeaders,
	});
};

function* loginUserSaga(action: LoginUser) {
	try {
		yield putEffect(pend(loginUser));
		yield axios.post(`${environment.apiUrl}${loginEndpoint}`, action.payload);
		yield putEffect(readUserRoles());
		setTimeout(() => {
			window.location.href = createRouteFromBlueprint(routePaths.home.root)({});
		}, 0);
		yield putEffect(fulfill(loginUser, action.payload));
	} catch (e) {
		yield putEffect(reject(loginUser, toSentance(getApiError(e))));
	}
}
async function sendSignupInfoToSalesForceAPI(payload: any) {
	const { oid, utm_parameter_key } = environment.salesforce;
	if (environment.isLite || !oid || !utm_parameter_key) return;

	try {
		await fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', {
			method: 'POST',
			mode: 'no-cors',
			body: new URLSearchParams({
				first_name: payload.firstName,
				email: payload.email,
				last_name: payload.lastName,
				company: payload.companyName,
				retURL: 'http://',
				oid,
				'00N2w00000JUAWZ': environment.salesforce.oid || '',
			}).toString(),
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	} catch (error) {
		console.error(error);
	}
}

function* signUpUserSaga(action: SignUpUser) {
	try {
		yield putEffect(pend(signUpUser));
		yield axios.post(`${environment.apiUrl}${signInEndPoint}`, {
			...action.payload,
			rememberUser: true,
			productUrl: window.location.origin,
		});
		yield putEffect(fulfill(signUpUser, action.payload));
		// if (search) {
		yield sendSignupInfoToSalesForceAPI(omit(['password'], action.payload));
		//   }
	} catch (e) {
		yield putEffect(reject(signUpUser, toSentance(getApiError(e))));
	}
}

function* resetPassword(action: ResetPasswordAction) {
	try {
		yield putEffect(pend(resetPasswordAction));
		yield axios.post(`${environment.apiUrl}${resetPassEndpoint}`, {
			...action.payload,
			productUrl: window.location.origin,
		});
		yield putEffect(fulfill(resetPasswordAction, action.payload));
	} catch (e) {
		yield putEffect(reject(resetPasswordAction, getApiError(e)));
	}
}

export function* readUserRolesSaga() {
	try {
		yield putEffect(pend(readUserRoles));
		const rolePermissions = yield call(getUserRolesRequest);
		yield putEffect(readUserRolesFulfilled(rolePermissions.data));
		yield putEffect(fulfill(readUserRoles));
	} catch (e) {
		yield putEffect(readUserRolesRejected(e as Error));
		yield putEffect(fulfill(readUserRoles));
	}
}

function* logoutSaga() {
	try {
		yield putEffect(pend(logoutUser));
		mixpanel.track('Sign Out');
		mixpanel.people.increment('Number of Sign out');
		yield axios.post(`${environment.apiUrl}${signoutEndpoint}`);
		yield putEffect(fulfill(logoutUser));
	} catch (e) {
		yield putEffect(reject(logoutUser, getApiError(e)));
	}
}

export const userSagas = [
	function* getLoginWatcher() {
		yield takeLeading(LOGIN_USER, loginUserSaga);
	},

	function* getSignUpWatcher() {
		yield takeLeading(SIGN_UP_USER, signUpUserSaga);
	},

	function* getResetPassWatcher() {
		yield takeLeading(RESET_PASSWORD, resetPassword);
	},

	function* getUserRolesWatcher() {
		yield takeLeading(GET_USER_ROLES, readUserRolesSaga);
	},
	function* logoutUserWatcher() {
		yield takeLeading(LOGOUT_USER, logoutSaga);
	},
];
