import {
	ApprovalReducer,
	ApprovalState,
	APPROVE,
	APPROVE_SUCCESS,
	GET_MESSAGES,
	GET_MESSAGES_SUCCESS,
	GET_QUESTIONNAIRES_COUNT,
	GET_QUESTIONNAIRES_COUNT_SUCCESS,
	POST_MESSAGE,
	POST_MESSAGE_SUCCESS,
	REJECT,
	REJECT_SUCCESS,
	SET_APPROVAL_ERROR,
} from './types';

const initialUsersState: ApprovalState = {
	error: '',
	loading: true,
	messages: [],
	count: 0,
};

export const approvalReducer: ApprovalReducer = (state = initialUsersState, action) => {
	switch (action.type) {
		case GET_MESSAGES:
		case APPROVE:
		case REJECT:
		case POST_MESSAGE:
		case GET_QUESTIONNAIRES_COUNT: {
			return {
				...state,
				loading: true,
			};
		}
		case GET_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				messages: action.payload,
			};
		case POST_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				messages: [...state.messages, action.payload],
			};
		case GET_QUESTIONNAIRES_COUNT_SUCCESS:
			return {
				...state,
				loading: false,
				count: action.payload,
			};
		case APPROVE_SUCCESS:
		case REJECT_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case SET_APPROVAL_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};
