import {
	GET_USERS,
	DasboardReducer,
	DashboardAction,
	DashboardState,
	SET_USERS,
	GET_ROLES,
	SET_ROLES,
	UPDATE_ROLES,
	DELETE_WORKSPACE,
	RENAME_WORKSPACE,
	REMOVE_USER_FROM_WORKSPACES,
	DELETE_USER_FROM_WORKSPACES,
	SET_WORKSPACE_DASHBOARD_ERROR,
	SET_USER_DASHBOARD_ERROR,
	UPDATE_USER,
	RENAME_WORKSPACE_REQUEST,
	RENAME_WORKSPACE_ERROR,
	ADD_NEW_USERS,
	ADD_NEW_USERS_FULFILLED,
	SET_DASHBOARD_LOADING,
	GET_DASHBOARD_WORKSPACES,
	SET_DASHBOARD_WORKSPACES,
	UserObj,
} from './types';
import { updateUsers, upsertUsers, updateWorkspaceUsers, addUsersToWorkspace } from './utils';

export const initialState: DashboardState = {
	users: [],
	totalUsers: 0,
	workspaces: [],
	totalWorkspaces: 0,
	workspaceRoles: [],
	loading: false,
	workspaceDashboardError: null,
	userDashboardError: null,
	renameWorkspaceError: null,
};

export const dashboardReducer: DasboardReducer = (state = initialState, action: DashboardAction) => {
	switch (action.type) {
		case GET_USERS:
		case GET_DASHBOARD_WORKSPACES:
			return { ...state, loading: true };
		case SET_USERS:
			return {
				...state,
				users: action.payload.items,
				totalUsers: action.payload.count,
				loading: false,
			};
		case SET_DASHBOARD_WORKSPACES:
			return {
				...state,
				workspaces: action.payload.items,
				totalWorkspaces: action.payload.count,
				isLoading: false,
			};
		case ADD_NEW_USERS:
			return {
				...state,
				loading: true,
				error: null,
			};
		case ADD_NEW_USERS_FULFILLED: {
			if (!action.payload.length)
				return {
					...state,
					loading: false,
					error: null,
				};

			const userObj: UserObj = {
				user: action.payload[0].user,
				workspaceUsers: action.payload.map((el) => ({
					id: el.id,
					role: el.role,
				})),
			};

			return {
				...state,
				loading: false,
				error: null,
				users: upsertUsers(state.users, [userObj]),
				workspaces: addUsersToWorkspace(state.workspaces, action.payload),
			};
		}
		case GET_ROLES:
		case UPDATE_ROLES:
		case REMOVE_USER_FROM_WORKSPACES:
			return {
				...state,
				loading: true,
				workspaceDashboardError: null,
			};

		case SET_ROLES:
			return { ...state, workspaceRoles: action.payload, loading: false };
		case DELETE_WORKSPACE:
			return {
				...state,
				workspaces: state.workspaces.filter((workspaceObj) => workspaceObj.workspace.id !== action.payload),
			};
		case RENAME_WORKSPACE_REQUEST:
			return {
				...state,
				renameWorkspaceError: null,
				loading: true,
			};
		case RENAME_WORKSPACE:
			return {
				...state,
				loading: false,
				workspaces: state.workspaces.map((workspaceObj) => {
					if (workspaceObj.workspace.id === action.payload.workspaceId) {
						return {
							...workspaceObj,
							workspace: {
								...workspaceObj.workspace,
								name: action.payload.newWorkspaceName,
							},
						};
					}
					return workspaceObj;
				}),
			};
		case RENAME_WORKSPACE_ERROR:
			return {
				...state,
				loading: false,
				renameWorkspaceError: action.payload,
			};
		case DELETE_USER_FROM_WORKSPACES:
			return {
				...state,
				users: state.users.filter((o) => o.user.id !== action.payload),
				loading: false,
			};
		case SET_WORKSPACE_DASHBOARD_ERROR:
			return {
				...state,
				workspaceDashboardError: action.payload,
				loading: false,
			};
		case SET_USER_DASHBOARD_ERROR:
			return {
				...state,
				userDashboardError: action.payload,
				loading: false,
			};
		case UPDATE_USER: {
			if (!action.payload.length)
				return {
					...state,
					loading: false,
				};

			const userObj: UserObj = {
				user: action.payload[0].user,
				workspaceUsers: action.payload.map((el) => ({
					id: el.id,
					role: el.role,
				})),
			};

			return {
				...state,
				loading: false,
				users: updateUsers(state.users, [userObj]),
				workspaces: updateWorkspaceUsers(state.workspaces, action.payload),
			};
		}
		case SET_DASHBOARD_LOADING:
			return { ...state, loading: false };
		default:
			return state;
	}
};
