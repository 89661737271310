import { Questionnaire, Survey } from '../types';
import {
	ChoiceOptionResponse,
	DisplayOptionLogicResponse,
	QuestionnaireResponse,
	QuestionsStates,
	SectionResponse,
	SectionStrokeResponse,
	SurveyApiResponse,
	SurveyQuestionDisplayLogicResponse,
	SurveyQuestionResponse,
} from '../../../../../shared/types/SurveyResponses';
import {
	MultipleChoiceOption,
	MultipleChoiceQuesState,
	MultipleOpinionScaleQuesState,
	OptionType,
	QuesState,
	RankingQuesState,
	SectionState,
	TextBoxQuesState,
} from '../../../../../shared/types/QuesTypes';
import { pickAll, sortBy } from 'ramda';
import { set, updateAll } from 'shades';
import { v4 as uuidv4 } from 'uuid';
import { QUES_TYPE } from '../../../../../shared/consts/quesType';
import {
	DisplayLogic,
	DisplayOptionLogic,
	QuestionPipe,
	ShowLogic,
	SkipLogic,
} from '../../../../../shared/types/QuestionLogic';
import { mapPipeValueToPipeAnswerType } from '../../utils';
import { NONE_OF_THE_ABOVE_OPTION } from '../../../../../shared/consts/questionare';
import { getFormattedTime } from '../../../../../shared/utils/datetime';
import { Research } from '../../../../../shared/enums/Research';
import moment from 'moment-timezone';

export const processMultipleChoiceQuestionOptions = (optionsResponse: ChoiceOptionResponse[]) => {
	const pipeAnswer: QuestionPipe[] = [];
	const options = Object.fromEntries(
		optionsResponse.map((option) => {
			if (option.optionType === OptionType.Pipe) {
				pipeAnswer.push({ pipeAnswer: mapPipeValueToPipeAnswerType(option.pipeValue), pipeId: option.pipeId });
			}
			return [
				option.id,
				{
					...pickAll(['id', 'orderPosition', 'optionType'], option),
					...(option.asset ? { url: option.asset.url, assetId: option.asset.id } : {}),
					...(option.pipeId
						? {
								pipe: {
									pipeId: option.pipeId,
									pipeAnswer:
										option.optionType === OptionType.Pipe
											? mapPipeValueToPipeAnswerType(option.pipeValue)
											: option.pipeValue,
								},
						  }
						: {}),
					value:
						option.optionType === OptionType.None
							? option?.value
								? option?.value
								: NONE_OF_THE_ABOVE_OPTION
							: option?.value,
					methodology: option.methodology,
					pipeType: option.pipeType,
					anchorOnRandomization: option.anchorOnRandomization,
					parentOptionId: option.parentOptionId,
					groupAnchorOnRandomization: option.groupAnchorOnRandomization,
				} as MultipleChoiceOption,
			];
		})
	);

	return { pipeAnswer, options };
};

export const processMultipleChoiceQuestion = (
	optionsResponse: ChoiceOptionResponse[],
	surveyQuestion: MultipleChoiceQuesState | RankingQuesState
): QuesState => {
	const { pipeAnswer, options } = processMultipleChoiceQuestionOptions(optionsResponse);
	const isAnsPipped: boolean = Object.values(options).some((key) => !!key.pipe);

	const rowsOptions = Object.fromEntries(
		Object.values(options)
			//@ts-ignore
			.filter((opt) => opt?.pipeType === 'rows')
			.map((opt) => [opt.id, { ...opt }])
	);

	//   if(opt?.pipeType === 'columns'){

	//   }
	const columnOptions = Object.fromEntries(
		Object.values(options)
			//@ts-ignore
			.filter((opt) => opt?.pipeType === 'columns')
			.map((opt) => [opt.id, { ...opt }])
	);
	return updateAll<MultipleChoiceQuesState | RankingQuesState>(
		set('options')(rowsOptions),
		set('columnOptions')(columnOptions),
		set('settings', 'optionOrder')(sortBy((o) => o.orderPosition, optionsResponse).map((o) => o.id)),
		set('logic', 'pipeAnswer')(pipeAnswer),
		set('pageBreak')(isAnsPipped ? true : surveyQuestion.pageBreak)
	)(surveyQuestion);
};

export const processSectionStrokes = (strokes: SectionStrokeResponse[]) => {
	return {
		strokeOrder: sortBy((a) => a.orderPosition, strokes).map(({ id }) => id),
		strokes: Object.fromEntries(strokes.map(({ id, ...rest }) => [id, { strokeId: id, ...rest }])),
	};
};

export const processMultipleOpinionScaleSectionsResponse = (sections: SectionResponse[]) => {
	const formattedSections = sections.map(
		(section) =>
			({
				...pickAll(['id', 'text', 'orderPosition'], section),
				...processSectionStrokes(section.strokes),
				asset: section.urls[0] || { id: '', url: '' },
			} as SectionState)
	);

	return {
		sections: Object.fromEntries(formattedSections.map((section) => [section.id, section])),
		sectionsOrder: sortBy((a) => a.orderPosition, formattedSections).map(({ id }) => id as string),
	};
};

export const processMultipleOpinionScaleQuestion = (
	sectionsResponse: SectionResponse[],
	surveyQuestion: MultipleOpinionScaleQuesState
): QuesState => {
	const { sections, sectionsOrder } = processMultipleOpinionScaleSectionsResponse(sectionsResponse);
	return updateAll<MultipleOpinionScaleQuesState>(
		set('sections')(sections),
		set('settings', 'sectionOrder')(sectionsOrder)
	)(surveyQuestion);
};

const mapOptionLogicResponseToOptionLogic = (optionLogic: DisplayOptionLogicResponse): DisplayOptionLogic =>
	({
		kind: optionLogic.kind,
		//@ts-ignore
		basedOn: optionLogic.basedOn,
		option: {
			type: optionLogic.type,
			value: optionLogic.value,
			//@ts-ignore
			colValue: optionLogic.colValue,
		},
	} as DisplayOptionLogic);

export const mapQuestionLogicResponseToDisplayLogic = (
	questionLogic: SurveyQuestionDisplayLogicResponse
): DisplayLogic => {
	return {
		showIf: questionLogic.showIf.map(
			(logic) =>
				({
					questionId: logic.questionId,
					logic: logic.logic,
					optionLogic: mapOptionLogicResponseToOptionLogic(logic.optionLogic),
				} as ShowLogic)
		),
		skipIf: questionLogic.skipIf.map(
			(logic) =>
				({
					questionId: logic.questionId,
					action: {
						type: logic.action,
						value: logic.actionValue,
					},
					optionLogic: mapOptionLogicResponseToOptionLogic(logic.optionLogic),
					logic: logic.logic,
				} as SkipLogic)
		),
	};
};
export const mapSectionLogicResponseToDisplayLogic = (questionLogic: any): any => {
	return {
		showIf: questionLogic.showIf.map(
			(logic) =>
				({
					questionId: logic.questionId,
					logic: logic.logic,
					optionLogic: mapOptionLogicResponseToOptionLogic(logic.optionLogic),
				} as ShowLogic)
		),
	};
};

export const mapSectionResponseToQuestion = (section: any): any => {
	let questionnaireId = !!section?.metaData
		? section?.questionnaireSectionId
		: section?.questions?.[0] &&
		  Object.keys(Object.fromEntries(section?.questions?.[0]?.map((question) => [question.id])));
	if (!!section?.questionType || !!section?.type) {
		return {
			...mapQuestionResponseToQuestion(section),
		};
	}
	return {
		...section,
		id: section.id,
		metaData: section.metaData,
		orderPosition: section.orderPosition,
		surveyId: section.surveyId,
		logic: {
			display: mapQuestionLogicResponseToDisplayLogic(section.display),
		},
		questionnaireId: questionnaireId,
		questions: !!section?.questions
			? Object.fromEntries(
					section?.questions?.map((question, index) => [question.id, mapQuestionResponseToQuestion(question)])
			  )
			: {},
	};
};

export const mapQuestionResponseToQuestion = (question: SurveyQuestionResponse): QuesState => {
	const surveyQuestion = {
		...pickAll(
			['id', 'orderPosition', 'settings', 'parentQuestionId', 'mandate', 'pageBreak', 'firstQuestionInPageId'],
			question
		),
		...pickAll(['text', 'hasDescription', 'description', 'column', 'creativeUrl', 'columnOptions'], question?.content),
		type: question.questionType,
		logic: {
			display: mapQuestionLogicResponseToDisplayLogic(question?.display),
		},
		options: {},
		pageBreak: question.pageBreak,
		questionnaireSectionId: question.questionnaireSectionId,
	} as QuesState;

	switch (surveyQuestion.type) {
		case QUES_TYPE.MULTIPLE_CHOICE_QUES:
		case QUES_TYPE.CREATIVE_QUES:
		case QUES_TYPE.RANKING_QUES:
		case QUES_TYPE.SCREENER_QUES:
		case QUES_TYPE.IMAGE_QUES:
			return processMultipleChoiceQuestion(
				question.content.options || [],
				surveyQuestion as MultipleChoiceQuesState | RankingQuesState
			);
		case QUES_TYPE.MATRIX_MATCH_QUES:
			return processMultipleChoiceQuestion(
				!!question?.content?.options ? [...question.content.options, ...question.content.columnOptions] : [],
				surveyQuestion as any
			);
		case QUES_TYPE.TEXT_BOX_QUES:
			return updateAll<TextBoxQuesState>(set('settings', 'questionTheme')(surveyQuestion.settings.questionTheme))(
				surveyQuestion
			);

		case QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES:
			return processMultipleOpinionScaleQuestion(
				question?.content?.sections ?? [],
				surveyQuestion as MultipleOpinionScaleQuesState
			);
		default:
			return surveyQuestion;
	}
};

export const mapSectionResponseToSurvey = (section: QuestionsStates) => {
	section.display = mapSectionLogicResponseToDisplayLogic(section?.display);

	return {
		...section,
		questionnaireId: section?.questions?.length > 0 ? section?.questions?.map((item) => item?.id) : [],
		questions: Object.fromEntries(
			section?.questions?.map((question, index) => [question.id, mapQuestionResponseToQuestion(question)])
		),
	};
};

export const mapSurveyDesignResponse = (surveyTemplateSetting) => {
	const { color, backgroundSettings, buttonStyle } = surveyTemplateSetting?.customize;
	const isButtonStyleEmpty = !buttonStyle.filled && !buttonStyle.border;
	return {
		...surveyTemplateSetting,
		customize: {
			...surveyTemplateSetting.customize,
			id: surveyTemplateSetting.customize.id || uuidv4(),
			fontStyle: surveyTemplateSetting.customize.fontStyle || 'Inter',
			backgroundSettings: {
				...backgroundSettings,
				backgroundSettingType: {
					image: backgroundSettings.backgroundSettingType.image || true,
					gradient: backgroundSettings.backgroundSettingType.gradient || false,
				},
				image: {
					...backgroundSettings.image,
					brightness: backgroundSettings.image.brightness || 100,
					blending: backgroundSettings.image.blending || 'normal',
				},
			},
			color: {
				...color,
				questionsColor: color?.questionsColor || '#152370',
				answersColor: color?.answersColor || '#4253A9',
				backgroundColor: color?.backgroundColor || '#FCFCFF',
				buttonsColor: color?.buttonsColor || '#7335CB',
				errorsColor: color?.errorsColor || '#eb4034',
			},
			buttonStyle: {
				...buttonStyle,
				filled: isButtonStyleEmpty ? true : buttonStyle.filled,
			},
		},
	};
};

export const processMatrixQuestion = (optionsResponse: any, surveyQuestion: any) => {
	const { pipeAnswer, options } = processMultipleChoiceQuestionOptions(optionsResponse.content.options);
	const isAnsPipped: boolean = Object.values(options).some((key) => !!key.pipe);

	const rowsOptions = Object.fromEntries(
		Object.values(options)
			//@ts-ignore
			.filter((opt) => opt?.pipeType === 'rows')
			.map((opt) => [opt.id, { ...opt }])
	);

	return updateAll<any>(
		set('options')(rowsOptions),
		set('columnOptions')(surveyQuestion.columnOptions),
		set('settings', 'optionOrder')(sortBy((o: any) => o.orderPosition, optionsResponse.content).map((o) => o.id)),
		set('logic', 'pipeAnswer')(surveyQuestion.logic.pipeAnswer),
		set('pageBreak')(isAnsPipped ? true : surveyQuestion.pageBreak),
		set('column')(optionsResponse.content.column),
		set('settings')(optionsResponse.settings)
	)(surveyQuestion);
};

export const mapSurveyResponseToSurvey = (survey: SurveyApiResponse | any): any => [
	survey.id,
	{
		id: survey.id,
		orderPosition: survey.orderPosition,
		gamesSelected: survey?.gamesSelected,
		moderatedTesting: {
			...survey.moderatedTesting,
			questions: Object.fromEntries(
				survey.moderatedTesting.questions.map((question) => [question.id, mapQuestionResponseToQuestion(question)])
			) as any,
		},
		game: survey.game,
		redirectURLs: survey.redirectURLs,
		questionnaireId: survey.questionnaireId,

		questions: Object.fromEntries(
			survey?.questions?.map((ques) => {
				if (ques?.metaData) {
					return [ques?.id, mapSectionResponseToSurvey(ques)];
				} else {
					return [ques?.id, mapQuestionResponseToQuestion(ques)];
				}
			})
		),
		research: {
			id: survey.research.id,
			type: survey.research.researchType,
		},
		instruction: survey.instruction,
		surveyDesignPreview: false,
	} as unknown as Survey,
];
export const mapQuestionnaireResponseToSurvey = (surveyResponse: QuestionnaireResponse): Questionnaire => ({
	macroTargetingGroupId: surveyResponse.macroTargetingGroupId,
	macroTargetingGroup: surveyResponse.macroTargetingGroup,
	surveyId: surveyResponse.id,
	name: surveyResponse.name || '',
	description: surveyResponse.description || '',
	allowMultipleResponse: surveyResponse.allowMultipleResponse || false,
	surveys: Object.fromEntries(surveyResponse.surveys.map(mapSurveyResponseToSurvey)),
	createdAt: getFormattedTime(surveyResponse.createdAt),
	allowEditAndResubmit: surveyResponse.allowEditAndResubmit,
	gamified: surveyResponse.gamified,
	theme: surveyResponse.theme,
	usePyxisLogo: surveyResponse.usePyxisLogo,
	surveyTemplateSetting: mapSurveyDesignResponse(surveyResponse?.surveyTemplateSetting),
	isRightOriented: surveyResponse.isRightOriented,
	howToPlay: surveyResponse.howToPlay,
	creator: surveyResponse.creator,
	testingGoal: surveyResponse.testingGoal,
	testingTargetUrls: surveyResponse.testingTargetUrls,
	isModerated: surveyResponse.isModerated,
	credits: surveyResponse.surveys[0]?.credits || 0,
	totalRespondents: surveyResponse.surveys[0]?.totalRespondents || 0,
	surveyCost: surveyResponse.surveys[0]?.surveyCost || 0,
	researchType: surveyResponse.surveys[0]?.research.researchType || Research.None,
	researchModule: surveyResponse.surveys[0]?.research.researchModule,
	pixelId: surveyResponse.pixelId,
});
