import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export const DELETE_BRAND_PRODUCTS = 'DELETE_BRAND_PRODUCTS';
export const DELETE_BRAND_PRODUCTS_FULFILLED = 'DELETE_BRAND_PRODUCTS_FULFILLED';
export const UPDATE_BRAND_PRODUCTS = 'UPDATE_BRAND_PRODUCTS';
export const UPDATE_BRAND_PRODUCTS_FULFILLED = 'UPDATE_BRAND_PRODUCTS_FULFILLED';
export const CREATE_BRAND_PRODUCTS = 'CREATE_BRAND_PRODUCTS';
export const CREATE_BRAND_PRODUCTS_FULFILLED = 'CREATE_BRAND_PRODUCTS_FULFILLED';

export const SET_CURRENT_BRAND_PRODUCTS = 'SET_CURRENT_BRAND_PRODUCTS';

export type SetCurrentBrandProductPayload = string;
export type SetCurrentBrandProduct = ReduxAction<typeof SET_CURRENT_BRAND_PRODUCTS, SetCurrentBrandProductPayload>;

export type BrandProductType = {
	id: string;
	name: string;
	keywords: [string];
	imageUrl: string;
	brandId: string;
	assetId: string;
};
const BrandProductSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		keywords: {
			anyOf: [
				{ type: 'array', items: { type: 'string' } },
				{ type: 'object', value: null },
			],
		},
		imageUrl: {
			anyOf: [
				{ type: 'string' },
				{ type: 'object', value: null }, // Sometimes it comes as null for unknown reasons
			],
		},
		brandId: { type: 'string' },
	},
};
export const BrandProductsSchema: JSONSchema = {
	type: 'array',
	items: BrandProductSchema,
};

export type CreateBrandProductPayload = Omit<BrandProductType, 'id' | 'imageUrl'> & {
	imageUrl: File;
};

export type CreateBrandProducts = ReduxAction<typeof CREATE_BRAND_PRODUCTS, CreateBrandProductPayload[]>;
export type CreateBrandProductsFulfilled = ReduxAction<typeof CREATE_BRAND_PRODUCTS_FULFILLED, BrandProductType[]>;

export type UpdateBrandProductPayload = Omit<BrandProductType, 'imageUrl'> & {
	imageUrl?: File;
};

export type UpdateBrandProducts = ReduxAction<typeof UPDATE_BRAND_PRODUCTS, UpdateBrandProductPayload[]>;
export type UpdateBrandProductsFulfilled = ReduxAction<typeof UPDATE_BRAND_PRODUCTS_FULFILLED, BrandProductType[]>;

export type DeleteBrandProducts = ReduxAction<typeof DELETE_BRAND_PRODUCTS, BrandProductType[]>;
export type DeleteBrandProductsFulfilled = ReduxAction<typeof DELETE_BRAND_PRODUCTS_FULFILLED, BrandProductType[]>;
export const READ_BRAND_PRODUCTS = 'READ_BRAND_PRODUCTS';
export const READ_BRAND_PRODUCTS_FULFILLED = 'READ_BRAND_PRODUCTS_FULFILLED';

export type ReadBrandProductsPayload = {
	brandId: string;
};
export type ReadBrandProductsFulfilledPayload = BrandProductType[];

export type ReadBrandProducts = ReduxAction<typeof READ_BRAND_PRODUCTS, ReadBrandProductsPayload>;
export type ReadBrandProductsFulfilled = ReduxAction<
	typeof READ_BRAND_PRODUCTS_FULFILLED,
	ReadBrandProductsFulfilledPayload
>;

export type BrandProductAction =
	| CreateBrandProducts
	| CreateBrandProductsFulfilled
	| DeleteBrandProducts
	| DeleteBrandProductsFulfilled
	| UpdateBrandProducts
	| UpdateBrandProductsFulfilled
	| ReadBrandProducts
	| ReadBrandProductsFulfilled;

export type BrandProductReducer = ReduxReducer<BrandState, BrandProductAction>;
