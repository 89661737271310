import { takeEvery, takeLeading } from 'redux-saga/effects';
import {
	ADD_QUESTION_PIPE,
	SET_SKIP_LOGIC,
	REMOVE_QUESTION_PIPE,
	SET_DISPLAY_LOGIC,
	MOVE_QUES,
	DELETE_QUES,
	ADD_QUES,
	SELECT_QUES,
	COPY_QUES,
	ADD_QUES_AT_INDEX,
	CHANGE_QUESTION_SETTINGS,
	CHANGE_SURVEY_SECTION_SETTINGS,
	CHANGE_QUESTION_INFO,
	SET_MANDATE_LOGIC,
	APPROVE_EDIT,
	CREATE_NEW_SECTION_TO_SURVEY,
	DELETE_SURVEY_SECTION,
	CHANGE_SECTION_INFO,
	COPY_SURVEY_SECTION,
	ADD_BULK_QUES,
} from './types';
import { handleRemove } from './sagas/handleRemove';
import { handleReorder } from './sagas/handleReorder';
import { setDisplayLogic } from './sagas/showLogic';
import { addQuestionPipe, removeQuestionPipe } from './sagas/questionPipe';
import { setSkipLogic } from './sagas/skipLogic';
import { createQuestion } from './sagas/createQuestion';
import { selectQuestion } from './sagas/selectQuestion';
import { duplicateQuestion } from './sagas/duplicateQuestion';
import { createQuestionAtIndex } from './sagas/createQuestionAtIndex';
import { updateQuestionSettings } from './sagas/updateQuestionSetttings';
import { updateQuestionInfo } from './sagas/updateQuestionInfo';
import { setMandateLogicSaga } from './sagas/mandateLogic';
import { handleApproveEdit } from './sagas/handleApproveEdit';
import { createSection } from './sagas/createSection';
import { handleRemoveSurveySection } from './sagas/handleRemoveSurveySection';
import { updateSectionInfo } from './sagas/updateSectionInfo';
import { updateSurveySectionSettingsAndLogics } from './sagas/updateSectionSettings';
import { handleCopySurveySection } from './sagas/handleCopySurveySection';
import { createBulkQuestions } from './sagas/createBulkQuestions';

function* addSkipLogicWatcher() {
	yield takeLeading(SET_SKIP_LOGIC, setSkipLogic);
}

function* addQuestionPipeWatcher() {
	yield takeLeading(ADD_QUESTION_PIPE, addQuestionPipe);
}

function* removeQuestionPipeWatcher() {
	yield takeEvery(REMOVE_QUESTION_PIPE, removeQuestionPipe);
}
function* handleApproveEditWatcher() {
	yield takeLeading(APPROVE_EDIT, handleApproveEdit);
}

function* setDisplayLogicWatcher() {
	yield takeEvery(SET_DISPLAY_LOGIC, setDisplayLogic);
}
function* setMandateLogicWatcher() {
	yield takeLeading(SET_MANDATE_LOGIC, setMandateLogicSaga);
}

function* moveQuesWatcher() {
	yield takeLeading(MOVE_QUES, handleReorder);
}

function* removeQuesWatcher() {
	yield takeEvery(DELETE_QUES, handleRemove);
}

function* createQuestionWatcher() {
	yield takeLeading(ADD_QUES, createQuestion);
}

function* selectQuestionWatcher() {
	yield takeLeading(SELECT_QUES, selectQuestion);
}

function* duplicateQuestionWatcher() {
	yield takeLeading(COPY_QUES, duplicateQuestion);
}

function* addQuestionAtIndexWatcher() {
	yield takeLeading(ADD_QUES_AT_INDEX, createQuestionAtIndex);
}

function* addBulkQuestionWatcher() {
	yield takeLeading(ADD_BULK_QUES, createBulkQuestions);
}

function* changeQuestionSettingsWatcher() {
	yield takeLeading(CHANGE_QUESTION_SETTINGS, updateQuestionSettings);
}

function* changeQuestionInfoWatcher() {
	yield takeLeading(CHANGE_QUESTION_INFO, updateQuestionInfo);
}

function* createSectionWatcher() {
	yield takeLeading(CREATE_NEW_SECTION_TO_SURVEY, createSection);
}

function* changeSectionInfoWatcher() {
	yield takeLeading(CHANGE_SECTION_INFO, updateSectionInfo);
}

function* removeSurveySectionWatcher() {
	yield takeEvery(DELETE_SURVEY_SECTION, handleRemoveSurveySection);
}
function* copySurveySectionWatcher() {
	yield takeEvery(COPY_SURVEY_SECTION, handleCopySurveySection);
}

function* changeSectionSettingsWatcher() {
	yield takeLeading(CHANGE_SURVEY_SECTION_SETTINGS, updateSurveySectionSettingsAndLogics);
}
export const questionerWatchers = [
	addSkipLogicWatcher,
	addQuestionPipeWatcher,
	removeQuestionPipeWatcher,
	setDisplayLogicWatcher,
	setMandateLogicWatcher,
	moveQuesWatcher,
	removeQuesWatcher,
	createQuestionWatcher,
	selectQuestionWatcher,
	duplicateQuestionWatcher,
	addQuestionAtIndexWatcher,
	addBulkQuestionWatcher,
	changeQuestionSettingsWatcher,
	changeQuestionInfoWatcher,
	handleApproveEditWatcher,
	createSectionWatcher,
	removeSurveySectionWatcher,
	copySurveySectionWatcher,
	changeSectionInfoWatcher,
	changeSectionSettingsWatcher,
];
