import { reduceReducers } from '../../shared/utils/reduceReducers';
import { UnionAction } from '../brand/types';
import { targetingInterestReducer } from './interest/reducer';
import { TargetingState } from './types';

const initialState: TargetingState = {
	interests: [],
};

export const targetingReducer = reduceReducers<TargetingState, UnionAction>(initialState, targetingInterestReducer);
