import {
	MatrixMatchQuesState,
	MultipleChoiceQuesState,
	OptionType,
	QuesState,
	RankingQuesState,
} from '../../../../../shared/types/QuesTypes';
import { isEmpty, omit, pickAll, without } from 'ramda';
import { DisplayLogic } from '../../../../../shared/types/QuestionLogic';
import { themeSelector } from 'components/Survey/utils';
import { MENTION_PREFIX_REGEX, QUESTION_UUID_REGEX } from 'shared/utils/mentionUtils';
import { getQuestionsOrderWithoutSections, getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { setQuestion } from '../actions';
import { set, updateAll } from 'shades';
import { useDeleteOption } from 'shared/utils/optionMethods';
import { deleteQuesOption } from '../../questionOptions/actions';

export const mapQuestionToCreateQuestionPayload = (
	question: QuesState,
	theme: string,
	surveyId: string,
	questionnaireId: string,
	reOrder = false
) => {
	return {
		surveyId,
		questionnaireId,
		questionType: question.type,
		content: pickAll(['text', 'hasDescription', 'description', 'column'], question),
		// to set the  default(1st template) question template as per the global theme selected
		settings: {
			...question.settings,
			questionTheme: themeSelector[theme],
		},
		orderPosition: question.orderPosition,
		display: question.logic.display,
		reOrder,
		pageBreak: question.pageBreak,
		questionnaireSectionId: question.questionnaireSectionId,
	};
};

export const mapShowLogicToDisplayLogicRequest = (displayLogic: DisplayLogic) => ({
	showIf: displayLogic.showIf.map((l) => ({
		questionId: l.questionId,
		logic: l.logic || null,
		optionLogic: {
			kind: l.optionLogic.kind,
			//@ts-ignore
			basedOn: l?.optionLogic?.basedOn,
			...l.optionLogic.option,
		},
	})),
});

export const getQuestionsFormat = (question) => {
	if (question?.metaData) {
		return {
			id: question?.id,
			metaData: {
				name: question?.metaData?.name,
				description: question?.metaData?.description,
			},
			orderPosition: question.orderPosition,
			questions: isEmpty(question?.questions) ? question?.questions : getModifiedQuestions(question?.questions),
		};
	}
	return {
		id: question?.id,
		orderPosition: question?.orderPosition,
		questionnaireSectionId: question?.questionnaireSectionId,
	};
};

export const getModifiedQuestions = (quess) => {
	const modifiedQues: Record<string, number[]> = Object.fromEntries(
		Object.values(quess).map((ques: any) => [ques.id, getQuestionsFormat(ques)])
	);
	return modifiedQues;
};
export const mapSkipLogicToDisplayLogicRequest = (displayLogic: DisplayLogic) => ({
	skipIf: displayLogic.skipIf.map((l) => ({
		questionId: l.questionId,
		action: l.action.type,
		actionValue: l.action.value,
		optionLogic: {
			kind: l.optionLogic.kind,
			//@ts-ignore
			basedOn: l?.optionLogic?.basedOn,
			...l.optionLogic.option,
		},
		logic: l.logic || null,
	})),
});

export const updateSectionActionAndMoved = (moved, actions, affected, changeSectionSettingsAndLogics, questions) => {
	const affectedWithousectionId = affected
		.map((id) => (questions[id]?.metaData ? questions[id].questionnaireId : id))
		.flat();
	let showLogic = [...moved?.display?.showIf];
	moved?.display?.showIf.forEach((pipe) => {
		if (affectedWithousectionId.includes(pipe.questionId)) {
			showLogic = showLogic.filter((l) => l.questionId !== pipe.questionId);
		}
	});

	if (showLogic.length !== moved.display.showIf.length) {
		actions.push(changeSectionSettingsAndLogics(moved.id, undefined, showLogic));
	}

	return;
};
export const updateQuestionsActionAndMoved = (
	moved,
	actions,
	affected,
	setSkipLogic,
	updateDisplayLogic,
	changeQuestionInfo,
	deleteQuestionPipe,
	questions
) => {
	let skipLogic = [...moved?.logic?.display?.skipIf];
	let showLogic = [...moved?.logic?.display?.showIf];
	let affectedAnswerPipe = Object?.values(moved.options);
	const affectedWithoutSections = affected
		.map((id) => (!!questions[id]?.metaData ? questions[id]?.questionnaireId : id))
		.flat();
	moved.logic.display.skipIf.forEach((pipe) => {
		if (affectedWithoutSections.includes(pipe.questionId)) {
			skipLogic = skipLogic.filter((l) => l.questionId !== pipe.questionId && pipe.action?.value !== l.questionId);
		}
	});
	if (skipLogic.length !== moved.logic.display.skipIf.length) {
		actions.push(setSkipLogic(moved.id, skipLogic, ''));
	}

	moved.logic.display.showIf.forEach((pipe) => {
		if (affectedWithoutSections.includes(pipe.questionId)) {
			showLogic = showLogic.filter((l) => l.questionId !== pipe.questionId);
		}
	});

	if (showLogic.length !== moved.logic.display.showIf.length) {
		actions.push(updateDisplayLogic(moved.id, showLogic, ''));
	}

	(moved.logic.pipeAnswer || []).forEach((pipe) => {
		if (affectedWithoutSections.includes(pipe.pipeId)) {
			actions.push(deleteQuestionPipe(moved.id, pipe.pipeId));
		}
	});

	(affectedAnswerPipe || []).forEach((opt: any) => {
		if (affectedWithoutSections.includes(opt?.pipe?.pipeId) && opt?.optionType === OptionType.Response) {
			actions.push(deleteQuesOption(moved.id, opt?.id, false));
		}
	});

	affectedWithoutSections.forEach((id) => {
		if (moved.text.includes(id)) {
			const matchedText = [...(moved.text.matchAll(QUESTION_UUID_REGEX) as any)].filter((option) =>
				option[0].includes(id)
			);
			const sanitizeIdText = matchedText.reduce((p, [mentionId]) => {
				return p.replace(mentionId, '');
			}, moved.text.replace(MENTION_PREFIX_REGEX, '')) as string;
			actions.push(
				changeQuestionInfo(moved.id, moved.type, {
					text: sanitizeIdText,
				})
			);
		}
	});
	return;
};

export const getOnePreviousQuestioId = (foundQuestion, questions) => {
	let newSelectQuesId: string;
	if (foundQuestion.orderPosition === 1) {
		return (newSelectQuesId = '');
	}
	if (!!questions[foundQuestion.questionnaireSectionId]) {
		newSelectQuesId = questions[foundQuestion.questionnaireSectionId]?.id;
	} else {
		const allQuestions = Object.values(questions).sort((a: any, b: any) => a.orderPosition - b.orderPosition);
		const filteredQuestions: any = allQuestions.filter(
			(question: any) => question.orderPosition < foundQuestion.orderPosition
		);
		newSelectQuesId = filteredQuestions[filteredQuestions.length - 1]?.id;
	}

	return newSelectQuesId;
};
