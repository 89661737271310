import React, { createContext, useContext } from 'react';
import { Notification } from '../../types/Notification';
import useNotifications from '../../utils/useNotifications';

type NotificationContextT<T = unknown> = {
	notifications: Notification<T>[];
	read: (notificationId: string) => void;
	list: () => void;
};

// TODO add proper union discrimination types when notifications are implemented
const NotificationContext = createContext({} as any);
type UseNotificationContext = <T = unknown>() => NotificationContextT<T>;
export const useNotificationContext: UseNotificationContext = () => useContext(NotificationContext);

export const NotificationsContextProvider = ({ children }) => {
	const { list, read, notifications } = useNotifications();

	return <NotificationContext.Provider value={{ read, notifications, list }}>{children}</NotificationContext.Provider>;
};
