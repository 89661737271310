import { AddStroke } from '../types';
import { select } from 'redux-saga/effects';
import { MultipleOpinionScaleQuesState } from '../../../../../shared/types/QuesTypes';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { questionSectionStrokesEndPoint } from '../consts';
import { updateAll, get, set } from 'shades';
import { inc } from 'ramda';
import { processSectionStrokes } from '../../surveyInfo/sagas/utils';
import { setQuestion } from '../../sharedQuestions/actions';

export function* addStroke(action: AddStroke) {
	const { quesId, sectionId } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId]) {
		const question: MultipleOpinionScaleQuesState = questions[quesId];

		try {
			const { data } = yield axios.post(getApiEndPointUrl(questionSectionStrokesEndPoint), {
				sectionId,
				text: ' ',
				orderPosition: inc(get('sections', sectionId, 'strokeOrder', 'length')(question)),
			});

			const { strokeOrder, strokes } = processSectionStrokes(data);

			yield putEffect(
				setQuestion(
					question.id,
					updateAll<MultipleOpinionScaleQuesState>(
						set('sections', sectionId, 'strokes')(strokes),
						set('sections', sectionId, 'strokeOrder')(strokeOrder)
					)(question)
				)
			);
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			let orderPositionSectionId: any = get('sections', sectionId, 'strokeOrder', 'length')(question);
			const { data } = yield axios.post(getApiEndPointUrl(questionSectionStrokesEndPoint), {
				sectionId,
				text: ' ',
				orderPosition: orderPositionSectionId + 1,
			});

			const { strokeOrder, strokes } = processSectionStrokes(data);
			questions[question?.questionnaireSectionId].questions[question.id] = updateAll<MultipleOpinionScaleQuesState>(
				set('sections', sectionId, 'strokes')(strokes),
				set('sections', sectionId, 'strokeOrder')(strokeOrder)
			)(question);

			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	}
}
