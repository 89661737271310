// We shall always be grateful to bugwheels94 for providing us with this beauty of code
import React, { useCallback, useContext } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '../../shared/utils/useMount';
import { StoreState } from '../../store';
import { reset } from '../../store/promise/actions';
import { useDeepCompareEffect } from './utils';

export const useReduxMutation = (
	action,
	{
		onFulfilled = (data: any) => {},
		onReject = () => {},
		onMutate = () => {},
		onSettled = () => {},
		hideGlobalLoader = false,
		hideGlobalError = false,
	} = {}
) => {
	const { pending, fulfilled, rejected } = useSelector((state: StoreState) => state.promise);
	const isPending = useMemo(() => pending.includesAll(action), [action, pending]);
	// const isFulfilled = useMemo(() => fulfilled.includesAll(action), [action, fulfilled]);
	const [isFulfilled, setIsFulfilled] = useState(false);
	const isRejected = useMemo(() => rejected.includesAll(action), [action, rejected]);
	const error = useMemo(() => rejected.findOne(action), [action, rejected]);
	const dispatch = useDispatch();
	const dictionary = useContext(ReduxQueryContext);
	const [data, setData] = useState<any>(undefined);
	useEffect(() => {
		if (fulfilled.includesAll(action)) {
			setIsFulfilled(true);
			const data = fulfilled.findOne(action)?.data;
			onFulfilled(data);
			setData(data);
			dispatch(reset(action));
		}
	}, [action, dispatch, fulfilled, onFulfilled]);
	useEffect(() => {
		if (pending.includesAll(action)) {
			setIsFulfilled(false);
			setData(undefined);
		}
	}, [action, pending]);
	useEffect(() => {
		dictionary.add(action);
		hideGlobalLoader && dictionary.addToGlobalLoaderBlackList(action);
		hideGlobalError && dictionary.addToGlobalErrorBlackList(action);
		return () => {
			hideGlobalError && dictionary.removeFromGlobalErrorBlackList(action);
			hideGlobalLoader && dictionary.removeFromGlobalLoaderBlackList(action);

			dictionary.remove(action);
			if (!dictionary.contains(action)) dispatch(reset(action));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, dispatch, hideGlobalError, hideGlobalLoader]);
	const mutate = useCallback((...args) => dispatch(action(...args)), [dispatch, action]);
	return {
		mutate,
		reset: () => {
			dispatch(reset(action));
			setIsFulfilled(false);
		},
		isPending,
		error: error?.reason,
		isFulfilled,
		isRejected,
		data,
		isIdle: !isPending && !isFulfilled && !isRejected,
	};
};
export const useReduxQuery = (
	key: any[],
	action,
	{ enabled = true, hideGlobalLoader = false, hideGlobalError = false } = {}
) => {
	const { pending, fulfilled, rejected } = useSelector((state: StoreState) => state.promise);
	const isPending = useMemo(() => {
		return !!action()?.type && pending.includesAll(action);
	}, [action, pending]);
	const hasMounted = useMount();
	const isFulfilled = useMemo(() => {
		return !!action()?.type && fulfilled.includesAll(action) && hasMounted;
	}, [action, fulfilled, hasMounted]);
	const isRejected = useMemo(
		() => !!action()?.type && rejected.includesAll(action) && hasMounted,
		[action, hasMounted, rejected]
	);
	const error = useMemo(() => rejected.findOne(action), [action, rejected]);
	const dispatch = useDispatch();

	const dictionary = useContext(ReduxQueryContext);
	useDeepCompareEffect(() => {
		if (!enabled) return;
		let type;
		hideGlobalLoader && dictionary.addToGlobalLoaderBlackList(action);
		hideGlobalError && dictionary.addToGlobalErrorBlackList(action);
		if (action()?.type) {
			dispatch(action());
			type = { type: action().type };
			dictionary.add(() => type);
		}
		return () => {
			dictionary.remove(() => type);
			if (!dictionary.contains(() => type)) {
				dispatch(reset(() => type));
				hideGlobalError && dictionary.removeFromGlobalErrorBlackList(action);
				hideGlobalLoader && dictionary.removeFromGlobalLoaderBlackList(action);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...key, enabled]);

	return {
		reset: () => {
			dispatch(reset(action));
		},
		isPending,
		isFulfilled,
		isRejected,
		isSettled: isRejected || isFulfilled,
		error,
		isIdle: !isPending && !isFulfilled && !isRejected,
	};
};
export const ReduxQueryContext = React.createContext({
	content: {},
	globalLoaderBlackList: {},
	globalErrorBlackList: {},
	addToGlobalLoaderBlackList: (v: Function) => {},
	removeFromGlobalLoaderBlackList: (v: Function) => {},
	addToGlobalErrorBlackList: (v: Function) => {},
	removeFromGlobalErrorBlackList: (v: Function) => {},

	add: (v: Function) => {},
	remove: (v: Function) => {},
	contains: (v: Function): boolean => false,
});
export const ReduxQueryContextProvider = ({ children }) => {
	const [content, setContent] = useState({});
	const [globalLoaderBlackList, setGlobalLoaderBlackList] = useState({});
	const [globalErrorBlackList, setGlobalErrorBlackList] = useState({});
	const add = useCallback(
		(action: Function) => {
			if (!action()?.type) return;
			content[action()?.type] = (content[action()?.type] || 0) + 1;
			setContent(content);
		},
		[content]
	);
	const remove = useCallback(
		(action: Function) => {
			if (!action()?.type) return;
			if (content[action()?.type] > 1) content[action()?.type] = (content[action()?.type] || 0) - 1;
			else delete content[action()?.type];
			setContent(content);
		},
		[content]
	);
	const contains = useCallback(
		(action: Function) => {
			return !!content[action()?.type];
		},
		[content]
	);
	const contextValue = useMemo(
		() => ({
			content,
			add,
			remove,
			contains,
			globalErrorBlackList,
			globalLoaderBlackList,
			addToGlobalLoaderBlackList: (v: Function) => {
				setGlobalLoaderBlackList((state) => ({ ...state, [v().type]: true }));
			},
			removeFromGlobalLoaderBlackList: (v: Function) => {
				delete globalLoaderBlackList[v().type];
				setGlobalLoaderBlackList(globalLoaderBlackList);
			},
			addToGlobalErrorBlackList: (v: Function) => {
				setGlobalErrorBlackList((state) => ({ ...state, [v().type]: true }));
			},
			removeFromGlobalErrorBlackList: (v: Function) => {
				delete globalErrorBlackList[v().type];
				setGlobalErrorBlackList(globalErrorBlackList);
			},
		}),
		[add, contains, content, globalErrorBlackList, globalLoaderBlackList, remove]
	);
	return <ReduxQueryContext.Provider value={contextValue}>{children}</ReduxQueryContext.Provider>;
};
const table = new WeakMap();

// counter of the key
let counter = 0;

// hashes an array of objects and returns a string
export default function hash(args: any[]): string {
	if (!args.length) return '';
	let key = 'arg';
	for (let i = 0; i < args.length; ++i) {
		const arg = args[i];
		const argType = typeof arg;

		let _hash;
		if (arg === null || (argType !== 'object' && argType !== 'function')) {
			// need to consider the case that `arg` is a string:
			// "undefined" -> '"undefined"'
			// 123         -> '123'
			// "null"      -> '"null"'
			// null        -> 'null'
			_hash = JSON.stringify(arg);
		} else {
			if (!table.has(arg)) {
				_hash = counter;
				table.set(arg, counter++);
			} else {
				_hash = table.get(arg);
			}
		}
		key += '$' + _hash;
	}
	return key;
}
