import {
	LOGIN_USER,
	LOGOUT_USER,
	LoginPayload,
	LoginUser,
	LogoutUserAction,
	SET_TOKEN,
	SetRegistrationStatusAction,
	SignUpPayload,
	SignUpUser,
	SIGN_UP_USER,
	RESET_PASSWORD,
	ResetPasswordPayload,
	ResetPasswordAction,
	ReadUserRoles,
	ReadUserRolesFulfilled,
	ReadUserRolesRejected,
	GET_USER_ROLES,
	GET_USER_ROLES_SUCCESS,
	GET_USER_ROLES_FAILED,
	Role,
} from './types';

export const loginUser: (credentials: LoginPayload) => LoginUser = (credentials) => ({
	type: LOGIN_USER,
	payload: credentials,
});

export const signUpUser: (credientals: SignUpPayload) => SignUpUser = (credentials) => ({
	type: SIGN_UP_USER,
	payload: credentials,
});

export const resetPasswordAction: (credentials: ResetPasswordPayload) => ResetPasswordAction = (credentials) => ({
	type: RESET_PASSWORD,
	payload: credentials,
});

export const setRegistrationStatusAction: (token?: string) => SetRegistrationStatusAction = (token) => ({
	type: SET_TOKEN,
	payload: token || '',
});

export const logoutUser: () => LogoutUserAction = () => {
	return {
		type: LOGOUT_USER,
		payload: null,
	};
};

export const readUserRoles = (): ReadUserRoles => {
	return {
		type: GET_USER_ROLES,
		payload: null,
	};
};

export const readUserRolesFulfilled = (payload: Role[]): ReadUserRolesFulfilled => {
	return {
		type: GET_USER_ROLES_SUCCESS,
		payload: payload,
	};
};

export const readUserRolesRejected = (error: Error): ReadUserRolesRejected => {
	return {
		type: GET_USER_ROLES_FAILED,
		payload: error,
	};
};
