import {
	BrandFunnelCombinedData,
	BrandFunnelComparison,
	ReadBrandFunnelCombinedData,
	ReadBrandFunnelCombinedDataFulfilled,
	ReadBrandFunnelCombinedDataPayload,
	ReadBrandFunnelComparisonData,
	ReadBrandFunnelComparisonDataFulfilled,
	ReadBrandFunnelComparisonPayload,
	ReadBrandFunnelDailyData,
	ReadBrandFunnelDailyDataFulfilled,
	ReadBrandFunnelDailyDataPayload,
	ReadBrandFunnelDataByCohort,
	ReadBrandFunnelDataByCohortFulfilled,
	ReadBrandFunnelDataByCohortPayload,
	READ_BRAND_FUNNEL_COMBINED_DATA,
	READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED,
	READ_BRAND_FUNNEL_COMPARISON,
	READ_BRAND_FUNNEL_COMPARISON_FULFILLED,
	READ_BRAND_FUNNEL_DAILY_DATA,
	READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED,
	READ_BRAND_FUNNEL_DATA_BY_COHORT,
	READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED,
} from './types';

export const readBrandFunnelComparisonData = (
	payload: ReadBrandFunnelComparisonPayload
): ReadBrandFunnelComparisonData => ({
	type: READ_BRAND_FUNNEL_COMPARISON,
	payload,
});

export const readBrandFunnelComparisonDataFulfilled = (
	payload: BrandFunnelComparison
): ReadBrandFunnelComparisonDataFulfilled => ({
	type: READ_BRAND_FUNNEL_COMPARISON_FULFILLED,
	payload,
});
export const readBrandFunnelDailyData = (payload: ReadBrandFunnelDailyDataPayload): ReadBrandFunnelDailyData => ({
	type: READ_BRAND_FUNNEL_DAILY_DATA,
	payload,
});

export const readBrandFunnelDailyDataFulfilled = (
	payload: BrandFunnelCombinedData[]
): ReadBrandFunnelDailyDataFulfilled => ({
	type: READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED,
	payload,
});
export const readBrandFunnelCombinedData = (
	payload: ReadBrandFunnelCombinedDataPayload
): ReadBrandFunnelCombinedData => ({
	type: READ_BRAND_FUNNEL_COMBINED_DATA,
	payload,
});

export const readBrandFunnelCombinedDataFulfilled = (
	payload: BrandFunnelCombinedData
): ReadBrandFunnelCombinedDataFulfilled => ({
	type: READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED,
	payload,
});
export const readBrandFunnelDataByCohort = (
	payload: ReadBrandFunnelDataByCohortPayload
): ReadBrandFunnelDataByCohort => ({
	type: READ_BRAND_FUNNEL_DATA_BY_COHORT,
	payload,
});

export const readBrandFunnelDataByCohortFulfilled = (
	payload: BrandFunnelCombinedData[]
): ReadBrandFunnelDataByCohortFulfilled => ({
	type: READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED,
	payload,
});
