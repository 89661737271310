export enum Research {
	None = 'None',
	BrandFunnel = 'BrandFunnel',
	NPS = 'NPS',
	FunnelAnalyzer = 'FunnelAnalyzer',
	CohortDeepDive = 'CohortDeepDive',
	CustomerLoyalty = 'CustomerLoyalty',
	BrandAssociation = 'BrandAssociation',
	BrandUsage = 'BrandUsage',
	PricePerception = 'PricePerception',
	IntentAnalysis = 'IntentAnalysis',
	AdRecall = 'AdRecall',
	WebsiteEvaluation = 'WebsiteEvaluation',
	WebsiteComparison = 'WebsiteComparison',
	WebsiteConversion = 'WebsiteConversion',
	SystemUsabilityTest = 'SystemUsabilityTest',
	ApplicationEvaluation = 'ApplicationEvaluation',
	ApplicationComparison = 'ApplicationComparison',
	PrototypeEvaluation = 'PrototypeEvaluation',
	ConceptValidation = 'ConceptValidation',
	UserJourney = 'UserJourney',
	ProductAdoption = 'ProductAdoption',
	WebsiteTesting = 'WebsiteTesting',
	PrototypeTesting = 'PrototypeTesting',
	ApplicationTesting = 'ApplicationTesting',
	ABTesting = 'ABTesting',
	LandingPage = 'LandingPage',
	CreativeDesign = 'CreativeDesign',
	CopyTesting = 'CopyTesting',
	Email = 'Email',
	CallToAction = 'CtaABTesting',
}

export enum SubResearch {
	BrandSimilarityMapping = 'BrandSimilarityMapping',
	CompetitorAnalysis = 'CompetitorAnalysis',
}
