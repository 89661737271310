import { SET_SURVEY_LIST } from '../../survey/surveyList/types';
import { BrandSurveyReducer, BrandSurveyAction, READ_SURVEYS_COUNT_FULFILLED, RESET_SURVEYS_COUNT } from './types';

export const brandSurveyReducer: BrandSurveyReducer = (state, action: BrandSurveyAction) => {
	switch (action.type) {
		case READ_SURVEYS_COUNT_FULFILLED:
			return {
				...state,
				surveysCount: action.payload,
			};

		case SET_SURVEY_LIST:
			return {
				...state,
				surveysCount: action.payload.items.length,
			};

		case RESET_SURVEYS_COUNT:
			return {
				...state,
				surveysCount: null,
			};

		default:
			return state;
	}
};
