import { SurveyBuilderState, SurveyReducer } from '../shared/types';
import {
	ADD_QUES_OPTION,
	CLEAR_OPTIONS_BY_IDS,
	DELETE_QUES_OPTION,
	EDIT_PIPE_QUES_OPTION,
	EDIT_QUES_OPTION,
	MOVE_QUES_OPTION,
	UPLOAD_OPTION_ASSET,
	MOVE_BULK_OPTION,
} from './types';
import { set, updateAll } from 'shades';
import { MultipleChoiceQuesState } from '../../../../shared/types/QuesTypes';
import { move, omit } from 'ramda';
import cloneDeep from 'lodash.clonedeep';

export const multipleChoiceReducer: SurveyReducer = (state, action) => {
	switch (action.type) {
		case ADD_QUES_OPTION:
		case DELETE_QUES_OPTION:
		case EDIT_QUES_OPTION:
		case UPLOAD_OPTION_ASSET:
		case EDIT_PIPE_QUES_OPTION:
		case MOVE_BULK_OPTION: {
			return set('requestInProgress')(false)(state);
		}
		case MOVE_QUES_OPTION: {
			const { quesId, srcIndex, destIndex } = action.payload;
			const question = state.questions[quesId] as MultipleChoiceQuesState;
			return updateAll<SurveyBuilderState<MultipleChoiceQuesState>>(
				set('questions', quesId, 'settings', 'optionOrder')(move(srcIndex)(destIndex)(question.settings.optionOrder)),
				set('requestInProgress')(true as boolean)
			)(state as SurveyBuilderState<MultipleChoiceQuesState> | any);
		}
		case CLEAR_OPTIONS_BY_IDS: {
			const { optionIds, questionId } = action.payload;
			const clonedState = cloneDeep(state);
			const question = clonedState.questions[questionId] as any;
			question.options = omit(question.options, optionIds);
			return clonedState;
		}
		default:
			return state;
	}
};
