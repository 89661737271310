// Backend bug!

import { Niche } from './types';

export const compareNichesId = (niche1: Niche, niche2: Niche) => niche1.id === niche2.id;

const removeExtraQuotes = (niche: Niche): Niche => ({
	...niche,
	relationshipStatuses: niche.relationshipStatuses.map((status) => status.replaceAll('"', '')),
});

export const getRidOfEscapeStrings = (niches: Niche[]): Niche[] => niches.map(removeExtraQuotes);
