import { BrandState, UnionAction } from './types';
import { reduceReducers } from '../../shared/utils/reduceReducers';
import { macroReducer } from './macro/reducer';
import { brandDetailReducer } from './detail/reducer';
import { brandAssetsReducer } from './assets/reducer';
import { brandCompetitorReducer } from './competitors/reducer';
import { brandColorReducer } from './colors/reducer';
import { brandProductReducer } from './products/reducer';
import { brandKeywordReducer } from './keywords/reducer';
import { brandSurveyReducer } from './surveys/reducer';
import { nicheReducer } from './niche/reducer';

const initialState: BrandState = {
	brands: [],
	macros: [],
	assets: [],
	brandKeywords: [],
	competitors: [],
	products: [],
	colors: [],
	niches: [],
	current: '',
	surveysCount: null,
	brand: null,
};

export const brandReducer = reduceReducers<BrandState, UnionAction>(
	initialState,
	macroReducer,
	brandDetailReducer,
	brandAssetsReducer,
	brandCompetitorReducer,
	brandColorReducer,
	brandProductReducer,
	brandSurveyReducer,
	nicheReducer,
	brandKeywordReducer
);
