import axios from '@utils/axios';
import { select } from 'redux-saga/effects';
import { fulfill, pend, reject } from 'store/promise/actions';

import { pickAll } from 'ramda';
import { surveyQuestionsApiUrl } from './consts';
import { assign } from '../../../../../shared/utils/assign';
import { changeQuestionInfo, setPopup, setQuestion } from '../actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { ChangeQuestionInfoAction, QuestionInfo, UpdateQuestionInfo } from '../types';

export function* updateQuestionInfo(action: ChangeQuestionInfoAction) {
	const { id, info, type, moderated } = action.payload;
	const { questions, moderatedQuestion } = yield select((s) => s.surveyBuilder);
	if (!!questions[id] || !!moderatedQuestion[id]) {
		const question = !moderated ? questions[id] : moderatedQuestion[id];
		const oldInfo: QuestionInfo = pickAll(['text', 'hasDescription', 'description', 'column'], question);
		yield putEffect(setQuestion(id, assign(info, question), false, moderated));
		try {
			yield putEffect(pend(changeQuestionInfo));
			let updates: UpdateQuestionInfo = {
				questionId: id,
				questionType: type,
				content: info,
			};
			if (info?.pageBreak !== undefined) {
				updates = { ...updates, pageBreak: info?.pageBreak };
			}
			const res = yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, id), updates);
			yield putEffect(setPopup(''));
			yield putEffect(fulfill(changeQuestionInfo, { payload: { ...action.payload, res }, res }));
			if (res?.data?.pageBreak) {
				yield putEffect(setQuestion(id, assign({ pageBreak: true, ...info }, question), false, moderated));
			}
		} catch (e) {
			yield putEffect(reject(changeQuestionInfo, getApiError(e)));
			yield putEffect(setQuestion(id, assign(oldInfo, question), undefined, moderated));
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[id]) {
					question = section?.questions?.[id];
					break;
				}
			}
		}
		const oldInfo: QuestionInfo = pickAll(['text', 'hasDescription', 'description', 'column'], question);
		questions[question?.questionnaireSectionId].questions[question.id] = assign(info, question);
		yield putEffect(
			setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], false, moderated)
		);
		try {
			yield putEffect(pend(changeQuestionInfo));
			let updates: UpdateQuestionInfo = {
				questionId: id,
				questionType: type,
				content: info,
			};
			if (info?.pageBreak !== undefined) {
				updates = { ...updates, pageBreak: info?.pageBreak };
			}
			const res = yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, id), updates);
			yield putEffect(setPopup(''));
			yield putEffect(fulfill(changeQuestionInfo, { payload: { ...action.payload, res }, res }));
			if (res?.data?.pageBreak) {
				questions[question?.questionnaireSectionId].questions[question.id] = assign(
					{ pageBreak: true, ...info },
					question
				);
				yield putEffect(
					setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], false, moderated)
				);
			}
		} catch (e) {
			yield putEffect(reject(changeQuestionInfo, getApiError(e)));
			questions[question?.questionnaireSectionId].questions[question.id] = assign(oldInfo, question);
			yield putEffect(
				setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], undefined, moderated)
			);
			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
