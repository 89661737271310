import { select, takeLatest, takeLeading } from 'redux-saga/effects';
import { notification } from 'antd';
import {
	ArchiveSurveyAction,
	ARCHIVE_SURVEY,
	ChangeSurveyStatusToDraftFulFilled,
	CHANGE_SERVEY_STATUS_TO_DRAFT,
	DeleteSurveyAction,
	DELETE_SURVEY,
	DuplicateSurveyAction,
	DUPLICATE_SURVEY,
	GetSurveyListAction,
	GetSurveyListSearchAction,
	GET_SURVEY_LIST,
	GET_SURVEY_LIST_SEARCH,
	GetImportSurveyListAction,
	GET_IMPORT_SURVEY_LIST,
	GET_SURVEYS_FROM_IMPORT_ISF_TABLE,
	GetSurveysFromImportIsfTableAction,
} from './types';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import {
	importSurveyFromISF,
	questionnairesApiEndpoint,
	questionnairesSearchApiEndpoint,
	surveyImportAsISF,
} from '../surveyBuilder/surveyInfo/sagas/consts';
import {
	changeSurveyStatusToDraft,
	changeSurveyStatusToDraftFulfilled,
	changeSurveyStatusToDraftRejected,
	duplicateSurveyAction,
	getImportSurveyList,
	getSurveyListAction,
	setSurveyListFromIsf,
	getSurveyListSearchAction,
	getSurveysFromImportIsfTableAction,
	setSurveyListAction,
	setSurveyListErrorAction,
	setSurveyListToExport,
} from './actions';
import { readQuestionnaires } from '../../questionnaire/actions';
import { getQuestionnaireCountAction } from '../../approval/actions';
import { mapSurveyListResponseToItems } from './utils';
import { fulfill, pend, reject } from '../../promise/actions';
import { OrderByEnum } from '../../questionnaire/types';

export function* getSurveysList(action: GetSurveyListAction) {
	const { brandId, status, limit, offset, isForExportSurvey = false } = action.payload;
	const order = OrderByEnum.DESC;
	try {
		yield putEffect(pend(getSurveyListAction));
		const { data } = yield axios.get(getApiEndPointUrl(questionnairesApiEndpoint), {
			params: {
				brandId,
				status: status || undefined,
				limit,
				offset,
				order,
			},
		});
		if (!!isForExportSurvey) {
			yield putEffect(setSurveyListToExport(data.items, data.count, offset));
		} else {
			yield putEffect(setSurveyListAction(data.items.map(mapSurveyListResponseToItems), data.count));
		}
		yield putEffect(fulfill(getSurveyListAction));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		yield putEffect(reject(getSurveyListAction, getApiError(e)));
	}
}
export function* getSurveysListSearch(action: GetSurveyListSearchAction) {
	const { workspaceId, search, limit, offset, order = OrderByEnum.DESC, status } = action.payload;

	try {
		yield putEffect(pend(getSurveyListSearchAction));
		const { data } = yield axios.get(getApiEndPointUrl(`${questionnairesSearchApiEndpoint}/${workspaceId}/${search}`), {
			params: { limit, offset, order, status },
		});
		yield putEffect(setSurveyListAction(data?.items.map(mapSurveyListResponseToItems), data?.count));
		yield putEffect(fulfill(getSurveyListSearchAction));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		yield putEffect(reject(getSurveyListSearchAction, getApiError(e)));
	}
}
export function* duplicateSurvey(action: DuplicateSurveyAction) {
	const { id, name, index } = action.payload;
	const params = yield select((s) => s.redirect.info.params);
	const page = params['survey-page'] ? params['survey-page'] : 1;
	const limit = params['survey-limit'] ? params['survey-limit'] : 10;
	const offset = index === limit - 1 ? page * limit : (page - 1) * limit;
	try {
		yield putEffect(pend(duplicateSurveyAction));
		yield axios.post(getApiEndPointUrl(questionnairesApiEndpoint, 'clone', id), { name });
		yield putEffect(fulfill(duplicateSurveyAction, { offset, limit }));
		yield putEffect(getSurveyListAction(params.brandId, undefined, offset, limit));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		yield putEffect(reject(duplicateSurveyAction, getApiError(e)));
	}
}

export function* deleteSurvey(action: DeleteSurveyAction) {
	const { id, brandId } = action.payload;
	const params = yield select((s) => s.redirect.info.params);
	const page = params['survey-page'] ? params['survey-page'] : 1;
	const limit = params['survey-limit'] ? params['survey-limit'] : 10;
	const offset = (page - 1) * limit;

	try {
		// yield putEffect(pend(deleteSurveyAction));
		yield axios.delete(getApiEndPointUrl(questionnairesApiEndpoint, id));
		yield putEffect(getSurveyListAction(params.brandId, undefined, offset, limit));
		if (brandId) {
			yield putEffect(getQuestionnaireCountAction(brandId));
			yield putEffect(readQuestionnaires({ brandId, offset: 0, limit: 10, order: OrderByEnum.DESC }));
		}
		// yield putEffect(fulfill(deleteSurveyAction, { offset, limit }));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		// yield putEffect(reject(deleteSurveyAction, getApiError(e)));
	}
}
export function* archiveSurvey(action: ArchiveSurveyAction) {
	const { id } = action.payload;
	const params = yield select((s) => s.redirect.info.params);

	try {
		yield axios.post(getApiEndPointUrl(questionnairesApiEndpoint, id, 'archive'));
		yield putEffect(getSurveyListAction(params.brandId));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
	}
}

export function* changeSurveyStatusToDraftSaga(action: ChangeSurveyStatusToDraftFulFilled) {
	const { surveyId } = action.payload;

	try {
		yield putEffect(pend(changeSurveyStatusToDraft));
		yield axios.put(getApiEndPointUrl(questionnairesApiEndpoint, surveyId, 'draft'));
		yield putEffect(fulfill(changeSurveyStatusToDraft));
		yield putEffect(changeSurveyStatusToDraftFulfilled(surveyId));
	} catch (e: any) {
		yield putEffect(changeSurveyStatusToDraftRejected(getApiError(e)));
		yield putEffect(reject(changeSurveyStatusToDraft, getApiError(e)));
	}
}

export function* getImportSurveysListSaga(action: GetImportSurveyListAction) {
	const { assetId } = action.payload;
	try {
		yield putEffect(pend(getImportSurveyList));
		const { data } = yield axios.get(getApiEndPointUrl(surveyImportAsISF), {
			params: {
				assetId,
			},
		});
		yield putEffect(setSurveyListFromIsf(data, false));
		yield putEffect(fulfill(getImportSurveyList));
	} catch (e: any) {
		yield putEffect(setSurveyListFromIsf([], true));
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		yield putEffect(reject(getImportSurveyList, getApiError(e)));
	}
}

export function* getSurveysListFromIsfTableSaga(action: GetSurveysFromImportIsfTableAction) {
	const { questionnaireIds, assetId } = action.payload;
	const params = yield select((s) => s.redirect.info.params);
	const page = params['survey-page'] ? params['survey-page'] : 1;
	const limit = params['survey-limit'] ? params['survey-limit'] : 10;
	const offset = limit - 1 === 0 ? page * limit : (page - 1) * limit;
	try {
		yield putEffect(pend(getSurveysFromImportIsfTableAction));
		yield axios.post(getApiEndPointUrl(importSurveyFromISF), {
			questionnaireIds,
			assetId,
			workspaceId: params?.workspaceId,
			brandId: params?.brandId,
		});
		yield putEffect(fulfill(getSurveysFromImportIsfTableAction));
		notification.success({
			message: 'Survey Successfully Imported.',
			closeIcon: false,
			duration: 3,
			placement: 'bottomRight',
		});
		yield putEffect(getSurveyListAction(params.brandId, undefined, offset, limit));
	} catch (e: any) {
		yield putEffect(setSurveyListErrorAction(getApiError(e)));
		yield putEffect(reject(getSurveysFromImportIsfTableAction, getApiError(e)));
	}
}

function* deleteSurveyWatcher() {
	yield takeLeading(DELETE_SURVEY, deleteSurvey);
}

function* getSurveysListWatcher() {
	yield takeLatest(GET_SURVEY_LIST, getSurveysList);
}

function* getSurveysListSearchWatcher() {
	yield takeLatest(GET_SURVEY_LIST_SEARCH, getSurveysListSearch);
}

function* duplicateSurveyWatcher() {
	yield takeLeading(DUPLICATE_SURVEY, duplicateSurvey);
}

function* archiveSurveyWatcher() {
	yield takeLeading(ARCHIVE_SURVEY, archiveSurvey);
}

function* changeSurveyStatusToDraftWatcher() {
	yield takeLeading(CHANGE_SERVEY_STATUS_TO_DRAFT, changeSurveyStatusToDraftSaga);
}

function* getImportSurveyListWatcher() {
	yield takeLeading(GET_IMPORT_SURVEY_LIST, getImportSurveysListSaga);
}

function* getSurveyFromIsfTableWatcher() {
	yield takeLeading(GET_SURVEYS_FROM_IMPORT_ISF_TABLE, getSurveysListFromIsfTableSaga);
}

export const surveyListWatchers = [
	deleteSurveyWatcher,
	getSurveysListWatcher,
	getSurveysListSearchWatcher,
	duplicateSurveyWatcher,
	archiveSurveyWatcher,
	changeSurveyStatusToDraftWatcher,
	getImportSurveyListWatcher,
	getSurveyFromIsfTableWatcher,
];
