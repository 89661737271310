import {
	ReadSurveysCountPayload,
	ReadSurveysCount,
	ReadSurveysCountFulfilled,
	READ_SURVEYS_COUNT,
	READ_SURVEYS_COUNT_FULFILLED,
	ResetSurveysCount,
	RESET_SURVEYS_COUNT,
} from './types';

export const readSurveysCount = (payload: ReadSurveysCountPayload): ReadSurveysCount => ({
	type: READ_SURVEYS_COUNT,
	payload,
});

export const readSurveysCountFulfilled = (payload: number): ReadSurveysCountFulfilled => ({
	type: READ_SURVEYS_COUNT_FULFILLED,
	payload: payload,
});

export const resetSurveysCount = (): ResetSurveysCount => ({
	type: RESET_SURVEYS_COUNT,
	payload: undefined,
});
