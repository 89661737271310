import axios from '@utils/axios';
import { select } from 'redux-saga/effects';
import { get, set } from 'shades';
import { fulfill, reject } from 'store/promise/actions';
import { assign } from '../../../../../shared/utils/assign';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { changeSectionInfo, setPopup, setSection } from '../actions';
import { ChangeSectionSettingsDisplayAction } from '../types';
import { surveySectionOptionAssetsApiUrl } from './consts';
import { getQuestionsResponse } from './createSection';
import { mapShowLogicToDisplayLogicRequest } from './utils';

export function* updateSurveySectionSettingsAndLogics(action: ChangeSectionSettingsDisplayAction) {
	const { id, settings, display, moderated } = action.payload;
	const { questions, surveys, surveyId, moderatedQuestion } = yield select((s) => s.surveyBuilder);
	const questionnaireId = surveys[surveyId]?.questionnaireId;

	try {
		const oldSettingsWithSection: any = get(id, settings !== undefined ? 'settings' : 'display')(questions);
		const section = set('display', 'showIf')(display)(questions[id] || moderatedQuestion[id]);
		try {
			let updates = {
				id: id,
				settings,
				display: !!display && mapShowLogicToDisplayLogicRequest(section.display),
				moderated,
			};
			const { data } = yield axios.patch(
				getApiEndPointUrl(`${surveySectionOptionAssetsApiUrl}?questionaireId=${questionnaireId}`),
				updates
			);
			const responseOfData = getQuestionsResponse(data?.surveys?.[0]?.questions);
			yield putEffect(setSection(id, assign(settings, responseOfData[id]), false, moderated));
			yield putEffect(setPopup(''));
			yield putEffect(
				fulfill(changeSectionInfo, { payload: { ...updates, ...responseOfData[id] }, ...responseOfData[id] })
			);
		} catch (e) {
			yield putEffect(reject(changeSectionInfo, getApiError(e)));
			yield putEffect(setSection(id, assign(oldSettingsWithSection, section), undefined, moderated));
			yield putEffect(setPopup(getApiError(e)));
		}
	} catch (e) {
		console.error('Error at updateSurveySectionSettingsAndLogics.ts:', updateSurveySectionSettingsAndLogics);
	}
}
