import { CustomVariablesReducer, CustomVariablesAction, READ_CUSTOM_VARIABLES_FULFILLED } from './types';

const initialState = {
	customVariables: [],
};
export const customVariableReducer: CustomVariablesReducer = (state = initialState, action: CustomVariablesAction) => {
	switch (action.type) {
		case READ_CUSTOM_VARIABLES_FULFILLED:
			return {
				...state,
				customVariables: action.payload,
			};
		default:
			return state;
	}
};
