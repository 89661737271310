import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import { readBrandFunnelCompetitors, readBrandFunnelCompetitorsFulfilled } from './actions';
import { brandFunnelCompetitorsEndPoint } from './const';
import {
	ReadBrandFunnelCompetitorsPayload,
	BrandFunnelCompetitorsSchema,
	READ_BRAND_FUNNEL_COMPETITORS,
	ReadBrandFunnelCompetitors,
} from './types';

function readBrandFunnelCompetitorsAPI(brand: ReadBrandFunnelCompetitorsPayload) {
	return axios.get(getAnalyticsApiEndPointUrl(brandFunnelCompetitorsEndPoint, brand.questionnaireId, 'competitors'), {
		params: brand,
	});
}

function* readBrandFunnelCompetitorsSaga(action: ReadBrandFunnelCompetitors) {
	try {
		yield putEffect(pend(readBrandFunnelCompetitors));
		const res = yield readBrandFunnelCompetitorsAPI(action.payload);
		yield putEffect(readBrandFunnelCompetitorsFulfilled(validate(res.data, BrandFunnelCompetitorsSchema)));
		yield putEffect(fulfill(readBrandFunnelCompetitors));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelCompetitors, getApiError(e)));
	}
}

export const brandFunnelCompetitorSagas = [
	function* getReadBrandFunnelCompetitorsWatcher() {
		yield takeLeading(READ_BRAND_FUNNEL_COMPETITORS, readBrandFunnelCompetitorsSaga);
	},
];
