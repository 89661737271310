import { EditQuesOption } from '../types';
import { select } from 'redux-saga/effects';
import { MultipleChoiceQuesState } from '../../../../../shared/types/QuesTypes';
import axios from '@utils/axios';
import { surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { set } from 'shades';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { fulfill, pend, reject } from 'store/promise/actions';
import { editQuesOption } from '../actions';

export function* updateChoiceOption(action: EditQuesOption) {
	const { quesId, optionId, optionText, methodology, anchorOnRandomization, moderated } = action.payload;
	const { questions, moderatedQuestion } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId] || !!moderatedQuestion[quesId]) {
		const question = moderated ? moderatedQuestion[quesId] : (questions[quesId] as MultipleChoiceQuesState);
		try {
			yield putEffect(pend(editQuesOption));
			const body: Record<string, any> = { questionId: question.id };
			if (optionText) body.value = optionText;
			if (methodology) body.methodology = methodology;
			if (anchorOnRandomization !== undefined) body.anchorOnRandomization = anchorOnRandomization;
			yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId), body);
			if (anchorOnRandomization !== undefined) {
				yield putEffect(
					setQuestion(
						question.id,
						set('options', optionId, 'anchorOnRandomization')(anchorOnRandomization)(question),
						undefined,
						moderated
					)
				);
			} else {
				if (methodology) {
					yield putEffect(
						setQuestion(
							question.id,
							set('options', optionId, 'methodology')(methodology)(question),
							undefined,
							moderated
						)
					);
				} else {
					yield putEffect(
						setQuestion(question.id, set('options', optionId, 'value')(optionText)(question), undefined, moderated)
					);
				}
			}

			yield putEffect(fulfill(editQuesOption, { payload: action.payload }));
		} catch (e) {
			yield putEffect(reject(editQuesOption, getApiError(e)));

			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			yield putEffect(pend(editQuesOption));
			const body: Record<string, any> = { questionId: question.id };
			if (optionText) body.value = optionText;
			if (methodology) body.methodology = methodology;
			if (anchorOnRandomization !== undefined) body.anchorOnRandomization = anchorOnRandomization;

			yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId), body);
			if (anchorOnRandomization !== undefined) {
				questions[question?.questionnaireSectionId].questions[question.id] = set(
					'options',
					optionId,
					'anchorOnRandomization'
				)(anchorOnRandomization)(question);
				yield putEffect(
					setQuestion(
						question?.questionnaireSectionId,
						questions[question?.questionnaireSectionId],
						undefined,
						moderated
					)
				);
			} else {
				if (methodology) {
					questions[question?.questionnaireSectionId].questions[question.id] = set(
						'options',
						optionId,
						'methodology'
					)(methodology)(question);
					yield putEffect(
						setQuestion(
							question?.questionnaireSectionId,
							questions[question?.questionnaireSectionId],
							undefined,
							moderated
						)
					);
				} else {
					questions[question?.questionnaireSectionId].questions[question.id] = set(
						'options',
						optionId,
						'value'
					)(optionText)(question);
					yield putEffect(
						setQuestion(
							question?.questionnaireSectionId,
							questions[question?.questionnaireSectionId],
							undefined,
							moderated
						)
					);
				}
			}
			yield putEffect(fulfill(editQuesOption, { payload: action.payload }));
		} catch (e) {
			yield putEffect(reject(editQuesOption, getApiError(e)));

			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
