export const READ_GENERAL_ANALYTICS = 'READ_GENERAL_ANALYTICS';
export const READ_GENERAL_ANALYTICS_FULFILLED = 'READ_GENERAL_ANALYTICS_FULFILLED';
export type ReadGeneralAnalyticsPayload = {
	id: string;
	cohorts: string[];
	reportId: string;
	count: number;
};
export type ResponsesCount =
	| {
			count: number;
			questionId: string;
			option: number;
	  }
	| { questionId: string; words: [string, number][] };

export type TotalResponseData = {
	count: number;
	questionId: string;
};
export type GeneralAnalytics = {
	results: ResponsesCount[];
	totalResponses: TotalResponseData[];
	rankingQnArray: any[];
	surveyId: string;
	approvedResponses: { total: number };
	averageResponseTime: string;
	denominator?: any;
};

export const readGeneralAnalytics = (payload: ReadGeneralAnalyticsPayload) => ({
	type: READ_GENERAL_ANALYTICS as typeof READ_GENERAL_ANALYTICS,
	payload,
});
export const readGeneralAnalyticsFulfilled = (payload: GeneralAnalytics) => ({
	type: READ_GENERAL_ANALYTICS_FULFILLED as typeof READ_GENERAL_ANALYTICS_FULFILLED,
	payload,
});
