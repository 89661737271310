import { set, updateAll } from 'shades';
import {
	GET_SURVEY_DATA,
	RELOAD_SURVEY_PREVIEW_STATE,
	SET_ACTIVE_SURVEY_PREVIEW,
	SET_SURVEY_DATA,
	SET_SURVEY_DATA_ERROR,
	SurveyPreviewDataReducer,
	SurveyPreviewDataState,
	SET_CREDITS_INFO,
} from './types';
import { Research } from '../../../shared/enums/Research';
import { ResearchModule } from '../../../shared/enums/ResearchModule';

const initialState: SurveyPreviewDataState = {
	image: null,
	id: '',
	name: '',
	description: '',
	allowMultipleResponse: false,
	createdAt: '',
	endTime: null,
	startTime: null,
	questionNumber: 0,
	requestInProgress: false,
	error: '',
	surveys: {},
	activeSurvey: '',
	usePyxisLogo: true,
	isRightOriented: true,
	allowEditAndResubmit: true,
	creator: null,
	testingTargetUrls: [],
	testingGoal: '',
	macroTargetingGroupId: null,
	macroTargetingGroup: null,
	credits: 0,
	totalRespondents: 0,
	surveyCost: 0,
	researchType: Research.None,
	researchModule: ResearchModule.FromScratch,
	brandId: '',
	workspaceId: '',
	isModerated: false,
	surveyTemplateSetting: {},
	pixelId: null,
};

export const surveyPreviewDataReducer: SurveyPreviewDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SURVEY_DATA:
			return set('requestInProgress')(true)(state);
		case SET_SURVEY_DATA:
			return updateAll<SurveyPreviewDataState>(
				set('requestInProgress')(false as boolean),
				set('error')('' as string),
				(state) => Object.assign({}, state, action.payload)
			)(state);
		case SET_ACTIVE_SURVEY_PREVIEW:
			return set('activeSurvey')(action.payload.id)(state);
		//NOte:Use below line, if error
		// return set('activeSurvey')(state.activeSurvey === action.payload.id ? '' : action.payload.id)(state);
		case SET_SURVEY_DATA_ERROR:
			return updateAll<SurveyPreviewDataState>(
				set('requestInProgress')(false as boolean),
				set('error')(action.payload.error)
			)(state);
		case SET_CREDITS_INFO:
			return updateAll<SurveyPreviewDataState>(
				set('credits')(action.payload.credits),
				set('totalRespondents')(action.payload.totalRespondents),
				set('surveyCost')(action.payload.surveyCost)
			)(state);
		case RELOAD_SURVEY_PREVIEW_STATE:
			return initialState;
		default:
			return state;
	}
};
