import {
	BaseQuesState,
	CreativeQuesState,
	EmailQuesState,
	ImageQuesState,
	LinearRangeQuesState,
	MatrixMatchQuesState,
	MatrixQuesState,
	ModeratedQuesState,
	ModeratedSlotDetailsState,
	ModeratedSlotSelectionState,
	MultipleChoiceQuesState,
	MultipleOpinionScaleQuesState,
	NumberQuesState,
	OpinionScaleQuesState,
	OptionType,
	QuesState,
	QuesType,
	RankingQuesState,
	RatingQuesState,
	ScreenerQuesState,
	TaskQuesState,
	TextBoxQuesState,
	ThankYouQuesState,
	VideoUploadQuesState,
	WelcomeScreenState,
	YesNoQuesState,
} from '../../../shared/types/QuesTypes';
import {
	setDisplayLogic as updateDisplayLogic,
	changeQuestionInfo,
	setSkipLogic,
	setQuestion,
	changeSectionSettingsAndLogics,
	removeQuestionPipe,
} from './sharedQuestions/actions';
import { SurveyAction } from './shared/types';

import * as uuid from 'uuid';
import { move, omit, without } from 'ramda';
import { set, updateAll } from 'shades';

import { QUES_TYPE } from '../../../shared/consts/quesType';
import { defaultValues } from '../../../shared/consts/defaultValues';
import { PipeAnswerType } from '../../../shared/types/QuestionLogic';
import { MENTION_PREFIX_REGEX, QUESTION_UUID_REGEX } from '../../../shared/utils/mentionUtils';
import { MatrixAnswerType } from '../../../shared/types/QuestionSettings';
import { SectionsInSurvey, QuestionsStates } from 'shared/types/SurveyResponses';
import { getQuestionsOrderWithoutSections, getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { deleteQuesOption } from './questionOptions/actions';

export const getQuestionModelByType: (quesType: QuesType, position: number, question?: any) => QuesState | undefined = (
	quesType,
	position,
	question
) => {
	let newQues: BaseQuesState = {
		id: uuid.v4(),
		text: question ? question.text : '',
		description: '',
		hasDescription: false,
		orderPosition: position,
		type: quesType,
		logic: {
			display: {
				showIf: [],
				skipIf: [],
			},
		},
		mandate: { type: '', options: [] },
		settings: {
			skip: false,
			isMandatoryQuestion: false,
		},
		parentQuestionId: null,
		firstQuestionInPageId: null,
		pageBreak: true,
		questionnaireSectionId: null,
	};

	switch (quesType) {
		case QUES_TYPE.WELCOME_SCREEN: {
			let newWelcomeScreen: WelcomeScreenState = newQues as WelcomeScreenState;
			const { buttonLabel } = defaultValues[QUES_TYPE.WELCOME_SCREEN];

			newWelcomeScreen = {
				...newWelcomeScreen,
				type: QUES_TYPE.WELCOME_SCREEN,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					creative: false,
					buttonLabel,
				},
			};

			return newWelcomeScreen;
		}

		case QUES_TYPE.OPINION_SCALE_QUES: {
			let newOpinionScaleQues: OpinionScaleQuesState = newQues as OpinionScaleQuesState;
			const { scale, leftLabel, rightLabel, startAtOne } = defaultValues[QUES_TYPE.OPINION_SCALE_QUES];

			newOpinionScaleQues = {
				...newOpinionScaleQues,
				type: QUES_TYPE.OPINION_SCALE_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					scale,
					leftLabel,
					rightLabel,
					startAtOne,
				},
			};

			return newOpinionScaleQues;
		}

		case QUES_TYPE.MULTIPLE_CHOICE_QUES: {
			let newMultipleChoiceQues: MultipleChoiceQuesState = newQues as MultipleChoiceQuesState;

			newMultipleChoiceQues = {
				...newMultipleChoiceQues,
				type: QUES_TYPE.MULTIPLE_CHOICE_QUES,
				options: {},
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					optionOrder: [],
					multipleAnswers: false,
					otherOption: false,
					noneOption: false,
					stacked: false,
					randomized: false,
					creative: false,
					type: 'Unlimited',
				},
			};

			return newMultipleChoiceQues;
		}
		case QUES_TYPE.RANKING_QUES: {
			let newRankingQues: RankingQuesState = newQues as RankingQuesState;

			newRankingQues = {
				...newRankingQues,
				type: QUES_TYPE.RANKING_QUES,
				options: {},
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					optionOrder: [],
					randomized: false,
				},
			};

			return newRankingQues;
		}
		case QUES_TYPE.NUMBER_QUES: {
			let newNumberQues: NumberQuesState = newQues as NumberQuesState;

			newNumberQues = {
				...newNumberQues,
				type: QUES_TYPE.NUMBER_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					minVal: defaultValues.NumberQues.minVal,
					maxVal: defaultValues.NumberQues.maxVal,
				},
			};
			return newNumberQues;
		}

		case QUES_TYPE.TEXT_BOX_QUES: {
			let newTextBoxQues: TextBoxQuesState = newQues as TextBoxQuesState;

			newTextBoxQues = {
				...newTextBoxQues,
				type: QUES_TYPE.TEXT_BOX_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					voiceTranscription: false,
					minChars: defaultValues.TextBox.minChars,
					maxChars: defaultValues.TextBox.maxChars,
				},
			};
			return newTextBoxQues;
		}

		case QUES_TYPE.RATING_QUES: {
			let newRatingQues: RatingQuesState = newQues as RatingQuesState;

			newRatingQues = {
				...newRatingQues,
				type: QUES_TYPE.RATING_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					iconType: defaultValues.RatingQues.iconType,
					scale: defaultValues.RatingQues.scale,
				},
			};
			return newRatingQues;
		}

		case QUES_TYPE.YES_NO_QUES: {
			let newYesNoQues: YesNoQuesState = newQues as YesNoQuesState;

			newYesNoQues = {
				...newYesNoQues,
				type: QUES_TYPE.YES_NO_QUES,
			};
			return newYesNoQues;
		}

		case QUES_TYPE.CREATIVE_QUES: {
			let newCreativeQues: CreativeQuesState = newQues as CreativeQuesState;

			newCreativeQues = {
				...newCreativeQues,
				type: QUES_TYPE.CREATIVE_QUES,
				options: {},
				settings: {
					type: 'Unlimited',
					skip: false,
					isMandatoryQuestion: false,
					optionOrder: [],
					multipleAnswers: false,
					otherOption: false,
					noneOption: false,
					fitImg: false,
					stacked: false,
					randomized: false,
					blurImg: false,
					otherImgUrl: '',
					noneImgUrl: '',
					// addCreative: false,
				},
			};

			return newCreativeQues;
		}

		case QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES: {
			let newMultipleOpinionScaleQues: MultipleOpinionScaleQuesState = newQues as MultipleOpinionScaleQuesState;
			const { scale, leftLabel, rightLabel, startAtOne } = defaultValues[QUES_TYPE.OPINION_SCALE_QUES];

			newMultipleOpinionScaleQues = {
				...newMultipleOpinionScaleQues,
				type: QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES,
				sections: {},
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					sectionOrder: [],
					fitImg: false,
					blurImg: false,
					scale,
					leftLabel,
					rightLabel,
					startAtOne,
					randomized: false,
				},
			};

			return newMultipleOpinionScaleQues;
		}

		case QUES_TYPE.MATRIX_QUES: {
			let newMatrixQues: MatrixQuesState = newQues as MatrixQuesState;

			newMatrixQues = {
				...newMatrixQues,
				type: QUES_TYPE.MATRIX_QUES,
				columns: {},
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					columnOrder: [],
					multipleAnswers: false,
					fitImg: false,
					blurImg: false,
					randomized: false,
				},
			};

			return newMatrixQues;
		}

		case QUES_TYPE.MATRIX_MATCH_QUES: {
			let newMatrixQues: MatrixMatchQuesState = newQues as MatrixMatchQuesState;

			newMatrixQues = {
				...newMatrixQues,
				type: QUES_TYPE.MATRIX_MATCH_QUES,
				column: [
					{ text: '1', orderPosition: 1, creativeUrl: '', optionType: OptionType.Normal },
					{ text: '2', orderPosition: 2, creativeUrl: '', optionType: OptionType.Normal },
					{ text: '3', orderPosition: 3, creativeUrl: '', optionType: OptionType.Normal },
				],
				settings: {
					skip: false,
					isMandatoryQuestion: false,
					answerType: MatrixAnswerType.SingleCorrect,
					randomizedRow: false,
					creative: false,
					randomized: false,
					noneOption: false,
					rowNoneOptionActive: false,
					columnNoneOptionActive: false,
					optionOrder: [],
					fixedRow: true,
					addNumber: false,
					addIcon: false,
					ranking: false,
					isBulkInserted: false,
				},
			};

			return newMatrixQues;
		}

		case QUES_TYPE.LINEAR_RANGE_QUES: {
			let newLinearRangeQues: LinearRangeQuesState = newQues as LinearRangeQuesState;

			newLinearRangeQues = {
				...newLinearRangeQues,
				type: QUES_TYPE.LINEAR_RANGE_QUES,
				settings: defaultValues.LinearRange,
			};
			return newLinearRangeQues;
		}

		case QUES_TYPE.TASK_QUES: {
			let newTask: TaskQuesState = newQues as TaskQuesState;

			newTask = {
				...newTask,
				type: QUES_TYPE.TASK_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};

			return newTask;
		}

		case QUES_TYPE.VIDEO_UPLOAD_QUES: {
			let newVideoUploadQues: VideoUploadQuesState = newQues as VideoUploadQuesState;

			newVideoUploadQues = {
				...newQues,
				type: QUES_TYPE.VIDEO_UPLOAD_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};

			return newVideoUploadQues;
		}

		case QUES_TYPE.SCREENER_QUES: {
			let newScreener = newQues as ScreenerQuesState;

			newScreener = {
				...newScreener,
				type: QUES_TYPE.SCREENER_QUES,
				settings: {
					optionOrder: [],
					skip: false,
					isMandatoryQuestion: false,
					noneOption: false,
					preferNotSay: false,
					multipleAnswers: false,
				},
			};

			return newScreener;
		}

		case QUES_TYPE.IMAGE_QUES: {
			let newImageQues = newQues as ImageQuesState;

			newImageQues = {
				...newImageQues,
				type: QUES_TYPE.IMAGE_QUES,
				settings: {
					time: true,
					next: true,
					skip: false,
					isMandatoryQuestion: false,
					timeDuration: 10,
				},
			};

			return newImageQues;
		}

		case QUES_TYPE.MODERATED_TIMEZONE: {
			let newTimezone: ModeratedQuesState = newQues as ModeratedQuesState;

			newTimezone = {
				...newTimezone,
				type: QUES_TYPE.MODERATED_TIMEZONE,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};

			return newTimezone;
		}
		case QUES_TYPE.MODERATED_SLOTS: {
			let newModerateTimeSlot: ModeratedSlotSelectionState = newQues as ModeratedSlotSelectionState;
			newModerateTimeSlot = {
				...newModerateTimeSlot,
				type: QUES_TYPE.MODERATED_SLOTS,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};
			return newModerateTimeSlot;
		}
		case QUES_TYPE.MODERATED_DETAILS: {
			let newModeratedDetails: ModeratedSlotDetailsState = newQues as ModeratedSlotDetailsState;
			newModeratedDetails = {
				...newModeratedDetails,
				type: QUES_TYPE.MODERATED_DETAILS,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};
			return newModeratedDetails;
		}
		case QUES_TYPE.EMAIL_QUES: {
			let newEmailQues: EmailQuesState = newQues as EmailQuesState;

			newEmailQues = {
				...newEmailQues,
				type: QUES_TYPE.EMAIL_QUES,
				settings: {
					skip: false,
					isMandatoryQuestion: false,
				},
			};
			return newEmailQues;
		}
		case QUES_TYPE.THANK_YOU: {
			let newThankYouQues: ThankYouQuesState = newQues as ThankYouQuesState;

			newThankYouQues = {
				...newThankYouQues,
				type: QUES_TYPE.THANK_YOU,
				settings: {
					hasButton: false,
					skip: false,
					isMandatoryQuestion: false,
				},
			};
			return newThankYouQues;
		}
		default: {
			// invalid question type
			return undefined;
		}
	}
};
export const getAffectedActions = (q, actions, id, questions = {}) => {
	const questionWithoutNesting = getQuestionsWithoutFunctions(questions);
	const affectedSkip = q?.logic?.display?.skipIf.find(({ questionId }) => questionId === id);
	const affectedSkipTo = q?.logic?.display?.skipIf.find(({ action }) => action?.value === id);
	const affectedShow = q?.logic?.display?.showIf.find(({ questionId }) => questionId === id);
	const affectedAnswerPipe = (q?.logic?.pipeAnswer || []).find(({ pipeId }) => pipeId === id);
	const pipeQuestion = q as MultipleChoiceQuesState | MatrixMatchQuesState | RankingQuesState;
	const affectedOptionPipe = Object.values(pipeQuestion?.options || [])?.find((opt) => opt?.pipe?.pipeId === id);
	if ((q?.text || '').includes(id)) {
		const matchedText = [...(q?.text?.matchAll(QUESTION_UUID_REGEX) as any)].filter((option) => option[0].includes(id));
		const sanitizeIdText = matchedText.reduce((p, [mentionId]) => {
			return p.replace(mentionId, '');
		}, q?.text?.replace(MENTION_PREFIX_REGEX, '')) as string;
		actions.push(
			changeQuestionInfo(q.id, q.type, {
				// text: q.text.replace(MENTION_PREFIX_REGEX, '').replace(id, ''),
				text: sanitizeIdText,
			})
		);
	}

	if (affectedSkip) {
		actions.push(
			setSkipLogic(
				q?.id,
				q?.logic?.display?.skipIf.filter((s) => s?.questionId !== affectedSkip.questionId),
				''
			)
		);
	}

	if (affectedSkipTo) {
		actions.push(
			setSkipLogic(
				q?.id,
				q?.logic?.display?.skipIf?.filter((s) => s?.action?.value !== affectedSkipTo?.action?.value),
				''
			)
		);
	}
	if (affectedShow) {
		actions.push(
			updateDisplayLogic(
				q?.id,
				q?.logic?.display?.showIf?.filter((s) => s?.questionId !== affectedShow?.questionId),
				''
			)
		);
	}

	if (affectedAnswerPipe && affectedOptionPipe?.optionType !== OptionType.Response) {
		const question = q as MultipleChoiceQuesState | MatrixMatchQuesState | RankingQuesState;

		const optionId =
			Object?.values(question.options).find((opt) => opt.pipe?.pipeId === affectedAnswerPipe.pipeId)?.id || '';

		actions.push(removeQuestionPipe(question.id, affectedAnswerPipe.pipeId));

		// actions?.push(
		// 	setQuestion(
		// 		question.id,
		// 		updateAll<any>(
		// 			set('options')(omit([optionId], question.options)),
		// 			set(
		// 				'logic',
		// 				'pipeAnswer'
		// 			)(question.logic.pipeAnswer?.filter(({ pipeId }) => pipeId !== affectedAnswerPipe.pipeId)),
		// 			set('columnOptions')(omit([optionId], question.columnOptions)),
		// 			set('settings', 'optionOrder')(without([optionId], question.settings.optionOrder))
		// 		)(question)
		// 	)
		// );
	}

	if (!!affectedOptionPipe && affectedOptionPipe?.optionType === OptionType.Response) {
		const question = q as MultipleChoiceQuesState | MatrixMatchQuesState | RankingQuesState;
		const optionId = affectedOptionPipe.id;
		actions.push(deleteQuesOption(question?.id, optionId, false));
		// const isValidPiping =
		// 	questionWithoutNesting[affectedOptionPipe?.pipe?.pipeId as string]?.orderPosition >= question?.orderPosition;
		// 	const optionId = affectedOptionPipe.id;
		// 	if (optionId) {
		// 		actions?.push(
		// 			setQuestion(
		// 				question?.id,
		// 				updateAll<any>(
		// 					set('options')(omit([optionId], question?.options)),
		// 					set('columnOptions')(omit([optionId], question?.columnOptions)),
		// 					set('settings', 'optionOrder')(without([optionId], question?.settings?.optionOrder))
		// 				)(question)
		// 			)
		// 		);
		// 	}
	}
	return;
};
export const getCleanupActions = (id: string, affectedQuestions: QuestionsStates[], questions = {}) => {
	const questionWithoutNesting = getQuestionsWithoutFunctions(questions);
	const quesOrder = getQuestionsOrderWithoutSections(questions);
	let actions: SurveyAction[] | any = [];
	affectedQuestions.forEach((q: QuestionsStates) => {
		if (q?.metaData) {
			const affectedShow = q?.display?.showIf.find(({ questionId }) => quesOrder.includes(questionId));
			if (affectedShow) {
				actions.push(
					changeSectionSettingsAndLogics(
						q?.id,
						undefined,
						q?.display?.showIf?.filter((s) => questionWithoutNesting[s?.questionId].orderPosition < q?.orderPosition)
					)
				);
			}
			if (!!questions[id]?.metaData) {
				questions[id]?.questionnaireId.forEach((quesId) => {
					Object.values(q?.questions).forEach((ques) => {
						getAffectedActions(ques, actions, quesId, questions);
					});
				});
				return;
			} else {
				Object.values(q?.questions).forEach((ques) => {
					getAffectedActions(ques, actions, id, questions);
				});
				return;
			}
		}
		if (!q?.metaData) {
			if (!!questions[id]?.metaData) {
				questions[id]?.questionnaireId.forEach((quesId) => {
					getAffectedActions(q, actions, quesId, questions);
				});
				return;
			} else {
				getAffectedActions(q, actions, id, questions);
				return;
			}
		}
	});
	return actions;
};
export const getOrderPosition = (order, index, updatedQuestions) => {
	const slicedOrder = order.slice(0, index + 1);
	return slicedOrder
		.map((id) =>
			updatedQuestions[id]?.metaData
				? updatedQuestions[id]?.questionnaireId?.length
					? Object.keys(updatedQuestions[id]?.questions)
					: id
				: id
		)
		.flat().length;
};
export const applyNewPositionOrderToAllQuestions = (questions: SectionsInSurvey, order: string[]): SectionsInSurvey => {
	return order?.reduce((updatedQuestions, questionId, index) => {
		if (!!updatedQuestions[questionId]?.metaData) {
			return updateAll<any>(
				set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, questions) + 1),
				set(
					questionId,
					'questions'
				)(
					updatedQuestions[questionId]?.questionnaireId.reduce(
						(nestedQuestions, questionId, idx) =>
							set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, questions) + 1 + idx)(
								nestedQuestions
							),
						updatedQuestions[questionId]?.questions
					)
				)
			)(updatedQuestions);
		}
		if (!!updatedQuestions[questionId]?.parentQuestionId) {
			return updatedQuestions;
		}
		return updateAll<any>(
			set(questionId, 'orderPosition')(getOrderPosition(order, index, questions)),
			set(
				questionId,
				'pageBreak'
			)(questions[order[index - 1]]?.metaData ? true : updatedQuestions[questionId]?.pageBreak)
		)(updatedQuestions);
	}, questions);
};
export const getQuestionsTop = (order, removedQestionId, questions) => {
	const updatedQuestionLists = order.map((id) =>
		id === removedQestionId[0] ? { ...removedQestionId[1], questionnaireSectionId: null } : questions[id]
	);
	return Object.fromEntries(updatedQuestionLists.map((question) => [question?.id, question]));
};
export const applyPositonByCategoryType = (
	questions: SectionsInSurvey,
	order: string[],
	type: string,
	src: any,
	dest: any
) => {
	const sourceCategoryId = src.droppableId;
	const destinationCategoryId = dest.droppableId;
	let uppdatedQuestionsList = {};
	if (type === 'droppable-items') {
		if (sourceCategoryId === destinationCategoryId) {
			uppdatedQuestionsList = order.reduce((updatedQuestions, questionId, index) => {
				if (sourceCategoryId === questionId) {
					const items: any = move(src?.index)(dest?.index)(updatedQuestions[questionId]?.questionnaireId);
					return set(
						questionId,
						'questions'
					)(
						items.reduce(
							(nestedQuestions, questionId, idx) =>
								set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, updatedQuestions) + 1 + idx)(
									nestedQuestions
								),
							updatedQuestions[questionId]?.questions
						)
					)(updatedQuestions);
				}

				return updatedQuestions;
			}, questions);
		} else {
			let removedQestionId = [
				questions[sourceCategoryId]?.questionnaireId[src?.index],
				questions[sourceCategoryId]?.questions[questions[sourceCategoryId]?.questionnaireId[src?.index]],
			];
			let items: any = questions[destinationCategoryId]?.questionnaireId?.toSpliced(
				dest?.index,
				0,
				removedQestionId[0]
			);
			let updatedQuestionList = items?.map((id) =>
				questions[destinationCategoryId]?.questions[id]
					? questions[destinationCategoryId]?.questions[id]
					: { ...removedQestionId[1], questionnaireSectionId: destinationCategoryId }
			);

			// const updatedOrder:any = order?.toSpliced((order.indexOf(destinationCategoryId)+1), 0, removedQestionId[0])

			uppdatedQuestionsList = order.reduce((updatedQuestions, questionId, index) => {
				if (sourceCategoryId === questionId) {
					const items: any = without(updatedQuestions[questionId]?.questionnaireId[src?.index])(
						updatedQuestions[questionId]?.questionnaireId
					);
					const updatedItems = Object.fromEntries(items.map((id) => [id, updatedQuestions[questionId]?.questions[id]]));
					return updateAll<any>(
						set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, updatedQuestions) + 1),
						set(questionId, 'questionnaireId')(items),
						set(
							questionId,
							'questions'
						)(
							!items?.length
								? {}
								: items.reduce(
										(nestedQuestions, questionId, idx) =>
											set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, updatedQuestions) + 1 + idx)(
												nestedQuestions
											),
										updatedItems
								  )
						)
					)(updatedQuestions);
				}
				if (destinationCategoryId === questionId && !!updatedQuestions[destinationCategoryId]?.metaData) {
					const updatedItems = Object.fromEntries(updatedQuestionList.map((question) => [question?.id, question]));
					return updateAll<any>(
						set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, updatedQuestions) + 1),
						set(questionId, 'questionnaireId')(items),
						set(
							questionId,
							'questions'
						)(
							!items?.length
								? {}
								: items?.reduce(
										(nestedQuestions, questionId, idx) =>
											set(questionId, 'orderPosition')(getOrderPosition(order, index - 1, updatedQuestions) + 1 + idx)(
												nestedQuestions
											),
										updatedItems
								  )
						)
					)(updatedQuestions);
				}
				if (destinationCategoryId === questionId && !updatedQuestions[destinationCategoryId]?.metaData) {
					return applyNewPositionOrderToAllQuestions(getQuestionsTop(order, removedQestionId, updatedQuestions), order);
				}
				return applyNewPositionOrderToAllQuestions(getQuestionsTop(order, removedQestionId, updatedQuestions), order);
			}, questions);
		}
	}
	if (type === 'droppable-category') {
		uppdatedQuestionsList = applyNewPositionOrderToAllQuestions(questions, order);
	}
	return uppdatedQuestionsList;
};
export enum PipeAnswerValue {
	Selected = 'Selected',
	All = 'All',
	Unselected = 'Unselected',
}

export const mapPipeAnswerTypeToValue = (pipeAnswer: PipeAnswerType | undefined): PipeAnswerValue | undefined => {
	switch (pipeAnswer) {
		case 'selected':
			return PipeAnswerValue.Selected;
		case 'unselected':
			return PipeAnswerValue.Unselected;
		case 'all':
			return PipeAnswerValue.All;
	}
};

export const mapPipeValueToPipeAnswerType = (pipeValue: PipeAnswerValue): PipeAnswerType => {
	switch (pipeValue) {
		case PipeAnswerValue.Selected:
			return 'selected';
		case PipeAnswerValue.Unselected:
			return 'unselected';
		case PipeAnswerValue.All:
			return 'all';
	}
};

export const processOptionsForBulkQuestions = (options: string[]) => {
	return options.map((option, index) => ({
		anchorOnRandomization: false,
		asset: null,
		groupAnchorOnRandomization: false,
		id: uuid.v4(),
		matrixText: null,
		matrixValue: null,
		methodology: 'Accept',
		optionType: 'normal',
		orderPosition: index + 1,
		parentOptionId: null,
		pipeId: null,
		pipeType: 'rows',
		pipeValue: null,
		value: option.replace(/•/, '').trim(),
	}));
};
