import { difference } from 'ramda';
import {
	BrandProductReducer,
	BrandProductAction,
	READ_BRAND_PRODUCTS_FULFILLED,
	CREATE_BRAND_PRODUCTS_FULFILLED,
	UPDATE_BRAND_PRODUCTS_FULFILLED,
	DELETE_BRAND_PRODUCTS_FULFILLED,
} from './types';

export const brandProductReducer: BrandProductReducer = (state, action: BrandProductAction) => {
	switch (action.type) {
		case READ_BRAND_PRODUCTS_FULFILLED:
			return {
				...state,
				products: action.payload,
			};

		case CREATE_BRAND_PRODUCTS_FULFILLED:
			return {
				...state,

				brandProducts: [...state.products, action.payload],
			};

		case UPDATE_BRAND_PRODUCTS_FULFILLED:
			return {
				...state,
				products: state.products.map(
					(product) => action.payload.find((newProduct) => newProduct.id === product.id) || product
				),
			};

		case DELETE_BRAND_PRODUCTS_FULFILLED:
			return {
				...state,
				products: difference(state.products, action.payload),
			};

		default:
			return state;
	}
};
