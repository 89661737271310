import { CreateSurveyFromResearchesAction } from '../types';
import axios from '@utils/axios';
import { surveysApiEndPoint } from './consts';
import { all } from 'redux-saga/effects';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
// import { RedirectState } from '../../../../redirect/types';
import { pend, fulfill, reject } from '../../../../promise/actions';
import { createSurveyFromResearchesAction } from '../actions';
import { environment } from '../../../../../environment';

const isLite = environment.isLite;
export function* createSurveyFromResearch(action: CreateSurveyFromResearchesAction) {
	const { questionnaireId, researches } = action.payload;

	try {
		yield putEffect(pend(createSurveyFromResearchesAction));
		const res = yield all(
			researches.map((researchId, i) =>
				axios.post(getApiEndPointUrl(surveysApiEndPoint), {
					questionnaireId,
					researchId,
					orderPosition: i + 1,
					isLite,
				})
			)
		);

		// const { info }: RedirectState = yield select((s) => s.redirect);

		yield putEffect(
			fulfill(
				createSurveyFromResearchesAction,
				res.map((el) => el.data)
			)
		);

		yield putEffect(setSurveyError(''));
	} catch (e) {
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(createSurveyFromResearchesAction, getApiError(e)));
	}
}
