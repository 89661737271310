import { QUES_TYPE } from './quesType';
import { LinearRangeSettings } from '../types/QuestionSettings';

type DefaultValuesType = {
	[QUES_TYPE.WELCOME_SCREEN]: {
		buttonLabel: string;
	};
	[QUES_TYPE.OPINION_SCALE_QUES]: {
		scale: number;
		leftLabel: string;
		rightLabel: string;
		startAtOne: boolean;
		scaleMin: number;
		scaleMax: number;
	};
	[QUES_TYPE.NUMBER_QUES]: {
		minVal: number;
		maxVal: number;
	};
	[QUES_TYPE.TEXT_BOX_QUES]: {
		minChars: number;
		maxChars?: number;
		voiceTranscription?: boolean;
	};
	[QUES_TYPE.RATING_QUES]: {
		scale: number;
		scaleMin: number;
		scaleMax: number;
		iconType: string;
	};
	[QUES_TYPE.LINEAR_RANGE_QUES]: LinearRangeSettings;
};

export const defaultValues: DefaultValuesType = {
	[QUES_TYPE.WELCOME_SCREEN]: {
		buttonLabel: 'Get Started',
	},
	[QUES_TYPE.OPINION_SCALE_QUES]: {
		scale: 10,
		leftLabel: 'Least Likely',
		rightLabel: 'Most Likely',
		startAtOne: false,
		scaleMin: 0,
		scaleMax: 10,
	},
	[QUES_TYPE.NUMBER_QUES]: {
		minVal: 1,
		maxVal: 1000,
	},
	[QUES_TYPE.TEXT_BOX_QUES]: {
		minChars: 3,
		maxChars: undefined,
		voiceTranscription: false,
	},
	[QUES_TYPE.RATING_QUES]: {
		scale: 5,
		scaleMin: 2,
		scaleMax: 6,
		iconType: 'star',
	},
	[QUES_TYPE.LINEAR_RANGE_QUES]: {
		skip: false,
		minVal: 0,
		maxVal: 100,
		step: 20,
		leftLabel: '',
		rightLabel: '',
		centerLabel: '',
		isMandatoryQuestion: false,
	},
};
