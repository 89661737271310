import { ReduxAction } from 'shared/types/ReduxAction';

export const SEND_REPORT_FILTERS = 'SEND_REPORT_FILTERS';
export const SEND_REPORT_FILTERS_FULFILLED = 'SEND_REPORT_FILTERS_FULFILLED';
export const READ_CURRENT_ANALYTICS_REPORT = 'READ_CURRENT_ANALYTICS_REPORT';
export const DELETE_GENERAL_ANALYTIC_REPORT = 'DELETE_GENERAL_ANALYTIC_REPORT';
export const READ_GENERAL_ANALYTIC_ALL_REPORT = 'READ_GENERAL_ANALYTIC_ALL_REPORT';
export const SET_GENERAL_ANALYTICS_REPORT_ERROR = 'SET_GENERAL_ANALYTICS_REPORT_ERROR';
export const READ_CURRENT_ANALYTICS_REPORT_FULFILLED = 'READ_CURRENT_ANALYTICS_REPORT_FULFILLED';
export const DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED = 'DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED';
export const READ_GENERAL_ANALYTICS_ALL_REPORT_FULFILLED = 'READ_GENERAL_ANALYTICS_ALL_REPORT_FULFILLED';
export const SEND_REPORT_COMPARISON = 'SEND_REPORT_COMPARISON';
export const SEND_REPORT_COMPARISON_FULFILLED = 'SEND_REPORT_COMPARISON_FULFILLED';

export type SetGeneralAnalyticsReportError = ReduxAction<typeof SET_GENERAL_ANALYTICS_REPORT_ERROR, string | null>;

export type UpdateReportPayload = {
	id: string;
	reportName?: string;
	questionnaireId?: string;
	filterOperator?: string;
	filterList?: Filter[];
	comparisonList?: Comparison[];
	denominator?: any;
	name?: string;
};

export type GetReportPayload = string;

export type GetCurrentReportPayload = {
	questionnaireId: string;
};

export type DeleteReportPayload = string;

export type Options = {
	label: string;
	value: string;
};

export type Filter = {
	questionId: string;
	questionType: string;
	questionText: string;
	optionOperator: string;
	options: Options[];
	logic: string;
};

export type Comparison = {
	questionId: string;
	questionType: string;
	optionOperator: string;
	questionText: string;
	options: Options[];
	logic: string;
};

export type GeneralAnalyticsReport = {
	id: string;
};

export type CurrentAnalyticsReport = {
	id: string;
};

export const setGeneralAnalyticsReportError: (payload: string | null) => SetGeneralAnalyticsReportError = (
	payload
) => ({
	type: SET_GENERAL_ANALYTICS_REPORT_ERROR,
	payload,
});

export const readGeneralAnalyticsAllReport = (payload: string) => ({
	type: READ_GENERAL_ANALYTIC_ALL_REPORT,
	payload: payload,
});

export const readGeneralAnalyticsAllReportFulfilled = (payload: UpdateReportPayload[]) => ({
	type: READ_GENERAL_ANALYTICS_ALL_REPORT_FULFILLED,
	payload,
});

export const readCurrentAnalyticsReport = (payload: CurrentAnalyticsReport) => ({
	type: READ_CURRENT_ANALYTICS_REPORT as typeof READ_CURRENT_ANALYTICS_REPORT,
	payload: { questionnaireId: payload.id },
});

export const readCurrentAnalyticsReportFulfilled = (payload: UpdateReportPayload[]) => ({
	type: READ_CURRENT_ANALYTICS_REPORT_FULFILLED as typeof READ_CURRENT_ANALYTICS_REPORT_FULFILLED,
	payload,
});

export const deleteGeneralAnalyticsReport = (payload: DeleteReportPayload) => ({
	type: DELETE_GENERAL_ANALYTIC_REPORT,
	payload: payload,
});

export const deleteGeneralAnalyticsReportFulfilled = (payload: UpdateReportPayload[]) => ({
	type: DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED as typeof DELETE_GENERAL_ANALYTIC_REPORT_FULFILLED,
	payload,
});

export const sendGeneralAnalyticsReportFilters = (payload: UpdateReportPayload) => ({
	type: SEND_REPORT_FILTERS,
	payload,
});

export const sendGeneralAnalyticsReportFulfilled = (payload: UpdateReportPayload[]) => ({
	type: SEND_REPORT_FILTERS_FULFILLED,
	payload,
});

// export const sendGeneralAnalyticsReportComparison = (payload: UpdateReportPayload) => ({
// 	type: SEND_REPORT_COMPARISON,
// 	payload,
// });

// export const sendGeneralAnalyticsComparisonReportFulfilled = (payload: UpdateReportPayload[]) => ({
// 	type: SEND_REPORT_COMPARISON_FULFILLED,
// 	payload,
// });
