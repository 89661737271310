import {
	READ_BRAND_FUNNEL_METRICS_FULFILLED,
	CREATE_BRAND_FUNNEL_METRIC_FULFILLED,
	BrandFunnelMetricAction,
	BrandFunnelMetricReducer,
	BrandFunnelMetric,
} from './types';
const colors = [
	'#FBBC1A',
	'#3ECA69',
	'#E73A5E',
	'#00AEC8',
	'#FB9300',
	'#7DB1A1',
	'#523B5D',
	'#F9B6A8',
	'#00A0FB',
	'#6E00FB',
];

const colorify = (metrics: BrandFunnelMetric[]): BrandFunnelMetric[] => {
	return metrics.map((metric, index) => ({
		...metric,
		color: colors[index % colors.length],
	}));
};
export const brandFunnelMetricReducer: BrandFunnelMetricReducer = (state, action: BrandFunnelMetricAction) => {
	switch (action.type) {
		case READ_BRAND_FUNNEL_METRICS_FULFILLED:
			return {
				...state,
				brandFunnelMetrics: colorify([
					{ label: 'Unaided Brand Awareness ', value: 'unaided' },
					{ label: 'Aided Brand Awareness ', value: 'aided' },
					{ value: 'preference', label: 'Consideration' },
					{ value: 'consideration', label: 'Top of the mind recall' },
					{ value: 'bought', label: 'Most Often Purchased' },
					...action.payload,
				]),
			};
		case CREATE_BRAND_FUNNEL_METRIC_FULFILLED:
			return {
				...state,
				brandFunnelMetrics: colorify([...state.brandFunnelMetrics, action.payload]),
			};
		default:
			return state;
	}
};
