import {
	ReadBrandFunnelMetrics,
	READ_BRAND_FUNNEL_METRICS,
	BrandFunnelMetric,
	ReadBrandFunnelMetricsFulfilled,
	READ_BRAND_FUNNEL_METRICS_FULFILLED,
	CreateBrandFunnelMetricPayload,
	ReadBrandFunnelMetricsPayload,
	CreateBrandFunnelMetric,
	CREATE_BRAND_FUNNEL_METRIC,
	CREATE_BRAND_FUNNEL_METRIC_FULFILLED,
	CreateBrandFunnelMetricFulfilled,
} from './types';

export const readBrandFunnelMetrics = (payload: ReadBrandFunnelMetricsPayload): ReadBrandFunnelMetrics => ({
	type: READ_BRAND_FUNNEL_METRICS,
	payload,
});

export const readBrandFunnelMetricsFulfilled = (payload: BrandFunnelMetric[]): ReadBrandFunnelMetricsFulfilled => ({
	type: READ_BRAND_FUNNEL_METRICS_FULFILLED,
	payload: payload,
});

export const createBrandFunnelMetric = (payload: CreateBrandFunnelMetricPayload): CreateBrandFunnelMetric => ({
	type: CREATE_BRAND_FUNNEL_METRIC,
	payload,
});

export const createBrandFunnelMetricFulfilled = (payload: BrandFunnelMetric): CreateBrandFunnelMetricFulfilled => ({
	type: CREATE_BRAND_FUNNEL_METRIC_FULFILLED,
	payload,
});
