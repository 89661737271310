import { makeActionCreatorSurvey } from '../../../../shared/utils/makeActionCreator';
import {
	ADD_QUES_OPTION,
	AddQuesOption,
	DELETE_QUES_OPTION,
	DeleteQuesOption,
	EDIT_QUES_OPTION,
	EditQuesOption,
	MOVE_QUES_OPTION,
	MoveQuesOption,
	UPLOAD_OPTION_ASSET,
	UploadOptionAsset,
	EditPipeQuesOption,
	EDIT_PIPE_QUES_OPTION,
	DeleteOptionAsset,
	DELETE_OPTION_ASSET,
	PatchQuesOptionsPayload,
	PatchQuesOptions,
	PATCH_QUES_OPTIONS,
	CLEAR_OPTIONS_BY_IDS,
	ClearOptionsByIds,
	MoveBulkOption,
	MOVE_BULK_OPTION,
} from './types';
import { QuestionPipe } from '../../../../shared/types/QuestionLogic';
import { MethodologyType, OptionType, PipeValue } from '../../../../shared/types/QuesTypes';

export const addQuesOption = (payload: {
	quesId: string;
	none?: boolean;
	pipe?: QuestionPipe;
	file?: File;
	url?: string;
	assetId?: string;
	optionText?: string;
	methodology?: string;
	bulkChoices?: boolean;
	bulkOptionsData?: any;
	rows?: number;
	columns?: number;
}): AddQuesOption => ({
	type: ADD_QUES_OPTION,
	payload,
});

export const editQuesOption = (payload: {
	quesId: string;
	optionId: string;
	optionText?: string;
	anchorOnRandomization?: boolean;
	methodology?: MethodologyType;
	moderated?: boolean;
}): EditQuesOption => ({
	type: EDIT_QUES_OPTION,
	payload,
});

export const patchQuesOptions = (payload: PatchQuesOptionsPayload): PatchQuesOptions => ({
	type: PATCH_QUES_OPTIONS,
	payload,
});

export const moveQuesOption = makeActionCreatorSurvey(
	MOVE_QUES_OPTION,
	'quesId',
	'srcIndex',
	'destIndex',
	'isBulkUpdation',
	'updatedOptions'
) as (
	quesId: string,
	srcIndex: number,
	destIndex: number,
	isBulkUpdation?: boolean,
	updatedOptions?: any
) => MoveQuesOption;

export const moveBulkOptions = makeActionCreatorSurvey(
	MOVE_BULK_OPTION,
	'quesId',
	'isBulkUpdation',
	'updatedOptions'
) as (quesId: string, isBulkUpdation?: boolean, updatedOptions?: any) => MoveBulkOption;
export const deleteQuesOption = makeActionCreatorSurvey(
	DELETE_QUES_OPTION,
	'quesId',
	'optionId',
	'moderated',
	'pipeId'
) as (quesId: string, optionId: string, moderated?: boolean, pipeId?: string) => DeleteQuesOption;

export const uploadQuesOptionAsset = makeActionCreatorSurvey(
	UPLOAD_OPTION_ASSET,
	'questionId',
	'optionId',
	'file',
	'url'
) as (questionId: string, optionId: string, file?: File, url?: string) => UploadOptionAsset;

export const editPipeQuesOption = makeActionCreatorSurvey(
	EDIT_PIPE_QUES_OPTION,
	'quesId',
	'pipeId',
	'pipeValue',
	'optionType',
	'optionId'
) as (
	quesId: string,
	pipeId: string | null,
	pipeValue: PipeValue | null,
	optionType: OptionType,
	optionId: string
) => EditPipeQuesOption;

export const deleteQuesOptionAsset = makeActionCreatorSurvey(DELETE_OPTION_ASSET, 'optionId') as (
	optionId: string
) => DeleteOptionAsset;

export const clearOptionsByIds = makeActionCreatorSurvey(CLEAR_OPTIONS_BY_IDS, 'questionId', 'optionsIds') as (
	questionId: string,
	optionsIds: string[]
) => ClearOptionsByIds;
