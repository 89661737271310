import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { readGeneralAnalytics, readGeneralAnalyticsFulfilled, READ_GENERAL_ANALYTICS_FULFILLED } from './actions';

export type GeneralAnalyticsAction =
	| ReturnType<typeof readGeneralAnalytics>
	| ReturnType<typeof readGeneralAnalyticsFulfilled>;
export type GeneralAnalyticsReducer = ReduxReducer<SurveyAnalytics, GeneralAnalyticsAction>;

export const generalAnalyticsReducer: GeneralAnalyticsReducer = (state, action: GeneralAnalyticsAction) => {
	switch (action.type) {
		case READ_GENERAL_ANALYTICS_FULFILLED:
			return {
				...state,
				generalAnalytics: [action.payload],
			};
		default:
			return state;
	}
};
