import { differenceWith } from 'ramda';
import {
	NicheReducer,
	NicheAction,
	CREATE_NICHES_FULFILLED,
	UPDATE_NICHES_FULFILLED,
	READ_NICHES_FULFILLED,
	DELETE_NICHES_FULFILLED,
	PUT_NICHES_FULFILLED,
} from './types';
import { compareNichesId } from './utils';

export const nicheReducer: NicheReducer = (state, action: NicheAction) => {
	switch (action.type) {
		case CREATE_NICHES_FULFILLED:
			return {
				...state,
				niches: [...state.niches, ...action.payload],
			};

		case UPDATE_NICHES_FULFILLED:
			return {
				...state,
				niches: state.niches.map((niche) => action.payload.find((newNiche) => newNiche.id === niche.id) || niche),
			};

		case READ_NICHES_FULFILLED:
		case PUT_NICHES_FULFILLED:
			return {
				...state,
				niches: action.payload,
			};
		case DELETE_NICHES_FULFILLED:
			return {
				...state,
				niches: differenceWith(compareNichesId, state.niches, action.payload),
			};

		default:
			return state;
	}
};
