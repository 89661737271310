import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';

export const READ_WORKSPACE_TEAM = 'READ_WORKSPACE_TEAM';
export const READ_WORKSPACE_TEAM_FULFILLED = 'READ_WORKSPACE_TEAM_FULFILLED';

export type WorkspaceTeamMember = {
	accessLevel: string;
	email: string;
	firstName: string;
	lastActiveAt: string;
	lastName: string;
	pendingApprovalCount: number;
	role: {
		id: string;
		name: string;
	};
};

export type WorkspaceTeamObject = {
	teamMembers: WorkspaceTeamMember[];
	totalPendingApprovalCount: number;
};

export type WorkspaceTeamState = WorkspaceTeamObject & {};

export type ReadWorkspaceTeamPayload = {
	workspaceId: string;
};

export type ReadWorkspaceTeamFulfilledPayload = WorkspaceTeamObject;

export type ReadWorkspaceTeam = ReduxAction<typeof READ_WORKSPACE_TEAM, ReadWorkspaceTeamPayload>;

export type ReadWorkspaceTeamFulfilled = ReduxAction<
	typeof READ_WORKSPACE_TEAM_FULFILLED,
	ReadWorkspaceTeamFulfilledPayload
>;

export type WorkspaceTeamAction = ReadWorkspaceTeam | ReadWorkspaceTeamFulfilled;

export type WorkspaceTeamReducer = ReduxReducer<WorkspaceTeamState, WorkspaceTeamAction>;
