import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';

import { putEffect } from '../../../shared/utils/putEffect';
import {
	readBrandAssetsFulfilled,
	createBrandAssetsFulfilled,
	deleteBrandAssetsFulfilled,
	readBrandAssets,
	deleteBrandAssets,
	createBrandAssets,
} from './actions';
import {
	ReadBrandAssetsPayload,
	ReadBrandAssets,
	READ_BRAND_ASSETS,
	CreateBrandAssets,
	CreateBrandAssetsPayload,
	BrandAsset,
	DeleteBrandAssets,
	CREATE_BRAND_ASSETS,
	DELETE_BRAND_ASSETS,
	BrandAssetsSchema,
} from './types';
import { brandAssetsEndpoint } from './const';
import { getApiError } from '../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { validate } from '../../../shared/utils/jsonschema';
import { pend, fulfill, reject } from '../../promise/actions';

function readBrandAssetsAPI(brand: ReadBrandAssetsPayload) {
	return axios.get(getApiEndPointUrl(brandAssetsEndpoint), {
		params: brand,
	});
}
function deleteBrandAssetAPI(asset: BrandAsset) {
	return axios.delete(getApiEndPointUrl(brandAssetsEndpoint, asset.id));
}

function createBrandAssetsAPI({ files, brandId }: CreateBrandAssetsPayload) {
	let formData = new FormData();
	Array.from(files).forEach((file) => formData.append('assets', file));
	formData.append('brandId', brandId);
	return axios.post(getApiEndPointUrl(brandAssetsEndpoint, 'batch'), formData);
}

function* readBrandAssetsSaga(action: ReadBrandAssets) {
	try {
		yield putEffect(pend(readBrandAssets));
		const res = yield readBrandAssetsAPI(action.payload);
		yield putEffect(fulfill(readBrandAssets, res.data));
		yield putEffect(readBrandAssetsFulfilled(validate(res.data, BrandAssetsSchema)));
	} catch (e) {
		yield putEffect(reject(readBrandAssets, getApiError(e)));
	}
}

function* deleteBrandAssetsSaga(action: DeleteBrandAssets) {
	try {
		yield putEffect(pend(deleteBrandAssets));
		yield Promise.all(action.payload.map(deleteBrandAssetAPI));
		yield putEffect(deleteBrandAssetsFulfilled(action.payload));
		yield putEffect(fulfill(deleteBrandAssets));
	} catch (e) {
		yield putEffect(reject(deleteBrandAssets, getApiError(e)));
	}
}

function* createBrandAssetsSaga({ payload }: CreateBrandAssets) {
	try {
		yield putEffect(pend(createBrandAssets));
		if (payload.files !== null && payload.files.length) {
			const res = yield createBrandAssetsAPI(payload);
			yield putEffect(createBrandAssetsFulfilled(validate(res.data, BrandAssetsSchema)));
		}
		yield putEffect(fulfill(createBrandAssets));
	} catch (e) {
		yield putEffect(reject(createBrandAssets, getApiError(e)));
	}
}

function* getReadBrandAssetsWatcher() {
	yield takeLeading(READ_BRAND_ASSETS, readBrandAssetsSaga);
}

function* getCreateBrandAssetsWatcher() {
	yield takeLeading(CREATE_BRAND_ASSETS, createBrandAssetsSaga);
}
function* getDeleteBrandAssetWatcher() {
	yield takeLeading(DELETE_BRAND_ASSETS, deleteBrandAssetsSaga);
}

export const brandAssetsSaga = [getReadBrandAssetsWatcher, getCreateBrandAssetsWatcher, getDeleteBrandAssetWatcher];
