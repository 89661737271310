import { GetSurveyBrand } from '../types';
import { putEffect } from '../../../../shared/utils/putEffect';
import { setSurveyError } from '../../surveyBuilder/shared/actions';
import { getApiError } from '../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../shared/utils/getApiEndpointUrl';
import axios from '@utils/axios';
import { mapBrandResponseToSurveyBrand } from './utils';
import { setSurveyBrandAction } from '../actions';

export function* getSurveyBrand(action: GetSurveyBrand) {
	try {
		const { data } = yield axios.get(getApiEndPointUrl('brands', action.payload.id));
		yield putEffect(setSurveyBrandAction(mapBrandResponseToSurveyBrand(data)));
	} catch (e) {
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
