import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';

export type PromiseError = {
	id: string;
	reason: string;
};
export type PromiseResult = {
	id: string;
	data?: any;
};
export const REJECTED = 'REJECTED';
export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const RESET = 'RESET';
export const RESET_ALL = 'RESET_ALL';

export type Reject = ReduxAction<typeof REJECTED, PromiseError>;
export type Fulfill = ReduxAction<typeof FULFILLED, PromiseResult>;
export type Pend = ReduxAction<typeof PENDING, string>;
export type Reset = ReduxAction<typeof RESET, string[]>;
export type ResetAll = ReduxAction<typeof RESET_ALL, null>;

export type PromiseAction = Reject | Fulfill | Pend | Reset | ResetAll;

export type PromiseState = {
	rejected: any;
	pending: any;
	fulfilled: any;
};

export class SuperArray extends Array<PromiseResult | PromiseError> {
	public includesAll(...subArray: Function[]): boolean {
		return subArray.every((v) => this.find((p) => p.id === v()?.type));
	}
	public includesAny(...subArray: Function[]): boolean {
		return subArray.some((v) => this.find((p) => p.id === v()?.type));
	}
	public findOne(action: Function): PromiseResult | undefined {
		return this.find((p) => p.id === action()?.type);
	}
}
export class SuperPendingArray extends Array<string> {
	public includesAll(...subArray: Function[]): boolean {
		return subArray.every((v) => this.find((p) => p === v()?.type));
	}
	public includesAny(...subArray: Function[]): boolean {
		return subArray.some((v) => this.find((p) => p === v()?.type));
	}
}

export type UnionAction = PromiseAction;

export type PromiseReducer = ReduxReducer<PromiseState, PromiseAction>;
