import { surveyGames, standard } from './../enums/DesignEnum';
import { gameTemplateType } from '../types/gameTemplate';

import forest from '../../assets/img/GameIcons/forest.png';
import none from '../../assets/img/GameIcons/none.png';

export const gameTemplate: gameTemplateType[] = [
	{
		name: surveyGames.Forest,
		src: forest,
		isActive: false,
	},
	// {
	//   name: surveyGames.Scenery,
	//   src: forest,
	//   isActive: false
	// }
];

export const standardTemplate: gameTemplateType[] = [
	{
		name: standard.None,
		src: none,
		isActive: false,
	},
];
