import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';
import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	readVideoRecordings,
	readVideoRecordingsFulfilled,
	updateVideoRecordingFulfilled,
	updateVideoRecording,
	updateVideoMarkers,
	updateVideoMarkersFulfilled,
} from './actions';
import { questionniareEndPoint, videoRecordingsEndPoint } from './const';
import {
	ReadVideoRecordingsPayload,
	READ_VIDEO_RECORDINGS,
	ReadVideoRecordings,
	VideoRecordingsSchema,
	UpdateVideoRecordingPayload,
	UpdateVideoMarkersPayload,
	UpdateVideoMarkers,
	UpdateVideoRecordings,
	UPDATE_VIDEO_RECORDINGS,
	UPDATE_VIDEO_MARKERS,
} from './types';
function readVideoRecordingsAPI(action: ReadVideoRecordingsPayload) {
	const { id, ...params } = action;
	return axios.get(getAnalyticsApiEndPointUrl(questionniareEndPoint, id, videoRecordingsEndPoint), {
		params,
	});
}

function updateVideoMarkersAPI({ videoMarkers, responseId, questionnaireId }: UpdateVideoMarkersPayload) {
	return axios.put(
		getAnalyticsApiEndPointUrl(questionniareEndPoint, questionnaireId, videoRecordingsEndPoint, responseId),
		{ videoMarkers }
	);
}

function* updateVideoMarkersSaga(action: UpdateVideoMarkers) {
	try {
		yield putEffect(pend(updateVideoMarkers));
		yield updateVideoMarkersAPI(action.payload);
		yield putEffect(updateVideoMarkersFulfilled(action.payload));
		yield putEffect(fulfill(updateVideoMarkers));
	} catch (e) {
		yield putEffect(reject(updateVideoMarkers, getApiError(e)));
	}
}

function updateVideoRecordingAPI({ comment, responseId, questionnaireId }: UpdateVideoRecordingPayload) {
	return axios.patch(
		getAnalyticsApiEndPointUrl(questionniareEndPoint, questionnaireId, videoRecordingsEndPoint, responseId),
		{ comment }
	);
}

function* updateVideoRecordingSaga(action: UpdateVideoRecordings) {
	try {
		yield putEffect(pend(updateVideoRecording));
		yield updateVideoRecordingAPI(action.payload);
		yield putEffect(updateVideoRecordingFulfilled(action.payload));
		yield putEffect(fulfill(updateVideoRecording));
	} catch (e) {
		yield putEffect(reject(updateVideoRecording, getApiError(e)));
	}
}

function* readVideoRecordingsSaga(action: ReadVideoRecordings) {
	try {
		yield putEffect(pend(readVideoRecordings));
		const res = yield readVideoRecordingsAPI(action.payload);

		yield putEffect(readVideoRecordingsFulfilled(validate(res.data, VideoRecordingsSchema)));
		yield putEffect(fulfill(readVideoRecordings));
	} catch (e) {
		yield putEffect(reject(readVideoRecordings, getApiError(e)));
	}
}

export const getVideoRecordingsWatchers = [
	function* () {
		yield takeLeading(READ_VIDEO_RECORDINGS, readVideoRecordingsSaga);
	},
	function* () {
		yield takeLeading(UPDATE_VIDEO_RECORDINGS, updateVideoRecordingSaga);
	},
	function* () {
		yield takeLeading(UPDATE_VIDEO_MARKERS, updateVideoMarkersSaga);
	},
];
