import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	readBrandKeywordsFulfilled,
	createBrandKeywordsFulfilled,
	createBrandKeywords,
	readBrandKeywords,
} from './actions';
import { brandKeywordEndPoint } from './const';
import {
	BrandKeywordsSchema,
	CreateBrandKeywords,
	CreateBrandKeywordsPayload,
	CREATE_BRAND_KEYWORDS,
	ReadBrandKeywords,
	ReadBrandKeywordsPayload,
	READ_BRAND_KEYWORDS,
} from './types';

function createBrandKeywordsAPI(brandKeywordObj: CreateBrandKeywordsPayload) {
	return axios.post(getApiEndPointUrl(brandKeywordEndPoint, 'batch/save'), brandKeywordObj);
}

function readBrandKeywordsAPI(brandObj: ReadBrandKeywordsPayload) {
	return axios.get(getApiEndPointUrl(brandKeywordEndPoint), {
		params: brandObj,
	});
}

function* createBrandKeywordsSaga({ payload }: CreateBrandKeywords) {
	try {
		yield putEffect(pend(createBrandKeywords));
		if (payload.keywords.length) {
			const res = yield createBrandKeywordsAPI(payload);
			yield putEffect(createBrandKeywordsFulfilled(validate(res.data, BrandKeywordsSchema)));
		}
		yield putEffect(fulfill(createBrandKeywords));
	} catch (e) {
		yield putEffect(reject(createBrandKeywords, getApiError(e)));
	}
}

function* readBrandKeywordsSaga(action: ReadBrandKeywords) {
	try {
		yield putEffect(pend(readBrandKeywords));
		const res = yield readBrandKeywordsAPI(action.payload);
		yield putEffect(readBrandKeywordsFulfilled(validate(res.data, BrandKeywordsSchema)));
		yield putEffect(fulfill(readBrandKeywords));
	} catch (e) {
		yield putEffect(reject(readBrandKeywords, getApiError(e)));
	}
}
function* getCreateBrandKeywordsWatcher() {
	yield takeLeading(CREATE_BRAND_KEYWORDS, createBrandKeywordsSaga);
}
function* getReadBrandKeywordsWatcher() {
	yield takeLeading(READ_BRAND_KEYWORDS, readBrandKeywordsSaga);
}

export const brandKeywordSagas = [getReadBrandKeywordsWatcher, getCreateBrandKeywordsWatcher];
