import axios from '@utils/axios';
import { call } from 'redux-saga/effects';
import { environment } from '../../../environment';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { approveAction, approveActionSuccess, rejectAction, rejectActionSuccess, setApprovalError } from '../actions';
import { ApproveAction, RejectAction } from '../types';
import { fulfill, pend, reject } from '../../promise/actions';

export enum Action {
	Approve = 'approve',
	Reject = 'reject',
	RequestReview = 'request-review',
}

const actionRequest =
	({ type }, action, body = {}) =>
	() => {
		return axios.post(`${environment.apiUrl}questionnaires/${type}/${action}`, body);
	};

export function* approveSaga({ payload }: ApproveAction) {
	try {
		yield putEffect(pend(approveAction));
		const approve = yield call(actionRequest(payload, Action.Approve, { userId: payload.userId }));
		yield putEffect(approveActionSuccess(approve));
		yield putEffect(fulfill(approveAction));
	} catch (e) {
		yield putEffect(setApprovalError(getApiError(e)));
		yield putEffect(reject(approveAction, getApiError(e)));
	}
}

export function* rejectSaga({ payload }: RejectAction) {
	try {
		yield putEffect(pend(rejectAction));
		const reject = yield call(actionRequest(payload, Action.Reject, { userId: payload.userId }));
		yield putEffect(rejectActionSuccess(reject));
		yield putEffect(fulfill(rejectAction));
	} catch (e) {
		yield putEffect(setApprovalError(getApiError(e)));
		yield putEffect(reject(rejectAction, getApiError(e)));
	}
}
