import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';

export const GET_SURVEY_BRAND = 'GET_SURVEY_BRAND';
export const SET_SURVEY_BRAND = 'SET_SURVEY_BRAND';

export type SurveyBrand = {
	id: string;
	name: string;
	website: string;
	industry: string;
	keywords: { id: string; keyword: string }[];
	brandAssets: { id: string; url: string; assetId: string }[];
	competitors: { id: string; name: string }[];
	products: { id: string; name: string; keywords: string[]; imageUrl: string; assetId: string }[];
};

export type SurveyBrandState = {
	info: SurveyBrand;
	requestInProgress: boolean;
};

export type GetSurveyBrand = ReduxAction<typeof GET_SURVEY_BRAND, { id: string }>;
export type SetSurveyBrand = ReduxAction<typeof SET_SURVEY_BRAND, SurveyBrand>;

export type SurveyBrandReducer = ReduxReducer<SurveyBrandState, GetSurveyBrand | SetSurveyBrand>;
