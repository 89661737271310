import { SurveyAnalytics } from '../types';
import { ReduxReducer } from 'shared/types/ReduxReducer';
import {
	readComparisonReport,
	readComparisonReportFulfilled,
	READ_COMPARISON_ANALYTICS_REPORT_FULFILLED,
} from './actions';

export type ComparisonReportAction =
	| ReturnType<typeof readComparisonReport>
	| ReturnType<typeof readComparisonReportFulfilled>;

export type ComparisonReducer = ReduxReducer<SurveyAnalytics, ComparisonReportAction>;
//@ts-ignore
export const ComparisonReportsReducer: ComparisonReducer = (state: SurveyAnalytics, action: ComparisonReportAction) => {
	switch (action.type) {
		case READ_COMPARISON_ANALYTICS_REPORT_FULFILLED:
			return {
				...state,
				comparisonReports: action.payload,
			};
		default:
			return state;
	}
};
