export const getPiped = (question) => {
	return !!question?.logic?.pipeAnswer?.length;
};
export enum GamifiedTheme {
	DENTAL = 'dental',
	STOCK = 'stock',
	EDUCATION = 'education',
	STOCK_TEMPLATE2 = 'stock_template2',
	STOCK_TEMPLATE3 = 'stock_template3',
}

export enum QuestionTheme {
	STOCK_TEMPLATE = 'stock',
	DENTAL_TEMPLATE1 = 'dental_template1',
	DENTAL_TEMPLATE2 = 'dental_template2',
	STOCK_TEMPLATE1 = 'stock_template1',
	STOCK_TEMPLATE2 = 'stock_template2',
	STOCK_TEMPLATE3 = 'stock_template3',
	STOCK_TEMPLATE4 = 'stock_template4',
	STOCK_TEMPLATE5 = 'stock_template5',
	EDUCATION_TEMPLATE1 = 'education_template1',
	EDUCATION_TEMPLATE2 = 'education_template2',
	EDUCATION_TEMPLATE3 = 'education_template3',
	EDUCATION_TEMPLATE4 = 'education_template4',
}

export const blockInvalidChar = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
