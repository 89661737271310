import mixpanel from 'mixpanel-browser';
import React, { lazy, Suspense } from 'react';
import { Router, useCurrentRoute, useNavigation, View } from 'react-navi';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import './App.scss';
import { ReduxErrorHandler, ReactQueryErrorHandler } from './components/ErrorHandler/ErrorHandler';
import { Loader, ReactQueryLoader } from './components/Loader/Loader';
import MixPanelProvider from './components/MixPanelProvider';
import { routes } from './routes';
import { usePutProfile, useReadProfile } from './services/profile';
import DrawerProvider from './shared/components/ConfirmDrawer/DrawerProvider';
import ModalProvider from './shared/components/ModalProvider';
import { NotificationsContextProvider } from './shared/components/Notifications/context';
import RedirectWatcher from './shared/components/RedirectWatcher';
import { Spinner } from './shared/components/Spinner/Spinner';
import { StyledGlobalTooltip } from './shared/components/StyledTooltip/styled';
import { theme } from './shared/consts/theme';
import { useDebouncedEffect } from './shared/utils/useDebouncedEffect';
import { useMount } from './shared/utils/useMount';
import { StoreState } from './store';
import { readUserRoles } from './store/auth/actions';
import { Role } from './store/auth/types';
import { customFetch } from './utils/customFetch';
import { useReduxQuery } from './utils/reduxQuery/useReduxMutation';
import { UrlQueryProvider } from './utils/urlQuery';

mixpanel.init('522e3394592d8a8760cce788892c4790');
const App = () => {
	const GlobalStyles = lazy(() => import('./styles/GlobalStyles'));
	const userRoles = useSelector<StoreState, Role[]>((s) => s.user?.roles || []);
	const hasMounted = useMount();
	const { isSuccess, isError } = useReadProfile();
	const { isSettled: isRolesSettled } = useReduxQuery([], readUserRoles, {
		hideGlobalError: true,
		enabled: hasMounted,
	});

	return (
		<SWRConfig
			value={{
				revalidateOnMount: true,
				fetcher: customFetch,
				revalidateOnFocus: false,
			}}
		>
			<ThemeProvider theme={theme}>
				<NotificationsContextProvider>
					<MixPanelProvider>
						<DrawerProvider>
							<ModalProvider>
								<div className="app">
									<Loader />
									<ReduxErrorHandler />
									<ReactQueryLoader />
									<ReactQueryErrorHandler />
									<Router
										routes={routes}
										context={{
											isSettled: (isSuccess || isError) && isRolesSettled,
											user: {
												isAuthenticated: isSuccess,
												userRoles,
											},
										}}
									>
										<Suspense fallback={<Spinner isFullScreen={true} />}>
											<GlobalStyles />
											<StyledGlobalTooltip />
											<RedirectWatcher />
											<InternalApp>
												<View />
											</InternalApp>
										</Suspense>
									</Router>
								</div>
							</ModalProvider>
						</DrawerProvider>
					</MixPanelProvider>
				</NotificationsContextProvider>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</SWRConfig>
	);
};
const InternalApp = ({ children }) => {
	const { navigate } = useNavigation();
	const {
		lastChunk: { request },
	} = useCurrentRoute();
	const workspaceId = request?.params.workspaceId;
	const { data: profile } = useReadProfile();
	const { mutate } = usePutProfile();
	// Debounce for 5 second to make sure that yeah workspace is correct and no redirects will happen
	useDebouncedEffect(
		() => {
			if (workspaceId === profile?.currentWorkspaceId || !profile?.id || !workspaceId) return;
			mutate({
				id: profile?.id,
				currentWorkspaceId: workspaceId || null,
			});
		},
		[workspaceId, profile?.currentWorkspaceId, profile?.id, mutate],
		5000
	);
	return (
		<UrlQueryProvider
			push={navigate}
			useRoute={useCurrentRoute}
			getQuery={(object) => {
				const {
					lastChunk: { request },
				} = object;
				return request?.query;
			}}
		>
			{children}
		</UrlQueryProvider>
	);
};
export default App;
