import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { Brand } from '../brand/detail/types';

export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const SET_WORKSPACE = 'SET_WORKSPACE';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const SET_WORKSPACE_ERROR = 'SET_WORKSPACE_ERROR';
export const NAVIGATE_TO_SUCCESS = 'NAVIGATE_TO_SUCCESS';
export const GET_WORKSPACES_ACTION = 'GET_WORKSPACES_ACTION';
export const SET_WORKSPACES_WITH_ID = 'SET_WORKSPACES_WITH_ID';

export type Workspace = {
	id: string;
	name: string;
	brands: Brand[];
	totalCredits: number;
};

export type WorkspacesState = {
	workspaces: Workspace[];
	current: string;
	selectedWorkspace: Workspace | null;
	loading: boolean;
	error: string | null;
	workspacesWithId: Workspace[];
};

export type SetWorkspacesWithIdPayload = Workspace[];
export type SetWorkspacesWithIdAction = ReduxAction<typeof SET_WORKSPACES_WITH_ID, SetWorkspacesWithIdPayload>;

export type SetWorkspaceActionPayload = Workspace[];
export type SetWorkspaceAction = ReduxAction<typeof SET_WORKSPACE, SetWorkspaceActionPayload>;

export type SetCurrentWorkspaceActionPayload = string;
export type SetCurrentWorkspaceAction = ReduxAction<typeof SET_CURRENT_WORKSPACE, SetCurrentWorkspaceActionPayload>;

export type CreateWorkspaceActionPayload = {
	name: string;
}[];

export type CreateWorkspaceAction = ReduxAction<typeof CREATE_WORKSPACE, CreateWorkspaceActionPayload>;

export type SetWorkspaceErrorAction = ReduxAction<typeof SET_WORKSPACE_ERROR, string | null>;
export type GetWorkspaceAction = ReduxAction<typeof GET_WORKSPACES_ACTION, null>;

export type WorkspaceAction =
	| CreateWorkspaceAction
	| SetWorkspaceAction
	| SetWorkspaceErrorAction
	| SetCurrentWorkspaceAction
	| GetWorkspaceAction
	| SetWorkspacesWithIdAction;

export type WorkspaceReducer = ReduxReducer<WorkspacesState, WorkspaceAction>;
