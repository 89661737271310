import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	createBrandProductsFulfilled,
	updateBrandProductsFulfilled,
	deleteBrandProductsFulfilled,
	readBrandProductsFulfilled,
	createBrandProducts,
	deleteBrandProducts,
	readBrandProducts,
	updateBrandProducts,
} from './actions';

import { brandProductEndPoint } from './const';
import {
	CreateBrandProductPayload,
	UpdateBrandProductPayload,
	BrandProductType,
	ReadBrandProductsPayload,
	CreateBrandProducts,
	DeleteBrandProducts,
	ReadBrandProducts,
	CREATE_BRAND_PRODUCTS,
	READ_BRAND_PRODUCTS,
	UPDATE_BRAND_PRODUCTS,
	DELETE_BRAND_PRODUCTS,
	UpdateBrandProducts,
	BrandProductsSchema,
} from './types';

function createBrandProductAPI(brandProduct: CreateBrandProductPayload) {
	let formData = new FormData();
	formData.append('image', brandProduct.imageUrl);
	formData.append('name', brandProduct.name);
	(brandProduct.keywords || []).forEach((keyword) => {
		formData.append('keywords[]', keyword);
	});
	formData.append('brandId', brandProduct.brandId);
	return axios.post(getApiEndPointUrl(brandProductEndPoint), formData);
}

function updateBrandProductAPI(brandProduct: UpdateBrandProductPayload) {
	return axios.patch(getApiEndPointUrl(brandProductEndPoint, brandProduct.id), brandProduct);
}
function deleteBrandProductAPI(brandProduct: BrandProductType) {
	return axios.delete(getApiEndPointUrl(brandProductEndPoint, brandProduct.id));
}
function readBrandProductsAPI(brandObj: ReadBrandProductsPayload) {
	return axios.get(getApiEndPointUrl(brandProductEndPoint), {
		params: brandObj,
	});
}

function* createBrandProductsSaga(action: CreateBrandProducts) {
	try {
		yield putEffect(pend(createBrandProducts));
		const responses = yield Promise.all(action.payload.map(createBrandProductAPI));
		yield putEffect(
			createBrandProductsFulfilled(
				validate(
					responses.map((response: { data: any }) => response.data),
					BrandProductsSchema
				)
			)
		);
		yield putEffect(fulfill(createBrandProducts));
	} catch (e) {
		yield putEffect(reject(createBrandProducts, getApiError(e)));
	}
}
function* updateBrandProductsSaga(action: UpdateBrandProducts) {
	try {
		yield putEffect(pend(updateBrandProducts));
		const responses = yield Promise.all(action.payload.map(updateBrandProductAPI));
		yield putEffect(
			updateBrandProductsFulfilled(
				validate(
					responses.map((response: { data: any }) => response.data),
					BrandProductsSchema
				)
			)
		);
		yield putEffect(fulfill(updateBrandProducts));
	} catch (e) {
		yield putEffect(reject(updateBrandProducts, getApiError(e)));
	}
}
function* deleteBrandProductsSaga(action: DeleteBrandProducts) {
	try {
		yield putEffect(pend(deleteBrandProducts));
		yield Promise.all(action.payload.map(deleteBrandProductAPI));
		yield putEffect(deleteBrandProductsFulfilled(action.payload));
		yield putEffect(fulfill(deleteBrandProducts));
	} catch (e) {
		yield putEffect(reject(deleteBrandProducts, getApiError(e)));
	}
}

function* readBrandProductsSaga(action: ReadBrandProducts) {
	try {
		yield putEffect(pend(readBrandProducts));
		const res = yield readBrandProductsAPI(action.payload);
		yield putEffect(readBrandProductsFulfilled(validate(res.data, BrandProductsSchema)));
		yield putEffect(fulfill(readBrandProducts));
	} catch (e) {
		yield putEffect(reject(readBrandProducts, getApiError(e)));
	}
}
function* getCreateBrandProductWatcher() {
	yield takeLeading(CREATE_BRAND_PRODUCTS, createBrandProductsSaga);
}
function* getReadBrandProductsWatcher() {
	yield takeLeading(READ_BRAND_PRODUCTS, readBrandProductsSaga);
}

function* getUpdateBrandProductWatcher() {
	yield takeLeading(UPDATE_BRAND_PRODUCTS, updateBrandProductsSaga);
}
function* getDeleteBrandProductWatcher() {
	yield takeLeading(DELETE_BRAND_PRODUCTS, deleteBrandProductsSaga);
}

export const brandProductSagas = [
	getReadBrandProductsWatcher,
	getCreateBrandProductWatcher,
	getUpdateBrandProductWatcher,
	getDeleteBrandProductWatcher,
];
