import { useCallback, useEffect, useRef, useState } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import { environment } from '../../environment';
import { SocketConnectionQuery } from '../types/SocketConnectionQuery';
import { SocketEvent } from '../enums/SocketEvent';
import { Notification } from '../types/Notification';
import { unshift } from 'shades';
import { useReadProfile } from '../../services/profile';
const useNotifications = <T extends Notification>() => {
	const [notifications, setNotifications] = useState<T[]>([]);
	const socketRef = useRef<typeof Socket>();
	const { data: profile } = useReadProfile();

	const list = useCallback(() => {
		!!socketRef.current && socketRef.current.emit(SocketEvent.ListNotifications, { offset: 0, limit: 100 });
	}, [socketRef]);

	const read = useCallback(
		(notificationId: string) => {
			!!socketRef.current && socketRef.current.emit(SocketEvent.MarkAsSeen, notificationId);
			list();
		},
		[socketRef, list]
	);

	const connect = useCallback((id: string) => {
		socketRef.current = socketIOClient(environment.notifications.apiUrl, {
			transports: ['websocket', 'polling'],
			query: {
				userId: id,
				application: environment.notifications.app,
				clientApiKey: environment.notifications.clientSecret,
			} as SocketConnectionQuery,
		});

		socketRef.current.on(SocketEvent.ListNotifications, setNotifications);
		socketRef.current.on(SocketEvent.NewNotification, (message: T) => setNotifications(unshift(message)));
	}, []);
	const { isSuccess } = useReadProfile();
	useEffect(() => {
		if (!isSuccess || !profile?.locusId) return;
		if (!socketRef.current) return connect(profile?.locusId);
		socketRef.current.on('disconnect', (reason) => {
			if (reason === 'io server disconnect') {
				// the disconnection was initiated by the server, you need to reconnect manually
				connect(profile?.locusId);
			}
			// else the socket will automatically try to reconnect
		});
	}, [profile?.locusId, socketRef, list, connect, isSuccess]);

	useEffect(() => {
		if (!isSuccess && !!socketRef.current) {
			socketRef.current.disconnect();
			socketRef.current = undefined;
		}
	}, [isSuccess, socketRef]);

	return { notifications, read, list };
};

export default useNotifications;
