import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	readBrandColorsFulfilled,
	createBrandColorsFulfilled,
	deleteBrandColorsFulfilled,
	createBrandColors,
	deleteBrandColors,
	readBrandColors,
} from './actions';
import { brandColorEndPoint } from './const';
import {
	BrandColor,
	BrandColorsSchema,
	CreateBrandColorPayload,
	CreateBrandColors,
	CREATE_BRAND_COLORS,
	DeleteBrandColors,
	DELETE_BRAND_COLORS,
	ReadBrandColors,
	ReadBrandColorsPayload,
	READ_BRAND_COLORS,
} from './types';

function createBrandColorAPI(brand: CreateBrandColorPayload) {
	return axios.post(getApiEndPointUrl(brandColorEndPoint), brand);
}

function deleteBrandColorAPI(brand: BrandColor) {
	return axios.delete(getApiEndPointUrl(brandColorEndPoint, brand.id));
}
function readBrandColorsAPI(brand: ReadBrandColorsPayload) {
	return axios.get(getApiEndPointUrl(brandColorEndPoint), {
		params: brand,
	});
}

function* createBrandColorsSaga(action: CreateBrandColors) {
	try {
		yield putEffect(pend(createBrandColors));
		const responses = yield Promise.all(action.payload.map(createBrandColorAPI));
		yield putEffect(
			createBrandColorsFulfilled(
				validate(
					responses.map((response: { data: any }) => response.data),
					BrandColorsSchema
				)
			)
		);
		yield putEffect(fulfill(createBrandColors));
	} catch (e) {
		yield putEffect(reject(createBrandColors, getApiError(e)));
	}
}

function* deleteBrandColorsSaga(action: DeleteBrandColors) {
	try {
		yield putEffect(pend(deleteBrandColors));
		yield Promise.all(action.payload.map(deleteBrandColorAPI));
		yield putEffect(deleteBrandColorsFulfilled(action.payload));
		yield putEffect(fulfill(deleteBrandColors));
	} catch (e) {
		yield putEffect(reject(deleteBrandColors, getApiError(e)));
	}
}

function* readBrandColorsSaga(action: ReadBrandColors) {
	try {
		yield putEffect(pend(readBrandColors));
		const res = yield readBrandColorsAPI(action.payload);
		yield putEffect(readBrandColorsFulfilled(validate(res.data, BrandColorsSchema)));
		yield putEffect(fulfill(readBrandColors));
	} catch (e) {
		yield putEffect(reject(readBrandColors, getApiError(e)));
	}
}
function* getCreateBrandColorsWatcher() {
	yield takeLeading(CREATE_BRAND_COLORS, createBrandColorsSaga);
}
function* getReadBrandColorsWatcher() {
	yield takeLeading(READ_BRAND_COLORS, readBrandColorsSaga);
}

function* getDeleteBrandColorsWatcher() {
	yield takeLeading(DELETE_BRAND_COLORS, deleteBrandColorsSaga);
}

export const brandColorSagas = [getReadBrandColorsWatcher, getCreateBrandColorsWatcher, getDeleteBrandColorsWatcher];
