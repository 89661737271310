import {
	BrandAssetReducer,
	BrandAssetAction,
	READ_BRAND_ASSETS_FULFILLED,
	CREATE_BRAND_ASSETS_FULFILLED,
	DELETE_BRAND_ASSETS_FULFILLED,
} from './types';
import { difference } from 'ramda';
export const brandAssetsReducer: BrandAssetReducer = (state, action: BrandAssetAction) => {
	switch (action.type) {
		case READ_BRAND_ASSETS_FULFILLED:
			return {
				...state,
				assets: action.payload,
			};
		case DELETE_BRAND_ASSETS_FULFILLED:
			return {
				...state,
				assets: difference(state.assets, action.payload),
			};

		case CREATE_BRAND_ASSETS_FULFILLED:
			return {
				...state,
				assets: [...state.assets, ...action.payload],
			};
		default:
			return state;
	}
};
