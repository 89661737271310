import { ReduxAction } from '../../../../shared/types/ReduxAction';
import { Research } from '../../../../shared/enums/Research';
import { Creator, SectionsInSurvey } from '../../../../shared/types/SurveyResponses';
import { Niche } from '../../../brand/niche/types';
import { ResearchModule } from '../../../../shared/enums/ResearchModule';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const UPDATE_SURVEY_DATA = 'UPDATE_SURVEY_DATA';
export const SET_SURVEY_INFO = 'SET_SURVEY_INFO';
export const GET_SURVEY = 'GET_SURVEY';
export const SET_SURVEY = 'SET_SURVEY';
export const SET_SURVEY_GAME = 'SET_SURVEY_GAME';
export const CREATE_SURVEY_FROM_RESEARCH = 'CREATE_SURVEY_FROM_RESEARCH';
export const SET_ACTIVE_SURVEY = 'SET_ACTIVE_SURVEY';
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';
export const CREATE_MODERATE_SLOT = 'CREATE_MODERATE_SLOT';
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';
export const SCHEDULE_SURVEY = 'SCHEDULE_SURVEY';
export const UPDATE_SURVEY_RESEARCH = 'UPDATE_SURVEY_RESEARCH';
export const UPDATE_SURVEY_RESEARCH_FULFILLED = 'UPDATE_SURVEY_RESEARCH_FULFILLED';
export const UPDATE_SURVEY_DESIGN_THEME = 'UPDATE_SURVEY_DESIGN_THEME';
export const SET_SURVEY_DESIGN_THEME = 'SET_SURVEY_DESIGN_THEME';
export const GET_PUBLIC_SURVEY = 'GET_PUBLIC_SURVEY';

export type SurveyInfo = {
	surveyId: string;
	name?: string;
	description?: string;
	allowMultipleResponse?: boolean;
	allowEditAndResubmit?: boolean;
	usePyxisLogo?: boolean;
	isRightOriented?: boolean;
	// surveyTemplateSetting: {
	// 	customize: ThemeDesign;
	// 	surveyTemplate: { myTheme: SurveyThemeDesign[]; exploreTheme: SurveyThemeDesign[]; selectedTemplateId: string };
	// };
	// surveyTemplateSetting: any;
	gamified?: boolean;
	creator?: Creator;
	testingGoal?: string;
	testingTargetUrls?: string[];
	macroTargetingGroupId?: string;
	isModerated?: boolean;
};

export type CreateSurveyFromResearchActionPayload = {
	questionnaireId: string;
	researches: string[];
};

export type CreateSurveyPayload = {
	brandId: string;
	workspaceId: string;
	name: string;
	description: string;
};

export type UpdateSurveyPayload = {
	surveyId: string;
	gamesSelected?: boolean;
	updatePayload?: updatePayload;
};

export type UpdateSurveyGamePayload = {
	surveyId: string;
	game: string;
};

export type updatePayload = {
	game: string;
};

export type SurveyThemeDesign<T = ThemeDesign> = {
	[id: string]: T;
};
export type ThemeDesign = {
	id: string;
	themeName: string;
	brandName: string;
	footerNotes: string;
	showHeader: boolean;
	showFooter: boolean;
	showProgressBar: boolean;
	showQuestionNumber: boolean;
	isMandatoryQuestions: boolean;
	brandLogoURL: string;
	iconImageURL: string;
	isRoundedButton: boolean;
	buttonStyle: {
		filled: boolean;
		border: boolean;
	};
	fontStyle: string;
	alignment: {
		left: boolean;
		center: boolean;
	};
	backgroundSettings: {
		backgroundSettingType: string;
		gradient: {
			backgroundColor: string;
		};
		image: {
			backgroundImageURL: string;
			brightness: number;
			isSmartPickColorEnalbled: boolean;
		};
	};
	color: {
		questionsColor: string;
		answersColor: string;
		backgroundColor: string;
		buttonsColor: string;
		errorsColor: string;
		answerSelected: string;
		answerSelectedBackground: string;
		answersBackground: string;
		buttonTextColor: string;
		descriptionColor: string;
		linearSelectorColor: string;
		matrixBorderColor: string;
		selectedBoxColor: string;
	};
};

export type Survey = {
	questionnaireId: any;
	id: string;
	orderPosition: number;
	questions: SectionsInSurvey;
	gamesSelected: boolean;
	game: string;
	research: {
		id: string;
		type: Research;
	};
	moderatedTesting: {
		sessionLength: number;
		name?: string;
		email?: string;
		screeningQues: boolean;
		timezone: string;
		slots: any[];
		questions: SectionsInSurvey;
		startTimeBuffer?: number;
	};
	surveyDesignPreview: boolean;
	// surveyTemplateSettings: {
	// 	customize: ThemeDesign;
	// 	surveyTemplate: { myTheme: SurveyThemeDesign[]; exploreTheme: SurveyThemeDesign[],selectedTemplateId:string	};
	// };
};

export type Questionnaire = SurveyInfo & {
	surveys: { [id: string]: Survey };
	createdAt?: string;
	credits: number;
	totalRespondents: number;
	surveyCost: number;
	researchType: Research;
	researchModule: ResearchModule | undefined;
	macroTargetingGroup: any;
	gamified: boolean;
	theme: string;
	howToPlay: string;
	surveyTemplateSetting: any;
	pixelId: string | null;
};

export type GlobalSettingsPayload = {
	surveyId: string;
	[field: string]: boolean | string;
};

export type ModerateSlotPayload = {
	slotId: string | number;
	surveyId: string;

	// [field: string]: boolean | string | number;
	slotDetail: {
		nicheId: string;
		name: string;
		email: string;
		start: string;
		end: string;
		bookedAt: string;
		timeZone: string;
	};
};

export type SurveyScheduleTime = {
	startTime: string;
	endTime: string;
};

export type ScheduleSurveyActionPayload = {
	surveyId: string;
	date: SurveyScheduleTime;
	surveyEntityId: string;
	selectedNiches: Niche[];
	userId: string;
};

export type UpdateSurveyResearchActionPayload = {
	surveyEntityId: string;
	researches: string[];
};

export type UpdateSurveyDesignThemeActionPayload = {
	surveyDesignThemeId: string;
	info: any;
};

export type CreateSurveyAction = ReduxAction<typeof CREATE_SURVEY, CreateSurveyPayload>;
export type GetSurveyAction = ReduxAction<typeof GET_SURVEY, { id: string; doReset?: boolean }>;
export type GetPublicSurveyAction = ReduxAction<typeof GET_PUBLIC_SURVEY, { id: string }>;
export type UpdateSurveyAction = ReduxAction<typeof UPDATE_SURVEY, { info: SurveyInfo }>;
export type UpdateSurveyGamesAction = ReduxAction<typeof UPDATE_SURVEY_DATA, UpdateSurveyPayload>;
export type SetSurveyInfoAction = ReduxAction<typeof SET_SURVEY_INFO, { info: SurveyInfo }>;
export type SetSurveyGameAction = ReduxAction<typeof SET_SURVEY_GAME, UpdateSurveyGamePayload>;
export type SetSurveyAction = ReduxAction<typeof SET_SURVEY, { questionnaire: Questionnaire }>;
export type CreateSurveyFromResearchesAction = ReduxAction<
	typeof CREATE_SURVEY_FROM_RESEARCH,
	CreateSurveyFromResearchActionPayload
>;
export type SetActiveSurveyAction = ReduxAction<typeof SET_ACTIVE_SURVEY, { id: string }>;
export type UpdateGlobalSettingsAction = ReduxAction<typeof UPDATE_GLOBAL_SETTINGS, { info: GlobalSettingsPayload }>;
export type CreateModerateSlotAction = ReduxAction<typeof CREATE_MODERATE_SLOT, { info: ModerateSlotPayload }>;
export type ScheduleSurveyAction = ReduxAction<typeof SCHEDULE_SURVEY, ScheduleSurveyActionPayload>;
export type UpdateSurveyResearchAction = ReduxAction<typeof UPDATE_SURVEY_RESEARCH, UpdateSurveyResearchActionPayload>;
export type UpdateSurveyResearchFulfilled = ReduxAction<typeof UPDATE_SURVEY_RESEARCH_FULFILLED, { survey: Survey }>;
export type UpdateSurveyDesignThemeAction = ReduxAction<
	typeof UPDATE_SURVEY_DESIGN_THEME,
	{ surveyId: string; updatedTemplateSettings: any }
>;
export type SetSurveyDesignThemeInfoAction = ReduxAction<
	typeof SET_SURVEY_DESIGN_THEME,
	{ surveyTemplateSetting: any }
>;
