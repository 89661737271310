export function flatQuestions(questions) {
	return questions.map((question) => (question?.metaData ? Object.values(question?.questions) : question))?.flat();
}
export function getCurrentQuestion(questions, currQuesId) {
	const sectionQuestions = Object.values(questions)
		.map((question: any) => Object.values(question?.questions || {}))
		.flat();
	const findSecQuestion = sectionQuestions.find((question: any) => question?.id === currQuesId);
	return findSecQuestion ? findSecQuestion : questions[currQuesId];
}

export function getQuestionsWithoutFunctions(questions) {
	const q = Object.values(questions)
		.map((question: any) => (question?.metaData ? Object.values(question?.questions) : question))
		?.flat();
	q.sort((a, b) => a?.orderPosition - b?.orderPosition);
	const questionsWithoutSection = Object.fromEntries(q.map((question) => [question?.id, question]));
	return questionsWithoutSection;
}

export function getQuestionsOrderWithoutSections(questions) {
	const q = Object.values(questions)
		.map((question: any) => (question?.metaData ? Object.values(question?.questions) : question))
		?.flat();
	q.sort((a, b) => a?.orderPosition - b?.orderPosition);
	return q.map((question) => question?.id);
}
