import { takeLeading } from 'redux-saga/effects';
import { approveSaga, rejectSaga } from './sagas/actionsSagas';
import { getMessagesSaga, postMessageSaga } from './sagas/messagesSagas';
import { questionnaireCountSaga } from './sagas/questionnareCountSaga';
import { APPROVE, GET_MESSAGES, GET_QUESTIONNAIRES_COUNT, POST_MESSAGE, REJECT } from './types';

function* getMessagesWatcher() {
	yield takeLeading(GET_MESSAGES, getMessagesSaga);
}

function* postMessageWatcher() {
	yield takeLeading(POST_MESSAGE, postMessageSaga);
}

function* approveWatcher() {
	yield takeLeading(APPROVE, approveSaga);
}

function* rejectWatcher() {
	yield takeLeading(REJECT, rejectSaga);
}

function* questionnaireCountWatcher() {
	yield takeLeading(GET_QUESTIONNAIRES_COUNT, questionnaireCountSaga);
}

export const approvalWatchers = [
	getMessagesWatcher,
	postMessageWatcher,
	approveWatcher,
	rejectWatcher,
	questionnaireCountWatcher,
];
