import axios from '@utils/axios';
import { select } from 'redux-saga/effects';
import { map, prop, sortBy, values } from 'ramda';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';

import { first } from 'shades';

import { AddBulkQues } from '../types';
import { surveyBulkQuestionApiUrl } from './consts';
import { mapQuestionToCreateQuestionPayload } from './utils';
import { addBulkQuestions, selectQues, setPopup, updateInfo } from '../actions';
import { QUES_TYPE } from 'shared/consts/quesType';
import { getOrderPosition } from './createQuestion';
import { getQuestionsResponse } from './createSection';
import { SurveyPreviewDataState } from 'store/survey/surveyPreview/types';
import { getQuestionModelByType, processOptionsForBulkQuestions } from '../../utils';
import { fulfill, pend } from 'store/promise/actions';

export function* createBulkQuestions({ payload: { questions: bulkQuestions } }: AddBulkQues) {
	const { hasWelcomeScreen, hasVideoUploadQues, surveyId, surveys, questions, theme, moderatedQuestion } = yield select(
		(s) => s.surveyBuilder
	);
	const { name, activeSurvey }: SurveyPreviewDataState = yield select((s) => s.surveyData);
	const { brandId } = yield select((s) => s.redirect.info.params);

	const questionnaireId = surveys[surveyId]?.questionnaireId;

	const quesOrder: any = map(
		prop('id'),
		sortBy(
			prop('orderPosition'),
			values(questions)?.filter((que) => !que.parentQuestionId)
		)
	);
	let questionsRequired: any = [];
	let questionsForPayload: any = [];

	try {
		for (let index = 0; index < bulkQuestions.length; index++) {
			const question = bulkQuestions[index];
			const { orderPosition, reOrder } = getOrderPosition({
				hasVideoUploadQues,
				quesOrder,
				moderated: false,
				quesType: question.questionType,
				questions: questions,
			});

			let questionModel: any = getQuestionModelByType(question.questionType, orderPosition + index, question);
			let questionForBody = mapQuestionToCreateQuestionPayload(
				questionModel,
				theme,
				surveyId || activeSurvey || first(Object.keys(surveys)),
				questionnaireId,
				reOrder
			);
			questionsRequired.push(questionModel);
			questionsForPayload.push(questionForBody);
		}
		questionsForPayload = questionsForPayload.map((question, index) => {
			if (bulkQuestions[index].options) {
				question.options = processOptionsForBulkQuestions(bulkQuestions[index].options);
			}
			return question;
		});
		const body = { brandId, questions: questionsForPayload, name, surveyId, questionnaireId };
		yield putEffect(pend(addBulkQuestions));
		const { data } = yield axios.post(getApiEndPointUrl(surveyBulkQuestionApiUrl), body);
		yield putEffect(fulfill(addBulkQuestions));
		const responseOfData = getQuestionsResponse(data?.surveys?.[0]?.questions);

		const allQuestionOrder = Object.values(responseOfData)
			.filter((que) => !que.parentQuestionId)
			.sort((a, b) => a.orderPosition - b.orderPosition)
			.map((ques) => ques.id);

		// checking if thank you questions is available or not
		const thankYouQues =
			allQuestionOrder?.filter((quesId) => responseOfData[quesId]?.type === QUES_TYPE.THANK_YOU) || [];
		// checking if video questions is available or not
		const isVideoUploadQues =
			allQuestionOrder?.filter((quesId) => responseOfData[quesId]?.type === QUES_TYPE.VIDEO_UPLOAD_QUES) || [];
		// logic for default selected question
		let currentQuestionId: string = '';
		if (isVideoUploadQues.length > 0) {
			if (thankYouQues.length > 0) {
				currentQuestionId = allQuestionOrder.splice(-thankYouQues.length - 1)?.[0] || '';
			} else {
				currentQuestionId = allQuestionOrder.pop() || '';
			}
		} else if (thankYouQues.length > 0) {
			currentQuestionId = allQuestionOrder.splice(-thankYouQues.length)?.[0] || '';
		} else {
			currentQuestionId =
				thankYouQues.length > 0
					? isVideoUploadQues.length > 0
						? allQuestionOrder.splice(-thankYouQues.length - 2)?.[0] || ''
						: allQuestionOrder.splice(-thankYouQues.length - 1)?.[0] || ''
					: allQuestionOrder.pop() || '';
		}

		yield putEffect(updateInfo(currentQuestionId, responseOfData));

		// yield putEffect(selectQues(currentQuestionId, true));
	} catch (e: any) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
