import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';

import { putEffect } from '../../../shared/utils/putEffect';
import { brandEndPoint } from './const';

import { getApiError } from '../../../shared/utils/getApiError';
import {
	createBrandFulfilled,
	updateBrandFulfilled,
	deleteBrandFulfilled,
	readBrandsFulfilled,
	readBrandFulfilled,
	createBrand,
	deleteBrand,
	readBrand,
	readBrands,
	updateBrand,
} from './actions';
import {
	CreateBrandPayload,
	ReadBrandsPayload,
	ReadBrandPayload,
	Brand,
	CreateBrand,
	UpdateBrand,
	DeleteBrand,
	ReadBrands,
	ReadBrand,
	CREATE_BRAND,
	READ_BRANDS,
	UPDATE_BRAND,
	DELETE_BRAND,
	READ_BRAND,
	BrandSchema,
	BrandsSchema,
} from './types';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { validate } from '../../../shared/utils/jsonschema';
import { pend, fulfill, reject } from '../../promise/actions';

function createBrandAPI(brand: CreateBrandPayload) {
	return axios.post(getApiEndPointUrl(brandEndPoint), brand);
}
function readBrandsAPI(brand: ReadBrandsPayload) {
	return axios.get(getApiEndPointUrl(brandEndPoint), {
		params: brand,
	});
}
function readBrandAPI(brand: ReadBrandPayload) {
	return axios.get(getApiEndPointUrl(brandEndPoint, brand.id));
}
function updateBrandAPI({ id, ...brand }: Brand) {
	return axios.patch(getApiEndPointUrl(brandEndPoint, id), brand);
}
function deleteBrandAPI(brandId: string) {
	return axios.delete(getApiEndPointUrl(brandEndPoint, brandId));
}

function* createBrandSaga(action: CreateBrand) {
	try {
		yield putEffect(pend(createBrand));
		const res = yield createBrandAPI(action.payload);
		yield putEffect(createBrandFulfilled(validate(res.data, BrandSchema)));
		yield putEffect(fulfill(createBrand, action.payload));
	} catch (e) {
		yield putEffect(reject(createBrand, getApiError(e)));
	}
}
function* updateBrandSaga(action: UpdateBrand) {
	try {
		yield putEffect(pend(updateBrand));
		const res = yield updateBrandAPI(action.payload);
		yield putEffect(updateBrandFulfilled(validate(res.data, BrandSchema)));
		yield putEffect(fulfill(updateBrand));
	} catch (e) {
		yield putEffect(reject(updateBrand, getApiError(e)));
	}
}
function* deleteBrandSaga(action: DeleteBrand) {
	try {
		yield putEffect(pend(deleteBrand));
		yield deleteBrandAPI(action.payload);
		yield putEffect(deleteBrandFulfilled(action.payload));
		yield putEffect(fulfill(deleteBrand));
	} catch (e) {
		yield putEffect(reject(deleteBrand, getApiError(e)));
	}
}
function* readBrandsSaga(action: ReadBrands) {
	try {
		yield putEffect(pend(readBrands));
		const response = yield readBrandsAPI(action.payload);
		yield putEffect(readBrandsFulfilled(validate(response.data, BrandsSchema)));
		yield putEffect(fulfill(readBrands));
	} catch (e) {
		yield putEffect(reject(readBrands, getApiError(e)));
	}
}
function* readBrandSaga(action: ReadBrand) {
	try {
		yield putEffect(pend(readBrand));
		const response = yield readBrandAPI(action.payload);
		yield putEffect(readBrandFulfilled(validate(response.data, BrandSchema)));
		yield putEffect(fulfill(readBrand));
	} catch (e) {
		yield putEffect(reject(readBrand, getApiError(e)));
	}
}

function* getCreateBrandWatcher() {
	yield takeLeading(CREATE_BRAND, createBrandSaga);
}
function* getReadBrandWatcher() {
	yield takeLeading(READ_BRAND, readBrandSaga);
}
function* getReadBrandsWatcher() {
	yield takeLeading(READ_BRANDS, readBrandsSaga);
}
function* getUpdateBrandWatcher() {
	yield takeLeading(UPDATE_BRAND, updateBrandSaga);
}
function* getDeleteBrandWatcher() {
	yield takeLeading(DELETE_BRAND, deleteBrandSaga);
}

export const brandDetailSagas = [
	getCreateBrandWatcher,
	getUpdateBrandWatcher,
	getDeleteBrandWatcher,
	getReadBrandsWatcher,
	getReadBrandWatcher,
];
