import axios from '@utils/axios';
import { environment } from '../../environment';
import { DEFAULT_CALCULATED_SURVEY_COST } from '../../pages/Survey/Targeting/useCalculateSurveyCost';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import {
	ReadTargetingCitiesPayload,
	ReadTargetingCountriesPayload,
	ReadTargetingRegionsPayload,
	SurveyCostType,
} from './types';
const regionEndpoint = `?dataType=region&`;

const cityEndpoint = `?dataType=city&`;

// We need this data locally as multiple location inputs can be there and same redux wont cut it. Wish we had thunk so thunk could have allowed to use same error tracker
export function readTargetingCountries(dataType: ReadTargetingCountriesPayload) {
	const apiUrl = environment.targetingApiUrl;
	return `${apiUrl}?dataType=${dataType}`;
}
export function readTargetingCities({ countryCode, query, regionId }: ReadTargetingCitiesPayload) {
	if (!countryCode || !query || !regionId) return null;
	const apiUrl = environment.targetingApiUrl;
	return `${apiUrl}${cityEndpoint}countryCode=${countryCode}&query=${query}&regionId=${regionId}`;
}

export function readTargetingRegions({ countryCode, query }: ReadTargetingRegionsPayload) {
	if (!countryCode || !query) return null;
	const apiUrl = environment.targetingApiUrl;
	return `${apiUrl}${regionEndpoint}countryCode=${countryCode}&query=${query}`;
}

export const calculateCreditsFetcher = async (surveyId, niches): Promise<SurveyCostType> => {
	if (!niches.length) return DEFAULT_CALCULATED_SURVEY_COST;

	const url = getApiEndPointUrl('surveys', surveyId, 'calculate-credit');
	const response = await axios.post(url, niches);
	return response.data;
};
