export type QuestionSettings<T1 = {}, T2 = {}, T3 = {}, T4 = {}, T5 = {}, T6 = {}, T7 = {}> = {
	skip?: boolean;
	isMandatoryQuestion?: boolean;
	questionTheme?: string;
} & T1 &
	T2 &
	T3 &
	T4 &
	T5 &
	T6 &
	T7;

export enum MatrixAnswerType {
	SingleCorrect = 'SingleCorrect',
	MultiCorrect = 'MultiCorrect',
	Optional = 'Optional',
}

export type DisplayPageSettings = { displayPage: boolean };
export type ButtonLabelSettings = { buttonLabel: string };
export type DisplaySectionHeaderOnQnSettings = { displaySectionHeaderOnQn: boolean };
export type DisplayAdCreativeSettings = { displayAdCreative: boolean };
export type MinMaxSettings = { minVal: number; maxVal: number };
export type MinMaxCharSettings = { minChars: number; maxChars?: number };
export type TextVoiceTranscriptionSettings = { voiceTranscription: boolean };
export type LeftRightLabelSettings = { leftLabel: string; rightLabel: string };
export type StartAtOneSettings = { startAtOne: boolean };
export type ScaleSettings = { scale: number };
export type RandomizedSettings = { randomized: boolean };
export type RandomizedRowSettings = { randomizedRow: boolean };
export type StackedSettings = { stacked: boolean };
export type MultipleAnswersSettings = { multipleAnswers: boolean };
export type AddCreativeSettings = { creative?: boolean };
export type StepSettings = { step: number };
export type CenterLabelSettings = { centerLabel: string };
export type IconTypeSettings = { iconType: string };
export type ThankYouTypeSettings = { hasButton: boolean; btnHeading?: string; btnText?: string; btnLink?: string };
export type ChoiceTypeSettings = {
	otherOption: boolean;
	noneOption: boolean;
	optionOrder: string[];
};
export type CreativeChoiceSettings = {
	otherImgUrl: string;
	noneImgUrl: string;
};
export type ImageTypeSettings = {
	fitImg: boolean;
	blurImg: boolean;
};
// export enum MultipleSelectionType {
// 	Unlimited = 'Unlimited',
// 	Exact = 'Exact',
// 	Range = 'Range',
// }

export type VideoUploadQuestionSettings = QuestionSettings;
export type TaskQuestionSettings = QuestionSettings;
export type ModeratedQuestionSettings = QuestionSettings;
export type WelcomeScreenQuestionSettings = QuestionSettings<ButtonLabelSettings, AddCreativeSettings>;
export type OpinionScaleQuestionSettings = QuestionSettings<LeftRightLabelSettings, ScaleSettings, StartAtOneSettings>;
export type RankingQuestionSettings = QuestionSettings<
	{ optionOrder: string[] },
	// MultipleAnswersSettings,
	// StackedSettings,
	RandomizedSettings
>;
export type MultipleChoiceQuestionSettings = QuestionSettings<
	ChoiceTypeSettings,
	MultipleAnswersSettings,
	StackedSettings,
	RandomizedSettings,
	AddCreativeSettings,
	{
		type: string;
		min?: number;
		max?: number;
		exact?: number;
	}
>;
export type NumberQuestionSettings = QuestionSettings<MinMaxSettings>;
export type TextBoxQuestionSettings = QuestionSettings<MinMaxCharSettings, TextVoiceTranscriptionSettings>;
export type RatingQuestionSettings = QuestionSettings<ScaleSettings, IconTypeSettings>;
export type CreativeQuestionSettings = QuestionSettings<
	ChoiceTypeSettings,
	MultipleAnswersSettings,
	ImageTypeSettings,
	StackedSettings,
	RandomizedSettings,
	CreativeChoiceSettings,
	// AddCreativeSettings,
	{
		type: string;
		min?: number;
		max?: number;
		exact?: number;
	}
>;
export type MultipleOpinionScaleSettings = QuestionSettings<
	ScaleSettings,
	StartAtOneSettings,
	LeftRightLabelSettings,
	RandomizedSettings,
	ImageTypeSettings,
	{ sectionOrder: string[] }
>;
export type MatrixQuestionSettings = QuestionSettings<
	ImageTypeSettings,
	MultipleAnswersSettings,
	RandomizedSettings,
	{ columnOrder: string[] }
>;
export type LinearRangeSettings = QuestionSettings<
	MinMaxSettings,
	LeftRightLabelSettings,
	StepSettings,
	CenterLabelSettings
>;

export type MatricMatchSettings = {
	answerType: MatrixAnswerType;
	creative: boolean;
	addIcon: boolean;
	randomized: boolean;
	randomizedRow: boolean;
	addNumber: boolean;
	noneOption: boolean;
	rowNoneOptionActive?: boolean;
	columnNoneOptionActive?: boolean;
	ranking: boolean;
	optionOrder: string[];
	fixedRow: boolean;
	isBulkInserted?: boolean;
};

export type ScreenerSettings = QuestionSettings<
	MultipleAnswersSettings,
	{
		noneOption: boolean;
		preferNotSay: boolean;
		optionOrder: string[];
		isMandatoryQuestion: boolean;
	}
>;

export type ImageQuesSettings = QuestionSettings<{
	time: boolean;
	timeDuration: number;
	next: boolean;
}>;

export type NormalSectionSettings = QuestionSettings<
	ButtonLabelSettings,
	DisplayPageSettings,
	RandomizedSettings,
	DisplaySectionHeaderOnQnSettings,
	DisplayAdCreativeSettings
>;

export type EmailQuesSettings = QuestionSettings;
export type ThankYouSettings = ThankYouTypeSettings;
export type QuestionSettingsUnion = NumberQuestionSettings &
	TextBoxQuestionSettings &
	WelcomeScreenQuestionSettings &
	MatrixQuestionSettings &
	MultipleChoiceQuestionSettings &
	RankingQuestionSettings &
	LinearRangeSettings &
	OpinionScaleQuestionSettings &
	MultipleOpinionScaleSettings &
	CreativeQuestionSettings &
	MatricMatchSettings &
	ScreenerSettings &
	ImageQuesSettings &
	EmailQuesSettings &
	ThankYouSettings;
