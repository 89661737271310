import { SurveyAnalytics } from './types';
import { reduceReducers } from '../../shared/utils/reduceReducers';
import { generalAnalyticsReducer } from './generalAnalytics/reducer';
import { brandFunnelReducer } from './brandFunnel/reducer';
import { brandFunnelMetricReducer } from './brandFunnelMetrics/reducer';
import { cohortDeepDiveStagesReducer } from './cohortDeepDiveStages/reducer';
import { funnelAnalyzerStagesReducer } from './funnelAnalyzerStages/reducer';
import { brandFunnelCompetitorReducer } from './brandFunnelCompetitors/reducer';
import { videoRecordingsReducer } from './VideoRecordings/reducer';
import { generalAnalyticsReportsReducer } from './filters/reducer';
import { ComparisonReportsReducer } from './compare/reducer';

const initialState: SurveyAnalytics = {
	generalAnalytics: [],
	brandFunnelComparisonData: [],
	brandFunnelDailyData: [],
	brandFunnelCombinedData: [],
	brandFunnelMetrics: [],
	cohortDeepDiveStages: null,
	funnelAnalyzerStages: null,
	brandFunnelDataByCohort: [],
	brandFunnelCompetitors: [],
	videoRecordings: null,
	comparisonReports: { data: [], responseCount: 0, averageTime: '', denominator: [] },
	generalAnalyticsReports: [],
	currentAnalyticsReport: null,
};

export const AnalyticsReducer = reduceReducers(
	initialState,
	generalAnalyticsReducer,
	brandFunnelReducer,
	brandFunnelMetricReducer,
	cohortDeepDiveStagesReducer,
	funnelAnalyzerStagesReducer,
	brandFunnelCompetitorReducer,
	videoRecordingsReducer,
	generalAnalyticsReportsReducer,
	ComparisonReportsReducer
);
