import { ReadWorkspaceTeam, ReadWorkspaceTeamPayload, READ_WORKSPACE_TEAM } from './types';
import { workspaceEndPoint } from './const';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { putEffect } from '../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../promise/actions';
import { readWorkspaceTeam, readWorkspaceTeamFulfilled } from './actions';
import { getApiError } from '../../shared/utils/getApiError';
import { takeLeading } from 'redux-saga/effects';

function readWorkspaceTeamAPI(payload: ReadWorkspaceTeamPayload) {
	return axios.get(`${getApiEndPointUrl(workspaceEndPoint)}/${payload.workspaceId}/team`);
}

function* readWorkspaceTeamSage(action: ReadWorkspaceTeam) {
	try {
		yield putEffect(pend(readWorkspaceTeam));
		const response = yield readWorkspaceTeamAPI(action.payload);
		yield putEffect(fulfill(readWorkspaceTeam));
		yield putEffect(readWorkspaceTeamFulfilled(response.data));
	} catch (error) {
		yield putEffect(reject(readWorkspaceTeam, getApiError(error)));
	}
}

function* getReadWorkspaceTeamWatcher() {
	yield takeLeading(READ_WORKSPACE_TEAM, readWorkspaceTeamSage);
}

export const workspaceTeamSagas = [getReadWorkspaceTeamWatcher];
