import {
	CREATE_BRAND_COLORS_FULFILLED,
	READ_BRAND_COLORS_FULFILLED,
	BrandColorAction,
	BrandColorReducer,
	DELETE_BRAND_COLORS_FULFILLED,
} from './types';
import { difference } from 'ramda';
export const brandColorReducer: BrandColorReducer = (state, action: BrandColorAction) => {
	switch (action.type) {
		case READ_BRAND_COLORS_FULFILLED:
			return {
				...state,
				colors: action.payload,
			};
		case CREATE_BRAND_COLORS_FULFILLED:
			return {
				...state,
				colors: [...state.colors, ...action.payload],
			};
		case DELETE_BRAND_COLORS_FULFILLED:
			return {
				...state,
				colors: difference(state.colors, action.payload),
			};
		default:
			return state;
	}
};
