// @ts-nocheck
import { MultipleChoiceOption, OptionType, QuesState } from '../../../shared/types/QuesTypes';
import { QUES_TYPE } from '../../../shared/consts/quesType';
import {
	DisplayOptionLogic,
	LogicalOperator,
	numberBasedOptionTypes,
	rankingOptionTypes,
	optionAnsweredTypes,
	OptionLogicType,
	optionSelectedTypes,
	QuestionLogicKind,
	ShowLogic,
	SkipLogic,
	MandateLogic,
	SkipTo,
	yesNoOptionsTypes,
} from '../../../shared/types/QuestionLogic';
import { has, range } from 'ramda';
import { MENTION_PREFIX, QUESTION_UUID_REGEX, replaceMentionText } from '../../../shared/utils/mentionUtils';
import { appendPositionPrefix } from '../../Survey/utils';
export const optionSelectSkipTypes = ['has selected less than', 'has selected more than'];

export const shouldShowOptionPicker = (option?: string) => {
	const optionfilterTypes = [...optionAnsweredTypes, ...optionSelectSkipTypes];
	return option && !optionfilterTypes.includes(option as any);
};
export const getSkipOptions = (types, question) => {
	const filtertypes = [QUES_TYPE.MULTIPLE_CHOICE_QUES, QUES_TYPE.CREATIVE_QUES];
	let temp = [...types];
	if (filtertypes.includes(question?.type) && question?.settings?.multipleAnswers) {
		temp = [...temp, ...optionSelectSkipTypes];
	}
	return temp;
};
export const getTotalOptions = (question, displayType) => {
	let options = Object.values(question?.options)
		.filter(({ optionType }) => optionType !== OptionType.Pipe)
		.map((_, index) => index + 1);
	//to exclude noneOption count
	options = options.slice(0, question?.settings?.noneOption ? options.length - 1 : options.length);
	if (displayType === 'has selected less than') {
		options = options.slice(1);
	}
	if (displayType === 'has selected more than') {
		options = options.slice(0, options?.length - 1);
	}
	return options;
};
const getAllPipedOption = (options, allQuestions) => {
	let totalOptions = [];
	options?.forEach((option, index) => {
		if (option.optionType !== OptionType.Pipe) {
			totalOptions.push(option);
		} else {
			const pipedQuestion = allQuestions.find(({ id }) => id === option?.pipe['pipeId']);
			totalOptions = [...totalOptions, ...getAllPipedOption(Object.values(pipedQuestion?.options), allQuestions)];
		}
	});
	return totalOptions;
};
export const getValuesWithPipedOptions = (question, questions) => {
	let values = Object.keys(question?.options);
	let questionWithPiped = Object.values(question?.options || {}).filter(
		(option) => option.optionType !== OptionType.Pipe
	);
	const pipedId = Object.values(question.options)?.find((option) => option.optionType === OptionType.Pipe)?.pipe[
		'pipeId'
	];
	if (pipedId) {
		questionWithPiped = getAllPipedOption(Object.values(question?.options), Object.values(questions));
		const noneOption = Object.values(question?.options).filter(({ optionType }) => optionType === OptionType.None);
		questionWithPiped = [
			...questionWithPiped.filter(({ optionType }) => optionType !== OptionType.None),
			...noneOption,
		];
		values = questionWithPiped?.map(({ id }) => id);
	}
	return [values, questionWithPiped];
};
export const getColumnsWithPipedOptions = (question, questions) => {
	let values = question?.column?.map((col) => col?.text);
	let questionWithPiped = question?.column?.map((col) => ({ value: col?.text, type: 'normal' }));
	const isPipedColumn = Object.keys(question?.columnOptions || {})?.length;
	if (isPipedColumn) {
		const questionWithPiped1 = getAllPipedOption(
			Object.values(question?.columnOptions),
			Object.values(questions)
		)?.filter(({ optionType }) => optionType !== OptionType.None);
		// const noneOption = Object.values(question?.options).filter(({ optionType }) => optionType === OptionType.None);
		// questionWithPiped = [
		// 	...questionWithPiped.filter(({ optionType }) => optionType !== OptionType.None),
		// 	...noneOption,
		// ];
		values = [
			...values,
			...questionWithPiped1?.map(({ value, optionType }) => (optionType !== OptionType.Other ? value : '-2')),
		];
		questionWithPiped = questionWithPiped.concat(questionWithPiped1);
	}
	return [values, questionWithPiped];
};
export const getQuestionOptions = (
	question: QuesState,
	questions
): {
	types: string[];
	values: (string | number)[];
	labels: (string | number)[];
} => {
	if (!question) {
		return {
			types: [],
			values: [],
			labels: [],
		};
	}

	switch (question.type) {
		case QUES_TYPE.YES_NO_QUES:
			return {
				types: yesNoOptionsTypes,
				values: ['yes', 'no'],
				labels: ['Yes', 'No'],
			};
		case QUES_TYPE.NUMBER_QUES:
			return {
				types: numberBasedOptionTypes,
				values: range(question.settings.minVal, question.settings.maxVal + 1),
				labels: range(question.settings.minVal, question.settings.maxVal + 1),
			};
		case QUES_TYPE.RATING_QUES:
			return {
				types: numberBasedOptionTypes,
				values: range(1, question.settings.scale + 1),
				labels: range(1, question.settings.scale + 1),
			};
		case QUES_TYPE.RANKING_QUES:
			return {
				types: rankingOptionTypes,
				values: [],
				labels: [],
			};
		case QUES_TYPE.MULTIPLE_CHOICE_QUES:
			const values = getValuesWithPipedOptions(question, questions);
			return {
				types: optionSelectedTypes,
				values: values[0],
				labels: values[1].map((v, i) => {
					if (v.optionType === OptionType.Other) {
						return 'Other Answer';
					}
					if (v.optionType === OptionType.Response) {
						return replaceMentionText(`${MENTION_PREFIX}${v?.pipe?.pipeId}${MENTION_PREFIX}`, questions);
					}
					return v.value;
				}),
			};

		case QUES_TYPE.MATRIX_MATCH_QUES: {
			const values = getValuesWithPipedOptions(question, questions);
			const colValues = getColumnsWithPipedOptions(question, questions);
			return {
				types: optionSelectedTypes,
				values: values[0],
				labels: values[1].map((v, i) => {
					if (v.optionType === OptionType.Other) {
						return 'Other Answer';
					}
					if (v.optionType === OptionType.Response) {
						return replaceMentionText(`${MENTION_PREFIX}${v?.pipe?.pipeId}${MENTION_PREFIX}`, questions);
					}
					return v.value;
				}),
				colValues: colValues[0],
				colLabels: colValues[1].map((v, i) => {
					if (v.optionType === OptionType.Other) {
						return 'Other Answer';
					}
					if (v.optionType === OptionType.Response) {
						return replaceMentionText(`${MENTION_PREFIX}${v?.pipe?.pipeId}${MENTION_PREFIX}`, questions);
					}
					return v.value;
				}),
			};
		}
		case QUES_TYPE.OPINION_SCALE_QUES:
			return {
				types: numberBasedOptionTypes,
				values: range(question.settings.startAtOne ? 1 : 0, question.settings.scale + 1),
				labels: range(question.settings.startAtOne ? 1 : 0, question.settings.scale + 1),
			};
		case QUES_TYPE.LINEAR_RANGE_QUES: {
			return {
				types: numberBasedOptionTypes,
				values: range(question.settings.minVal, question.settings.maxVal + 1),
				labels: range(question.settings.minVal, question.settings.maxVal + 1),
			};
		}

		case QUES_TYPE.CREATIVE_QUES: {
			// const values = Object.keys(question.options);
			const values = getValuesWithPipedOptions(question, questions);
			return {
				types: optionSelectedTypes,
				values: values[0],
				labels: values[1].map((v, i) => (v.optionType === OptionType.Other ? 'Other Answer' : v.value)),
			};
		}
		default:
			return {
				types: [],
				values: [],
				labels: [],
			};
	}
};

const createEmptyOption = (optionType: string) => ({
	type: optionType,
	value: undefined,
});

export const questionsWithDisplayLogic = [
	QUES_TYPE.YES_NO_QUES,
	QUES_TYPE.CREATIVE_QUES,
	QUES_TYPE.NUMBER_QUES,
	QUES_TYPE.LINEAR_RANGE_QUES,
	QUES_TYPE.RATING_QUES,
	QUES_TYPE.MULTIPLE_CHOICE_QUES,
	QUES_TYPE.OPINION_SCALE_QUES,
	QUES_TYPE.RANKING_QUES,
	QUES_TYPE.MATRIX_MATCH_QUES,
] as string[];

export const mapTypeToKind = (type: string): QuestionLogicKind => {
	switch (type) {
		case QUES_TYPE.YES_NO_QUES:
			return QuestionLogicKind.YesNo;
		case QUES_TYPE.NUMBER_QUES:
		case QUES_TYPE.LINEAR_RANGE_QUES:
		case QUES_TYPE.RATING_QUES:
		case QUES_TYPE.OPINION_SCALE_QUES:
			return QuestionLogicKind.Number;
		case QUES_TYPE.MULTIPLE_CHOICE_QUES:
		case QUES_TYPE.CREATIVE_QUES:
			return QuestionLogicKind.Choice;
		case QUES_TYPE.RANKING_QUES:
			return QuestionLogicKind.Ranking;
		case QUES_TYPE.MATRIX_MATCH_QUES:
			return QuestionLogicKind.Matrix;
	}

	return undefined as unknown as QuestionLogicKind;
};

export const getOptionLogicModel = (type: string, optionType: string): DisplayOptionLogic => {
	const kind = mapTypeToKind(type);

	return {
		kind,
		option: kind ? createEmptyOption(optionType) : undefined,
	} as DisplayOptionLogic;
};
export const getMatrixOptionLogicModel = (type: string, option: any, optionType: string): DisplayOptionLogic => {
	const kind = mapTypeToKind(type);

	return {
		kind,
		basedOn: option.optionLogic.basedOn,
		option: kind
			? {
					...option.optionLogic.option,
					type: kind ? optionType : undefined,
			  }
			: undefined,
	} as DisplayOptionLogic;
};
export const getDefaultShowOptionLogic = (index: number): ShowLogic =>
	({
		questionId: undefined,
		logic: index ? LogicalOperator.And : null,
		optionLogic: {
			basedOn: undefined,
			kind: '',
			option: {
				type: undefined,
				value: undefined,
			},
		},
	} as any);
export const getDefaultShowMandateLogic = (index: number): MandateLogic =>
	({
		type: null,
		options: [
			{
				rowNumber: 0,
				specificRow: [],
				minRange: '',
				maxRange: '',
				logic: index ? LogicalOperator.And : undefined,
			},
		],
	} as any);

export const getDefaultSkipOptionLogic = (index: number): SkipLogic =>
	({
		questionId: undefined,
		logic: index ? LogicalOperator.And : undefined,
		optionLogic: {
			kind: '',
			option: {
				type: undefined,
				value: undefined,
			},
		},
		action: {
			type: undefined,
			value: undefined,
		},
	} as any);

export const checkDisplayLogic = (value: string | number | boolean | any, logic: DisplayOptionLogic) => {
	// [
	// 	{
	// 		"value": "cfde4692-5da6-4545-af19-206e9f561e97",
	// 		"origin": "cfde4692-5da6-4545-af19-206e9f561e97",
	// 		"orderPosition": 1,
	// 		"anchorOnRandomization": false,
	// 		"text": "Test 1",
	// 		"optionType": "normal",
	// 		"columnOrderPosition": 1,
	// 		"columnText": "1",
	// 		"columnOptionType": "normal"
	// 	},
	// 	{
	// 		"value": "b08cfb50-1299-48b1-9541-4f6ca629a205",
	// 		"origin": "b08cfb50-1299-48b1-9541-4f6ca629a205",
	// 		"orderPosition": 2,
	// 		"anchorOnRandomization": false,
	// 		"text": "Test 2",
	// 		"optionType": "normal",
	// 		"columnOrderPosition": 1,
	// 		"columnText": "1",
	// 		"columnOptionType": "normal"
	// 	}
	// ]
	// {
	// 	"kind": "matrix",
	// 	"basedOn": "row",
	// 	"option": {
	// 		"type": "noSelected",
	// 		"value": "cfde4692-5da6-4545-af19-206e9f561e97",
	// 		"colValue": "3"
	// 	}
	// }
	switch (logic.kind) {
		case QuestionLogicKind.YesNo:
			switch (logic.option.type) {
				case 'is':
					return (value ? 'yes' : 'no') === logic?.option?.value;
				case 'not':
					return (value ? 'yes' : 'no') !== logic?.option?.value;
			}
			break;
		case QuestionLogicKind.Matrix:
			switch (logic.option.type) {
				case 'noSelected':
					return !value?.some(
						(v) =>
							v.origin === logic?.option?.value &&
							(logic?.option?.colValue === '-2'
								? v.columnValue === logic?.option?.colValue
								: v.columnText === logic?.option?.colValue)
					);
				case 'selected':
					return value?.some(
						(v) =>
							v.origin === logic?.option?.value &&
							(logic?.option?.colValue === '-2'
								? v.columnValue === logic?.option?.colValue
								: v.columnText === logic?.option?.colValue)
					);
				case 'answered':
					return !!value?.filter((response) =>
						logic.basedOn === 'row'
							? response.origin === logic?.option?.value
							: logic?.option?.colValue === '-2'
							? response.columnValue === logic?.option?.colValue
							: response.columnText === logic?.option?.colValue
					)?.length;
				case 'notAnswered':
					return !value?.filter((response) =>
						logic.basedOn === 'row'
							? response.origin === logic?.option?.value
							: logic?.option?.colValue === '-2'
							? response.columnValue === logic?.option?.colValue
							: response.columnText === logic?.option?.colValue
					)?.length;
				case 'has selected less than':
					/* Handling skip with respect to current condition of skip-logic,
							if user click to skip than we won't be checking and return false.
							Otherwise it will work as per response for respective question.
						*/
					return value?.value?.length === 0 ? false : value?.value?.length < logic.option.value;
				case 'has selected more than':
					return value?.value?.length > logic?.option?.value;
			}
			break;
		case QuestionLogicKind.Choice:
			switch (logic.option.type) {
				case 'noSelected':
					return !value?.value?.some((v) => v.origin === logic?.option?.value);
				case 'selected':
					return value?.value?.some((v) => v.origin === logic?.option?.value);
				case 'answered':
					return !!value?.value?.length;
				case 'notAnswered':
					return !value?.value?.length;
				case 'has selected less than':
					/* Handling skip with respect to current condition of skip-logic,
						if user click to skip than we won't be checking and return false.
						Otherwise it will work as per response for respective question.
					*/
					return value?.value?.length === 0 ? false : value?.value?.length < logic.option.value;
				case 'has selected more than':
					return value?.value?.length > logic?.option?.value;
			}
			break;
		case QuestionLogicKind.Number:
			switch (logic.option.type) {
				case 'eq':
					return +value === logic?.option?.value;
				case 'gt':
					return value > logic?.option?.value;
				case 'lt':
					return value < logic?.option?.value;
				case 'neq':
					return +value !== logic?.option?.value;
			}
			break;
	}

	if (logic?.option?.type === 'answered') {
		return value !== undefined;
	}

	return value === undefined;
};

export const logicTypeToLabel = (type?: OptionLogicType | string) => {
	switch (type) {
		case 'neq':
			return 'not equal to';
		case 'gt':
			return 'greater than';
		case 'eq':
			return 'equal to';
		case 'lt':
			return 'less than';
		case 'not':
			return 'is not';
		case 'is':
			return 'is';
		case 'noSelected':
			return 'has not selected';
		case 'selected':
			return 'has selected';
		case 'notAnswered':
			return 'has not answered';
		case 'answered':
			return 'has answered';
		default:
			return type as string;
	}
};

export const hideExtremeValues = (
	option: string | undefined,
	values: (string | number)[],
	value: string | number
): boolean => {
	const firstValue = values[0],
		lastValue = values[values.length - 1];
	// eslint-disable-next-line
	if ((option === 'lt' && value == firstValue) || (option === 'gt' && value == lastValue)) {
		return true;
	} else {
		return false;
	}
};

export const replaceQuestionOptionWithText = (
	questionId: string,
	option: string,
	questions: { [key: string]: QuesState }
): string => {
	if (option.toString().search(QUESTION_UUID_REGEX) === -1) {
		return option;
	}

	const [optionId] = [...((option?.matchAll(QUESTION_UUID_REGEX) as any) || [])].pop();

	return replaceQuestionOptionWithText(
		questionId,
		replaceMentionText(
			questions[questionId].options ? questions[questionId].options[optionId] : questions[questionId].text,
			questions
		),
		questions
	);
};

export const replacePipeWithLabel = (
	option: string | number | MultipleChoiceOption,
	questions: { [key: string]: QuesState }
): string => {
	if (typeof option === 'number' || typeof option === 'string' || option?.optionType !== OptionType.Pipe) {
		return has('value', option) ? (option?.value ? option?.value : null) : option?.toString();
	}

	const [pipeId] = [...(((option.pipe.pipeId || option)?.matchAll(QUESTION_UUID_REGEX) as any) || [])].pop();

	const response: string = `Piped from ${appendPositionPrefix(questions[pipeId])}. ${questions[pipeId].text}`;
	return replacePipeWithLabel(response, questions);
};

export const isSkipToValid = (skipTo: SkipTo): boolean => {
	return skipTo.type === 'end' || !!skipTo.value;
};

export const getColumnPosition = (column, value) => {
	return column.find(({ text }) => value === text)?.orderPosition;
};
export const checkAnswerPiped = (question) => {
	if (question?.type === QUES_TYPE.MATRIX_MATCH_QUES) {
		const { options } = question as MatrixMatchQuesState;
		return Object.values(options).filter((option) => option?.pipe)?.length;
	}
};
