import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const CREATE_BRAND_FUNNEL_METRIC = 'CREATE_BRAND_FUNNEL_METRIC';
export const CREATE_BRAND_FUNNEL_METRIC_FULFILLED = 'CREATE_BRAND_FUNNEL_METRIC_FULFILLED';

export type BrandFunnelCompetitor = {
	value: string;
};

const BrandFunnelCompetitorSchema: JSONSchema = {
	type: 'object',
};
export const BrandFunnelCompetitorsSchema: JSONSchema = {
	type: 'array',
	items: BrandFunnelCompetitorSchema,
};

export const READ_BRAND_FUNNEL_COMPETITORS = 'READ_BRAND_FUNNEL_COMPETITORS';
export const READ_BRAND_FUNNEL_COMPETITORS_FULFILLED = 'READ_BRAND_FUNNEL_COMPETITORS_FULFILLED';

export type ReadBrandFunnelCompetitorsPayload = {
	questionnaireId: string;
};

export type ReadBrandFunnelCompetitors = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMPETITORS,
	ReadBrandFunnelCompetitorsPayload
>;
export type ReadBrandFunnelCompetitorsFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMPETITORS_FULFILLED,
	BrandFunnelCompetitor[]
>;

export type BrandFunnelCompetitorAction = ReadBrandFunnelCompetitors | ReadBrandFunnelCompetitorsFulfilled;

export type BrandFunnelCompetitorReducer = ReduxReducer<SurveyAnalytics, BrandFunnelCompetitorAction>;
