import { MoveQuesOption } from '../types';
import { select } from 'redux-saga/effects';
import axios from '@utils/axios';
import { surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { processMultipleChoiceQuestion } from '../../surveyInfo/sagas/utils';

export function* handleBulkOptionReorder(action: MoveQuesOption) {
	const { quesId, isBulkUpdation = false, updatedOptions = [] } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	let question: any;
	if (!!questions[quesId]) {
		question = !!questions[quesId];
	} else {
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
	}
	try {
		if (!!isBulkUpdation) {
			if (!!questions[quesId]) {
				const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl), updatedOptions);
				yield putEffect(setQuestion(quesId, processMultipleChoiceQuestion(data, questions[quesId])));
			} else {
				const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl), updatedOptions);
				questions[question?.questionnaireSectionId].questions[question.id] = processMultipleChoiceQuestion(
					data,
					question
				);
				yield putEffect(
					setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], false)
				);
			}
		}
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
