import { select, takeLatest, takeLeading } from 'redux-saga/effects';
import {
	GetQuestionnaireAction,
	GET_QUESTIONNAIRE,
	ReadQuestionnaireCountByStatus,
	ReadQuestionnaireCountByStatusPayload,
	READ_QUESTIONNAIRES_COUNT_BY_STATUS,
	SubmitSurveyAction,
	SUBMIT_SURVEY,
	ReadQuestionnaires,
	ReadQuestionnairesPayload,
	READ_QUESTIONNAIRES,
} from './types';
import axios from '@utils/axios';
import { pickAll } from 'ramda';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../shared/utils/getApiError';
import { putEffect } from '../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../promise/actions';
import { questionnaireCountByStatusEndPoint, questionnairesEndPoint } from './const';
import {
	readQuestionnaireCountByStatus,
	readQuestionnaireCountByStatusFulfilled,
	readQuestionnaires,
	readQuestionnairesFulfilled,
	setQuestionnaireAction,
	setQuestionnaireErrorAction,
} from './actions';
import { environment } from '../../environment';
import { assign } from '../../shared/utils/assign';
import { bundleSurveyTemplates } from '../../shared/utils/bundleSurveyTemplates';
import { mapQuestionnaireResponseToSurvey } from '../survey/surveyBuilder/surveyInfo/sagas/utils';
import { getVideoType, mapResponseStateToCreateQuestionnairePayload, removeResponses } from './utils';
import { QUES_TYPE } from 'shared/consts/quesType';
export type ApiError = {
	message: string;
};
export function* getQuestionnaire(action: GetQuestionnaireAction) {
	const { id, nicheId, moderated } = action.payload;

	try {
		var myHeaders = new Headers();
		myHeaders.append('pragma', 'no-cache');
		myHeaders.append('cache-control', 'no-cache');
		const { data } = yield axios.get(getApiEndPointUrl('public/questionnaires', id, nicheId), {
			cache: 'no-store',
			headers: myHeaders,
		});
		const questionnaire = mapQuestionnaireResponseToSurvey(data);
		yield putEffect(
			setQuestionnaireAction(
				assign(
					bundleSurveyTemplates(questionnaire.surveys, moderated),
					pickAll(
						[
							'id',
							'name',
							'description',
							'createdAt',
							'usePyxisLogo',
							'allowEditAndResubmit',
							'surveys',
							'isRightOriented',
							'gamified',
							'theme',
							'howTo',
							'surveyTemplateSetting',
							'pixelId',
						],
						questionnaire
					)
				)
			)
		);
	} catch (error: any) {
		const err = error?.message as ApiError;
		if (err?.message?.length) {
			yield putEffect(setQuestionnaireErrorAction(err?.message));
		}
		if (!err?.message?.length) {
			yield putEffect(setQuestionnaireErrorAction(getApiError(error)));
		}
	}
}

function* getQuestionnaireWatcher() {
	yield takeLeading(GET_QUESTIONNAIRE, getQuestionnaire);
}

export function* submitSurvey(action: SubmitSurveyAction) {
	const { removeResponsesForQuestionIds = [], duration } = action.payload;
	const {
		redirect: {
			info: {
				params: { id, nicheId },
			},
		},
		surveyResponse,
	} = yield select((s) => s);

	try {
		yield axios.post(
			`${environment.questionnaireResponseApiUrl}submit`,
			removeResponses(
				mapResponseStateToCreateQuestionnairePayload(surveyResponse, id, nicheId, duration),
				removeResponsesForQuestionIds
			)
		);
		if (!!getVideoType(surveyResponse)?.type && getVideoType(surveyResponse)?.type === QUES_TYPE.VIDEO_UPLOAD_QUES) {
			yield axios.post(`${environment.surveyAnalyticsApiUrl}transcription`, {
				questionnaireId: id,
				nicheId,
				//@ts-ignore
				videoURL: getVideoType(surveyResponse)?.response?.videoUrl,
			});
		}
	} catch (e) {
		yield putEffect(setQuestionnaireErrorAction(getApiError(e)));
	}
}

function* submitSurveyWatcher() {
	yield takeLeading(SUBMIT_SURVEY, submitSurvey);
}

function readQuestionnaireCountByStatusAPI(payload: ReadQuestionnaireCountByStatusPayload) {
	return axios.get(getApiEndPointUrl(questionnaireCountByStatusEndPoint), {
		params: payload,
	});
}

function* readQuestionnaireCountByStatusSaga(action: ReadQuestionnaireCountByStatus) {
	try {
		yield putEffect(pend(readQuestionnaireCountByStatus));
		const response = yield readQuestionnaireCountByStatusAPI(action.payload);
		yield putEffect(fulfill(readQuestionnaireCountByStatus));
		yield putEffect(readQuestionnaireCountByStatusFulfilled(response.data));
	} catch (error) {
		yield putEffect(reject(readQuestionnaireCountByStatus, getApiError(error)));
	}
}

function* getReadQuestionnaireCountByStatusWatcher() {
	yield takeLeading(READ_QUESTIONNAIRES_COUNT_BY_STATUS, readQuestionnaireCountByStatusSaga);
}

function readQuestionnairesAPI(payload: ReadQuestionnairesPayload) {
	return axios.get(getApiEndPointUrl(questionnairesEndPoint), {
		params: payload,
	});
}

function* readQuestionnairesSaga(action: ReadQuestionnaires) {
	try {
		yield putEffect(pend(readQuestionnaires));
		const response = yield readQuestionnairesAPI(action.payload);
		yield putEffect(fulfill(readQuestionnaires));
		yield putEffect(readQuestionnairesFulfilled(response.data));
	} catch (error) {
		yield putEffect(reject(readQuestionnaires, getApiError(error)));
	}
}

function* getReadQuestionnairesWatcher() {
	yield takeLatest(READ_QUESTIONNAIRES, readQuestionnairesSaga);
}

export const questionnaireActionWatchers = [
	getQuestionnaireWatcher,
	submitSurveyWatcher,
	getReadQuestionnaireCountByStatusWatcher,
	getReadQuestionnairesWatcher,
];
