import { GetPublicSurveyAction, GetSurveyAction } from '../types';
import { delay, select } from 'redux-saga/effects';
import axios from '@utils/axios';
import { questionnairesApiEndpoint, questionnairesPublicApiEndpoint } from './consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { redirect } from '../../../../redirect/actions';
import { createRouteFromBlueprint } from '../../../../../shared/utils/routeMatcher';
import { routePaths } from '../../../../../shared/consts/routePaths';
import { getSurveyAction, setActiveSurveyAction, setSurveyAction } from '../actions';
import { mapQuestionnaireResponseToSurvey } from './utils';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { pend, fulfill, reject } from '../../../../promise/actions';

export function* getSurvey(action: GetSurveyAction) {
	const { id } = action.payload;
	const { params } = yield select((s) => s.redirect.info);

	try {
		yield delay(1500);
		yield putEffect(pend(getSurveyAction));
		const { data } = yield axios.get(getApiEndPointUrl(questionnairesApiEndpoint, id));
		yield putEffect(setSurveyAction(mapQuestionnaireResponseToSurvey(data)));
		yield putEffect(setActiveSurveyAction(data.surveys[0]?.id || ''));
		yield putEffect(fulfill(getSurveyAction, data));
	} catch (e) {
		console.error(' Error in getSurvey.ts =========>', e);
		yield putEffect(redirect(createRouteFromBlueprint(routePaths.survey.root, routePaths.survey.sub.list)(params)));
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(getSurveyAction, getApiError(e)));
	}
}
export function* getPublicSurvey(action: GetPublicSurveyAction) {
	const { id } = action.payload;
	// const { params } = yield select((s) => s.redirect.info);

	try {
		yield delay(1500);
		yield putEffect(pend(getSurveyAction));
		const { data } = yield axios.get(getApiEndPointUrl(questionnairesPublicApiEndpoint, id));
		yield putEffect(setSurveyAction(mapQuestionnaireResponseToSurvey(data)));
		yield putEffect(setActiveSurveyAction(data.surveys[0]?.id || ''));
		yield putEffect(fulfill(getSurveyAction, data));
	} catch (e) {
		// yield putEffect(redirect(createRouteFromBlueprint(routePaths.survey.root, routePaths.survey.sub.list)(params)));
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(getSurveyAction, getApiError(e)));
	}
}
