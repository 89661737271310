import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiError } from 'shared/utils/getApiError';
import { putEffect } from 'shared/utils/putEffect';
import { fulfill, pend, reject } from 'store/promise/actions';
import { readComparisonReport, readComparisonReportFulfilled, READ_COMPARISON_ANALYTIC_REPORT } from './actions';
import { getAnalyticsApiEndPointUrl } from 'shared/utils/getApiEndpointUrl';
import { generalAnalyticsEndPoint } from '../generalAnalytics/sagas';
export const postGeneralReportApiFilterEndPoint = `general-analytics`;

function getComparisonReportAPI(action: any) {
	const { id, ...params } = action;
	return axios.get(getAnalyticsApiEndPointUrl(generalAnalyticsEndPoint, id, 'comparison'), { params });
}

function* getComparisonReportSaga(action: ReturnType<typeof readComparisonReport>) {
	try {
		yield putEffect(pend(readComparisonReport));
		const res = yield getComparisonReportAPI(action.payload);
		yield putEffect(readComparisonReportFulfilled(res.data));
		yield putEffect(fulfill(readComparisonReport));
	} catch (e) {
		yield putEffect(reject(readComparisonReport, getApiError(e)));
	}
}
function* readComparisonReportWatcher() {
	yield takeLeading(READ_COMPARISON_ANALYTIC_REPORT, getComparisonReportSaga);
}

export const comparisonAnalyticsReportWatcher = [readComparisonReportWatcher];
