import {
	WorkspacesState,
	WorkspaceAction,
	CREATE_WORKSPACE,
	WorkspaceReducer,
	SET_WORKSPACE,
	SET_CURRENT_WORKSPACE,
	SET_WORKSPACE_ERROR,
	GET_WORKSPACES_ACTION,
	SET_WORKSPACES_WITH_ID,
} from './types';

const initialState: WorkspacesState = {
	workspaces: [],
	current: '',
	selectedWorkspace: null,
	loading: false,
	error: null,
	workspacesWithId: [],
};

export const workspaceReducer: WorkspaceReducer = (state = initialState, action: WorkspaceAction) => {
	switch (action.type) {
		case CREATE_WORKSPACE:
		case GET_WORKSPACES_ACTION:
			return {
				...state,
				loading: true,
			};
		case SET_WORKSPACE:
			return {
				...state,
				loading: false,
				workspaces: action.payload,
			};
		case SET_CURRENT_WORKSPACE:
			return {
				...state,
				loading: false,
				current: action.payload,
			};
		case SET_WORKSPACES_WITH_ID:
			return {
				...state,
				loading: false,
				workspacesWithId: action.payload,
			};
		case SET_WORKSPACE_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
