import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';

export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';

export const APPROVE = 'APPROVE';
export const APPROVE_SUCCESS = 'APPROVE_SUCCESS';

export const REJECT = 'REJECT';
export const REJECT_SUCCESS = 'REJECT_SUCCESS';

export const GET_QUESTIONNAIRES_COUNT = 'GET_QUESTIONNAIRES_COUNT';
export const GET_QUESTIONNAIRES_COUNT_SUCCESS = 'GET_QUESTIONNAIRES_COUNT_SUCCESS';

export const SET_APPROVAL_ERROR = 'SET_APPROVAL_ERROR';

export type MessageRepsonse = {
	id: string;
	content: string;
	createdAt: string;
	questionnaireId: string;
	author: {
		id: string;
		firstName: string;
		lastName: string;
		profilePicture: string;
	};
};

export type Count = {
	count: number;
};

export type Message = {
	id: string;
	content: string;
	createdAt: string;
	questionnaireId: string;
	profilePicture: string;
	authorName: string;
};

export type PostMessagePayload = {
	content: string;
	questionnaireId: string;
};

export type ApprovalState = {
	loading: boolean;
	messages: Message[];
	count: number | string;
	error: string;
};

export type GetMessagesAction = ReduxAction<typeof GET_MESSAGES, string>;
export type GetMessagesActionSuccess = ReduxAction<typeof GET_MESSAGES_SUCCESS, Message[]>;

export type PostMessageAction = ReduxAction<typeof POST_MESSAGE, PostMessagePayload>;
export type PostMessageActionSuccess = ReduxAction<typeof POST_MESSAGE_SUCCESS, Message>;

export type ApproveAction = ReduxAction<typeof APPROVE, { type: string; userId: string }>;
export type ApproveActionSuccess = ReduxAction<typeof APPROVE_SUCCESS, string>;

export type RejectAction = ReduxAction<typeof REJECT, { type: string; userId: string }>;
export type RejectActionSuccess = ReduxAction<typeof REJECT_SUCCESS, string>;

export type GetQuestionnaireCountAction = ReduxAction<typeof GET_QUESTIONNAIRES_COUNT, string>;
export type GetQuestionnaireCountActionSuccess = ReduxAction<typeof GET_QUESTIONNAIRES_COUNT_SUCCESS, number>;

export type SetApprovalError = ReduxAction<typeof SET_APPROVAL_ERROR, string>;

export type ApprovalAction =
	| GetMessagesAction
	| GetMessagesActionSuccess
	| PostMessageAction
	| PostMessageActionSuccess
	| ApproveAction
	| ApproveActionSuccess
	| RejectAction
	| RejectActionSuccess
	| GetQuestionnaireCountAction
	| GetQuestionnaireCountActionSuccess
	| SetApprovalError;

export type ApprovalReducer = ReduxReducer<ApprovalState, ApprovalAction>;
