import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { JSONSchema, validate } from '../../../shared/utils/jsonschema';
import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	readGeneralAnalytics,
	readGeneralAnalyticsFulfilled,
	ReadGeneralAnalyticsPayload,
	READ_GENERAL_ANALYTICS,
} from './actions';
export const generalAnalyticsEndPoint = `general-analytics`;

export const GeneralAnalyticsSchema: JSONSchema = {
	type: 'object',
	properties: {
		results: { type: 'array', items: { type: 'object' } },
		totalResponses: { type: 'array', items: { type: 'object' } },
	},
};

function readGeneralAnalyticsAPI(action: ReadGeneralAnalyticsPayload) {
	var myHeaders = new Headers();
	myHeaders.append('pragma', 'no-cache');
	myHeaders.append('cache-control', 'no-cache');
	const { id, ...params } = action;
	return axios.get(getAnalyticsApiEndPointUrl(generalAnalyticsEndPoint, id), {
		params,
		cache: 'no-store',
		headers: myHeaders,
	});
}

function* readGeneralAnalyticsSaga(action: ReturnType<typeof readGeneralAnalytics>) {
	try {
		yield putEffect(pend(readGeneralAnalytics));
		const res = yield readGeneralAnalyticsAPI(action.payload);
		yield putEffect(readGeneralAnalyticsFulfilled(validate(res.data, GeneralAnalyticsSchema)));
		yield putEffect(fulfill(readGeneralAnalytics));
	} catch (e) {
		yield putEffect(reject(readGeneralAnalytics, getApiError(e)));
	}
}

function* analyticsDataWatcher() {
	yield takeLeading(READ_GENERAL_ANALYTICS, readGeneralAnalyticsSaga);
}

export const getSurveyDataWatchers = [analyticsDataWatcher];
