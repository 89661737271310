import {
	GET_QUESTIONNAIRE,
	GetQuestionnaireAction,
	SET_QUESTIONNAIRE,
	SET_QUESTIONNAIRE_ERROR,
	SetQuestionnaireAction,
	SetQuestionnaireActionPayload,
	SetQuestionnaireErrorAction,
	SUBMIT_SURVEY,
	SubmitSurveyAction,
	ReadQuestionnaireCountByStatus,
	ReadQuestionnaireCountByStatusFulfilled,
	ReadQuestionnaireCountByStatusFulfilledPayload,
	ReadQuestionnaireCountByStatusPayload,
	READ_QUESTIONNAIRES_COUNT_BY_STATUS,
	READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED,
	ReadQuestionnairesPayload,
	READ_QUESTIONNAIRES,
	ReadQuestionnaires,
	ReadQuestionnairesFulfilled,
	ReadQuestionnairesFulfilledPayload,
	READ_QUESTIONNAIRES_FULFILLED,
} from './types';

export const getQuestionnaireAction: (id: string, nicheId: string, moderated: boolean) => GetQuestionnaireAction = (
	id,
	nicheId,
	moderated
) => ({
	type: GET_QUESTIONNAIRE,
	payload: { id, nicheId, moderated },
});

export const setQuestionnaireAction: (patch: SetQuestionnaireActionPayload) => SetQuestionnaireAction = (patch) => ({
	type: SET_QUESTIONNAIRE,
	payload: patch,
});

export const setQuestionnaireErrorAction: (error: string) => SetQuestionnaireErrorAction = (error) => ({
	type: SET_QUESTIONNAIRE_ERROR,
	payload: { error },
});

export const submitSurveyAction = (removeResponsesForQuestionIds?: string[], duration?: any): SubmitSurveyAction => ({
	type: SUBMIT_SURVEY,
	payload: {
		removeResponsesForQuestionIds,
		duration,
	},
});

export const readQuestionnaireCountByStatus = (
	payload: ReadQuestionnaireCountByStatusPayload
): ReadQuestionnaireCountByStatus => ({
	type: READ_QUESTIONNAIRES_COUNT_BY_STATUS,
	payload,
});

export const readQuestionnaireCountByStatusFulfilled = (
	payload: ReadQuestionnaireCountByStatusFulfilledPayload
): ReadQuestionnaireCountByStatusFulfilled => ({
	type: READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED,
	payload,
});

export const readQuestionnaires = (payload: ReadQuestionnairesPayload): ReadQuestionnaires => ({
	type: READ_QUESTIONNAIRES,
	payload,
});

export const readQuestionnairesFulfilled = (
	payload: ReadQuestionnairesFulfilledPayload
): ReadQuestionnairesFulfilled => ({
	type: READ_QUESTIONNAIRES_FULFILLED,
	payload,
});
