import {
	BrandFunnelReducer,
	BrandFunnelAction,
	READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED,
	READ_BRAND_FUNNEL_COMPARISON_FULFILLED,
	READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED,
	READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED,
} from './types';

export const brandFunnelReducer: BrandFunnelReducer = (state, action: BrandFunnelAction) => {
	switch (action.type) {
		case READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED:
			return {
				...state,
				brandFunnelDailyData: action.payload,
			};
		case READ_BRAND_FUNNEL_COMPARISON_FULFILLED:
			return {
				...state,
				brandFunnelComparisonData: [action.payload],
			};
		case READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED:
			return {
				...state,
				brandFunnelCombinedData: [action.payload],
			};
		case READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED:
			return {
				...state,
				brandFunnelDataByCohort: action.payload,
			};
		default:
			return state;
	}
};
