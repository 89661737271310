import { AddSection } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { select } from 'redux-saga/effects';
import { MultipleOpinionScaleQuesState } from '../../../../../shared/types/QuesTypes';
import axios from '@utils/axios';
import { questionSectionsEndPoint } from '../consts';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { setQuestion } from '../../sharedQuestions/actions';
import { set, updateAll } from 'shades';
import { processMultipleOpinionScaleSectionsResponse } from '../../surveyInfo/sagas/utils';

export function* addSection(action: AddSection) {
	const {
		payload: { quesId },
	} = action;
	const { questions } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId]) {
		const question: MultipleOpinionScaleQuesState = questions[quesId];
		try {
			const { data } = yield axios.post(getApiEndPointUrl(questionSectionsEndPoint), {
				questionId: quesId,
				text: '  ',
				orderPosition: question.settings.sectionOrder.length + 1,
			});

			const { sections, sectionsOrder } = processMultipleOpinionScaleSectionsResponse(data);

			yield putEffect(
				setQuestion(
					question.id,
					updateAll<MultipleOpinionScaleQuesState>(
						set('sections')(sections),
						set('settings', 'sectionOrder')(sectionsOrder)
					)(question)
				)
			);
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			const { data } = yield axios.post(getApiEndPointUrl(questionSectionsEndPoint), {
				questionId: quesId,
				text: '  ',
				orderPosition: question.settings.sectionOrder.length + 1,
			});

			const { sections, sectionsOrder } = processMultipleOpinionScaleSectionsResponse(data);
			questions[question?.questionnaireSectionId].questions[question.id] = updateAll<MultipleOpinionScaleQuesState>(
				set('sections')(sections),
				set('settings', 'sectionOrder')(sectionsOrder)
			)(question);

			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	}
}
