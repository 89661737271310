import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { SelectQues } from '../types';
import { select } from 'redux-saga/effects';

export function* selectQuestion(action: SelectQues) {
	const { quesId, shouldScrollIntoView } = action.payload;
	try {
		const { questions } = yield select((s) => s.surveyBuilder);
		const questionsWithoutSections = getQuestionsWithoutFunctions(questions);

		if ((questions[quesId] || questionsWithoutSections[quesId]) && shouldScrollIntoView) {
			setTimeout(() => {
				document.getElementById(quesId)?.scrollIntoView({ behavior: 'auto', block: 'start' });
			}, 100);
		}
	} catch (e) {
		console.error(e);
	}
}
