import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { SetSurveyListAction } from '../../survey/surveyList/types';
import { BrandState } from '../types';

export const READ_SURVEYS_COUNT = 'READ_SURVEYS_COUNT';
export const READ_SURVEYS_COUNT_FULFILLED = 'READ_SURVEYS_COUNT_FULFILLED';
export const RESET_SURVEYS_COUNT = 'RESET_SURVEYS_COUNT';

export type ReadSurveysCountPayload = {
	brandId: string;
};
export const SurveyCountSchema: JSONSchema = {
	type: 'number',
};

export type ReadSurveysCount = ReduxAction<typeof READ_SURVEYS_COUNT, ReadSurveysCountPayload>;
export type ReadSurveysCountFulfilled = ReduxAction<typeof READ_SURVEYS_COUNT_FULFILLED, number>;
export type ResetSurveysCount = ReduxAction<typeof RESET_SURVEYS_COUNT, undefined>;

export type BrandSurveyAction = ReadSurveysCount | ReadSurveysCountFulfilled | SetSurveyListAction | ResetSurveysCount;

export type BrandSurveyReducer = ReduxReducer<BrandState, BrandSurveyAction>;
