import axios from '@utils/axios';

import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { surveyDesignPThemePatchApiEndpoint } from './consts';
import { UpdateSurveyDesignThemeAction } from '../types';
import { setSurveyError } from '../../shared/actions';
import { updateSurveyDesignTheme, setSurveyDesignTheme } from '../actions';

export function* updateSurveyDesignThemeSaga(action: UpdateSurveyDesignThemeAction) {
	const { surveyId, updatedTemplateSettings } = action.payload;
	try {
		yield putEffect(pend(updateSurveyDesignTheme));
		const { data } = yield axios.patch(getApiEndPointUrl('questionnaires', surveyId), {
			surveyTemplateSetting: { ...updatedTemplateSettings },
		});
		yield putEffect(setSurveyDesignTheme({ ...data.surveyTemplateSetting }));
		yield putEffect(fulfill(updateSurveyDesignTheme, data));
	} catch (e) {
		yield putEffect(reject(updateSurveyDesignTheme, getApiError(e)));
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
