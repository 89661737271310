import styled, { css } from 'styled-components';
import RightOutlined from '@ant-design/icons/RightOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';

export const DrawerImage = styled.img`
	width: 80%;
	max-width: 46.7rem;
	margin-top: 5.6rem;
	margin-bottom: 5.6rem;
`;

export const DrawerTitle = styled.div`
	${({ theme }) => `
    font-weight: ${theme.fonts.weight.bolder};
    font-size: ${theme.fonts.size.lead};
    line-height: ${theme.fonts.lineHeight.lead};
    color: ${theme.colors.primaryDark};
  `}
	padding-bottom: 2.4rem;
`;

export const DrawerSubTitle = styled.div`
	${({ theme }) => `
    font-size: ${theme.fonts.size.big};
    line-height: ${theme.fonts.lineHeight.bigger};
    color: ${theme.colors.textLight};
  `}
	margin: 0 9.9rem;
`;
export const StyledDrawerText = styled.div`
	text-align: center;
`;
const MixinText16 = css`
	font-size: ${({ theme }) => theme.fonts.size.big};
	line-height: ${({ theme }) => theme.fonts.lineHeight.bigger};
	font-weight: 400;
	color: ${({ theme }) => theme.colors.primaryDark};
	margin: 0;
`;
export const StyledButton = styled.button<{ disabled?: boolean; margin?: string; onClick?: (e: Event) => void }>`
	${MixinText16}
	font-weight: 600;
	background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightPurple : theme.colors.primaryButton)};
	border-radius: ${({ theme }) => theme.layouts.borderRadius.small};
	color: ${({ theme }) => theme.colors.surfaceLight};
	padding: ${({ theme }) => theme.layouts.padding.majorButton};
	border: none;
	cursor: pointer;
`;
export const StyledCancelButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.primaryButton};
	cursor: pointer;
	border: none;
	background: none;
	margin: 0;
	padding: 0;
`;
export const StyledFooter = styled.footer`
	display: flex;
	justify-content: space-between;
	padding: 0 3.2rem;
`;
export const StyledRightOutlined = styled(RightOutlined)`
	padding-left: 1.4rem;
`;
export const StyledLeftOutlined = styled(LeftOutlined)`
	padding-right: 1.4rem;
`;

export const StyledBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	justify-self: center;
	height: 100%;
`;
