import {
	GetUsersAction,
	GET_USERS,
	SetUsersPayloaad,
	SET_USERS,
	SetUsersAction,
	GetRolesAction,
	GET_ROLES,
	SetRolesPayload,
	SetRolesAction,
	SET_ROLES,
	UpdateUserRolesPayload,
	UPDATE_ROLES,
	UpdateUserRolesAction,
	DeleteWorkspaceAction,
	DELETE_WORKSPACE,
	RenameWorkspacePayload,
	RENAME_WORKSPACE,
	RenameWorkspaceAction,
	REMOVE_USER_FROM_WORKSPACES,
	RemoveUserFromWorkspaces,
	DeleteUserFromWorkspaces,
	DELETE_USER_FROM_WORKSPACES,
	SET_WORKSPACE_DASHBOARD_ERROR,
	SetWorkspaceDashboardError,
	SET_USER_DASHBOARD_ERROR,
	SetUserDashboardError,
	UpdateUserAction,
	UPDATE_USER,
	RenameWorkspaceRequestAction,
	RenameWorkspaceRequestPayload,
	RENAME_WORKSPACE_REQUEST,
	RENAME_WORKSPACE_ERROR,
	RenameWorkspaceError,
	AddNewUsersFulfilled,
	AddNewUserPayload,
	AddNewUsersAction,
	ADD_NEW_USERS,
	ADD_NEW_USERS_FULFILLED,
	SET_DASHBOARD_LOADING,
	SetDashboardLoading,
	GET_DASHBOARD_WORKSPACES,
	SET_DASHBOARD_WORKSPACES,
	GetUsersPayload,
	GetWorkspacesPayload,
	GetWorkspacesAction,
	SetWorkspacesPayload,
	SetWorkspacesAction,
	UserWithRole,
} from './types';

export const getUsersAction: (payload: GetUsersPayload) => GetUsersAction = (payload) => ({
	type: GET_USERS,
	payload,
});

export const setUsersAction: (payload: SetUsersPayloaad) => SetUsersAction = (payload) => ({
	type: SET_USERS,
	payload,
});

export const getWorkspacesAction: (payload: GetWorkspacesPayload) => GetWorkspacesAction = (payload) => ({
	type: GET_DASHBOARD_WORKSPACES,
	payload,
});

export const setWorkspacesAction: (payload: SetWorkspacesPayload) => SetWorkspacesAction = (payload) => ({
	type: SET_DASHBOARD_WORKSPACES,
	payload,
});

export const getRolesAction: (payload: string[]) => GetRolesAction = (payload) => ({
	type: GET_ROLES,
	payload,
});

export const setRolesAction: (payload: SetRolesPayload) => SetRolesAction = (payload) => ({
	type: SET_ROLES,
	payload,
});

export const updateUserRolesAction: (payload: UpdateUserRolesPayload) => UpdateUserRolesAction = (payload) => ({
	type: UPDATE_ROLES,
	payload,
});

export const deleteWorkspace: (workspaceId: string) => DeleteWorkspaceAction = (payload) => ({
	type: DELETE_WORKSPACE,
	payload,
});

export const renameWorkspaceRequestAction: (payload: RenameWorkspaceRequestPayload) => RenameWorkspaceRequestAction = (
	payload
) => ({
	type: RENAME_WORKSPACE_REQUEST,
	payload,
});

export const renameWorkspaceAction: (payload: RenameWorkspacePayload) => RenameWorkspaceAction = (payload) => ({
	type: RENAME_WORKSPACE,
	payload,
});

export const setRenameWorkspaceErrorAction: (payload: string | null) => RenameWorkspaceError = (payload) => ({
	type: RENAME_WORKSPACE_ERROR,
	payload,
});
export const addNewUsersAction: (payload: AddNewUserPayload) => AddNewUsersAction = (payload: AddNewUserPayload) => ({
	type: ADD_NEW_USERS,
	payload,
});
export const addNewUsersFulfilled = (payload: UserWithRole[]): AddNewUsersFulfilled => ({
	type: ADD_NEW_USERS_FULFILLED,
	payload,
});

export const removeUserFromWorkspaces: (payload: string) => RemoveUserFromWorkspaces = (payload) => ({
	type: REMOVE_USER_FROM_WORKSPACES,
	payload,
});

export const deleteUserFromWorkspaces: (payload: string) => DeleteUserFromWorkspaces = (payload) => ({
	type: DELETE_USER_FROM_WORKSPACES,
	payload,
});

export const setWorkspaceDashboardError: (payload: string | null) => SetWorkspaceDashboardError = (payload) => ({
	type: SET_WORKSPACE_DASHBOARD_ERROR,
	payload,
});

export const setUserDashboardError: (payload: string | null) => SetUserDashboardError = (payload) => ({
	type: SET_USER_DASHBOARD_ERROR,
	payload,
});

export const updateUserAction: (payload: UserWithRole[]) => UpdateUserAction = (payload) => ({
	type: UPDATE_USER,
	payload,
});

export const setDashboardLoading: (loading: boolean) => SetDashboardLoading = (payload) => ({
	type: SET_DASHBOARD_LOADING,
	payload,
});
