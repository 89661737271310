import { ReduxAction } from '../../../../shared/types/ReduxAction';
import { QuestionPipe } from '../../../../shared/types/QuestionLogic';
import { MethodologyType, MultipleChoiceOption, OptionType } from '../../../../shared/types/QuesTypes';

export const ADD_QUES_OPTION = 'ADD_QUES_OPTION';
export const EDIT_QUES_OPTION = 'EDIT_QUES_OPTION';
export const PATCH_QUES_OPTIONS = 'PATCH_QUES_OPTIONS';
export const MOVE_QUES_OPTION = 'MOVE_QUES_OPTION';
export const MOVE_BULK_OPTION = 'MOVE_BULK_OPTION';
export const DELETE_QUES_OPTION = 'DELETE_QUES_OPTION';
export const UPLOAD_OPTION_ASSET = 'UPLOAD_OPTION_ASSET';
export const DELETE_OPTION_ASSET = 'DELETE_OPTION_ASSET';
export const EDIT_PIPE_QUES_OPTION = 'EDIT_PIPE_QUES_OPTION';
export const CLEAR_OPTIONS_BY_IDS = 'CLEAR_OPTIONS_BY_IDS';

export type EditQuesOptionPayload = {
	quesId: string;
	optionId: string;
	optionText?: string;
	methodology?: MethodologyType;
	moderated?: boolean;
	anchorOnRandomization?: boolean;
};

export type PatchQuesOptionsPayload = {
	questionId: string;
	updates: (Partial<MultipleChoiceOption> & { id: string })[];
	moderated?: boolean;
};

export type MoveQuesOptionPayload = {
	quesId: string;
	srcIndex: number;
	destIndex: number;
	isBulkUpdation?: boolean;
	updatedOptions?: any;
};

export type MoveBulkOptionsPlyload = {
	quesId: string;
	isBulkUpdation?: boolean;
	updatedOptions?: any;
};

export type UploadOptionAssetPayload = {
	questionId: string;
	optionId: string;
	file?: File;
	url?: string;
};

export type EditPipeQuesOptionPayload = {
	quesId: string;
	pipeId: string;
	pipeValue: string;
	optionType: OptionType;
	optionId: string;
};

export type AddQuesOption = ReduxAction<
	typeof ADD_QUES_OPTION,
	{
		quesId: string;
		none?: boolean;
		pipe?: QuestionPipe;
		file?: File;
		url?: string;
		assetId?: string;
		optionText?: string;
		methodology?: string;
		bulkChoices?: boolean;
		bulkOptionsData?: any;
		rows?: number;
		columns?: number;
	}
>;
export type EditQuesOption = ReduxAction<typeof EDIT_QUES_OPTION, EditQuesOptionPayload>;
export type PatchQuesOptions = ReduxAction<typeof PATCH_QUES_OPTIONS, PatchQuesOptionsPayload>;
export type MoveQuesOption = ReduxAction<typeof MOVE_QUES_OPTION, MoveQuesOptionPayload>;
export type MoveBulkOption = ReduxAction<typeof MOVE_BULK_OPTION, MoveBulkOptionsPlyload>;
export type DeleteQuesOption = ReduxAction<
	typeof DELETE_QUES_OPTION,
	{ quesId: string; optionId: string; moderated?: boolean; pipeId?: string }
>;
export type UploadOptionAsset = ReduxAction<typeof UPLOAD_OPTION_ASSET, UploadOptionAssetPayload>;
export type DeleteOptionAsset = ReduxAction<typeof DELETE_OPTION_ASSET, { optionId: string }>;
export type ClearOptionsByIds = ReduxAction<typeof CLEAR_OPTIONS_BY_IDS, { optionIds: string[]; questionId: string }>;
export type EditPipeQuesOption = ReduxAction<typeof EDIT_PIPE_QUES_OPTION, EditPipeQuesOptionPayload>;

export type MultipleChoiceAction =
	| AddQuesOption
	| EditQuesOption
	| MoveQuesOption
	| DeleteQuesOption
	| UploadOptionAsset
	| EditPipeQuesOption
	| DeleteOptionAsset
	| MoveBulkOption
	| ClearOptionsByIds;

export type MultipleChoiceActionType =
	| typeof ADD_QUES_OPTION
	| typeof EDIT_QUES_OPTION
	| typeof MOVE_QUES_OPTION
	| typeof DELETE_QUES_OPTION
	| typeof UPLOAD_OPTION_ASSET
	| typeof DELETE_OPTION_ASSET
	| typeof EDIT_PIPE_QUES_OPTION
	| typeof MOVE_BULK_OPTION
	| typeof CLEAR_OPTIONS_BY_IDS;
