import { RELOAD_STATE, SET_SURVEY_ERROR, SurveyAction, SurveyBuilderState, SurveyReducer } from './types';
import { multipleChoiceReducer } from '../questionOptions/reducer';
import { reduceReducers } from '../../../../shared/utils/reduceReducers';
import { multipleOpinionScaleReducer } from '../questionSections/reducer';
import { matrixReducer } from '../matrixQues/reducer';
import { surveyInfoReducer } from '../surveyInfo/reducer';
import { sharedQuestionsReducer } from '../sharedQuestions/reducer';
import { set } from 'shades';
import { Research } from '../../../../shared/enums/Research';
import { GET_PUBLIC_SURVEY, GET_SURVEY } from '../surveyInfo/types';

export const initialSurveyBuilderState: SurveyBuilderState = {
	surveyId: '',
	name: '',
	description: '',
	questions: {},
	moderatedQuestion: {},
	quesOrder: [],
	currQuesId: '',
	hasWelcomeScreen: false,
	hasVideoUploadQues: false,
	error: '',
	research: Research.None,
	researchModule: undefined,
	howToPlay: 'Footer',
	requestInProgress: false,
	popup: '',
	surveys: {},
	allowEditAndResubmit: false,
	usePyxisLogo: false,
	isRightOriented: true,
	gamified: false,
	theme: 'stockTheme',
	testingGoal: '',
	testingTargetUrls: [],
	creator: undefined,
	isModerated: false,
	surveyTemplateSetting: {},
};

export const sharedReducer: SurveyReducer = (state = initialSurveyBuilderState, action) => {
	switch (action.type) {
		case SET_SURVEY_ERROR:
			return set('requestInProgress')(false)(set('error')(action.payload.error)(state));
		case GET_SURVEY: {
			if (!!action.payload.doReset) {
				return initialSurveyBuilderState;
			} else return state;
		}
		case GET_PUBLIC_SURVEY:
		case RELOAD_STATE:
			return initialSurveyBuilderState;
		default: {
			if (state.error) {
				return set('error')('')(state);
			}
			return state;
		}
	}
};

export const surveyReducer = reduceReducers<SurveyBuilderState, SurveyAction>(
	initialSurveyBuilderState,
	sharedReducer,
	multipleChoiceReducer,
	multipleOpinionScaleReducer,
	matrixReducer,
	surveyInfoReducer,
	sharedQuestionsReducer
);
