import { QUES_TYPE } from '../../shared/consts/quesType';
import { MatrixMatchResponseState, MultipleChoiceResponseState, ResponseState } from '../../shared/types/ResponseTypes';
import { MENTION_PREFIX_REGEX, QUESTION_UUID_REGEX } from '../../shared/utils/mentionUtils';
import { MultipleChoiceQuesState } from '../../shared/types/QuesTypes';
import { ResponseIndices } from '../../shared/enums/ResponseIndices';
import { SurveySectionsQuestionStates } from 'shared/types/SurveyResponses';
// import { getTextWithoutTags } from '@utils/removeTags';

export const pipeResponse = (
	responses: { [id: string]: ResponseState },
	questions: { [k: string]: SurveySectionsQuestionStates },
	text?: string
) => {
	if (!text) {
		return '';
	}
	let result: string = text.replace(MENTION_PREFIX_REGEX, '');

	[...((text.matchAll(QUESTION_UUID_REGEX) as any) || [])].forEach((match, index) => {
		let quesIdWithColumn: string = match[0];
		const quesId = match[0].split(':')[0];
		const quesResponse: ResponseState = responses[quesId];
		if (quesResponse === undefined) return;
		let replacementText: string;
		if (quesResponse.response !== undefined) {
			switch (quesResponse.type) {
				case QUES_TYPE.MULTIPLE_CHOICE_QUES:
				case QUES_TYPE.CREATIVE_QUES: {
					const allOptions = Object.values(questions)
						.filter((res) => res.type === QUES_TYPE.MULTIPLE_CHOICE_QUES || res.type === QUES_TYPE.CREATIVE_QUES)
						// @ts-ignore
						.reduce((p: any, res: MultipleChoiceQuesState) => {
							Object.entries(res.options).forEach(([id, { value, pipe }]) => {
								p[id] = { value: value, pipe };
							});
							return p;
						}, {});
					const response = responses[quesIdWithColumn] as MultipleChoiceResponseState;
					if (response) {
						replacementText = (response?.response?.value || []).reduce(
							(p, c, i) =>
								`${p}${index || i ? ' , ' : ''}${
									[ResponseIndices.OtherOption.toString(), ResponseIndices.NoneOption.toString()].includes(c.value) ||
									!isNaN(responses[allOptions[c.value].pipe?.pipeId]?.response as number)
										? c.text
										: c.text
								}`,
							''
						);
					} else {
						replacementText = '';
					}
					break;
				}
				case QUES_TYPE.MATRIX_MATCH_QUES: {
					const columnName = quesIdWithColumn?.split(':')[1];
					const response = responses[quesId] as MatrixMatchResponseState;
					const textFill = response.response
						.filter((option) => option.columnText === columnName)
						.map((option) => option.text);
					replacementText = textFill.join(', ');
					break;
				}
				case QUES_TYPE.YES_NO_QUES: {
					replacementText = quesResponse.response ? 'Yes' : 'No';
					break;
				}

				case QUES_TYPE.OPINION_SCALE_QUES:
				case QUES_TYPE.NUMBER_QUES: {
					replacementText = Number.isNaN(quesResponse.response) ? '' : quesResponse.response.toString();
					break;
				}

				default: {
					replacementText = (quesResponse.response || '').toString();
				}
			}
		} else {
			replacementText = '';
		}

		result = result.replace(match[0], replacementText);
	});
	return result.replace('undefined', '').trim();
};
