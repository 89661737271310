import { QuesState, QuesType } from '../../../../shared/types/QuesTypes';
import { makeActionCreatorSurvey } from '../../../../shared/utils/makeActionCreator';
import {
	ADD_QUES,
	ADD_QUES_AT_INDEX,
	ADD_QUESTION_PIPE,
	AddQues,
	AddQuesAtIndex,
	AddQuestionPipe,
	CHANGE_QUESTION_INFO,
	CHANGE_QUESTION_SETTINGS,
	CHANGE_SURVEY_SECTION_SETTINGS,
	ChangeQuestionInfoAction,
	ChangeQuestionSettingsAction,
	COPY_QUES,
	CopyQues,
	DELETE_QUES,
	DeleteQues,
	INSERT_QUESTION,
	INSERT_QUESTIONS,
	InsertQuestion,
	InsertQuestions,
	MOVE_QUES,
	MoveQues,
	QuestionInfo,
	REMOVE_QUESTION_PIPE,
	RemoveQuestionPipe,
	SELECT_QUES,
	SelectQues,
	SET_DISPLAY_LOGIC,
	SET_POPUP,
	SET_QUESTION,
	SET_SKIP_LOGIC,
	SetDisplayLogic,
	SetPopup,
	SetQuestion,
	SetSkipLogic,
	SetMandateLogic,
	SET_MANDATE_LOGIC,
	INSERT_MODERATED_QUESTION,
	InsertModeratedQuestion,
	APPROVE_EDIT,
	ApproveEdit,
	AddSection,
	CREATE_NEW_SECTION_TO_SURVEY,
	ADDED_SECTION_SUCCESS,
	InsertSection,
	DELETE_SURVEY_SECTION,
	DeleteSurveySection,
	CHANGE_SECTION_INFO,
	ChangeSectionInfoAction,
	SectionInfo,
	SET_SECTION,
	SetSection,
	SET_SECTION_QUESTION,
	SetSectionQuestion,
	UPDATE_INFO,
	UpdateInfo,
	ChangeSectionSettingsDisplayAction,
	COPY_SURVEY_SECTION,
	CopySurveySection,
	ADD_BULK_QUES,
	AddBulkQues,
} from './types';
import { MandateLogic, QuestionPipe, ShowLogic, SkipLogic } from '../../../../shared/types/QuestionLogic';
import { QuestionsStates, SectionsInSurvey, SurveySection } from 'shared/types/SurveyResponses';

export const addQues = makeActionCreatorSurvey(
	ADD_QUES,
	'quesType',
	'parentQuestionId',
	'moderated',
	'questionnaireSectionId'
) as (quesType: QuesType, parentQuestionId?: string, moderated?: boolean, questionnaireSectionId?: string) => AddQues;

export const addSection = makeActionCreatorSurvey(CREATE_NEW_SECTION_TO_SURVEY, 'questionnaireId', 'moderated') as (
	questionnaireId?: string,
	moderated?: boolean
) => AddSection;

export const insertSection = makeActionCreatorSurvey(ADDED_SECTION_SUCCESS, 'section', 'index') as (
	section: any,
	index: number
) => InsertSection;

export const deleteSurveySection = makeActionCreatorSurvey(
	DELETE_SURVEY_SECTION,
	'sectionId',
	'moderated',
	'foundQuestion'
) as (sectionId: string, moderated?: boolean, foundQuestion?: any) => DeleteSurveySection;

export const copyQuesSurveySection = makeActionCreatorSurvey(COPY_SURVEY_SECTION, 'sectionId', 'moderated') as (
	sectionId: string,
	moderated?: boolean
) => CopySurveySection;

export const addQuesAtIndex = makeActionCreatorSurvey(
	ADD_QUES_AT_INDEX,
	'quesType',
	'orderPosition',
	'index',
	'questionnaireSectionId'
) as (quesType: QuesType, index: number, orderPosition: number, questionnaireSectionId?: string) => AddQuesAtIndex;
export const selectQues = makeActionCreatorSurvey(SELECT_QUES, 'quesId', 'shouldScrollIntoView', 'moderated') as (
	id: string,
	shouldScrollIntoView?: boolean,
	moderated?: boolean
) => SelectQues;
export const copyQues = makeActionCreatorSurvey(COPY_QUES, 'quesId', 'moderated') as (
	id: string,
	moderated?: boolean
) => CopyQues;
export const deleteQues = makeActionCreatorSurvey(DELETE_QUES, 'quesId', 'moderated', 'foundQuestion') as (
	id: string,
	moderated?: boolean,
	foundQuestion?: any
) => DeleteQues;
export const addBulkQuestions = makeActionCreatorSurvey(ADD_BULK_QUES, 'questions') as (questions: any) => AddBulkQues;
export const approveEdit = makeActionCreatorSurvey(APPROVE_EDIT, 'surveyId') as (id: string) => ApproveEdit;
export const moveQues = makeActionCreatorSurvey(MOVE_QUES, 'src', 'dest', 'type') as (
	src: any,
	dest: any,
	type: string
) => MoveQues;
export const setDisplayLogic = makeActionCreatorSurvey(SET_DISPLAY_LOGIC, 'id', 'logic', 'type') as (
	id: string,
	logic: ShowLogic[],
	type: string
) => SetDisplayLogic;
export const setMandateLogic = makeActionCreatorSurvey(SET_MANDATE_LOGIC, 'id', 'mandate') as (
	id: string,
	mandate: MandateLogic
) => SetMandateLogic;
export const removeQuestionPipe = makeActionCreatorSurvey(REMOVE_QUESTION_PIPE, 'id', 'pipeId', 'matrixId') as (
	id: string,
	pipeId: string,
	matrixId?: number
) => RemoveQuestionPipe;
export const addQuestionPipe = makeActionCreatorSurvey(ADD_QUESTION_PIPE, 'id', 'pipe') as (
	id: string,
	pipe: QuestionPipe
) => AddQuestionPipe;
export const setSkipLogic = makeActionCreatorSurvey(SET_SKIP_LOGIC, 'id', 'logic', 'type') as (
	id: string,
	skip: SkipLogic[],
	type: string
) => SetSkipLogic;
export const setQuestion = makeActionCreatorSurvey(SET_QUESTION, 'id', 'state', 'notConfirmed', 'moderated') as (
	id: string,
	state: QuesState | SurveySection,
	notConfirmed?: boolean,
	moderated?: boolean
) => SetQuestion;

export const setSectionQuestion = makeActionCreatorSurvey(
	SET_SECTION_QUESTION,
	'id',
	'updatedResponse',
	'moderated'
) as (id: string, updatedResponse: any, moderated?: boolean) => SetSectionQuestion;

export const setPopup = makeActionCreatorSurvey(SET_POPUP, 'message') as (message: string) => SetPopup;

export const insertQuestion = makeActionCreatorSurvey(INSERT_QUESTION, 'question', 'index') as (
	question: QuesState | SurveySection,
	index: number
) => InsertQuestion;

export const insertModeratedQuestion = makeActionCreatorSurvey(INSERT_MODERATED_QUESTION, 'question', 'index') as (
	question: QuesState | SurveySection,
	index: number
) => InsertModeratedQuestion;

export const insertQuestions = makeActionCreatorSurvey(INSERT_QUESTIONS, 'questions', 'moderated') as (
	questions: QuesState[] | SurveySection[],
	moderated?: boolean
) => InsertQuestions;

export const changeQuestionSettings = makeActionCreatorSurvey(
	CHANGE_QUESTION_SETTINGS,
	'id',
	'type',
	'settings',
	'moderated'
) as (id: string, type: QuesType, settings: any, moderated?: boolean) => ChangeQuestionSettingsAction;

export const changeSectionSettingsAndLogics = makeActionCreatorSurvey(
	CHANGE_SURVEY_SECTION_SETTINGS,
	'id',
	'settings',
	'display',
	'moderated'
) as (id: string, settings: any, display: any, moderated?: boolean) => ChangeSectionSettingsDisplayAction;

export const changeQuestionInfo = makeActionCreatorSurvey(
	CHANGE_QUESTION_INFO,
	'id',
	'type',
	'info',
	'moderated',
	'sectionId'
) as (
	id: string,
	type: QuesType | undefined,
	info: Partial<QuestionInfo>,
	moderated?: boolean,
	sectionId?: string | null
) => ChangeQuestionInfoAction;

export const changeSectionInfo = makeActionCreatorSurvey(CHANGE_SECTION_INFO, 'id', 'info', 'moderated') as (
	id: string,
	info: SectionInfo | any,
	moderated?: boolean
) => ChangeSectionInfoAction;

export const setSection = makeActionCreatorSurvey(SET_SECTION, 'id', 'state', 'notConfirmed', 'moderated') as (
	id: string,
	state: SectionInfo | any,
	notConfirmed?: boolean,
	moderated?: boolean
) => SetSection;

export const updateInfo = makeActionCreatorSurvey(UPDATE_INFO, 'currentSelectedId', 'response', 'moderated') as (
	currentSelectedId: string,
	response: SectionsInSurvey<QuestionsStates>,
	moderated?: boolean
) => UpdateInfo;
