import { AddQuesAtIndex } from '../types';
import { call, select } from 'redux-saga/effects';
import { getQuestionModelByType } from '../../utils';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, updateInfo } from '../actions';
import axios from '@utils/axios';
import { surveyQuestionsApiUrl } from './consts';
import { mapQuestionToCreateQuestionPayload } from './utils';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { first } from 'shades';
import { getQuestionsResponse } from './createSection';
import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { QUES_TYPE } from 'shared/consts/quesType';

export function* createQuestionAtIndex({
	payload: { index, quesType, orderPosition, questionnaireSectionId },
}: AddQuesAtIndex) {
	const { currQuesId, surveyId, surveys, theme, moderated, questions } = yield select((s) => s.surveyBuilder);
	const allSurveyQuestions = getQuestionsWithoutFunctions(questions);
	let question: any;
	if (!!questions[currQuesId]) {
		const quesWithParentIds = Object.values(allSurveyQuestions)
			.filter(
				(question): any =>
					(question as any)?.parentQuestionId?.length || (question as any)?.type === QUES_TYPE.THANK_YOU
			)
			.map((ques): any => (ques as any)?.id);
		const questionIds = Object.keys(allSurveyQuestions).filter((id) => !quesWithParentIds.includes(id));
		const lastId = questionIds[questionIds.length - 1];
		let orderPositionOfNewQuestion = allSurveyQuestions[lastId]?.orderPosition;
		if (questions[currQuesId]?.type === QUES_TYPE.SCREENER_QUES) {
			orderPositionOfNewQuestion = Object.values(questions).filter((question) =>
				[QUES_TYPE.WELCOME_SCREEN, QUES_TYPE.SCREENER_QUES].includes((question as any)?.type)
			).length;
		}
		question = getQuestionModelByType(quesType, orderPositionOfNewQuestion + 1);
	} else {
		const sectionRequired = questions?.[allSurveyQuestions?.[currQuesId]?.questionnaireSectionId];
		const sectionQuestionLength = sectionRequired?.questionnaireId?.length;
		const newSectionQuestionPosition = sectionRequired?.orderPosition + sectionQuestionLength;
		question = getQuestionModelByType(quesType, newSectionQuestionPosition);
	}
	const questionnaireId = surveys[surveyId]?.questionnaireId;
	if (!question) {
		yield putEffect(setPopup('Question type is not supported'));
		return;
	}

	try {
		const body: Record<string, any> = mapQuestionToCreateQuestionPayload(
			question,
			theme,
			surveyId || first(Object.keys(surveys)),
			questionnaireId,
			true
		);
		if (!body.questionnaireSectionId) {
			body.questionnaireSectionId = questionnaireSectionId;
		}
		if (!!questionnaireSectionId) {
			const { data } = yield call(axios.post, getApiEndPointUrl(surveyQuestionsApiUrl), body);

			const responseOfData = getQuestionsResponse(data?.surveys?.[0]?.questions);

			yield putEffect(updateInfo(currQuesId, responseOfData, moderated));
		}
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
