import {
	CreateMacro,
	CREATE_MACRO,
	Macro,
	CreateMacroFulfilled,
	CREATE_MACRO_FULFILLED,
	UpdateMacroPayload,
	UpdateMacro,
	UPDATE_MACRO,
	UpdateMacroFulfilled,
	UPDATE_MACRO_FULFILLED,
	ReadMacrosPayload,
	ReadMacros,
	READ_MACROS,
	ReadMacrosFulfilled,
	READ_MACROS_FULFILLED,
	CreateMacroPayload,
} from './types';

export const createMacro = (payload: CreateMacroPayload): CreateMacro => ({
	type: CREATE_MACRO,
	payload,
});

export const createMacroFulfilled = (payload: Macro): CreateMacroFulfilled => ({
	type: CREATE_MACRO_FULFILLED,
	payload,
});

export const updateMacro = (payload: UpdateMacroPayload): UpdateMacro => ({
	type: UPDATE_MACRO,
	payload,
});

export const updateMacroFulfilled = (payload: Macro): UpdateMacroFulfilled => ({
	type: UPDATE_MACRO_FULFILLED,
	payload,
});

export const readMacros = (payload: ReadMacrosPayload): ReadMacros => ({
	type: READ_MACROS,
	payload,
});

export const readMacrosFulfilled = (payload: Macro[]): ReadMacrosFulfilled => ({
	type: READ_MACROS_FULFILLED,
	payload,
});
