import {
	GET_RESEARCH_TEMPLATES,
	GetResearchTemplatesAction,
	SET_RESEARCH_TEMPLATES,
	SET_RESEARCH_TEMPLATES_ERROR,
	SetResearchTemplatesAction,
	SetResearchTemplatesErrorAction,
} from './types';
import { ResearchModuleT } from '../../shared/types/ResearchModule';
import { ResearchTemplate } from '../../shared/types/ResearchTemplate';

export const getResearchTemplatesAction: () => GetResearchTemplatesAction = () => ({
	type: GET_RESEARCH_TEMPLATES,
	payload: null,
});

export const setResearchTemplatesAction: (
	modules: ResearchModuleT[],
	templates: ResearchTemplate[]
) => SetResearchTemplatesAction = (modules, templates) => ({
	type: SET_RESEARCH_TEMPLATES,
	payload: { modules, templates },
});

export const setResearchTemplatesErrorAction: (error: string) => SetResearchTemplatesErrorAction = (error) => ({
	type: SET_RESEARCH_TEMPLATES_ERROR,
	payload: error,
});
