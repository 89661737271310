import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';

export const REDIRECT = 'REDIRECT';
export const SET_ROUTE_INFO = 'SET_ROUTE_INFO';

export type RouteInfo = {
	params: {
		[key: string]: string;
	};
	query: {
		[key: string]: string;
	};
	url: string;
};

export type RedirectAction = ReduxAction<typeof REDIRECT, { url: string | undefined }>;
export type SetRouteInfoAction = ReduxAction<typeof SET_ROUTE_INFO, { info: RouteInfo }>;

export type RedirectState = {
	url: string | undefined;
	info: RouteInfo;
};
export type RedirectReducer = ReduxReducer<RedirectState, RedirectAction | SetRouteInfoAction>;
