import {
	GetWorskpacesAction,
	GET_WORKSPACES,
	SET_WORKSPACES,
	SetWorskpacesUserAction,
	WorkspacesPayload,
	SET_NOTIFICATION,
	SetNotificationAction,
	SetErrorAction,
	SET_WORKSPACE_USER_ERROR,
	ResetWorkspaceUserErrorAction,
	RESET_WORKSPACE_USER_ERROR,
} from './types';

export const getWorkspaceAction: () => GetWorskpacesAction = () => ({
	type: GET_WORKSPACES,
	payload: null,
});

export const setWorkspaceUserAction: (payload: WorkspacesPayload) => SetWorskpacesUserAction = (
	payload: WorkspacesPayload
) => {
	return {
		type: SET_WORKSPACES,
		payload,
	};
};

export const setNotificationAction: (payload: string | null) => SetNotificationAction = (payload) => ({
	type: SET_NOTIFICATION,
	payload,
});

export const setErrorAction: (payload: string | null) => SetErrorAction = (payload) => ({
	type: SET_WORKSPACE_USER_ERROR,
	payload,
});

export const resetWorkspaceUsersErrorAction: () => ResetWorkspaceUserErrorAction = () => ({
	type: RESET_WORKSPACE_USER_ERROR,
	payload: null,
});
