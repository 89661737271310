import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const CREATE_BRAND_FUNNEL_METRIC = 'CREATE_BRAND_FUNNEL_METRIC';
export const CREATE_BRAND_FUNNEL_METRIC_FULFILLED = 'CREATE_BRAND_FUNNEL_METRIC_FULFILLED';

export type BrandFunnelMetric = {
	label: string;
	formula?: {
		operator: string;
		weight: number;
		quantity: string;
	}[];
	value: string;
	color?: string;
};

const BrandFunnelMetricSchema: JSONSchema = {
	type: 'object',
};
export const BrandFunnelMetricsSchema: JSONSchema = {
	type: 'array',
	items: BrandFunnelMetricSchema,
};

export type CreateBrandFunnelMetricPayload = BrandFunnelMetric & { questionnaireId: string };

export type CreateBrandFunnelMetric = ReduxAction<typeof CREATE_BRAND_FUNNEL_METRIC, CreateBrandFunnelMetricPayload>;
export type CreateBrandFunnelMetricFulfilled = ReduxAction<
	typeof CREATE_BRAND_FUNNEL_METRIC_FULFILLED,
	BrandFunnelMetric
>;

export const READ_BRAND_FUNNEL_METRICS = 'READ_BRAND_FUNNEL_METRICS';
export const READ_BRAND_FUNNEL_METRICS_FULFILLED = 'READ_BRAND_FUNNEL_METRICS_FULFILLED';

export type ReadBrandFunnelMetricsPayload = {
	questionnaireId: string;
};

export type ReadBrandFunnelMetrics = ReduxAction<typeof READ_BRAND_FUNNEL_METRICS, ReadBrandFunnelMetricsPayload>;
export type ReadBrandFunnelMetricsFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_METRICS_FULFILLED,
	BrandFunnelMetric[]
>;

export type BrandFunnelMetricAction =
	| ReadBrandFunnelMetrics
	| ReadBrandFunnelMetricsFulfilled
	| CreateBrandFunnelMetric
	| CreateBrandFunnelMetricFulfilled;

export type BrandFunnelMetricReducer = ReduxReducer<SurveyAnalytics, BrandFunnelMetricAction>;
