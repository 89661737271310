import { brandAssetsSaga } from './assets/sagas';
import { brandColorSagas } from './colors/sagas';
import { brandCompetitorSagas } from './competitors/sagas';
import { brandDetailSagas } from './detail/sagas';
import { macroSagas } from './macro/sagas';
import { nicheSagas } from './niche/sagas';
import { brandProductSagas } from './products/sagas';
import { brandKeywordSagas } from './keywords/sagas';
import { brandSurveySagas } from './surveys/sagas';

export const brandSagas = [
	...brandDetailSagas,
	...macroSagas,
	...nicheSagas,
	...brandAssetsSaga,
	...brandCompetitorSagas,
	...brandColorSagas,
	...brandProductSagas,
	...brandSurveySagas,
	...brandKeywordSagas,
];
