export enum ApprovalStatus {
	Draft = 'draft',
	InReview = 'inReview',
	Approved = 'approved',
	Rejected = 'rejected',
	Scheduled = 'scheduled',
	Active = 'active',
	Completed = 'completed',
	Archived = 'archived',
}
