import { Reject, REJECTED, Reset, RESET, ResetAll, RESET_ALL, Fulfill, FULFILLED, Pend, PENDING } from './types';

export const reject = (payload: Function, reason: string): Reject => ({
	type: REJECTED,
	payload: {
		id: payload()?.type,
		reason,
	},
});
export const reset = (...payload: string[] | Function[]): Reset => ({
	type: RESET,
	payload: payload.map((p: Function | string) => (typeof p === 'function' ? p()?.type : p)),
});
export const resetAll = (): ResetAll => ({
	type: RESET_ALL,
	payload: null,
});

export const fulfill = (payload: Function, data?: any): Fulfill => ({
	type: FULFILLED,
	payload: {
		id: payload()?.type,
		data,
	},
});
export const pend = (payload: Function): Pend => ({
	type: PENDING,
	payload: payload()?.type,
});
