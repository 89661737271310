import axios from '@utils/axios';
import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';
import { select } from 'redux-saga/effects';
import { get } from 'shades';
import { fulfill, pend, reject } from 'store/promise/actions';
import { QUES_TYPE } from '../../../../../shared/consts/quesType';
import {
	MultipleAnswerMethodologyType,
	MultipleChoiceOption,
	SingleAnswerMethodologyType,
} from '../../../../../shared/types/QuesTypes';
import { assign } from '../../../../../shared/utils/assign';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { patchQuesOptions } from '../../questionOptions/actions';
import { mapQuestionResponseToQuestion } from '../../surveyInfo/sagas/utils';
import { changeQuestionSettings, changeSectionInfo, setPopup, setQuestion, setSection } from '../actions';
import { ChangeQuestionSettingsAction } from '../types';
import { surveyQuestionsApiUrl, surveySectionOptionAssetsApiUrl } from './consts';

export function* updateQuestionSettings(action: ChangeQuestionSettingsAction) {
	const { id, settings, type, moderated } = action.payload;
	const { questions, moderatedQuestion } = yield select((s) => s.surveyBuilder);
	const q = moderated ? getQuestionsWithoutFunctions(moderatedQuestion) : getQuestionsWithoutFunctions(questions);
	const oldSettings: any = type !== 'Section' && get(id, 'settings')(q);
	const question = q[id];
	if (type === 'Section') {
		const oldSettingsWithSection: any = get(id, 'settings')(questions);
		const section = questions[id] || moderatedQuestion[id];
		yield putEffect(setSection(id, assign(settings, section), false, moderated));
		try {
			yield putEffect(pend(changeSectionInfo));
			let updates = {
				id: id,
				settings,
				moderated,
			};
			const res = yield axios.patch(getApiEndPointUrl(surveySectionOptionAssetsApiUrl), updates);
			yield putEffect(setPopup(''));
			yield putEffect(fulfill(changeSectionInfo, { payload: { ...updates, res }, res }));
		} catch (e) {
			yield putEffect(reject(changeSectionInfo, getApiError(e)));
			yield putEffect(setSection(id, assign(oldSettingsWithSection, section), undefined, moderated));
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		if (!!questions[id] || !!moderatedQuestion[id]) {
			const nextQueId: any =
				Object?.keys(getQuestionsWithoutFunctions(questions))?.find(
					(key) => questions[key]?.orderPosition === question?.orderPosition + 1
				) || '';
			// setting pagebreak true for next question if skip is enabled
			if (settings.skip && nextQueId) {
				yield putEffect(setQuestion(nextQueId, assign({ pageBreak: true }, q[nextQueId]), false, moderated));
			}
			// Performing an action before API confirms for smooth UI changeQuestionSettings
			yield putEffect(setQuestion(id, assign({ settings }, q[id]), true, moderated));
			try {
				yield putEffect(pend(changeQuestionSettings));
				const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, id), {
					questionId: id,
					questionType: type,
					settings,
				});

				yield putEffect(setQuestion(id, mapQuestionResponseToQuestion(data), undefined, moderated));
				// if (settings.hasOwnProperty('multipleAnswers')) {
				// 	yield putEffect(setQuestion(id, assign({ display: { skipIf: [] } }, questions[id])));
				// }
				if (
					(q[id].type === QUES_TYPE.SCREENER_QUES || q[id].parentQuestionId) &&
					settings.hasOwnProperty('multipleAnswers') &&
					settings.multipleAnswers !== oldSettings?.multipleAnswers
				) {
					const newMethodology = settings.multipleAnswers
						? MultipleAnswerMethodologyType.MUST_SELECT
						: SingleAnswerMethodologyType.ACCEPT;
					const optionUpdates = Object.values(q[id].options as MultipleChoiceOption[]).map((option) => ({
						id: option.id,
						methodology: newMethodology,
					}));
					if (optionUpdates.length) {
						yield putEffect(patchQuesOptions({ questionId: id, updates: optionUpdates, moderated }));
					}
				}
				yield putEffect(fulfill(changeQuestionSettings, { action, data: questions }));
			} catch (e) {
				yield putEffect(reject(changeQuestionSettings, getApiError(e)));

				// Reverting action if API call failed with propper message
				yield putEffect(setQuestion(id, assign({ settings: oldSettings }, questions[id])));
				yield putEffect(setPopup(getApiError(e)));
			}
		} else {
			const nextQueId: any =
				Object?.keys(getQuestionsWithoutFunctions(questions))?.find(
					(key) => questions[key]?.orderPosition === question?.orderPosition + 1
				) || '';
			// setting pagebreak true for next question if skip is enabled
			if (settings.skip && nextQueId) {
				yield putEffect(setQuestion(nextQueId, assign({ pageBreak: true }, q[nextQueId]), false, moderated));
			}
			// Performing an action before API confirms for smooth UI changeQuestionSettings
			yield putEffect(setQuestion(id, assign({ settings }, q[id]), true, moderated));
			try {
				yield putEffect(pend(changeQuestionSettings));
				const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, id), {
					questionId: id,
					questionType: type,
					settings,
				});
				questions[question?.questionnaireSectionId].questions[question.id] = mapQuestionResponseToQuestion(data);
				yield putEffect(
					setQuestion(
						question?.questionnaireSectionId,
						questions[question?.questionnaireSectionId],
						undefined,
						moderated
					)
				);
				// if (settings.hasOwnProperty('multipleAnswers')) {
				// 	yield putEffect(setQuestion(id, assign({ display: { skipIf: [] } }, questions[id])));
				// }
				if (
					(q[id].type === QUES_TYPE.SCREENER_QUES || q[id].parentQuestionId) &&
					settings.hasOwnProperty('multipleAnswers') &&
					settings.multipleAnswers !== oldSettings?.multipleAnswers
				) {
					const newMethodology = settings.multipleAnswers
						? MultipleAnswerMethodologyType.MUST_SELECT
						: SingleAnswerMethodologyType.ACCEPT;
					const optionUpdates = Object.values(q[id].options as MultipleChoiceOption[]).map((option) => ({
						id: option.id,
						methodology: newMethodology,
					}));
					if (optionUpdates.length) {
						yield putEffect(patchQuesOptions({ questionId: id, updates: optionUpdates, moderated }));
					}
				}
				yield putEffect(fulfill(changeQuestionSettings, { action, data: questions }));
			} catch (e) {
				yield putEffect(reject(changeQuestionSettings, getApiError(e)));

				// Reverting action if API call failed with propper message
				yield putEffect(setQuestion(id, assign({ settings: oldSettings }, questions[id])));
				yield putEffect(setPopup(getApiError(e)));
			}
		}
	}
}
