import {
	ARCHIVE_SURVEY,
	CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED,
	DELETE_SURVEY,
	DUPLICATE_SURVEY,
	GET_IMPORT_SURVEY_LIST,
	GET_SURVEYS_FROM_IMPORT_ISF_TABLE,
	GET_SURVEY_LIST,
	SET_SURVEY_LIST_FROM_ISF,
	GET_SURVEY_LIST_SEARCH,
	RELOAD_SURVEY_LIST_STATE,
	SET_SURVEY_LIST,
	SET_SURVEY_LIST_ERROR,
	SET_SURVEY_LIST_TO_EXPORT,
	SurveyListReducer,
	SurveyListState,
	UPDATE_SURVEY_PAYMENT_STATUS,
} from './types';
import { fill, set } from 'shades';
import cloneDeep from 'lodash.clonedeep';
import { SurveyStatus } from '../../../shared/enums/SurveyStatus';

const initialState: SurveyListState = {
	items: [],
	error: '',
	fetchingSurveys: false,
	count: 0,
	surveyToExport: [],
	surveyFromIsf: [],
	isFileCorrupted: false,
};
export const surveyListReducer: SurveyListReducer = (state = initialState, action) => {
	switch (action.type) {
		case DELETE_SURVEY:
		case DUPLICATE_SURVEY:
		case GET_SURVEY_LIST:
		case GET_SURVEY_LIST_SEARCH:
		case ARCHIVE_SURVEY:
		case GET_IMPORT_SURVEY_LIST:
		case GET_SURVEYS_FROM_IMPORT_ISF_TABLE:
			return set('fetchingSurveys')(true)(state);
		case SET_SURVEY_LIST:
			return fill(state)({
				items: action.payload.items,
				fetchingSurveys: false,
				count: action.payload.count,
			});
		case SET_SURVEY_LIST_TO_EXPORT:
			let surveyToExport;
			if (action.payload.offset === 0) {
				surveyToExport = action.payload.items;
			} else {
				surveyToExport = [...state.surveyToExport, ...action.payload.items];
			}
			return fill(state)({
				fetchingSurveys: false,
				surveyToExport: surveyToExport,
			});
		case SET_SURVEY_LIST_ERROR:
			return fill(state)({ error: action.payload.error, fetchingSurveys: false });
		case SET_SURVEY_LIST_FROM_ISF:
			const { data, isFileCorrupted } = action.payload;
			return fill(state)({ surveyFromIsf: data, fetchingSurveys: false, isFileCorrupted });
		case CHANGE_SERVEY_STATUS_TO_DRAFT_FULFILLED: {
			const { surveyId } = action.payload;

			const items = cloneDeep(state.items);
			const item = items.find((item) => item.surveyId === surveyId);
			if (item) {
				item.status = SurveyStatus.Draft;
				item.approvalStatus = SurveyStatus.Draft;
			}

			return {
				...state,
				items,
			};
		}
		case UPDATE_SURVEY_PAYMENT_STATUS: {
			const { surveyId, paymentStatus } = action.payload;

			const items = cloneDeep(state.items);
			const item = items.find((item) => item.surveyId === surveyId);
			if (item) {
				item.paymentStatus = paymentStatus;
			}

			return {
				...state,
				items,
			};
		}
		case RELOAD_SURVEY_LIST_STATE:
			return initialState;
		default: {
			if (state.error) {
				return set('error')('')(state);
			}
			return state;
		}
	}
};
