import axios from '@utils/axios';

import { select } from 'redux-saga/effects';
import { fulfill, reject } from 'store/promise/actions';

import { setPopup, updateInfo } from '../actions';
import { getQuestionsResponse } from './createSection';
import { surveySectionOptionAssetsApiUrl } from './consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { ChangeSectionInfoAction, UpdateSectionInfo } from '../types';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';

export function* updateSectionInfo(action: ChangeSectionInfoAction) {
	const { id, info, moderated } = action.payload;
	const { surveyId, surveys } = yield select((s) => s.surveyBuilder);
	const questionnaireId = surveys[surveyId]?.questionnaireId;

	try {
		let updates: UpdateSectionInfo = {
			id: id,
			metaData: info,
		};
		const res = yield axios.patch(
			getApiEndPointUrl(`${surveySectionOptionAssetsApiUrl}?questionaireId=${questionnaireId}`),
			updates
		);
		const responseOfData = getQuestionsResponse(res?.data?.surveys?.[0]?.questions);
		yield putEffect(updateInfo(id, responseOfData, moderated));
		yield putEffect(setPopup(''));
		yield putEffect(fulfill(updateInfo, { payload: { ...action.payload, responseOfData }, responseOfData }));
	} catch (e) {
		yield putEffect(reject(updateInfo, getApiError(e)));
		yield putEffect(setPopup(getApiError(e)));
	}
}
