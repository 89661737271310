import { ReduxAction } from '../../../../shared/types/ReduxAction';

export const ADD_COLUMN = 'ADD_COLUMN';
export const DELETE_COLUMN = 'DELETE_COLUMN';
export const MOVE_COLUMN = 'MOVE_COLUMN';
export const EDIT_COLUMN_TEXT = 'EDIT_COLUMN_TEXT';
export const SET_COLUMN_URL = 'SET_COLUMN_URL';
export const ADD_ROW = 'ADD_ROW';
export const DELETE_ROW = 'DELETE_ROW';
export const MOVE_ROW = 'MOVE_ROW';
export const EDIT_ROW_TEXT = 'EDIT_ROW_TEXT';

export type AddColumn = ReduxAction<typeof ADD_COLUMN, { quesId: string; url: string }>;
export type DeleteColumn = ReduxAction<typeof DELETE_COLUMN, { quesId: string; columnId: string }>;
export type MoveColumn = ReduxAction<typeof MOVE_COLUMN, { quesId: string; srcIndex: number; destIndex: number }>;
export type EditColumnText = ReduxAction<typeof EDIT_COLUMN_TEXT, { quesId: string; columnId: string; text: string }>;
export type SetColumnUrl = ReduxAction<typeof SET_COLUMN_URL, { quesId: string; columnId: string; url: string }>;
export type AddRow = ReduxAction<typeof ADD_ROW, { quesId: string; columnId: string }>;
export type DeleteRow = ReduxAction<typeof DELETE_ROW, { quesId: string; columnId: string; rowId: string }>;
export type MoveRow = ReduxAction<
	typeof MOVE_ROW,
	{ quesId: string; columnId: string; srcIndex: number; destIndex: number }
>;
export type EditRowText = ReduxAction<
	typeof EDIT_ROW_TEXT,
	{ quesId: string; columnId: string; rowId: string; text: string }
>;

export type MatrixAction =
	| AddColumn
	| DeleteColumn
	| MoveColumn
	| EditColumnText
	| SetColumnUrl
	| AddRow
	| DeleteRow
	| MoveRow
	| EditRowText;

export type MatrixActionType =
	| typeof ADD_COLUMN
	| typeof DELETE_COLUMN
	| typeof MOVE_COLUMN
	| typeof EDIT_COLUMN_TEXT
	| typeof SET_COLUMN_URL
	| typeof ADD_ROW
	| typeof DELETE_ROW
	| typeof MOVE_ROW
	| typeof EDIT_ROW_TEXT;
