import {
	BrandDetailReducer,
	BrandDetailAction,
	SET_CURRENT_BRAND,
	CREATE_BRAND_FULFILLED,
	READ_BRAND_FULFILLED,
	READ_BRANDS_FULFILLED,
	UPDATE_BRAND_FULFILLED,
	DELETE_BRAND_FULFILLED,
} from './types';

export const brandDetailReducer: BrandDetailReducer = (state, action: BrandDetailAction) => {
	switch (action.type) {
		case SET_CURRENT_BRAND:
			return {
				...state,
				current: action.payload,
			};
		case CREATE_BRAND_FULFILLED:
			return {
				...state,
				brands: [...state.brands, action.payload],
			};
		case READ_BRAND_FULFILLED:
			return {
				...state,
				brands: [action.payload],
			};
		case READ_BRANDS_FULFILLED:
			return {
				...state,
				brands: action.payload,
			};
		case UPDATE_BRAND_FULFILLED:
			let brands = state.brands.map((brand) => (brand.id === action.payload.id ? action.payload : brand));
			return {
				...state,
				brands,
			};
		case DELETE_BRAND_FULFILLED:
			return {
				...state,
				brands: state.brands.filter((brand) => brand.id !== action.payload),
				brand: null,
			};

		default:
			return state;
	}
};
