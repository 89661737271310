import {
	ReadWorkspaceTeam,
	ReadWorkspaceTeamFulfilled,
	ReadWorkspaceTeamFulfilledPayload,
	ReadWorkspaceTeamPayload,
	READ_WORKSPACE_TEAM,
	READ_WORKSPACE_TEAM_FULFILLED,
} from './types';

export const readWorkspaceTeam = (payload: ReadWorkspaceTeamPayload): ReadWorkspaceTeam => ({
	type: READ_WORKSPACE_TEAM,
	payload,
});

export const readWorkspaceTeamFulfilled = (payload: ReadWorkspaceTeamFulfilledPayload): ReadWorkspaceTeamFulfilled => ({
	type: READ_WORKSPACE_TEAM_FULFILLED,
	payload,
});
