import axios from '@utils/axios';
import { call } from 'redux-saga/effects';
import { environment } from '../../../environment';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import { getMessagesActionSuccess, postMessageAction, postMessageActionSuccess, setApprovalError } from '../actions';
import { messagesEndPoint } from '../consts';
import { GetMessagesAction, MessageRepsonse, PostMessageAction } from '../types';
import { mapResponseToComments } from '../utils';

const getMessagesRequest = (id) => () => {
	return axios.get(`${environment.apiUrl}${messagesEndPoint}?questionnaireId=${id}`);
};

const postMessageRequest = (payload) => () => {
	return axios.post(`${environment.apiUrl}${messagesEndPoint}`, {
		...payload,
		surveyDomain: window.location.origin,
	});
};

export function* getMessagesSaga({ payload }: GetMessagesAction) {
	try {
		const messages = yield call(getMessagesRequest(payload));
		yield putEffect(getMessagesActionSuccess(messages.data.map((m: MessageRepsonse) => mapResponseToComments(m))));
	} catch (e) {
		yield putEffect(setApprovalError(getApiError(e)));
	}
}

export function* postMessageSaga({ payload }: PostMessageAction) {
	try {
		yield putEffect(pend(postMessageAction));
		const messages = yield call(postMessageRequest(payload));
		const result = mapResponseToComments(messages.data);
		yield putEffect(postMessageActionSuccess(result));
		yield putEffect(fulfill(postMessageAction, result));
	} catch (e) {
		yield putEffect(setApprovalError(getApiError(e)));
		yield putEffect(reject(postMessageAction, getApiError(e)));
	}
}
