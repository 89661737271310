import { ReduxAction } from '../../../../shared/types/ReduxAction';
import { MatrixMatchColumn, QuesState, QuesType } from '../../../../shared/types/QuesTypes';
import { DisplayLogic, MandateLogic, QuestionPipe, ShowLogic, SkipLogic } from '../../../../shared/types/QuestionLogic';
import { NormalSectionSettings, QuestionSettingsUnion } from '../../../../shared/types/QuestionSettings';
import { QuestionsStates, SectionsInSurvey } from 'shared/types/SurveyResponses';

export const ADD_QUES = 'ADD_QUES';
export const ADD_QUES_AT_INDEX = 'ADD_QUES_AT_INDEX';
export const ADD_BULK_QUES = 'ADD_BULK_QUES';
export const SELECT_QUES = 'SELECT_QUES';
export const COPY_QUES = 'COPY_QUES';
export const DELETE_QUES = 'DELETE_QUES';
export const APPROVE_EDIT = 'APPROVE_EDIT';
export const MOVE_QUES = 'MOVE_QUES';
export const SET_SKIP_LOGIC = 'ADD_SKIP_LOGIC';
export const REMOVE_SKIP_LOGIC = 'REMOVE_SKIP_LOGIC';
export const ADD_QUESTION_PIPE = 'ADD_QUESTION_PIPE';
export const REMOVE_QUESTION_PIPE = 'REMOVE_QUESTION_PIPE';
export const SET_DISPLAY_LOGIC = 'SET_DISPLAY_LOGIC';
export const SET_MANDATE_LOGIC = 'SET_MANDATE_LOGIC';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_SECTION_QUESTION = 'SET_SECTION_QUESTION';
export const SET_POPUP = 'SET_POPUP';
export const INSERT_QUESTION = 'INSERT_QUESTION';
export const INSERT_MODERATED_QUESTION = 'INSERT_MODERATED_QUESTION';
export const INSERT_QUESTIONS = 'INSERT_QUESTIONS';
export const CHANGE_QUESTION_SETTINGS = 'CHANGE_QUESTION_SETTINGS';
export const CHANGE_QUESTION_INFO = 'CHANGE_QUESTION_INFO';
export const CREATE_NEW_SECTION_TO_SURVEY = 'CREATE_NEW_SECTION_TO_SURVEY';
export const ADDED_SECTION_SUCCESS = 'ADDED_SECTION_SUCCESS';
export const DELETE_SURVEY_SECTION = 'DELETE_SURVEY_SECTION';
export const COPY_SURVEY_SECTION = 'COPY_SURVEY_SECTION';
export const CHANGE_SECTION_INFO = 'CHANGE_SECTION_INFO';
export const SET_SECTION = 'SET_SECTION';
export const UPDATE_INFO = 'UPDATE_INFO';
export const CHANGE_SURVEY_SECTION_SETTINGS = 'CHANGE_SURVEY_SECTION_SETTINGS';
// action types

// type definitions of payloads
export type MoveQuesPayload = {
	src: any;
	dest: any;
	type: string;
};

export type EditQuesTextPayload = {
	id: string;
	text: string;
};

// type definitions of actions
export type AddQues = ReduxAction<
	typeof ADD_QUES,
	{ quesType: QuesType; parentQuestionId?: string; moderated?: boolean; questionnaireSectionId?: string }
>;
export type AddSection = ReduxAction<typeof CREATE_NEW_SECTION_TO_SURVEY, { sectionId?: string; moderated?: boolean }>;
export type InsertSection = ReduxAction<typeof ADDED_SECTION_SUCCESS, { section: any; index: number }>;
export type DeleteSurveySection = ReduxAction<
	typeof DELETE_SURVEY_SECTION,
	{ sectionId: string; moderated?: boolean; foundQuestion?: any }
>;
export type CopySurveySection = ReduxAction<typeof COPY_SURVEY_SECTION, { sectionId: string; moderated?: boolean }>;

export type AddQuesAtIndex = ReduxAction<
	typeof ADD_QUES_AT_INDEX,
	{ quesType: QuesType; index: number; orderPosition: number; questionnaireSectionId?: string }
>;

export type AddBulkQues = ReduxAction<typeof ADD_BULK_QUES, { questions: any }>;
export type SelectQues = ReduxAction<
	typeof SELECT_QUES,
	{ quesId: string; shouldScrollIntoView?: boolean; moderated?: boolean }
>;
export type CopyQues = ReduxAction<typeof COPY_QUES, { quesId: string; moderated?: boolean }>;
export type DeleteQues = ReduxAction<typeof DELETE_QUES, { quesId: string; moderated?: boolean; foundQuestion?: any }>;
export type ApproveEdit = ReduxAction<typeof APPROVE_EDIT, { surveyId: string }>;
export type MoveQues = ReduxAction<typeof MOVE_QUES, MoveQuesPayload>;
export type SetSkipLogic = ReduxAction<
	typeof SET_SKIP_LOGIC,
	{
		id: string;
		logic: SkipLogic[];
		type: string;
	}
>;

export type RemoveSkipLogic = ReduxAction<
	typeof REMOVE_SKIP_LOGIC,
	{
		id: string;
		skipQuestionId: string;
	}
>;

export type AddQuestionPipe = ReduxAction<
	typeof ADD_QUESTION_PIPE,
	{
		id: string;
		pipe: QuestionPipe;
	}
>;

export type RemoveQuestionPipe = ReduxAction<
	typeof REMOVE_QUESTION_PIPE,
	{
		id: string;
		pipeId: string;
		matrixId?: any;
	}
>;

export type SetDisplayLogic = ReduxAction<
	typeof SET_DISPLAY_LOGIC,
	{
		id: string;
		logic: ShowLogic[];
		type: string;
	}
>;
export type SetMandateLogic = ReduxAction<
	typeof SET_MANDATE_LOGIC,
	{
		id: string;
		mandate: MandateLogic;
	}
>;

export type SetQuestion = ReduxAction<
	typeof SET_QUESTION,
	{
		id: string;
		state: QuesState;
		notConfirmed?: boolean; // indicates a FE change without API confirmation
		moderated?: boolean;
	}
>;

export type InsertQuestion = ReduxAction<typeof INSERT_QUESTION, { question: QuesState; index: number }>;
export type InsertModeratedQuestion = ReduxAction<
	typeof INSERT_MODERATED_QUESTION,
	{ question: QuesState; index: number }
>;
export type InsertQuestions = ReduxAction<typeof INSERT_QUESTIONS, { questions: QuesState[]; moderated?: boolean }>;

export type SetPopup = ReduxAction<typeof SET_POPUP, { message: string }>;

export type ChangeQuestionSettingsPayload = {
	id: string;
	type: QuesType;
	settings: Partial<QuestionSettingsUnion>;
	moderated?: boolean;
};
export type ChangeQuestionSettingsAction = ReduxAction<typeof CHANGE_QUESTION_SETTINGS, ChangeQuestionSettingsPayload>;

export type ChangeSectionSettingsDisplayPayload = {
	id: string;
	settings: Partial<QuestionSettingsUnion>;
	display: DisplayLogic;
	moderated?: boolean;
};

export type ChangeSectionSettingsDisplayAction = ReduxAction<
	typeof CHANGE_SURVEY_SECTION_SETTINGS,
	ChangeSectionSettingsDisplayPayload
>;

export type QuestionInfo = {
	text: string;
	hasDescription: boolean;
	description: string;
	column?: MatrixMatchColumn[];
	creativeUrl?: string;
	columnSelected?: number;
	pageBreak?: boolean;
	firstQuestionInPageId?: string;
};
export type UpdateQuestionInfo = {
	questionId: string;
	questionType: string;
	content: any;
	pageBreak?: string | boolean;
	// questionnaireId: string;
};
export type ChangeQuestionInfoPayload = {
	id: string;
	type: QuesType;
	info: Partial<QuestionInfo>;
	moderated?: boolean;
	sectionId?: string | null;
};
export type ChangeQuestionInfoAction = ReduxAction<typeof CHANGE_QUESTION_INFO, ChangeQuestionInfoPayload>;

export type SectionInfo = {
	name: string;
	hasDescription: boolean;
	description: string;
};

export type ChangeSectionInfoPayload = {
	id: string;
	info: SectionInfo;
	moderated?: boolean;
};

export type UpdateSectionInfo = {
	id: string;
	metaData: any;
};

export type ChangeSectionInfoAction = ReduxAction<typeof CHANGE_SECTION_INFO, ChangeSectionInfoPayload>;

export type SetSection = ReduxAction<
	typeof SET_SECTION,
	{
		id: string;
		state: SectionInfo;
		notConfirmed?: boolean;
		moderated?: boolean;
	}
>;

export type SetSectionQuestion = ReduxAction<
	typeof SET_SECTION_QUESTION,
	{
		id: string;
		updatedResponse: any;
		moderated?: boolean;
	}
>;

export type UpdateInfo = ReduxAction<
	typeof UPDATE_INFO,
	{
		currentSelectedId: string;
		response: SectionsInSurvey<QuestionsStates>;
		moderated?: boolean;
	}
>;
