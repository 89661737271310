const timeFormat = {
	hour: '2-digit',
	minute: '2-digit',
	day: 'numeric',
	month: 'short',
	year: 'numeric',
} as Intl.DateTimeFormatOptions;
const defaultLocale = 'en-US';

export const getFormattedTime = (dateString?: string | null) =>
	dateString ? new Date(dateString).toLocaleTimeString(defaultLocale, timeFormat) : 'Not Available';

const dateFormat = {
	day: 'numeric',
	month: 'short',
} as Intl.DateTimeFormatOptions;

export const getFormattedDate = (dateString: string | null) =>
	dateString
		? new Date(dateString)
				.toLocaleTimeString(defaultLocale, dateFormat)
				.split(', ')
				.filter(Boolean)[0]
				.split(' ')
				.reverse()
				.join(' ')
		: 'Not Available';

export const getDateDDMMYY = (dateString: string | null) => {
	if (!dateString) return 'Not Available';
	const date = new Date(dateString);

	const year = date.getFullYear();

	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return day + '/' + month + '/' + year;
};

export const getDateInCustomFormat = (date, formator) => {
	const startingDate = new Date(date);
	const [month, day, year] = [startingDate.getMonth(), startingDate.getDate(), startingDate.getFullYear()];
	return `${year}${formator}${month + 1}${formator}${day}`;
};

// Function to get the suffix for the day
function getDaySuffix(day: number) {
	if (day >= 11 && day <= 13) {
		return 'th';
	}
	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
}

function formatTime(hours, minutes) {
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; // convert 0 to 12 for 12 AM
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const getFormattedDateForExportImportSurvey = (dateString?: string | null, getTime?: boolean) => {
	if (!dateString) return '';

	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.toLocaleString('default', { month: 'short' });
	const year = date.getFullYear();

	function getTimeString(dateString: string): string {
		const date = new Date(dateString);
		const hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, '0');
		return `${formattedHours}:${minutes} ${ampm}`;
	}

	const daySuffix = getDaySuffix(day);
	const time = getTimeString(new Date(dateString).toLocaleTimeString(defaultLocale, timeFormat));

	return `${day}${daySuffix} ${month}, ${year} [${time}]`;
};

export const getFormattedCurrentDateTime = () => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const date = new Date();
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const formattedTime = formatTime(hours, minutes);

	const suffix = getDaySuffix(day);
	return `${day}${suffix} ${month}, ${year} [${formattedTime}]`;
};
