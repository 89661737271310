import { SurveyBuilderState } from '../../shared/types';
import { select } from 'redux-saga/effects';
import { StoreState } from '../../../../index';
import { getCleanupActions } from '../../utils';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { selectQues, setPopup } from '../actions';
import { DeleteQues } from '../types';
import axios from '@utils/axios';
import { surveyQuestionsApiUrl } from './consts';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getOnePreviousQuestioId } from './utils';

export function* handleRemove(action: DeleteQues) {
	const { quesId, moderated, foundQuestion } = action.payload;
	if (!!moderated) {
		//NOTE: moderated has SCREENING type only w/o piping support
		//If more types and dependency is to be added, follow the else block
		try {
			yield axios.delete(getApiEndPointUrl(surveyQuestionsApiUrl, quesId));
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		const { questions, quesOrder }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
		const affected = quesOrder.map((id) => questions[id]);
		const actions = getCleanupActions(quesId, affected, questions);
		try {
			const newSelectQuesId = getOnePreviousQuestioId(foundQuestion, questions);
			yield axios.delete(getApiEndPointUrl(surveyQuestionsApiUrl, quesId));
			// yield putEffect(selectQues(newSelectQuesId, true));
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
		for (const action of actions) {
			yield putEffect(action);
		}
		if (actions.length) {
			yield putEffect(setPopup('Question piping has been modified after deleting the question.'));
		}
	}
}
