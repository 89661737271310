import {
	ReadCohortDeepDiveStagesPayload,
	ReadCohortDeepDiveStages,
	READ_COHORT_DEEP_DIVE_STAGES,
	ReadCohortDeepDiveStagesFulfilled,
	READ_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	CohortDeepDiveStages,
	UpdateCohortDeepDiveStages,
	UpdateCohortDeepDiveStagesFulfilled,
	UpdateCohortDeepDiveStagesPayload,
	UPDATE_COHORT_DEEP_DIVE_STAGES,
	UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED,
} from './types';

export const readCohortDeepDiveStages = (payload: ReadCohortDeepDiveStagesPayload): ReadCohortDeepDiveStages => ({
	type: READ_COHORT_DEEP_DIVE_STAGES,
	payload,
});

export const readCohortDeepDiveStagesFulfilled = (
	payload: CohortDeepDiveStages
): ReadCohortDeepDiveStagesFulfilled => ({
	type: READ_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	payload: payload,
});

export const updateCohortDeepDiveStages = (payload: UpdateCohortDeepDiveStagesPayload): UpdateCohortDeepDiveStages => ({
	type: UPDATE_COHORT_DEEP_DIVE_STAGES,
	payload,
});

export const updateCohortDeepDiveStagesFulfilled = (
	payload: CohortDeepDiveStages
): UpdateCohortDeepDiveStagesFulfilled => ({
	type: UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	payload,
});
