import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	createMacroFulfilled,
	updateMacroFulfilled,
	readMacrosFulfilled,
	createMacro,
	updateMacro,
	readMacros,
} from './actions';
import { macroTargetingGroupdEndpoint } from './const';
import {
	CreateMacroPayload,
	CreateMacro,
	CREATE_MACRO,
	ReadMacros,
	READ_MACROS,
	UpdateMacro,
	UpdateMacroPayload,
	UPDATE_MACRO,
	MacroSchema,
} from './types';

function createMacroAPI(macro: CreateMacroPayload) {
	return axios.post(getApiEndPointUrl(macroTargetingGroupdEndpoint), macro);
}
export function updateMacroAPI({ id, ...macro }: UpdateMacroPayload) {
	return axios.patch(getApiEndPointUrl(macroTargetingGroupdEndpoint, id), macro);
}
function readMacrosAPI(id: string) {
	return axios.get(getApiEndPointUrl(macroTargetingGroupdEndpoint, id));
}

function* createMacroSaga(action: CreateMacro) {
	try {
		yield putEffect(pend(createMacro));
		const res = yield createMacroAPI(action.payload);
		yield putEffect(createMacroFulfilled(validate(res.data, MacroSchema)));
		yield putEffect(fulfill(createMacro));
	} catch (e) {
		yield putEffect(reject(createMacro, getApiError(e)));
	}
}
function* updateMacroSaga(action: UpdateMacro) {
	try {
		yield putEffect(pend(updateMacro));
		const res = yield updateMacroAPI(action.payload);
		yield putEffect(updateMacroFulfilled(validate(res.data, MacroSchema)));
		yield putEffect(fulfill(updateMacro));
	} catch (e) {
		yield putEffect(reject(updateMacro, getApiError(e)));
	}
}
function* readMacrosSaga(action: ReadMacros) {
	try {
		yield putEffect(pend(readMacros));
		if (action.payload.id) {
			const res = yield readMacrosAPI(action.payload.id);
			// backend changed to one reponse but old code expect array hence manual addition
			yield putEffect(readMacrosFulfilled([validate(res.data, MacroSchema)]));
		} else yield putEffect(readMacrosFulfilled([]));

		yield putEffect(fulfill(readMacros));
	} catch (e) {
		yield putEffect(reject(readMacros, getApiError(e)));
	}
}

function* getCreateMacroWatcher() {
	yield takeLeading(CREATE_MACRO, createMacroSaga);
}
function* getReadMacrosWatcher() {
	yield takeLeading(READ_MACROS, readMacrosSaga);
}
function* getUpdateMacroWatcher() {
	yield takeLeading(UPDATE_MACRO, updateMacroSaga);
}

export const macroSagas = [getCreateMacroWatcher, getReadMacrosWatcher, getUpdateMacroWatcher];
