import { gameTemplate, standardTemplate } from '../consts/surveyDesign';
import { gameTemplateType } from '../types/gameTemplate';

export const isGameSelected = (game: string) => {
	return gameTemplate.find((g) => g.name === game) ? true : false;
};

export const finalTemplate = (game: string): gameTemplateType[] => {
	let gameSelected = gameTemplate.find((g) => g.name === game) ? true : false,
		copyGames: gameTemplateType[],
		gameIndex;

	if (gameSelected) {
		copyGames = gameTemplate.map((g, i) => {
			if (game === g.name) gameIndex = i;
			return { ...g, isActive: false };
		});
		copyGames[gameIndex] && (copyGames[gameIndex].isActive = true);
	} else {
		copyGames = standardTemplate.map((g, i) => {
			if (game === g.name) gameIndex = i;
			return { ...g, isActive: false };
		});
		copyGames[gameIndex] && (copyGames[gameIndex].isActive = true);
	}

	return copyGames;
};
