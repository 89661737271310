import { createSurveySaga } from './sagas/createSurvey';
import { updateSurvey } from './sagas/updateSurvey';
import { updateSurveyGames } from './sagas/updateSurveyGame';
import { takeLeading } from 'redux-saga/effects';
import {
	CREATE_MODERATE_SLOT,
	CREATE_SURVEY,
	CREATE_SURVEY_FROM_RESEARCH,
	GET_PUBLIC_SURVEY,
	GET_SURVEY,
	SCHEDULE_SURVEY,
	UPDATE_GLOBAL_SETTINGS,
	UPDATE_SURVEY,
	UPDATE_SURVEY_DATA,
	UPDATE_SURVEY_DESIGN_THEME,
	UPDATE_SURVEY_RESEARCH,
} from './types';
import { getPublicSurvey, getSurvey } from './sagas/getSurvey';
import { createSurveyFromResearch } from './sagas/createSurveyFromResearch';
import { updateGlobalSettings } from './sagas/updateGlobalSettings';
import { scheduleSurvey } from './sagas/scheduleSurvey';
import { updateSurveyResearchSaga } from './sagas/updateSurveyResearch';
import { createModerateSlot } from './sagas/createModerateSlot';
import { updateSurveyDesignThemeSaga } from './sagas/updateSurveyDesignTheme';

function* createSurveyWatcher() {
	yield takeLeading(CREATE_SURVEY, createSurveySaga);
}

function* updateSurveyWatcher() {
	yield takeLeading(UPDATE_SURVEY, updateSurvey);
}

function* updateSurveyResearchWatcher() {
	yield takeLeading(UPDATE_SURVEY_RESEARCH, updateSurveyResearchSaga);
}

function* updateSurveyDesignThemeWatcher() {
	yield takeLeading(UPDATE_SURVEY_DESIGN_THEME, updateSurveyDesignThemeSaga);
}

function* updateSurveyGamesWatcher() {
	yield takeLeading(UPDATE_SURVEY_DATA, updateSurveyGames);
}

function* getSurveyWatcher() {
	yield takeLeading(GET_SURVEY, getSurvey);
}
function* getPublicSurveyWatcher() {
	yield takeLeading(GET_PUBLIC_SURVEY, getPublicSurvey);
}

function* createSurveyFromResearchWatcher() {
	yield takeLeading(CREATE_SURVEY_FROM_RESEARCH, createSurveyFromResearch);
}

function* updateGlobalSettingsWatcher() {
	yield takeLeading(UPDATE_GLOBAL_SETTINGS, updateGlobalSettings);
}

function* createModerateSlotWatcher() {
	yield takeLeading(CREATE_MODERATE_SLOT, createModerateSlot);
}

function* scheduleSurveyWatcher() {
	yield takeLeading(SCHEDULE_SURVEY, scheduleSurvey);
}

export const surveyInfoWatchers = [
	createSurveyWatcher,
	updateSurveyWatcher,
	updateSurveyGamesWatcher,
	getSurveyWatcher,
	createSurveyFromResearchWatcher,
	updateGlobalSettingsWatcher,
	createModerateSlotWatcher,
	scheduleSurveyWatcher,
	updateSurveyResearchWatcher,
	updateSurveyDesignThemeWatcher,
	getPublicSurveyWatcher,
];
