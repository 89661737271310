import {
	CreateWorkspaceActionPayload,
	CREATE_WORKSPACE,
	SET_WORKSPACE,
	SET_CURRENT_WORKSPACE,
	SetWorkspaceActionPayload,
	SetWorkspaceAction,
	CreateWorkspaceAction,
	SET_WORKSPACE_ERROR,
	SetWorkspaceErrorAction,
	SetCurrentWorkspaceActionPayload,
	SetCurrentWorkspaceAction,
	GetWorkspaceAction,
	GET_WORKSPACES_ACTION,
	SetWorkspacesWithIdAction,
	SetWorkspacesWithIdPayload,
	SET_WORKSPACES_WITH_ID,
} from './types';

export const createWorkspaces: (workspaces: CreateWorkspaceActionPayload) => CreateWorkspaceAction = (
	payload: CreateWorkspaceActionPayload
) => ({
	type: CREATE_WORKSPACE,
	payload,
});

export const setWorkspaceAction: (workspaces: SetWorkspaceActionPayload) => SetWorkspaceAction = (
	payload: SetWorkspaceActionPayload
) => ({
	type: SET_WORKSPACE,
	payload: payload,
});

export const setCurrentWorkspaceAction: (workspace: SetCurrentWorkspaceActionPayload) => SetCurrentWorkspaceAction = (
	payload: SetCurrentWorkspaceActionPayload
) => ({
	type: SET_CURRENT_WORKSPACE,
	payload: payload,
});

export const setWorkspaceError: (payload: string | null) => SetWorkspaceErrorAction = (payload) => ({
	type: SET_WORKSPACE_ERROR,
	payload,
});

export const getWorkspacesAction: () => GetWorkspaceAction = () => ({
	type: GET_WORKSPACES_ACTION,
	payload: null,
});
export const setWorkspacesWithIdAction: (payload: SetWorkspacesWithIdPayload) => SetWorkspacesWithIdAction = (
	payload
) => ({
	type: SET_WORKSPACES_WITH_ID,
	payload,
});
