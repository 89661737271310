import { FC } from 'react';
import { QUES_TYPE } from '../consts/quesType';
import { MandateLogic, QuestionLogic, QuestionPipe } from './QuestionLogic';
import {
	CreativeQuestionSettings,
	LinearRangeSettings,
	MatricMatchSettings,
	MatrixQuestionSettings,
	MultipleChoiceQuestionSettings,
	MultipleOpinionScaleSettings,
	NumberQuestionSettings,
	TextBoxQuestionSettings,
	OpinionScaleQuestionSettings,
	QuestionSettings,
	RatingQuestionSettings,
	TaskQuestionSettings,
	VideoUploadQuestionSettings,
	WelcomeScreenQuestionSettings,
	ScreenerSettings,
	ImageQuesSettings,
	ModeratedQuestionSettings,
	EmailQuesSettings,
	ThankYouSettings,
	RankingQuestionSettings,
} from './QuestionSettings';

export type QuesType =
	| typeof QUES_TYPE.WELCOME_SCREEN
	| typeof QUES_TYPE.OPINION_SCALE_QUES
	| typeof QUES_TYPE.MULTIPLE_CHOICE_QUES
	| typeof QUES_TYPE.NUMBER_QUES
	| typeof QUES_TYPE.TEXT_BOX_QUES
	| typeof QUES_TYPE.RATING_QUES
	| typeof QUES_TYPE.YES_NO_QUES
	| typeof QUES_TYPE.CREATIVE_QUES
	| typeof QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES
	| typeof QUES_TYPE.MATRIX_QUES
	| typeof QUES_TYPE.LINEAR_RANGE_QUES
	| typeof QUES_TYPE.TASK_QUES
	| typeof QUES_TYPE.RANKING_QUES
	| typeof QUES_TYPE.VIDEO_UPLOAD_QUES
	| typeof QUES_TYPE.SCREENER_QUES
	| typeof QUES_TYPE.MATRIX_MATCH_QUES
	| typeof QUES_TYPE.IMAGE_QUES
	| typeof QUES_TYPE.MODERATED_TIMEZONE
	| typeof QUES_TYPE.MODERATED_SLOTS
	| typeof QUES_TYPE.MODERATED_DETAILS
	| typeof QUES_TYPE.EMAIL_QUES
	| typeof QUES_TYPE.THANK_YOU
	| typeof QUES_TYPE.QUESTION_SECTION;

export interface BaseQuesState<T = QuestionSettings> {
	id: string;
	text: string;
	hasDescription: boolean;
	description: string;
	logic: QuestionLogic;
	mandate: MandateLogic;
	orderPosition: number;
	settings: T;
	type: QuesType;
	parentQuestionId: string | null;
	firstQuestionInPageId: string | null;
	pageBreak: boolean;
	questionnaireSectionId: string | null;
}

export type WelcomeScreenState = BaseQuesState & {
	creativeUrl?: string;
	type: typeof QUES_TYPE.WELCOME_SCREEN;
	settings: WelcomeScreenQuestionSettings;
};

export type OpinionScaleQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.OPINION_SCALE_QUES;
	settings: OpinionScaleQuestionSettings;
};

export type TaskQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.TASK_QUES;
	settings: TaskQuestionSettings;
};

export type VideoUploadQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.VIDEO_UPLOAD_QUES;
	settings: VideoUploadQuestionSettings;
};

export type MatrixMatchColumn = { text: string; orderPosition: number; creativeUrl?: string; optionType?: OptionType };
export type MatrixMatchQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.MATRIX_MATCH_QUES;
	settings: MatricMatchSettings;
	column: MatrixMatchColumn[];
	columnOptions: any[];
	options: {
		[rowId: string]: MultipleChoiceOption;
	};
	creativeUrl?: string;
};

export type ScreenerQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.SCREENER_QUES;
	settings: ScreenerSettings;
	options: {
		[optionId: string]: MultipleChoiceOption;
	};
	moderated?: boolean;
	showAddMoreQuestions?: boolean;
	index?: number;
};

export type ModeratedQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.MODERATED_TIMEZONE;
	settings: ModeratedQuestionSettings;
};
export type ModeratedSlotSelectionState = BaseQuesState & {
	type: typeof QUES_TYPE.MODERATED_SLOTS;
	settings: ModeratedQuestionSettings;
};

export type ModeratedSlotDetailsState = BaseQuesState & {
	type: typeof QUES_TYPE.MODERATED_DETAILS;
	settings: ModeratedQuestionSettings;
};

export enum OptionType {
	Normal = 'normal',
	Pipe = 'pipe',
	None = 'none',
	Other = 'other',
	Competitor = 'competitor',
	Product = 'product',
	Keyword = 'keyword',
	Asset = 'asset',
	Response = 'response',
}

export enum PipeValue {
	ALL = 'All',
	SELECTED = 'Selected',
	UNSELECTED = 'Unselected',
	SINGLE = 'Single',
	REFERENCE = 'Reference',
}

export enum SingleAnswerMethodologyType {
	ACCEPT = 'Accept',
	REJECT = 'Reject',
}

export enum MultipleAnswerMethodologyType {
	MUST_SELECT = 'Must Select',
	MAY_SELECT = 'May Select',
	SCREEN_OUT = 'Screen Out',
}

export type MethodologyType = SingleAnswerMethodologyType | MultipleAnswerMethodologyType;

export type MultipleChoiceOption = {
	id: string;
	value: string;
	pipe: QuestionPipe | undefined;
	matrixValue?: any;
	orderPosition: number;
	optionType: OptionType;
	/** used in screener question to make `screen in`/`screen out` decisions */
	methodology: MethodologyType;
	anchorOnRandomization?: boolean;
	parentOptionId?: string;
	groupAnchorOnRandomization?: boolean;
};

export type MultipleChoiceQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.MULTIPLE_CHOICE_QUES;
	options: {
		[optionId: string]: MultipleChoiceOption;
	};
	settings: MultipleChoiceQuestionSettings;
	creativeUrl?: string;
	columnOptions?: any;
	pageBreak: boolean;
};
export type RankingQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.RANKING_QUES;
	options: {
		[optionId: string]: MultipleChoiceOption;
	};
	settings: RankingQuestionSettings;
	creativeUrl?: string;
	columnOptions?: any;
	pageBreak: boolean;
};
export type NumberQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.NUMBER_QUES;
	settings: NumberQuestionSettings;
};

export type TextBoxQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.TEXT_BOX_QUES;
	settings: TextBoxQuestionSettings;
};

export type RatingQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.RATING_QUES;
	settings: RatingQuestionSettings;
};

export type YesNoQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.YES_NO_QUES;
	settings: QuestionSettings;
};

export type CreativeQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.CREATIVE_QUES;
	options: {
		[optionId: string]: {
			url: string;
			text: string;
			assetId?: string;
		} & MultipleChoiceOption;
	};
	settings: CreativeQuestionSettings;
};

export type StrokeState = { strokeId: string; text: string; orderPosition: number };

export type SectionState = {
	id: string;
	text: string;
	asset: {
		url: string;
		id: string;
	};
	orderPosition: number;
	strokeOrder: string[];
	strokes: {
		[strokeId: string]: StrokeState;
	};
};

export type MultipleOpinionScaleQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES;
	sections: {
		[sectionId: string]: SectionState;
	};
	settings: MultipleOpinionScaleSettings;
};

export type MatrixColumnState = {
	columnId: string;
	text: string;
	url: string;
	rows: {
		[rowId: string]: string;
	};
	rowOrder: string[];
};

export type MatrixQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.MATRIX_QUES;
	columns: {
		[columnId: string]: MatrixColumnState;
	};
	settings: MatrixQuestionSettings;
	creativeUrl?: string;
};

export type LinearRangeQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.LINEAR_RANGE_QUES;
	settings: LinearRangeSettings;
};

export type ImageQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.IMAGE_QUES;
	options: {
		[optionId: string]: {
			url: string;
			text: string;
			assetId?: string;
		} & MultipleChoiceOption;
	};
	settings: ImageQuesSettings;
};

export type EmailQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.EMAIL_QUES;
	settings: EmailQuesSettings;
};
export type ThankYouQuesState = BaseQuesState & {
	type: typeof QUES_TYPE.THANK_YOU;
	settings: ThankYouSettings;
};

export type GroupOption = {
	id: string;
	orderPosition: number;
	optionType: string;
	value: string;
	methodology: string;
	pipeType: string;
	anchorOnRandomization: boolean;
	parentOptionId?: string | null;
	groupAnchorOnRandomization: boolean;
	parentGroupId?: string;
};

export type Group = {
	id: string;
	optionType: string;
	value: string;
	anchorOnRandomization?: boolean;
	options: GroupOption[];
	orderPosition?: number;
};
export type QuesState =
	| WelcomeScreenState
	| OpinionScaleQuesState
	| MultipleChoiceQuesState
	| NumberQuesState
	| TextBoxQuesState
	| RatingQuesState
	| YesNoQuesState
	| CreativeQuesState
	| MultipleOpinionScaleQuesState
	| MatrixQuesState
	| RankingQuesState
	| LinearRangeQuesState
	| TaskQuesState
	| VideoUploadQuesState
	| MatrixMatchQuesState
	| ScreenerQuesState
	| ImageQuesState
	| ModeratedQuesState
	| ModeratedSlotSelectionState
	| ModeratedSlotDetailsState
	| EmailQuesState
	| ThankYouQuesState;

// for question lists
export type QuesMetadata = {
	name: string;
	type: QuesType | undefined;
	color: string;
	icon: FC<{ className?: string; style?: React.CSSProperties }>;
};
