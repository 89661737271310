type OptionAnswered = 'answered' | 'notAnswered';
type NumberCompareType = 'lt' | 'gt' | 'eq' | 'neq';
type OptionSelectedType = 'selected' | 'noSelected';
type YesNoType = 'is' | 'not';
export type OptionLogicType = OptionAnswered | NumberCompareType | OptionSelectedType | YesNoType;

export type PipeAnswerType = 'all' | 'selected' | 'unselected';
export type PipeTo = 'rows' | 'columns';

export enum LogicalOperator {
	And = 'and',
	Or = 'or',
}
export const optionAnsweredTypes: OptionAnswered[] = ['answered', 'notAnswered'];
export const numberBasedOptionTypes: (NumberCompareType | OptionAnswered)[] = [
	...optionAnsweredTypes,
	'lt',
	'gt',
	'eq',
	'neq',
];
export const optionSelectedTypes: (OptionSelectedType | OptionAnswered)[] = [
	...optionAnsweredTypes,
	'selected',
	'noSelected',
];
export const rankingOptionTypes: OptionAnswered[] = [...optionAnsweredTypes];
export const yesNoOptionsTypes: (YesNoType | OptionAnswered)[] = [...optionAnsweredTypes, 'is', 'not'];

type NumberBasedOptionLogic = {
	type?: NumberCompareType | OptionAnswered;
	value: number | undefined;
};
type RankingBasedOptionLogic = {
	type?: OptionAnswered;
	value: string | number | undefined;
};

type ChoiceBasedOptionLogic = {
	type?: OptionSelectedType | OptionAnswered;
	value: string | number | undefined;
};
type MatrixBasedOptionLogic = {
	type?: OptionSelectedType | OptionAnswered;
	value: string | number | undefined;
	colValue: string | number | undefined;
};

export enum QuestionLogicKind {
	Choice = 'multipleChoice',
	Number = 'number',
	YesNo = 'yesNo',
	Ranking = 'ranking',
	Matrix = 'matrix',
}

export type MultipleChoiceDisplayOptionLogic = {
	kind: QuestionLogicKind.Choice;
	option: ChoiceBasedOptionLogic;
};
export type MatrixDisplayOptionLogic = {
	kind: QuestionLogicKind.Matrix;
	basedOn: string | undefined;
	option: MatrixBasedOptionLogic;
};

export type YesNoDisplayOptionLogic = {
	kind: QuestionLogicKind.YesNo;
	option: {
		type?: YesNoType | OptionAnswered;
		value: string | boolean | undefined;
	};
};

export type NumberDisplayOptionLogic = {
	kind: QuestionLogicKind.Number;
	option: NumberBasedOptionLogic;
};
export type RankingDisplayOptionLogic = {
	kind: QuestionLogicKind.Ranking;
	option: RankingBasedOptionLogic;
};

export type DisplayOptionLogic =
	| MultipleChoiceDisplayOptionLogic
	| YesNoDisplayOptionLogic
	| NumberDisplayOptionLogic
	| RankingDisplayOptionLogic
	| MatrixDisplayOptionLogic;

export type SkipToType = 'question' | 'end' | 'link' | 'thankyou';

export type SkipTo = {
	type: SkipToType | undefined;
	value: string | undefined;
};

export type ShowLogic = {
	questionId: string;

	logic: LogicalOperator | undefined;
	optionLogic: DisplayOptionLogic;
};
export type MandateLogic = {
	type: string;
	options: {
		rowNumber: number;
		specificRow: string[];
		minRange: string;
		maxRange: string;
		logic: LogicalOperator | undefined;
	}[];
};
export type SkipLogic = {
	questionId: string;
	optionLogic: DisplayOptionLogic;
	action: SkipTo;
	logic: LogicalOperator | undefined;
};

export type DisplayLogic = {
	showIf: ShowLogic[];
	skipIf: SkipLogic[];
};

export type QuestionPipe = {
	pipeId: string;
	pipeAnswer: PipeAnswerType;
	pipeType?: PipeTo;
	matrixValue?: any;
	matrixText?: any;
};

export type QuestionLogic = {
	display: DisplayLogic;
	pipeAnswer?: QuestionPipe[];
};
