import { VideoRecordingsReducer, VideoRecordingsAction, READ_VIDEO_RECORDINGS_FULFILLED } from './types';

export const videoRecordingsReducer: VideoRecordingsReducer = (state, action: VideoRecordingsAction) => {
	switch (action.type) {
		case READ_VIDEO_RECORDINGS_FULFILLED:
			return {
				...state,
				videoRecordings: action.payload,
			};
		default:
			return state;
	}
};
