import axios from '@utils/axios';

import { call } from 'redux-saga/effects';
import { environment } from '../../../environment';
import { getApiError } from '../../../shared/utils/getApiError';
import { putEffect } from '../../../shared/utils/putEffect';
import { getQuestionnaireCountActionSuccess, setApprovalError } from '../actions';
import { questionnairesCountEndPoint } from '../consts';
import { ApprovalStatus } from '../enums';
import { GetQuestionnaireCountAction } from '../types';
import { mapQuestionnaireCountResponse } from './utils';

const questionnaireCountRequest = (braindId) => () => {
	return axios.get(
		`${environment.apiUrl}${questionnairesCountEndPoint}?brandId=${braindId}&status=${ApprovalStatus.InReview}`
	);
};

export function* questionnaireCountSaga({ payload }: GetQuestionnaireCountAction) {
	try {
		const { data } = yield call(questionnaireCountRequest(payload));
		yield putEffect(getQuestionnaireCountActionSuccess(mapQuestionnaireCountResponse(data)));
	} catch (e) {
		yield putEffect(setApprovalError(getApiError(e)));
	}
}
