type QuesTypeMirror = {
	WELCOME_SCREEN: 'WelcomeScreen';
	OPINION_SCALE_QUES: 'OpinionScale';
	MULTIPLE_CHOICE_QUES: 'MultipleChoice';
	NUMBER_QUES: 'NumberQues';
	TEXT_BOX_QUES: 'TextBox';
	RATING_QUES: 'RatingQues';
	YES_NO_QUES: 'YesNoQues';
	CREATIVE_QUES: 'Creative';
	RANKING_QUES: 'RankingQues';
	MULTIPLE_OPINION_SCALE_QUES: 'MultipleOpinionScale';
	MATRIX_QUES: 'Matrix_old';
	LINEAR_RANGE_QUES: 'LinearRange';
	TASK_QUES: 'Task';
	VIDEO_UPLOAD_QUES: 'VideoUpload';
	MATRIX_MATCH_QUES: 'Matrix';
	SCREENER_QUES: 'Screener';
	IMAGE_QUES: 'Image';
	MODERATED_TIMEZONE: 'ModeratedTimezone';
	MODERATED_SLOTS: 'ModeratedSlots';
	MODERATED_DETAILS: 'ModeratedDetails';
	EMAIL_QUES: 'Email';
	THANK_YOU: 'ThankYou';
	QUESTION_SECTION: 'Section';
};

export const QUES_TYPE: QuesTypeMirror = {
	WELCOME_SCREEN: 'WelcomeScreen',
	OPINION_SCALE_QUES: 'OpinionScale',
	MULTIPLE_CHOICE_QUES: 'MultipleChoice',
	NUMBER_QUES: 'NumberQues',
	TEXT_BOX_QUES: 'TextBox',
	RATING_QUES: 'RatingQues',
	RANKING_QUES: 'RankingQues',
	YES_NO_QUES: 'YesNoQues',
	CREATIVE_QUES: 'Creative',
	MULTIPLE_OPINION_SCALE_QUES: 'MultipleOpinionScale',
	MATRIX_QUES: 'Matrix_old',
	LINEAR_RANGE_QUES: 'LinearRange',
	TASK_QUES: 'Task',
	VIDEO_UPLOAD_QUES: 'VideoUpload',
	MATRIX_MATCH_QUES: 'Matrix',
	SCREENER_QUES: 'Screener',
	IMAGE_QUES: 'Image',
	MODERATED_TIMEZONE: 'ModeratedTimezone',
	MODERATED_SLOTS: 'ModeratedSlots',
	MODERATED_DETAILS: 'ModeratedDetails',
	EMAIL_QUES: 'Email',
	THANK_YOU: 'ThankYou',
	QUESTION_SECTION: 'Section',
};
