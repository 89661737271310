import { ReduxAction } from '../../../../shared/types/ReduxAction';

export const ADD_SECTION = 'ADD_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const MOVE_SECTION = 'MOVE_SECTION';
export const EDIT_SECTION_TEXT = 'EDIT_SECTION_TEXT';
export const SET_SECTION_IMAGE = 'SET_SECTION_IMAGE';
export const ADD_STROKE = 'ADD_STROKE';
export const EDIT_STROKE = 'EDIT_STROKE';
export const DELETE_STROKE = 'DELETE_STROKE';
export const MOVE_STROKE = 'MOVE_STROKE';

export type AddSection = ReduxAction<typeof ADD_SECTION, { quesId: string }>;
export type DeleteSection = ReduxAction<typeof DELETE_SECTION, { quesId: string; sectionId: string }>;
export type MoveSection = ReduxAction<typeof MOVE_SECTION, { quesId: string; srcIndex: number; destIndex: number }>;
export type EditSectionText = ReduxAction<
	typeof EDIT_SECTION_TEXT,
	{ quesId: string; sectionId: string; text: string }
>;
export type SetSectionImage = ReduxAction<typeof SET_SECTION_IMAGE, { quesId: string; sectionId: string; file: File }>;
export type AddStroke = ReduxAction<typeof ADD_STROKE, { quesId: string; sectionId: string }>;
export type EditStroke = ReduxAction<
	typeof EDIT_STROKE,
	{ quesId: string; sectionId: string; strokeId: string; text: string }
>;
export type DeleteStroke = ReduxAction<typeof DELETE_STROKE, { quesId: string; sectionId: string; strokeId: string }>;
export type MoveStroke = ReduxAction<
	typeof MOVE_STROKE,
	{ quesId: string; sectionId: string; srcIndex: number; destIndex: number }
>;

export type MultipleOpinionScaleAction =
	| AddSection
	| DeleteSection
	| MoveSection
	| EditSectionText
	| SetSectionImage
	| AddStroke
	| EditStroke
	| DeleteStroke
	| MoveStroke;

export type MultipleOpinionScaleActionType =
	| typeof ADD_SECTION
	| typeof DELETE_SECTION
	| typeof MOVE_SECTION
	| typeof EDIT_SECTION_TEXT
	| typeof SET_SECTION_IMAGE
	| typeof ADD_STROKE
	| typeof EDIT_STROKE
	| typeof DELETE_STROKE
	| typeof MOVE_STROKE;
