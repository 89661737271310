import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export type Macro = {
	id: string;
	country?: string;
	region?: string;
	city?: string;
	ageFrom?: number;
	ageTo?: number;
	gender?: string;
};
export const MacroSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		country: { type: 'string' },
		region: { anyOf: [{ type: 'string' }, { type: 'object', value: null }] },
		city: {
			anyOf: [{ type: 'string' }, { type: 'object', value: null }],
		},
		ageFrom: { type: 'number' },
		ageTo: { type: 'number' },
		gender: { type: 'string' },
	},
};
export const MacrosSchema: JSONSchema = {
	type: 'array',
	items: MacroSchema,
};
export const CREATE_MACRO = 'CREATE_MACRO';
export const CREATE_MACRO_FULFILLED = 'CREATE_MACRO_FULFILLED';

export type CreateMacroPayload = Omit<Macro, 'id'>;
export type CreateMacro = ReduxAction<typeof CREATE_MACRO, CreateMacroPayload>;
export type CreateMacroFulfilled = ReduxAction<typeof CREATE_MACRO_FULFILLED, Macro>;

export const UPDATE_MACRO = 'UPDATE_MACRO';
export const UPDATE_MACRO_FULFILLED = 'UPDATE_MACRO_FULFILLED';

export type UpdateMacroPayload = Omit<Macro, 'brandId'>;

export type UpdateMacro = ReduxAction<typeof UPDATE_MACRO, UpdateMacroPayload>;
export type UpdateMacroFulfilled = ReduxAction<typeof UPDATE_MACRO_FULFILLED, Macro>;

export const READ_MACROS = 'READ_MACROS';
export const READ_MACROS_FULFILLED = 'READ_MACROS_FULFILLED';

export type ReadMacrosPayload = { id?: string };

export type ReadMacros = ReduxAction<typeof READ_MACROS, ReadMacrosPayload>;
export type ReadMacrosFulfilled = ReduxAction<typeof READ_MACROS_FULFILLED, Macro[]>;

export type MacroAction =
	| CreateMacro
	| CreateMacroFulfilled
	| ReadMacros
	| ReadMacrosFulfilled
	| UpdateMacro
	| UpdateMacroFulfilled;

export type MacroReducer = ReduxReducer<BrandState, MacroAction>;
