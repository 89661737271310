import {
	CohortDeepDiveStageReducer,
	CohortDeepDiveStageAction,
	READ_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED,
} from './types';

export const cohortDeepDiveStagesReducer: CohortDeepDiveStageReducer = (state, action: CohortDeepDiveStageAction) => {
	switch (action.type) {
		case READ_COHORT_DEEP_DIVE_STAGES_FULFILLED:
		case UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED:
			return {
				...state,
				cohortDeepDiveStages: action.payload,
			};
		default:
			return state;
	}
};
