import {
	GET_QUESTIONNAIRE,
	QuestionnaireReducer,
	QuestionnaireState,
	READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED,
	READ_QUESTIONNAIRES_FULFILLED,
	SET_QUESTIONNAIRE,
	SET_QUESTIONNAIRE_ERROR,
	SUBMIT_SURVEY,
} from './types';
import { set, updateAll } from 'shades';
import { assign } from '../../shared/utils/assign';
import { map, prop, sortBy, values } from 'ramda';

const initialState: QuestionnaireState = {
	name: '',
	description: '',
	allowMultipleResponse: false,
	quesOrder: [],
	questions: {},
	requestInProgress: false,
	error: '',
	allowEditAndResubmit: true,
	usePyxisLogo: true,
	surveys: null,
	howToPlay: 'Footer',
	isRightOriented: true,
	gamified: false,
	countByStatus: {},
	questionnaires: [], // all questionnaires
	totalQuestionnaires: 0,
	redirectURLs: [],
	instruction: [],
	theme: '',
	surveyTemplateSetting: {},
	pixelId: null,
};

export const questionnaireReducer: QuestionnaireReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_QUESTIONNAIRE:
			return set('requestInProgress')(false)(state);
		case SET_QUESTIONNAIRE:
			return updateAll<QuestionnaireState>(
				set('requestInProgress')(false),
				set('error')('' as string),
				set('quesOrder')(map(prop('id'), sortBy(prop('orderPosition'), values(action.payload.questions)))),
				(state) => assign(action.payload, state)
			)(state);
		case SET_QUESTIONNAIRE_ERROR:
			return updateAll<QuestionnaireState>(set('requestInProgress')(false), set('error')(action.payload.error))(state);
		case SUBMIT_SURVEY:
			return updateAll<QuestionnaireState>(set('requestInProgress')(false), set('error')('' as string))(state);
		case READ_QUESTIONNAIRES_COUNT_BY_STATUS_FULFILLED:
			return {
				...state,
				countByStatus: action.payload.reduce((acc, item) => {
					acc[item.status] = item.count;
					return acc;
				}, {}),
			};
		case READ_QUESTIONNAIRES_FULFILLED:
			return {
				...state,
				questionnaires: action.payload.items,
				totalQuestionnaires: action.payload.count,
			};
		default:
			return state;
	}
};
