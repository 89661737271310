import { takeLeading, takeEvery } from 'redux-saga/effects';
import {
	ADD_QUES_OPTION,
	DELETE_QUES_OPTION,
	EDIT_PIPE_QUES_OPTION,
	EDIT_QUES_OPTION,
	MOVE_BULK_OPTION,
	MOVE_QUES_OPTION,
	PATCH_QUES_OPTIONS,
	UPLOAD_OPTION_ASSET,
} from './types';
import { createChoiceOption } from './sagas/createChoiceOption';
import { updateChoiceOption } from './sagas/updateChoiceOption';
import { deleteChoiceOption } from './sagas/deleteChoiceOption';
import { uploadOptionAsset } from './sagas/uploadOptionAsset';
import { handleOptionReorder } from './sagas/handleOptionReorder';
import { updatePipeChoiceOption } from './sagas/updatePipeChoiceOption';
import { patchChoiceOptionsSaga } from './sagas/patchChoiceOptions';
import { handleBulkOptionReorder } from './sagas/handleBulkOptionReorder';

function* createQuestionOptionWatcher() {
	yield takeLeading(ADD_QUES_OPTION, createChoiceOption);
}

function* updateQuestionOptionWatcher() {
	yield takeLeading(EDIT_QUES_OPTION, updateChoiceOption);
}

function* deleteQuestionOptionWatcher() {
	yield takeEvery(DELETE_QUES_OPTION, deleteChoiceOption);
}

function* uploadOptionAssetWatcher() {
	yield takeLeading(UPLOAD_OPTION_ASSET, uploadOptionAsset);
}

function* handleOptionReorderWatcher() {
	yield takeLeading(MOVE_QUES_OPTION, handleOptionReorder);
}

function* handleBulkOptionReorderWatcher() {
	yield takeLeading(MOVE_BULK_OPTION, handleBulkOptionReorder);
}

function* updatePipeQuestionOptionWatcher() {
	yield takeLeading(EDIT_PIPE_QUES_OPTION, updatePipeChoiceOption);
}

function* patchQuestionOptionsWatcher() {
	yield takeLeading(PATCH_QUES_OPTIONS, patchChoiceOptionsSaga);
}

export const questionOptionsWatchers = [
	createQuestionOptionWatcher,
	updateQuestionOptionWatcher,
	deleteQuestionOptionWatcher,
	uploadOptionAssetWatcher,
	handleOptionReorderWatcher,
	handleBulkOptionReorderWatcher,
	updatePipeQuestionOptionWatcher,
	patchQuestionOptionsWatcher,
];
