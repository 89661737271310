import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	createBrandFunnelMetric,
	createBrandFunnelMetricFulfilled,
	readBrandFunnelMetrics,
	readBrandFunnelMetricsFulfilled,
} from './actions';
import { brandFunnelMetricEndPoint, questionnaireEndPoint } from './const';
import {
	CreateBrandFunnelMetricPayload,
	ReadBrandFunnelMetricsPayload,
	CreateBrandFunnelMetric,
	BrandFunnelMetricsSchema,
	ReadBrandFunnelMetrics,
	CREATE_BRAND_FUNNEL_METRIC,
	READ_BRAND_FUNNEL_METRICS,
} from './types';

function createBrandFunnelMetricAPI(brand: CreateBrandFunnelMetricPayload) {
	const { questionnaireId, ...data } = brand;
	return axios.post(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelMetricEndPoint),
		data
	);
}

function readBrandFunnelMetricsAPI(brand: ReadBrandFunnelMetricsPayload) {
	return axios.get(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelMetricEndPoint),
		{
			params: brand,
		}
	);
}

function* createBrandFunnelMetricSaga(action: CreateBrandFunnelMetric) {
	try {
		yield putEffect(pend(createBrandFunnelMetric));
		yield createBrandFunnelMetricAPI(action.payload);
		yield putEffect(createBrandFunnelMetricFulfilled(action.payload));
		yield putEffect(fulfill(createBrandFunnelMetric));
	} catch (e) {
		yield putEffect(reject(createBrandFunnelMetric, getApiError(e)));
	}
}

function* readBrandFunnelMetricsSaga(action: ReadBrandFunnelMetrics) {
	try {
		yield putEffect(pend(readBrandFunnelMetrics));
		const res = yield readBrandFunnelMetricsAPI(action.payload);
		yield putEffect(readBrandFunnelMetricsFulfilled(validate(res.data, BrandFunnelMetricsSchema)));
		yield putEffect(fulfill(readBrandFunnelMetrics));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelMetrics, getApiError(e)));
	}
}

export const brandFunnelMetricSagas = [
	function* getCreateBrandFunnelMetricWatcher() {
		yield takeLeading(CREATE_BRAND_FUNNEL_METRIC, createBrandFunnelMetricSaga);
	},
	function* getReadBrandFunnelMetricsWatcher() {
		yield takeLeading(READ_BRAND_FUNNEL_METRICS, readBrandFunnelMetricsSaga);
	},
];
