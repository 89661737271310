import { SurveyResponseState } from '../survey/surveyResponse/types';
import { getUtcDateIsoString } from '../../shared/utils/getUtcDateIsoString';
import { QUES_TYPE } from '../../shared/consts/quesType';
import { set } from 'shades';
import { QuesType } from '../../shared/types/QuesTypes';
import { parse } from '@lytejs/query-string';
import { SurveySectionType } from 'shared/consts/sectionType';

type QuestionResponse<T = any> = { type: QuesType | SurveySectionType; response: T };
const addDefaultAnswerIfNeeded = (response: QuestionResponse) => {
	if (response.response !== undefined) {
		if (response.type === QUES_TYPE.VIDEO_UPLOAD_QUES) {
			return { ...response, videoType: 'manual' };
		}
		return response;
	}

	switch (response.type) {
		case QUES_TYPE.MULTIPLE_CHOICE_QUES:
		case QUES_TYPE.CREATIVE_QUES:
			return set('response')({ value: [] })(response);
		default:
			return set('response')(null)(response);
	}
};

export const mapResponseStateToCreateQuestionnairePayload = (
	response: SurveyResponseState,
	id: string,
	nicheId: string,
	duration?: any
) => {
	const search = window.location.search.substring(1);
	const variables = parse(search);

	return {
		id,
		nicheId,
		startTime: response.startTime,
		duration,
		endTime: getUtcDateIsoString(),
		variables,
		responses: Object.entries(response.responses)
			.map(([id, res]) => ({
				id,
				...addDefaultAnswerIfNeeded(res),
			}))
			.filter((res) => !response.skipped.includes(res.id)),
		skipped: response.skipped,
	};
};

export const removeResponses = (
	requestBody: ReturnType<typeof mapResponseStateToCreateQuestionnairePayload>,
	removeResponsesForQuestionIds: string[]
) => ({
	...requestBody,
	responses: requestBody.responses.filter((response) => !removeResponsesForQuestionIds.includes(response.id)),
});
export const getVideoType = (response: SurveyResponseState) => {
	return Object.values(response.responses).find((res) => res.type === QUES_TYPE.VIDEO_UPLOAD_QUES);
};
