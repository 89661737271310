import { map, prop, sortBy, values } from 'ramda';
import { select } from 'redux-saga/effects';
import axios from '@utils/axios';

import { AddSection } from '../types';
import { getCurrentPosition } from './createQuestion';
import { surveySectionOptionAssetsApiUrl } from './consts';
import { selectQues, setPopup, updateInfo } from '../actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { SurveyPreviewDataState } from 'store/survey/surveyPreview/types';
import { mapQuestionResponseToQuestion, mapSectionResponseToSurvey } from '../../surveyInfo/sagas/utils';
import { QUES_TYPE } from 'shared/consts/quesType';

export const getQuestionsResponse = (questions) => {
	return Object.fromEntries(
		questions?.map((ques) => {
			if (!!ques?.metaData) {
				return [ques?.id, mapSectionResponseToSurvey(ques)];
			} else {
				return [ques?.id, mapQuestionResponseToQuestion(ques)];
			}
		})
	);
};
export function* createSection(action: AddSection) {
	const { moderated } = action.payload;
	const { questions, surveys, surveyId } = yield select((s) => s.surveyBuilder);

	const { activeSurvey }: SurveyPreviewDataState = yield select((s) => s.surveyData);
	const questionnaireId = surveys[surveyId]?.questionnaireId;
	const quesOrder: any = map(
		prop('id'),
		sortBy(
			prop('orderPosition'),
			values(questions).filter((ques) => !ques.parentQuestionId)
		)
	);
	const thankYouQues = quesOrder?.some((quesId) =>
		[QUES_TYPE.THANK_YOU, QUES_TYPE.VIDEO_UPLOAD_QUES].includes(questions[quesId]?.type)
	);
	const thankYouCount = quesOrder?.filter((quesId) =>
		[QUES_TYPE.THANK_YOU, QUES_TYPE.VIDEO_UPLOAD_QUES].includes(questions[quesId]?.type)
	)?.length;

	try {
		const body: Record<string, any> = {
			surveyId: activeSurvey,
			metaData: {
				name: '',
				description: '',
				hasDescription: false,
			},
			// for now keeping it here as API gives error 'settings' and 'display' is not provided
			settings: {
				buttonLabel: 'Get Started',
			},
			display: {
				showIf: [],
			},
			orderPosition: thankYouQues ? getCurrentPosition(questions) - thankYouCount : getCurrentPosition(questions),
		};

		const { data } = yield axios.post(
			getApiEndPointUrl(`${surveySectionOptionAssetsApiUrl}?questionaireId=${questionnaireId}`),
			body
		);
		const responseOfData = getQuestionsResponse(data?.surveys?.[0]?.questions);
		const allQuestionOrder = Object.values(responseOfData)
			?.filter((que) => !que.parentQuestionId)
			?.sort((a, b) => a.orderPosition - b.orderPosition)
			?.map((ques) => ques.id);
		// logic for default selected section
		const currentQuestionId: string = !!thankYouCount
			? allQuestionOrder?.splice(-thankYouCount - 1)?.[0]
			: allQuestionOrder?.pop();
		yield putEffect(updateInfo(currentQuestionId as string, responseOfData, moderated));
		yield putEffect(selectQues(currentQuestionId as string, true));
		setTimeout(() => {
			document.getElementById(`${currentQuestionId}`)?.scrollIntoView({ behavior: 'auto', block: 'end' });
		}, 100);
		setTimeout(() => {
			document.getElementById(`selected-${currentQuestionId}`)?.scrollIntoView({ behavior: 'auto', block: 'start' });
		}, 150);
	} catch (e: any) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
