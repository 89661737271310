import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { RedirectState } from '../../store/redirect/types';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { setRouteInfo, redirect } from '../../store/redirect/actions';
import { equals } from 'ramda';

const RedirectWatcher = () => {
	const { url, info } = useSelector<StoreState, RedirectState>((s) => s.redirect);
	const dispatch = useDispatch();
	const { navigate } = useNavigation();
	const {
		lastChunk: { request },
	} = useCurrentRoute();

	useEffect(() => {
		if (url) {
			navigate(url);
			dispatch(redirect(undefined));
		}
	}, [navigate, url, dispatch]);

	useEffect(() => {
		if (request && !equals(request.params, info.params)) {
			dispatch(
				setRouteInfo({
					params: request.params,
					query: request.query,
					url: request.url,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	return <></>;
};

export default memo(RedirectWatcher, () => true);
