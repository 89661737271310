import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export const CREATE_BRAND_COMPETITORS = 'CREATE_BRAND_COMPETITORS';
export const CREATE_BRAND_COMPETITORS_FULFILLED = 'CREATE_BRAND_COMPETITORS_FULFILLED';

export const SET_CURRENT_BRAND_COMPETITOR = 'SET_CURRENT_BRAND_COMPETITOR';

export type CreateBrandCompetitorsPayload = {
	competitors: {
		name: string;
		id: string;
	}[];
	brandId: string;
};
export type BrandCompetitor = {
	id: string;
	name: string;
	brandId: string;
};
const BrandCompetitorSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		brandId: { type: 'string' },
	},
};
export const BrandCompetitorsSchema: JSONSchema = {
	type: 'array',
	items: BrandCompetitorSchema,
};
export type CreateBrandCompetitors = ReduxAction<typeof CREATE_BRAND_COMPETITORS, CreateBrandCompetitorsPayload>;
export type CreateBrandCompetitorsFulfilled = ReduxAction<typeof CREATE_BRAND_COMPETITORS_FULFILLED, BrandCompetitor[]>;

export const READ_BRAND_COMPETITORS = 'READ_BRAND_COMPETITORS';
export const READ_BRAND_COMPETITORS_FULFILLED = 'READ_BRAND_COMPETITORS_FULFILLED';

export type ReadBrandCompetitorsPayload = {
	brandId: string;
};

export type ReadBrandCompetitors = ReduxAction<typeof READ_BRAND_COMPETITORS, ReadBrandCompetitorsPayload>;
export type ReadBrandCompetitorsFulfilled = ReduxAction<typeof READ_BRAND_COMPETITORS_FULFILLED, BrandCompetitor[]>;

export type BrandCompetitorAction =
	| CreateBrandCompetitors
	| CreateBrandCompetitorsFulfilled
	| ReadBrandCompetitors
	| ReadBrandCompetitorsFulfilled;

export type BrandCompetitorReducer = ReduxReducer<BrandState, BrandCompetitorAction>;
