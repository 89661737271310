import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { CreateWorkspaceAction, CREATE_WORKSPACE, GET_WORKSPACES_ACTION } from './types';
import { environment } from '../../environment';
import { createWorkspaceEndPoint, getWorkspacesEndPoint } from './const';
import { createWorkspaces, setWorkspaceAction, setWorkspaceError, setWorkspacesWithIdAction } from './actions';
import { putEffect } from '../../shared/utils/putEffect';
import { getApiError } from '../../shared/utils/getApiError';
import mixpanel from 'mixpanel-browser';
import { readUserRoles } from '../auth/actions';
import { fulfill, pend, reject } from '../promise/actions';

function sendWorkspaceRequest(workspaceObj: { name: string }) {
	return axios.post(`${environment.apiUrl}${createWorkspaceEndPoint}`, workspaceObj);
}

function getWorkspacesRequest() {
	return axios.get(`${environment.apiUrl}${getWorkspacesEndPoint}`);
}

function* createWorkspacesSaga(action: CreateWorkspaceAction) {
	try {
		yield putEffect(pend(createWorkspaces));
		const res = yield Promise.all(action.payload.map(sendWorkspaceRequest)).then((data) => data.map((res) => res.data));
		yield putEffect(readUserRoles());
		yield putEffect(setWorkspaceAction(res));
		yield putEffect(fulfill(createWorkspaces, res));
		mixpanel.track('Create Workspaces', {
			workspace: res.map((data = { id: '' }) => data.id),
		});
		mixpanel.people.increment('Number of Workspaces', res.length);
	} catch (e) {
		yield putEffect(reject(createWorkspaces, getApiError(e)));
		yield putEffect(setWorkspaceError(getApiError(e)));
	}
}

function* getWorkspaces() {
	try {
		const res = yield getWorkspacesRequest();
		yield putEffect(setWorkspacesWithIdAction(res.data));
	} catch (e) {
		yield putEffect(setWorkspaceError(getApiError(e)));
	}
}

export const workspaceSagas = [
	function* () {
		yield takeLeading(CREATE_WORKSPACE, createWorkspacesSaga);
	},
	function* () {
		yield takeLeading(GET_WORKSPACES_ACTION, getWorkspaces);
	},
];
