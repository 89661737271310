import axios from '@utils/axios';

import { UpdateSurveyGamesAction } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { setSurveyError } from '../../shared/actions';
import { surveysApiEndPoint } from './consts';
import { setSurveyGameAction } from '../actions';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';

export function* updateSurveyGames(action: UpdateSurveyGamesAction) {
	const { surveyId, updatePayload } = action.payload;

	try {
		const {
			data: { game },
		} = yield axios.patch(getApiEndPointUrl(surveysApiEndPoint, surveyId), updatePayload);

		yield putEffect(setSurveyGameAction(surveyId, game));
	} catch (e: any) {
		yield putEffect(setSurveyError(getApiError(e)));
	}
}
