import {
	ReadCustomVariables,
	READ_CUSTOM_VARIABLES,
	Variable,
	ReadCustomVariablesFulfilled,
	READ_CUSTOM_VARIABLES_FULFILLED,
} from './types';

export const readCustomVariables = (payload: string): ReadCustomVariables => ({
	type: READ_CUSTOM_VARIABLES,
	payload,
});

export const readCustomVariablesFulfilled = (payload: Variable[]): ReadCustomVariablesFulfilled => ({
	type: READ_CUSTOM_VARIABLES_FULFILLED,
	payload: payload,
});
