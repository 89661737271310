import axios from '@utils/axios';

import { first } from 'shades';
import { select } from 'redux-saga/effects';
import { map, prop, sortBy, values } from 'ramda';

import { AddQues } from '../types';
import { surveyQuestionsApiUrl } from './consts';
import { getQuestionModelByType } from '../../utils';
import { getQuestionsResponse } from './createSection';
import { mapQuestionToCreateQuestionPayload } from './utils';
import { selectQues, setPopup, updateInfo } from '../actions';
import { QuesType } from '../../../../../shared/types/QuesTypes';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { QUES_TYPE } from '../../../../../shared/consts/quesType';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { SurveyPreviewDataState } from 'store/survey/surveyPreview/types';
import { SectionsInSurvey, QuestionsStates } from 'shared/types/SurveyResponses';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';

export const getOrderPosition = ({
	quesOrder,
	quesType,
	moderated,
	questions,
	hasVideoUploadQues,
	parentQuestionId,
	questionnaireSectionId,
}: {
	questions: SectionsInSurvey<QuestionsStates>;
	quesType: QuesType;
	moderated?: boolean;
	quesOrder: string[];
	hasVideoUploadQues: boolean;
	parentQuestionId?: string | null;
	questionnaireSectionId?: string | null;
}) => {
	if (quesType === QUES_TYPE.WELCOME_SCREEN) return { orderPosition: 1, reOrder: true };
	if (quesType === QUES_TYPE.SCREENER_QUES)
		return {
			reOrder: true,
			orderPosition: moderated
				? 100000
				: Object.values(questions).filter((question) =>
						[QUES_TYPE.WELCOME_SCREEN, QUES_TYPE.SCREENER_QUES].includes(question?.type as any)
				  ).length + 1,
		};

	if (parentQuestionId) {
		return {
			reOrder: false,
			orderPosition:
				questions[parentQuestionId].orderPosition * (moderated ? 1 : 1000) +
				Object.values(questions).filter((question) => question?.parentQuestionId === parentQuestionId).length +
				1,
		};
	}
	if (!!questionnaireSectionId) {
		const section = questions[questionnaireSectionId as string];
		const sectionOrderPosition = section.orderPosition;
		return {
			reOrder: true,
			orderPosition: sectionOrderPosition + section?.questionnaireId?.length,
		};
	}
	const quesOrderWithOutNestedQuestions = quesOrder.filter((quesId) => !questions[quesId]?.parentQuestionId);
	const thankYouQues = quesOrder.filter((quesId) => questions[quesId]?.type === QUES_TYPE.THANK_YOU);
	const quesOrderWithOutThankYou = quesOrder.filter(
		(quesId) => !questions[quesId]?.parentQuestionId && questions[quesId]?.type !== QUES_TYPE.THANK_YOU
	);
	if (hasVideoUploadQues && quesType !== QUES_TYPE.THANK_YOU) {
		return {
			reOrder: true,
			orderPosition: thankYouQues.length ? quesOrderWithOutThankYou.length : quesOrderWithOutNestedQuestions.length,
		};
	}
	if (!hasVideoUploadQues && thankYouQues.length) {
		return {
			reOrder: true,
			orderPosition: questions[thankYouQues[0]].orderPosition,
		};
	}
	if (hasVideoUploadQues && quesType === QUES_TYPE.THANK_YOU) {
		return {
			reOrder: true,
			orderPosition: !!thankYouQues?.length
				? questions[thankYouQues[0]].orderPosition
				: Math.max(quesOrderWithOutThankYou.length + 1, getCurrentPosition(questions)),
		};
	}

	return {
		reOrder: false,
		orderPosition: Math.max(quesOrderWithOutThankYou.length + 1, getCurrentPosition(questions)),
	};
};

export const getCurrentPosition = (questions) => {
	const allQuestionOrder: string[] = [];
	const quesOrder: any = map(
		prop('id'),
		sortBy(
			prop('orderPosition'),
			values(questions).filter((ques) => !ques.parentQuestionId)
		)
	);

	quesOrder.forEach((id) => {
		if (!!questions[id].metaData) {
			allQuestionOrder.push(id);
			if (questions[id]?.questionnaireId?.length > 0) {
				allQuestionOrder.push(...questions[id]?.questionnaireId?.slice(0, -1));
			}
		} else {
			allQuestionOrder.push(id);
		}
	});
	return allQuestionOrder.length + 1;
};
export function* createQuestion(action: AddQues) {
	const { parentQuestionId, moderated, questionnaireSectionId } = action.payload;

	const { hasWelcomeScreen, hasVideoUploadQues, surveyId, surveys, questions, theme, moderatedQuestion } = yield select(
		(s) => s.surveyBuilder
	);
	const questionnaireId = surveys[surveyId]?.questionnaireId;

	const { activeSurvey }: SurveyPreviewDataState = yield select((s) => s.surveyData);
	const isWelcomeScreen = action.payload.quesType === QUES_TYPE.WELCOME_SCREEN;
	const isVideoUploadQues = action.payload.quesType === QUES_TYPE.VIDEO_UPLOAD_QUES;
	const isThankYouQues = action.payload.quesType === QUES_TYPE.THANK_YOU;
	const quesOrder: any = map(
		prop('id'),
		sortBy(
			prop('orderPosition'),
			values(questions)?.filter((que) => !que.parentQuestionId)
		)
	);

	const { orderPosition, reOrder } = getOrderPosition({
		hasVideoUploadQues,
		quesOrder,
		moderated,
		quesType: action.payload.quesType,
		questions: moderated ? moderatedQuestion : questions,
		parentQuestionId,
		questionnaireSectionId,
	});

	const question = getQuestionModelByType(action.payload.quesType, orderPosition);
	if (!question) {
		yield putEffect(setPopup('Question type is not supported'));
		return;
	}
	if (isWelcomeScreen && hasWelcomeScreen) {
		yield putEffect(setPopup('Only one welcome screen is supported per survey. '));
		return;
	}

	if (isVideoUploadQues && hasVideoUploadQues) {
		yield putEffect(setPopup('Only one video upload question is supported per survey. '));
		return;
	}

	try {
		const body: Record<string, any> = mapQuestionToCreateQuestionPayload(
			question,
			theme,
			surveyId || activeSurvey || first(Object.keys(surveys)),
			questionnaireId,
			reOrder
		);
		if (parentQuestionId) {
			body.parentQuestionId = parentQuestionId;
			body.questionnaireSectionId = questions?.[parentQuestionId]?.questionnaireSectionId;
		}
		if (questionnaireSectionId) body.questionnaireSectionId = questionnaireSectionId;
		if (moderated) body.moderatedQuestion = moderated;

		const { data } = yield axios.post(getApiEndPointUrl(surveyQuestionsApiUrl), body);
		const responseOfData = getQuestionsResponse(
			!moderated ? data?.surveys?.[0]?.questions : data?.surveys[0]?.moderatedTesting?.questions
		);

		const allQuestionOrder = Object.values(responseOfData)
			.filter((que) => !que.parentQuestionId)
			.sort((a, b) => a.orderPosition - b.orderPosition)
			.map((ques) => ques.id);

		// checking if thank you questions is available or not
		const thankYouQues = allQuestionOrder?.filter((quesId) => responseOfData[quesId]?.type === QUES_TYPE.THANK_YOU);
		// logic for default selected question
		let currentQuestionId: string = '';
		if (isVideoUploadQues) {
			currentQuestionId = !!thankYouQues?.length
				? allQuestionOrder?.splice(-thankYouQues?.length - 1)?.[0]
				: allQuestionOrder?.pop();
		} else if (isThankYouQues && !!thankYouQues) {
			currentQuestionId = !!thankYouQues?.length
				? allQuestionOrder?.splice(-thankYouQues?.length)?.[0]
				: allQuestionOrder?.pop();
		} else {
			currentQuestionId = !!thankYouQues?.length
				? hasVideoUploadQues
					? allQuestionOrder?.splice(-thankYouQues?.length - 2)?.[0]
					: allQuestionOrder?.splice(-thankYouQues?.length - 1)?.[0]
				: allQuestionOrder?.pop();
		}

		yield putEffect(
			updateInfo(!!questionnaireSectionId ? questionnaireSectionId : currentQuestionId, responseOfData, moderated)
		);
		if (!parentQuestionId) {
			yield putEffect(
				selectQues(
					!!questionnaireSectionId
						? responseOfData?.[questionnaireSectionId as any]?.questionnaireId?.slice(-1)[0]
						: currentQuestionId,
					true
				)
			);
			setTimeout(() => {
				document
					.getElementById(
						`${
							!!questionnaireSectionId
								? responseOfData?.[questionnaireSectionId as any]?.questionnaireId?.slice(-1)[0]
								: currentQuestionId
						}`
					)
					?.scrollIntoView({ behavior: 'auto', block: 'center' });
			}, 100);
			setTimeout(() => {
				document
					.getElementById(`selected-${!!questionnaireSectionId ? questionnaireSectionId : currentQuestionId}`)
					?.scrollIntoView({ behavior: 'auto', block: 'center' });
			}, 150);
		} else {
			yield document
				.getElementById(`nested-${currentQuestionId}`)
				?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	} catch (e: any) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
