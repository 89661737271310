import { takeLeading, call } from 'redux-saga/effects';
import { READ_INTERESTS, ReadTargetingInterests } from './types';
import { fulfill, pend, reject } from '../../promise/actions';
import { environment } from '../../../environment';
import { readTargetingInterests, readTargetingInterestsFulfilled } from './actions';
import axios from '@utils/axios';
import { putEffect } from '../../../shared/utils/putEffect';
import { getApiError } from '../../../shared/utils/getApiError';

const getInterests = (query: string, limit: number) => {
	return axios.get(`${environment.targetingInterests}&query=Golf&limit=30`);
};

function* getInterestsSaga(action: ReadTargetingInterests) {
	try {
		yield putEffect(pend(readTargetingInterests));
		const res = yield call(getInterests, action.payload.query, action.payload.limit);
		yield putEffect(readTargetingInterestsFulfilled(res.data));
		yield putEffect(fulfill(readTargetingInterests));
	} catch (error) {
		yield putEffect(reject(readTargetingInterests, getApiError(error)));
	}
}

function* getInterestsWatcher() {
	yield takeLeading(READ_INTERESTS, getInterestsSaga);
}

export const targetingInterestsSagas = [getInterestsWatcher];
