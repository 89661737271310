import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PyxisLoader from '../../shared/components/PyxisLoader/PyxisLoader';
import { StoreState } from '../../store';
import { useIsLoading } from '../../utils/reactQueryPlus/provider';
import { ReduxQueryContext } from '../../utils/reduxQuery/useReduxMutation';

export const Loader = (): ReactElement => {
	const dictionary = useContext(ReduxQueryContext);

	const pending = useSelector((state: StoreState) => state.promise.pending);
	const isVisible = useMemo(
		() => !!pending.filter((type) => !dictionary.globalLoaderBlackList[type]).length,
		[dictionary.globalLoaderBlackList, pending]
	);
	const [reallyVisible, setReallyVisible] = useState<boolean>(false);
	const ref = useRef<any>(null);
	const ref2 = useRef<any>(null);
	useEffect(() => {
		if (isVisible) {
			ref2.current && clearTimeout(ref2.current);
			ref.current = setTimeout(() => setReallyVisible(true), ref.current === null ? 0 : 200);
		} else {
			ref.current && clearTimeout(ref.current);
			ref2.current = setTimeout(() => setReallyVisible(false), 200);
		}
	}, [isVisible]);
	return <PyxisLoader visible={reallyVisible} />;
};
export const ReactQueryLoader = (): ReactElement => {
	const isVisible = useIsLoading();
	const [reallyVisible, setReallyVisible] = useState<boolean>(false);
	const ref = useRef<any>(null);
	const ref2 = useRef<any>(null);
	useEffect(() => {
		if (isVisible) {
			ref2.current && clearTimeout(ref2.current);
			ref.current = setTimeout(() => setReallyVisible(true), ref.current === null ? 0 : 200);
		} else {
			ref.current && clearTimeout(ref.current);
			ref2.current = setTimeout(() => setReallyVisible(false), 200);
		}
	}, [isVisible]);
	return <PyxisLoader visible={reallyVisible} />;
};
