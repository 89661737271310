import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';
import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	readBrandFunnelCombinedData,
	readBrandFunnelCombinedDataFulfilled,
	readBrandFunnelComparisonData,
	readBrandFunnelComparisonDataFulfilled,
	readBrandFunnelDailyData,
	readBrandFunnelDailyDataFulfilled,
	readBrandFunnelDataByCohort,
	readBrandFunnelDataByCohortFulfilled,
} from './actions';
import { brandFunnelEndPoint } from './const';
import {
	ReadBrandFunnelDailyData,
	BrandFunnelDailyDataSchema,
	READ_BRAND_FUNNEL_DAILY_DATA,
	ReadBrandFunnelDailyDataPayload,
	ReadBrandFunnelComparisonData,
	BrandFunnelComparisonSchema,
	READ_BRAND_FUNNEL_COMPARISON,
	ReadBrandFunnelComparisonPayload,
	BrandFunnelCombinedDataSchema,
	ReadBrandFunnelCombinedData,
	READ_BRAND_FUNNEL_COMBINED_DATA,
	ReadBrandFunnelDataByCohort,
	ReadBrandFunnelDataByCohortPayload,
	READ_BRAND_FUNNEL_DATA_BY_COHORT,
	BrandFunnelDataByCohortSchema,
	ReadBrandFunnelCombinedDataPayload,
} from './types';

function readBrandFunnelDailyDataAPI(action: ReadBrandFunnelDailyDataPayload) {
	const { id, ...params } = action;
	// return axios.get(getAnalyticsApiEndPointUrl(brandFunnelEndPoint, id));
	return axios.get(getAnalyticsApiEndPointUrl('questionnaire', id, brandFunnelEndPoint, 'daily-data'), {
		params,
	});
}
function readBrandFunnelCombinedDataAPI(action: ReadBrandFunnelCombinedDataPayload) {
	const { id, ...params } = action;
	// return axios.get(getAnalyticsApiEndPointUrl(brandFunnelEndPoint, id));
	return axios.get(getAnalyticsApiEndPointUrl('questionnaire', id, brandFunnelEndPoint, 'combined-data'), {
		params,
	});
}
function readBrandFunnelComparisonAPI(action: ReadBrandFunnelComparisonPayload) {
	const { id, ...params } = action;
	// return axios.get(getAnalyticsApiEndPointUrl(brandFunnelEndPoint, id));
	return axios.get(getAnalyticsApiEndPointUrl('questionnaire', id, brandFunnelEndPoint, 'comparison-data'), {
		params,
	});
}
function readBrandFunnelDataByCohortAPI(action: ReadBrandFunnelDataByCohortPayload) {
	const { id, ...params } = action;
	// return axios.get(getAnalyticsApiEndPointUrl(brandFunnelEndPoint, id));
	return axios.get(getAnalyticsApiEndPointUrl('questionnaire', id, brandFunnelEndPoint, 'data-by-cohort'), {
		params,
	});
}

function* readBrandFunnelDailyDataSaga(action: ReadBrandFunnelDailyData) {
	try {
		yield putEffect(pend(readBrandFunnelDailyData));
		const res = yield readBrandFunnelDailyDataAPI(action.payload);
		yield putEffect(readBrandFunnelDailyDataFulfilled(validate(res.data, BrandFunnelDailyDataSchema)));
		yield putEffect(fulfill(readBrandFunnelDailyData));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelDailyData, getApiError(e)));
	}
}
function* readBrandFunnelCombinedDataSaga(action: ReadBrandFunnelCombinedData) {
	try {
		yield putEffect(pend(readBrandFunnelCombinedData));
		const res = yield readBrandFunnelCombinedDataAPI(action.payload);
		yield putEffect(readBrandFunnelCombinedDataFulfilled(validate(res.data, BrandFunnelCombinedDataSchema)));
		yield putEffect(fulfill(readBrandFunnelCombinedData));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelCombinedData, getApiError(e)));
	}
}
function* readBrandFunnelComparisonSaga(action: ReadBrandFunnelComparisonData) {
	try {
		yield putEffect(pend(readBrandFunnelComparisonData));
		const res = yield readBrandFunnelComparisonAPI(action.payload);
		yield putEffect(readBrandFunnelComparisonDataFulfilled(validate(res.data, BrandFunnelComparisonSchema)));
		yield putEffect(fulfill(readBrandFunnelComparisonData));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelComparisonData, getApiError(e)));
	}
}
function* readBrandFunnelDataByCohortSaga(action: ReadBrandFunnelDataByCohort) {
	try {
		yield putEffect(pend(readBrandFunnelDataByCohort));
		const res = yield readBrandFunnelDataByCohortAPI(action.payload);
		yield putEffect(readBrandFunnelDataByCohortFulfilled(validate(res.data, BrandFunnelDataByCohortSchema)));
		yield putEffect(fulfill(readBrandFunnelDataByCohort));
	} catch (e) {
		yield putEffect(reject(readBrandFunnelDataByCohort, getApiError(e)));
	}
}

export const getBrandFunnelWatchers = [
	function* () {
		yield takeLeading(READ_BRAND_FUNNEL_DAILY_DATA, readBrandFunnelDailyDataSaga);
	},
	function* () {
		yield takeLeading(READ_BRAND_FUNNEL_COMBINED_DATA, readBrandFunnelCombinedDataSaga);
	},
	function* () {
		yield takeLeading(READ_BRAND_FUNNEL_DATA_BY_COHORT, readBrandFunnelDataByCohortSaga);
	},
	function* () {
		yield takeLeading(READ_BRAND_FUNNEL_COMPARISON, readBrandFunnelComparisonSaga);
	},
];
