import { AddQuesOption } from '../types';
import { select } from 'redux-saga/effects';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import {
	surveyMatrixQuestionCustomRowColumns,
	surveyQuestionBulkOptionsApiUrl,
	surveyQuestionOptionsApiUrl,
} from '../../sharedQuestions/sagas/consts';
import { difference, last } from 'ramda';
import { mapPipeAnswerTypeToValue } from '../../utils';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { addQuesOption, editPipeQuesOption, uploadQuesOptionAsset } from '../actions';
import { processMatrixQuestion, processMultipleChoiceQuestion } from '../../surveyInfo/sagas/utils';
import { ChoiceOptionResponse } from '../../../../../shared/types/SurveyResponses';
import { OptionType, PipeValue } from '../../../../../shared/types/QuesTypes';
import { fulfill, pend, reject } from 'store/promise/actions';

export function* createChoiceOption(action: AddQuesOption) {
	const {
		quesId,
		pipe,
		file,
		url,
		assetId,
		optionText = '',
		methodology,
		none,
		bulkChoices,
		bulkOptionsData,
		rows,
		columns,
	} = action.payload;
	const { questions, moderatedQuestion, surveys, surveyId } = yield select((s) => s.surveyBuilder);
	const questionnaireId = surveys[surveyId]?.questionnaireId;
	if (!!questions[quesId] || !!moderatedQuestion[quesId]) {
		let question;
		let isModerated = false;
		if (questions[quesId]) {
			question = questions[quesId];
			isModerated = false;
		} else {
			question = moderatedQuestion[quesId];
			isModerated = true;
		}
		try {
			yield putEffect(pend(addQuesOption));
			let data;
			let newOptionId;
			if (!!bulkChoices) {
				const result = bulkOptionsData.map((questionId, index) => ({
					questionId: question.id,
					value: bulkOptionsData[index],
					optionType: OptionType.Normal,
				}));
				data = yield axios.post(getApiEndPointUrl(surveyQuestionBulkOptionsApiUrl), result);
			} else if (!!rows || !!columns) {
				// to insert rows and column in the matrix question
				data = yield axios.post(getApiEndPointUrl(surveyMatrixQuestionCustomRowColumns), {
					questionId: question.id,
					questionnaireId: questionnaireId,
					rows: rows,
					columns: columns,
				});
			} else {
				data = yield axios.post(getApiEndPointUrl(surveyQuestionOptionsApiUrl), {
					questionId: question.id,
					value: optionText,
					optionType: pipe ? OptionType.Pipe : none ? OptionType.None : OptionType.Normal,
					pipeId: pipe?.pipeId,
					pipeValue: mapPipeAnswerTypeToValue(pipe?.pipeAnswer),
					methodology,
					pipeType: pipe?.pipeType,
				});
			}
			if (!!rows || !!columns) {
				// to insert rows and column in the matrix question (set the response in the question by finding out the question from the response)
				const responseQuestions = data.data.surveys[0].questions;
				const foundQuestion = responseQuestions.find((ques) => ques.id === question.id);
				foundQuestion.settings.optionOrder = foundQuestion.content.options
					.sort((a, b) => a.orderPosition - b.orderPosition)
					.map((option) => option.id);
				yield putEffect(
					setQuestion(question.id, processMatrixQuestion(foundQuestion, question), undefined, isModerated)
				);
			} else {
				yield putEffect(
					setQuestion(question.id, processMultipleChoiceQuestion(data.data, question), undefined, isModerated)
				);
			}

			if (!rows || !columns) {
				newOptionId = last(
					difference(
						data.data.map((o: ChoiceOptionResponse) => o.id),
						question.settings.optionOrder
					) as string[]
				);
			}

			if ((file || url) && newOptionId) {
				yield putEffect(uploadQuesOptionAsset(question.id, newOptionId, file, url));
			}
			if (assetId && newOptionId) {
				yield putEffect(editPipeQuesOption(quesId, assetId, PipeValue.REFERENCE, OptionType.Asset, newOptionId));
			}
			yield putEffect(fulfill(addQuesOption));
		} catch (e) {
			yield putEffect(reject(addQuesOption, getApiError(e)));

			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		const isModerated = false;
		try {
			yield putEffect(pend(addQuesOption));
			let data;
			let newOptionId;
			if (!!bulkChoices) {
				const result = bulkOptionsData.map((questionId, index) => ({
					questionId: question.id,
					value: bulkOptionsData[index],
					optionType: OptionType.Normal,
				}));
				data = yield axios.post(getApiEndPointUrl(surveyQuestionBulkOptionsApiUrl), result);
			}
			if (!!rows || !!columns) {
				// to insert rows and column in the matrix question
				data = yield axios.post(getApiEndPointUrl(surveyMatrixQuestionCustomRowColumns), {
					questionId: question.id,
					questionnaireId: questionnaireId,
					rows: rows,
					columns: columns,
				});
			}
			if (!bulkChoices && !rows && !columns) {
				data = yield axios.post(getApiEndPointUrl(surveyQuestionOptionsApiUrl), {
					questionId: question.id,
					value: optionText,
					optionType: pipe ? OptionType.Pipe : OptionType.Normal,
					pipeId: pipe?.pipeId,
					pipeValue: mapPipeAnswerTypeToValue(pipe?.pipeAnswer),
					methodology,
					pipeType: pipe?.pipeType,
				});
			}
			if (!!rows || !!columns) {
				// to insert rows and column in the matrix question (set the response in the question by finding out the question from the response)
				const responseQuestions = data.data.surveys[0].questions;
				const foundSection = responseQuestions.find((ques) => ques.id === question.questionnaireSectionId);
				const foundQuestion = foundSection.questions.find((ques) => ques.id === question.id);
				foundQuestion.settings.optionOrder = foundQuestion.content.options
					.sort((a, b) => a.orderPosition - b.orderPosition)
					.map((option) => option.id);
				questions[question?.questionnaireSectionId].questions[question.id] = processMatrixQuestion(
					foundQuestion,
					question
				);
			} else {
				questions[question?.questionnaireSectionId].questions[question.id] = processMultipleChoiceQuestion(
					data.data,
					question
				);
			}
			if (!rows || !columns) {
				newOptionId = last(
					difference(
						data.data.map((o: ChoiceOptionResponse) => o.id),
						question.settings.optionOrder
					) as string[]
				);
			}

			yield putEffect(
				setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId], isModerated)
			);

			if ((file || url) && newOptionId) {
				yield putEffect(uploadQuesOptionAsset(question.id, newOptionId, file, url));
			}
			if (assetId && newOptionId) {
				yield putEffect(editPipeQuesOption(quesId, assetId, PipeValue.REFERENCE, OptionType.Asset, newOptionId));
			}
			yield putEffect(fulfill(addQuesOption));
		} catch (e) {
			yield putEffect(reject(addQuesOption, getApiError(e)));

			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
