import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export const CREATE_BRAND_ASSETS = 'CREATE_BRAND_ASSETS';
export const CREATE_BRAND_ASSETS_FULFILLED = 'CREATE_BRAND_ASSETS_FULFILLED';

export type CreateBrandAssetsPayload = {
	files: File[];
	brandId: string;
};
export type BrandAsset = {
	id: string;
	filename: string;
	url: string;
	brandId: string;
	assetId: string;
};
const BrandAssetSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		fileName: { type: 'string' },
		url: { type: 'string' },
		brandId: { type: 'string' },
	},
};
export const BrandAssetsSchema: JSONSchema = {
	type: 'array',
	items: BrandAssetSchema,
};
export type CreateBrandAssets = ReduxAction<typeof CREATE_BRAND_ASSETS, CreateBrandAssetsPayload>;
export type CreateBrandAssetsFulfilled = ReduxAction<typeof CREATE_BRAND_ASSETS_FULFILLED, BrandAsset[]>;

export const READ_BRAND_ASSETS = 'READ_BRAND_ASSETS';
export const READ_BRAND_ASSETS_FULFILLED = 'READ_BRAND_ASSETS_FULFILLED';

export type ReadBrandAssetsPayload = {
	brandId: string;
};

export type ReadBrandAssets = ReduxAction<typeof READ_BRAND_ASSETS, ReadBrandAssetsPayload>;
export type ReadBrandAssetsFulfilled = ReduxAction<typeof READ_BRAND_ASSETS_FULFILLED, BrandAsset[]>;

export const DELETE_BRAND_ASSETS = 'DELETE_BRAND_ASSETS';
export const DELETE_BRAND_ASSETS_FULFILLED = 'DELETE_BRAND_ASSETS_FULFILLED';

export type DeleteBrandAssets = ReduxAction<typeof DELETE_BRAND_ASSETS, BrandAsset[]>;
export type DeleteBrandAssetsFulfilled = ReduxAction<typeof DELETE_BRAND_ASSETS_FULFILLED, BrandAsset[]>;

export type BrandAssetAction =
	| ReadBrandAssets
	| ReadBrandAssetsFulfilled
	| DeleteBrandAssets
	| DeleteBrandAssetsFulfilled
	| CreateBrandAssets
	| CreateBrandAssetsFulfilled;

export type BrandAssetReducer = ReduxReducer<BrandState, BrandAssetAction>;
