import { surveyInfoWatchers } from '../surveyInfo/sagas';
import { questionerWatchers } from '../sharedQuestions/sagas';
import { questionOptionsWatchers } from '../questionOptions/sagas';
import { questionSectionsWatchers } from '../questionSections/sagas';

export const surveyBuilderWatchers = [
	...surveyInfoWatchers,
	...questionerWatchers,
	...questionOptionsWatchers,
	...questionSectionsWatchers,
];
