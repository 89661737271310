import { useEffect, useState } from 'react';
import useSwr from 'swr';
import { calculateCreditsFetcher } from '../../../services/targeting';
import { SurveyCostType } from '../../../services/targeting/types';
import { useDebouncedEffect } from '../../../shared/utils/useDebouncedEffect';

export const DEFAULT_CALCULATED_SURVEY_COST: SurveyCostType = {
	credits: 0,
	totalRespondents: 0,
	surveyCost: 0,
};

const useCalculateSurveyCost = (surveyEntityId, niches) => {
	const [debouncedNiches, setDebouncedNiches] = useState(niches);
	const [responseCache, setResponseCache] = useState(DEFAULT_CALCULATED_SURVEY_COST);

	useDebouncedEffect(
		() => {
			setDebouncedNiches(niches);
		},
		[niches],
		500
	);

	const swrResponse = useSwr<SurveyCostType>(
		['calculate-credit', surveyEntityId, debouncedNiches],
		() => (surveyEntityId ? calculateCreditsFetcher(surveyEntityId, debouncedNiches) : DEFAULT_CALCULATED_SURVEY_COST),
		{ initialData: responseCache }
	);

	useEffect(() => {
		if (!swrResponse.data) return;
		setResponseCache(swrResponse.data);
	}, [swrResponse.data]);

	return Object.freeze({
		...swrResponse,
		// comparing object reference to know if data is initial data or not (i.e, loading or not)
		// eslint-disable-next-line eqeqeq
		isLoading: swrResponse.data == DEFAULT_CALCULATED_SURVEY_COST,
	});
};

export default useCalculateSurveyCost;
