import { Drawer, DrawerProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import { DrawerImage, DrawerSubTitle, DrawerTitle, StyledDrawerText, StyledBodyContainer } from './styled';
import { theme } from '../../consts/theme';
import { HaltProceedFooter, HaltProceedFooterProps } from '../../../components/HaltProceedFooter/HaltProceedFooter';

export type ConfirmDrawerDefaultBodyProps = {
	customTitle?: string;
	subTitle?: string;
	description?: string;
	banner: string;
};

export type ConfirmDrawerProps = DrawerProps &
	ConfirmDrawerDefaultBodyProps & {
		haltProceedFooterProps?: HaltProceedFooterProps;
		body?: ReactNode;
	};

export const ConfirmDrawerDefaultBody: FC<ConfirmDrawerDefaultBodyProps> = ({
	banner,
	children,
	customTitle,
	subTitle,
	description,
}) => (
	<StyledBodyContainer>
		<DrawerImage src={banner} />
		<StyledDrawerText>
			<DrawerTitle>{customTitle}</DrawerTitle>
			<DrawerSubTitle>{subTitle}</DrawerSubTitle>
			<DrawerSubTitle>{description}</DrawerSubTitle>
		</StyledDrawerText>
		{children}
	</StyledBodyContainer>
);

const ConfirmDrawer: FC<ConfirmDrawerProps> = ({
	customTitle = 'Are you sure?',
	subTitle = '',
	description = '',
	banner,
	haltProceedFooterProps = {}, // ignored if footer is passed
	footer,
	body,
	...props
}) => {
	const finalFooter = footer === undefined ? <HaltProceedFooter {...haltProceedFooterProps} /> : footer;
	const finalBody =
		body === undefined ? (
			<ConfirmDrawerDefaultBody
				banner={banner}
				customTitle={customTitle}
				subTitle={subTitle}
				description={description}
			/>
		) : (
			body
		);
	return (
		<Drawer
			zIndex={theme.layouts.zIndex.drawer}
			width="70rem"
			footer={finalFooter}
			footerStyle={{ padding: 0, border: 'none' }}
			{...props}
			bodyStyle={{
				background: 'white',
				display: 'flex',
				padding: 0,
				flexDirection: 'column',
			}}
			// visible={true}
		>
			{finalBody}
		</Drawer>
	);
};

export default ConfirmDrawer;
