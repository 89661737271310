import React, { FC, useMemo } from 'react';

import './spinner.scss';

type SpinnerProps = {
	isFullScreen?: boolean;
};

export const Spinner = ({ isFullScreen = false }: SpinnerProps) => {
	const SpinnerWrapper: FC = useMemo(
		() =>
			({ children }) => {
				if (isFullScreen) {
					return <div className="spinner-wrapper">{children}</div>;
				}
				return <>{children}</>;
			},
		[isFullScreen]
	);
	return (
		<SpinnerWrapper>
			<section className="loader" />
		</SpinnerWrapper>
	);
};
