import {
	ReadBrandAssetsPayload,
	ReadBrandAssets,
	READ_BRAND_ASSETS,
	BrandAsset,
	ReadBrandAssetsFulfilled,
	READ_BRAND_ASSETS_FULFILLED,
	DeleteBrandAssets,
	DELETE_BRAND_ASSETS,
	DeleteBrandAssetsFulfilled,
	DELETE_BRAND_ASSETS_FULFILLED,
	CreateBrandAssetsPayload,
	CreateBrandAssets,
	CREATE_BRAND_ASSETS,
	CreateBrandAssetsFulfilled,
	CREATE_BRAND_ASSETS_FULFILLED,
} from './types';

export const readBrandAssets = (payload: ReadBrandAssetsPayload): ReadBrandAssets => ({
	type: READ_BRAND_ASSETS,
	payload,
});

export const readBrandAssetsFulfilled = (payload: BrandAsset[]): ReadBrandAssetsFulfilled => ({
	type: READ_BRAND_ASSETS_FULFILLED,
	payload: payload,
});

export const deleteBrandAssets = (payload: BrandAsset[]): DeleteBrandAssets => ({
	type: DELETE_BRAND_ASSETS,
	payload,
});

export const deleteBrandAssetsFulfilled = (payload: BrandAsset[]): DeleteBrandAssetsFulfilled => ({
	type: DELETE_BRAND_ASSETS_FULFILLED,
	payload: payload,
});

export const createBrandAssets = (payload: CreateBrandAssetsPayload): CreateBrandAssets => ({
	type: CREATE_BRAND_ASSETS,
	payload,
});

export const createBrandAssetsFulfilled = (payload: BrandAsset[]): CreateBrandAssetsFulfilled => ({
	type: CREATE_BRAND_ASSETS_FULFILLED,
	payload: payload,
});
