import { READ_WORKSPACE_TEAM_FULFILLED, WorkspaceTeamReducer, WorkspaceTeamState } from './types';

const initialState: WorkspaceTeamState = {
	teamMembers: [],
	totalPendingApprovalCount: 0,
};

export const workspaceTeamReducer: WorkspaceTeamReducer = (state = initialState, action) => {
	switch (action.type) {
		case READ_WORKSPACE_TEAM_FULFILLED:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};
