import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { combineWatchers } from '../shared/utils/conbineWatchers';
import { surveyReducer } from './survey/surveyBuilder/shared/reducer';
import { SurveyBuilderState } from './survey/surveyBuilder/shared/types';
import { surveyResponseReducer } from './survey/surveyResponse/reducer';
import { SurveyResponseState } from './survey/surveyResponse/types';
import { userReducer } from './auth/reducer';
import { userSagas } from './auth/sagas';
import { UserState } from './auth/types';
import { WorkspacesState } from './workspace/types';
import { workspaceReducer } from './workspace/reducer';
import { workspaceSagas } from './workspace/sagas';
import { WorkspaceUserState } from './workspaceUsers/types';
import { workspaceUserSagas } from './workspaceUsers/sagas';
import { workspaceUserReducer } from './workspaceUsers/reducer';
import { BrandState } from './brand/types';
import { brandReducer } from './brand/reducer';
import { brandSagas } from './brand/sagas';
import { redirectReducer } from './redirect/reducer';
import { RedirectState } from './redirect/types';
import { surveyBuilderWatchers } from './survey/surveyBuilder/shared/sagas';
import { targetingSagas } from './targeting/sagas';
import { TargetingState } from './targeting/types';
import { targetingReducer } from './targeting/reducer';
import { dashboardSagas } from './dashboards/sagas';
import { DashboardState } from './dashboards/types';
import { dashboardReducer } from './dashboards/reducer';
import { surveyListWatchers } from './survey/surveyList/sagas';
import { surveyListReducer } from './survey/surveyList/reducer';
import { SurveyListState } from './survey/surveyList/types';
import { questionnaireActionWatchers } from './questionnaire/sagas';
import { QuestionnaireState } from './questionnaire/types';
import { questionnaireReducer } from './questionnaire/reducer';
import { surveyBrandWatchers } from './survey/surveyBrand/sagas';
import { SurveyBrandState } from './survey/surveyBrand/types';
import { surveyBrandReducer } from './survey/surveyBrand/reducer';
import { getSurveyDataWatchers } from './survey/surveyPreview/sagas';
import { SurveyPreviewDataState } from './survey/surveyPreview/types';
import { surveyPreviewDataReducer } from './survey/surveyPreview/reducer';
import { ResearchState } from './research/types';
import { researchReducer } from './research/reducer';
import { approvalReducer } from './approval/reducer';
import { researchTemplatesWatchers } from './research/sagas';
import { ApprovalState } from './approval/types';
import { approvalWatchers } from './approval/sagas';
import { PromiseState } from './promise/types';
import { promiseReducer } from './promise/reducer';
import { workspaceTeamReducer } from './workspaceTeams/reducer';
import { workspaceTeamSagas } from './workspaceTeams/sagas';
import { WorkspaceTeamState } from './workspaceTeams/types';
import { SurveyAnalytics } from './surveyAnalytics/types';
import { AnalyticsReducer } from './surveyAnalytics/reducer';
import { surveyAnalyticsSagas } from './surveyAnalytics/sagas';
import { VariablesState } from './survey/customVariable/types';
import { customVariableReducer } from './survey/customVariable/reducer';
import { getCustomVariablesWatchers } from './survey/customVariable/saga';

const surveyDesignerWatchers = combineWatchers(
	userSagas,
	workspaceSagas,
	workspaceUserSagas,
	dashboardSagas,
	targetingSagas,
	brandSagas,
	surveyBuilderWatchers,
	surveyListWatchers,
	surveyBrandWatchers,
	getSurveyDataWatchers,
	researchTemplatesWatchers,
	approvalWatchers,
	questionnaireActionWatchers,
	workspaceTeamSagas,
	surveyAnalyticsSagas,
	getCustomVariablesWatchers
);

export function* rootSaga() {
	yield all(surveyDesignerWatchers);
}

const sagaMiddleware = createSagaMiddleware();

export type StoreState = {
	user: UserState;
	surveyBuilder: SurveyBuilderState;
	surveyResponse: SurveyResponseState;
	workspaces: WorkspacesState;
	workspaceuser: WorkspaceUserState;
	workspaceTeam: WorkspaceTeamState;
	brands: BrandState;
	redirect: RedirectState;
	targeting: TargetingState;
	dashboard: DashboardState;
	surveyList: SurveyListState;
	questionnaire: QuestionnaireState;
	surveyBrand: SurveyBrandState;
	surveyData: SurveyPreviewDataState;
	research: ResearchState;
	approval: ApprovalState;
	promise: PromiseState;
	surveyAnalytics: SurveyAnalytics;
	customVariables: VariablesState;
};

const commonReducers = {
	surveyResponse: surveyResponseReducer,
	promise: promiseReducer,
	redirect: redirectReducer,
};

const surveyDesignerReducers = {
	...commonReducers,
	user: userReducer,
	surveyBuilder: surveyReducer,
	workspaces: workspaceReducer,
	workspaceUser: workspaceUserReducer,
	workspaceTeam: workspaceTeamReducer,
	dashboard: dashboardReducer,
	brands: brandReducer,
	targeting: targetingReducer,
	surveyList: surveyListReducer,
	questionnaire: questionnaireReducer,
	surveyBrand: surveyBrandReducer,
	surveyData: surveyPreviewDataReducer,
	research: researchReducer,
	approval: approvalReducer,
	surveyAnalytics: AnalyticsReducer,
	customVariables: customVariableReducer,
};

const appReducer = combineReducers(surveyDesignerReducers);
const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_USER') {
		state = undefined;
	}

	return appReducer(state, action);
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
