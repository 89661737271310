import { makeActionCreatorSurvey } from '../../../../shared/utils/makeActionCreator';
import {
	CREATE_SURVEY,
	CREATE_SURVEY_FROM_RESEARCH,
	CreateSurveyAction,
	CreateSurveyFromResearchesAction,
	GET_SURVEY,
	GetSurveyAction,
	GlobalSettingsPayload,
	Questionnaire,
	SCHEDULE_SURVEY,
	ScheduleSurveyAction,
	UpdateSurveyGamesAction,
	SET_ACTIVE_SURVEY,
	SET_SURVEY,
	SET_SURVEY_GAME,
	SetActiveSurveyAction,
	SetSurveyInfoAction,
	SurveyInfo,
	UPDATE_GLOBAL_SETTINGS,
	UPDATE_SURVEY,
	UPDATE_SURVEY_DATA,
	UpdateGlobalSettingsAction,
	updatePayload,
	ScheduleSurveyActionPayload,
	UpdateSurveyResearchAction,
	UpdateSurveyResearchActionPayload,
	UPDATE_SURVEY_RESEARCH,
	UPDATE_SURVEY_RESEARCH_FULFILLED,
	Survey,
	UpdateSurveyResearchFulfilled,
	GET_PUBLIC_SURVEY,
	GetPublicSurveyAction,
	CREATE_MODERATE_SLOT,
	ModerateSlotPayload,
	CreateModerateSlotAction,
	UpdateSurveyDesignThemeActionPayload,
	UPDATE_SURVEY_DESIGN_THEME,
	UpdateSurveyDesignThemeAction,
	SET_SURVEY_DESIGN_THEME,
	SetSurveyDesignThemeInfoAction,
} from './types';

export const createSurvey = makeActionCreatorSurvey(CREATE_SURVEY, 'brandId', 'workspaceId') as (
	brandId: string,
	workspaceId: string
) => CreateSurveyAction;
export const getSurveyAction = makeActionCreatorSurvey(GET_SURVEY, 'id', 'doReset') as (
	id: string,
	doReset?: boolean
) => GetSurveyAction;
export const getPublicSurveyAction = makeActionCreatorSurvey(GET_PUBLIC_SURVEY, 'id') as (
	id: string
) => GetPublicSurveyAction;
export const setSurveyInfoAction = makeActionCreatorSurvey(UPDATE_SURVEY, 'info') as (
	info: SurveyInfo
) => SetSurveyInfoAction;
export const setSurveyAction = makeActionCreatorSurvey(SET_SURVEY, 'questionnaire') as (
	questionnaire: Questionnaire
) => SetSurveyInfoAction;
export const setSurveyGameAction = makeActionCreatorSurvey(SET_SURVEY_GAME, 'surveyId', 'game') as (
	surveyId: string,
	game: string
) => UpdateSurveyGamesAction;
export const createSurveyFromResearchesAction = makeActionCreatorSurvey(
	CREATE_SURVEY_FROM_RESEARCH,
	'questionnaireId',
	'researches'
) as (questionnaireId: string, researches: string[]) => CreateSurveyFromResearchesAction;
export const setActiveSurveyAction = makeActionCreatorSurvey(SET_ACTIVE_SURVEY, 'id') as (
	id: string
) => SetActiveSurveyAction;
export const updateGloballSettingsAction = makeActionCreatorSurvey(UPDATE_GLOBAL_SETTINGS, 'info') as (
	info: GlobalSettingsPayload
) => UpdateGlobalSettingsAction;

export const createModerateSlotAction = makeActionCreatorSurvey(CREATE_MODERATE_SLOT, 'info') as (
	info: ModerateSlotPayload
) => CreateModerateSlotAction;

export const scheduleSurveyAction = (payload: ScheduleSurveyActionPayload): ScheduleSurveyAction => ({
	type: SCHEDULE_SURVEY,
	payload,
});

export const updateSurveyGamesAction = makeActionCreatorSurvey(UPDATE_SURVEY_DATA, 'surveyId', 'updatePayload') as (
	surveyId: string,
	updatePayload: updatePayload
) => UpdateSurveyGamesAction;

export const updateSurveyResearch = (payload: UpdateSurveyResearchActionPayload): UpdateSurveyResearchAction => ({
	type: UPDATE_SURVEY_RESEARCH,
	payload,
});

export const updateSurveyResearchFulfilled = (survey: Survey): UpdateSurveyResearchFulfilled => ({
	type: UPDATE_SURVEY_RESEARCH_FULFILLED,
	payload: { survey },
});

export const updateSurveyDesignTheme = (payload: any): UpdateSurveyDesignThemeAction => ({
	type: UPDATE_SURVEY_DESIGN_THEME,
	payload,
});

export const setSurveyDesignTheme = makeActionCreatorSurvey(SET_SURVEY_DESIGN_THEME, 'surveyTemplateSetting') as (
	surveyTemplateSetting: any
) => SetSurveyDesignThemeInfoAction;
