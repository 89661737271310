import { ResearchTemplate } from '../../shared/types/ResearchTemplate';
import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { ResearchModuleT } from '../../shared/types/ResearchModule';

export const GET_RESEARCH_TEMPLATES = 'GET_RESEARCH_TEMPLATES';
export const SET_RESEARCH_TEMPLATES = 'SET_RESEARCH_TEMPLATES';
export const SET_RESEARCH_TEMPLATES_ERROR = 'SET_RESEARCH_TEMPLATES_ERROR';

export type ResearchState = {
	modules: ResearchModuleT[];
	templates: ResearchTemplate[];
	requestInProgress: boolean;
	error: string;
};

export type GetResearchTemplatesAction = ReduxAction<typeof GET_RESEARCH_TEMPLATES, null>;
export type SetResearchTemplatesAction = ReduxAction<
	typeof SET_RESEARCH_TEMPLATES,
	{ modules: ResearchModuleT[]; templates: ResearchTemplate[] }
>;
export type SetResearchTemplatesErrorAction = ReduxAction<typeof SET_RESEARCH_TEMPLATES_ERROR, string>;

export type ResearchReducer = ReduxReducer<
	ResearchState,
	GetResearchTemplatesAction | SetResearchTemplatesAction | SetResearchTemplatesErrorAction
>;
