import { putEffect } from '../../../shared/utils/putEffect';
import { setResearchTemplatesAction, setResearchTemplatesErrorAction } from '../actions';
import { getApiError } from '../../../shared/utils/getApiError';
import {
	mapTemplatesResponseToResearchModules,
	mapTemplatesResponseToResearchTemplates,
	putCreateFromScratchToTop,
} from './utils';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { surveysApiEndPoint } from '../../survey/surveyBuilder/surveyInfo/sagas/consts';

export function* getResearchTemplates() {
	try {
		const { data } = yield axios.get(getApiEndPointUrl(surveysApiEndPoint, 'templates'));
		yield putEffect(
			setResearchTemplatesAction(
				mapTemplatesResponseToResearchModules(data),
				putCreateFromScratchToTop(mapTemplatesResponseToResearchTemplates(data))
			)
		);
	} catch (e) {
		yield putEffect(setResearchTemplatesErrorAction(getApiError(e)));
	}
}
