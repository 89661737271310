import { Link } from 'react-navi';
import styled, { css } from 'styled-components';
import { MixinText16, MixinText14 } from '../Typography/styled';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
export const MixinButton = css<{
	disabled?: boolean;
	onClick?: (e: Event) => void;
	$fakeDisabled?: boolean;
}>`
	cursor: pointer;
	font-weight: 500;
	background-color: ${({ theme, disabled, $fakeDisabled }) =>
		disabled || $fakeDisabled ? theme.colors.lightPurple : theme.colors.primaryButton};
	border-radius: 0.4rem;
	color: ${({ theme }) => theme.colors.surfaceLight};
	border: none;
	/* display: inline-block;
  vertical-align: middle; */
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;
export const MixinHaltButton = css`
	${MixinButton}
	color: ${({ theme }) => theme.colors.primaryButton};
	/* cursor: pointer; */
	border: none;
	background: none;
	padding: 0;
	font-size: ${({ theme }) => theme.fonts.size.big};
`;
export const MixinThickButton = css`
	${MixinText16}
	${MixinButton}
  padding: 0 2.4rem;
	height: 5.6rem;
`;
export const MixinThinButton = css`
	${MixinText14}
	${MixinButton}
  padding: 0 1.6rem;
	height: 4rem;
`;
export const MixinOutlinedButton = css`
	${MixinText14}
	${MixinHaltButton}
  padding: 0 1.6rem;
	border: solid 2px ${({ theme }) => theme.colors.primaryButton};
	background: #fff;
	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.primaryButton};
		color: ${({ theme }) => theme.colors.primaryButton};
	}
`;
export const MixinThinOutlinedButton = css`
	height: 4rem;
	${MixinOutlinedButton}
`;
export const MixinThickOutlinedButton = css`
	height: 5.6rem;
	${MixinOutlinedButton}
`;
const defaultProps = ({ type }) => ({
	type: type || 'button',
});
export const StyledHaltButton = styled.button.attrs(defaultProps)`
	${MixinHaltButton}
`;

export const StyledThickButton = styled.button.attrs(defaultProps)<{
	color?: string;
	borderButton?: boolean;
	colorTemplate?: any;
	buttonsColor?: string;
	buttonTextColor?: string;
	isRounded?: boolean;
}>`
	${MixinThickButton}
	background: ${({ disabled, borderButton, color }) => (borderButton ? '#fff !important' : `${color} !important`)};
	color: ${({ disabled, borderButton, buttonsColor, buttonTextColor }) =>
		buttonTextColor
			? borderButton && (buttonTextColor === '#fff' || buttonTextColor === '#ffffff')
				? `${buttonsColor}  !important`
				: `${buttonTextColor}  !important`
			: !!borderButton
			? `${buttonsColor} !important`
			: disabled
			? `#FFF !important`
			: '#fff'};
	border: 1px solid ${({ color }) => color};
	border-color: ${({ disabled, borderButton, buttonsColor, buttonTextColor }) =>
		buttonTextColor
			? borderButton && (buttonTextColor === '#fff' || buttonTextColor === '#ffffff')
				? `${buttonsColor}  !important`
				: `${buttonTextColor}  !important`
			: !!borderButton
			? `${buttonsColor} !important`
			: disabled
			? `#FFF !important`
			: '#fff'};

	border-radius: ${({ isRounded }) => isRounded && '3.5rem !important'};
	&:focus,
	&:hover {
		background: ${({ disabled, borderButton, color }) => (borderButton ? '#fff !important' : `${color} !important`)};
		color: ${({ disabled, borderButton, color, colorTemplate }) =>
			borderButton
				? colorTemplate?.buttonTextColor
					? colorTemplate?.buttonTextColor
					: `${color} !important`
				: '#fff'};
		color: ${({ disabled, borderButton, buttonsColor, buttonTextColor }) =>
			buttonTextColor
				? borderButton && (buttonTextColor === '#fff' || buttonTextColor === '#ffffff')
					? `${buttonsColor}  !important`
					: `${buttonTextColor}  !important`
				: !!borderButton
				? `${buttonsColor} !important`
				: disabled
				? `#FFF !important`
				: '#fff'};
		border: 1px solid ${({ color }) => color};
		border-color: ${({ disabled, borderButton, buttonsColor, buttonTextColor }) =>
			buttonTextColor
				? borderButton && (buttonTextColor === '#fff' || buttonTextColor === '#ffffff')
					? `${buttonsColor}  !important`
					: `${buttonTextColor}  !important`
				: !!borderButton
				? `${buttonsColor} !important`
				: disabled
				? `#FFF !important`
				: '#fff'};
	}
`;
export const StyledThinButton = styled.button.attrs(defaultProps)`
	${MixinThinButton}
`;
export const StyledThickLink = styled(Link)`
	${MixinThickButton}
`;
export const StyledThinLink = styled(Link)`
	${MixinThinButton}
`;
export const StyledThinAnchor = styled.a`
	${MixinThinButton}
`;
export const StyledThickAnchor = styled.a`
	${MixinThickButton}
`;
export const StyledThinOutlinedLink = styled(Link)`
	${MixinThinOutlinedButton}
`;
export const StyledThickOutlinedLink = styled(Link)`
	${MixinThickOutlinedButton}
`;
export const StyledThinOutlinedButton = styled.button.attrs(defaultProps)`
	${MixinThinOutlinedButton}
`;
export const StyledThickOutlinedButton = styled.button.attrs(defaultProps)`
	${MixinThickOutlinedButton}
`;
export const StyledInfoButton = styled(InfoCircleOutlined)<{ size?: number; $color?: string }>`
	font-size: ${({ size }) => (size ? `${size}rem` : '2.4rem')};
	color: ${({ $color = '#9da4fb' }) => $color};
`;

// // CHECKPOINT 1 !!!
