import { SurveyAction, SurveyActionType } from '../../store/survey/surveyBuilder/shared/types';
import { SurveyResponseAction, SurveyResponseActionType } from '../../store/survey/surveyResponse/types';

export const commonCode = (actionType: any, argNames: string[], args: any): { type: any; payload: any } => {
	let actionPayload: any = {};
	argNames.forEach((arg: string, index: number) => {
		actionPayload[arg] = args[index];
		return;
	});

	return {
		type: actionType,
		payload: actionPayload,
	};
};

export const makeActionCreatorSurvey =
	(actionType: SurveyActionType, ...argNames: string[]) =>
	(...args: any): SurveyAction => {
		return commonCode(actionType, argNames, args);
	};

export const makeActionCreatorSurveyResponse =
	(actionType: SurveyResponseActionType, ...argNames: string[]) =>
	(...args: any): SurveyResponseAction => {
		return commonCode(actionType, argNames, args);
	};
