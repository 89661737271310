import { CreateSurveyAction } from '../types';
import axios from '@utils/axios';
import { questionnairesApiEndpoint } from './consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { fulfill, pend, reject } from '../../../../promise/actions';
import { createSurvey } from '../actions';

export function* createSurveySaga(action: CreateSurveyAction) {
	const { brandId, description, name } = action.payload;

	try {
		yield putEffect(pend(createSurvey));
		const { data } = yield axios.post(getApiEndPointUrl(questionnairesApiEndpoint), { brandId, description, name });
		yield putEffect(setSurveyError(''));
		yield putEffect(fulfill(createSurvey, data));
	} catch (e) {
		yield putEffect(setSurveyError(getApiError(e)));
		yield putEffect(reject(createSurvey, getApiError(e)));
	}
}
