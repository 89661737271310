export const READ_COMPARISON_ANALYTIC_REPORT = 'READ_COMPARISON_ANALYTIC_REPORT';
export const READ_COMPARISON_ANALYTICS_REPORT_FULFILLED = 'READ_COMPARISON_ANALYTICS_REPORT_FULFILLED';
export type ComparisonResponse = {
	data: any[];
	responseCount: number;
	averageTime: string;
	denominator: any[];
};
export const readComparisonReport = (payload: any) => ({
	type: READ_COMPARISON_ANALYTIC_REPORT,
	payload: payload,
});

export const readComparisonReportFulfilled = (payload: any) => ({
	type: READ_COMPARISON_ANALYTICS_REPORT_FULFILLED,
	payload,
});
