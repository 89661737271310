import { RenameWorkspacePayload, UserObj, UserWithRole, WorkspaceObj } from './types';
import { adjust, unionWith } from 'ramda';
import cloneDeep from 'lodash.clonedeep';

type RequestResponse = {
	id: string;
	name: string;
};

export const mapRenameRequestResponseToPayload = (data: RequestResponse): RenameWorkspacePayload => ({
	workspaceId: data.id,
	newWorkspaceName: data.name,
});

export const updateUsers = (oldL: UserObj[], newL: UserObj[]): UserObj[] => {
	return unionWith(
		(oldUser, newUser) => {
			return oldUser.user.id === newUser.user.id;
		},
		oldL,
		newL
	);
};

export const upsertUsers = (old: UserObj[], newUsers: UserObj[]): UserObj[] => {
	return newUsers.reduce((acc, newUser) => {
		const index = acc.findIndex((user) => user.user.id === newUser.user.id);
		if (index < 0) {
			return [...acc, newUser];
		}
		return adjust(index, () => newUser, acc);
	}, old);
};

/** note: all `UserWithRole` needs to be from same workspace */
export const addUsersToWorkspace = (workspaces: WorkspaceObj[], usersWithRole: UserWithRole[]) => {
	const clonedWorkspaces = cloneDeep(workspaces);
	const workspaceObj = clonedWorkspaces.find((workspaceObj) => {
		return workspaceObj.workspace.id === usersWithRole[0].role.workspace.id;
	});
	workspaceObj?.workspaceUsers.push(...usersWithRole);

	return clonedWorkspaces;
};

/** note: all `UserWithRole` needs to be from same workspace */
export const updateWorkspaceUsers = (workspaces: WorkspaceObj[], usersWithRole: UserWithRole[]) => {
	const clonedWorkspaces = cloneDeep(workspaces);
	const workspaceIndex = clonedWorkspaces.findIndex((workspaceObj) => {
		return workspaceObj.workspace.id === usersWithRole[0].role.workspace.id;
	});
	if (workspaceIndex !== -1) {
		const updates = usersWithRole.reduce((acc, el) => {
			acc[el.user.id] = el;
			return acc;
		}, {} as Record<string, UserWithRole>);

		clonedWorkspaces[workspaceIndex].workspaceUsers = clonedWorkspaces[workspaceIndex].workspaceUsers.map(
			(workspaceUser) => {
				return updates[workspaceUser.user.id] || workspaceUser;
			}
		);
	}

	return clonedWorkspaces;
};
