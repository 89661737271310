export const environment = {
	isLite: process.env.mode === 'lite',
	isUgc: process.env.mode === 'ugc',
	apiUrl: `${process.env.REACT_APP_API_URL}survey-designer/`,
	targetingApiUrl: `${process.env.REACT_APP_API_URL}geo-data/`,
	questionnaireApiUrl: `${process.env.REACT_APP_API_URL}questionnaire/`,
	surveyAnalyticsApiUrl: `${process.env.REACT_APP_API_URL}survey-analytics/`,
	paymentApiUrl: `${process.env.REACT_APP_API_URL}payment/`,
	questionnaireResponseApiUrl: `${process.env.REACT_APP_API_URL}questionnaire-response/`,
	activeQuestionnaireUrl: process.env.REACT_APP_ACTIVE_QUESTIONNAIRE_URL as string,
	targetingInterests: `${process.env.REACT_APP_API_URL}targeting-data?dataType=interest`,
	notifications: {
		apiUrl: process.env.REACT_APP_NOTIFICATIONS_API_URL as string,
		clientSecret: process.env.REACT_APP_NOTIFICATIONS_CLIENT_SECRET as string,
		app: process.env.REACT_APP_NOTIFICATIONS_APP as string,
	},
	salesforce: {
		oid: process.env.SALESFORCE_OID,
		utm_parameter_key: process.env.UTM_PARAMETER_KEY,
	},
};
