import { Message, MessageRepsonse } from './types';

type MapResponseToComments = (data: MessageRepsonse) => Message;
export const mapResponseToComments: MapResponseToComments = (data) => {
	return {
		id: data.id,
		content: data.content,
		createdAt: new Date(data.createdAt).toDateString(),
		questionnaireId: data.questionnaireId,
		profilePicture: data.author.profilePicture,
		authorName: data.author.firstName ? `${data.author.firstName} ${data.author.lastName}` : 'N/A',
	};
};
