import {
	GET_SURVEY_DATA,
	GetSurveyPreviewDataAction,
	RELOAD_SURVEY_PREVIEW_STATE,
	ReloadSurveyPreviewState,
	SET_ACTIVE_SURVEY_PREVIEW,
	SET_SURVEY_DATA,
	SET_SURVEY_DATA_ERROR,
	SetActiveSurveyPreviewAction,
	SetSurveyPreviewDataAction,
	SetSurveyPreviewDataActionPayload,
	SetSurveyPreviewDataErrorAction,
	SetCreditsInfoAction,
	SET_CREDITS_INFO,
} from './types';

// This is questionnaire not survey. #fix it someday
export const getSurveyPreviewDataAction: (id: string) => GetSurveyPreviewDataAction = (id) => ({
	type: GET_SURVEY_DATA,
	payload: { id },
});

export const setSurveyPreviewDataAction: (patch: SetSurveyPreviewDataActionPayload) => SetSurveyPreviewDataAction = (
	data
) => ({
	type: SET_SURVEY_DATA,
	payload: data,
});

export const setSurveyPreviewDataErrorAction: (error: string) => SetSurveyPreviewDataErrorAction = (error) => ({
	type: SET_SURVEY_DATA_ERROR,
	payload: { error },
});

export const setActiveSurveyPreviewAction: (id: string) => SetActiveSurveyPreviewAction = (id) => ({
	type: SET_ACTIVE_SURVEY_PREVIEW,
	payload: { id },
});

export const reloadSurveyPreviewState: () => ReloadSurveyPreviewState = () => ({
	type: RELOAD_SURVEY_PREVIEW_STATE,
	payload: null,
});

export const setCreditsInfo: (payload: {
	credits: number;
	totalRespondents: number;
	surveyCost: number;
}) => SetCreditsInfoAction = (payload) => ({
	type: SET_CREDITS_INFO,
	payload,
});
