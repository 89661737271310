import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const READ_BRAND_FUNNEL_COMPARISON = 'READ_BRAND_FUNNEL_COMPARISON';
export const READ_BRAND_FUNNEL_COMPARISON_FULFILLED = 'READ_BRAND_FUNNEL_COMPARISON_FULFILLED';
export type CompetitorData = {
	count: number;
	questionId: string;
	index: number;
};
export type DataByCompetitor = {
	competitor: string;
	data: CompetitorData[];
};
export type TotalResponseData = {
	count: number;
	questionId: string;
	date?: string;
};
export type BrandFunnelCombinedData = {
	_id?: string; // can be cohort or date whichever is on
	dataByCompetitor: DataByCompetitor[];
	totalResponses: TotalResponseData[];
};

export type BrandFunnelComparison = {
	firstRange: BrandFunnelCombinedData;
	secondRange: BrandFunnelCombinedData;
	surveyId: string;
};
export type ReadBrandFunnelComparisonPayload = {
	id: string;
	firstRangeFrom: string;
	secondRangeFrom: string;
	firstRangeTo: string;
	secondRangeTo: string;
	cohorts: string[];
};

export const BrandFunnelComparisonSchema: JSONSchema = {
	type: 'object',
	properties: {
		firstRange: { type: 'object' },
		secondRange: { type: 'object' },
	},
};

export type ReadBrandFunnelComparisonData = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMPARISON,
	ReadBrandFunnelComparisonPayload
>;
export type ReadBrandFunnelComparisonDataFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMPARISON_FULFILLED,
	BrandFunnelComparison
>;

export const READ_BRAND_FUNNEL_DAILY_DATA = 'READ_BRAND_FUNNEL_DAILY_DATA';
export const READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED = 'READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED';
export type ReadBrandFunnelDailyDataPayload = {
	id: string;
	from: string;
	to: string;
	cohorts: string[];
	timezone: string;
};
export const BrandFunnelDailyDataSchema: JSONSchema = {
	type: 'object',
};

export type ReadBrandFunnelDailyData = ReduxAction<
	typeof READ_BRAND_FUNNEL_DAILY_DATA,
	ReadBrandFunnelDailyDataPayload
>;
export type ReadBrandFunnelDailyDataFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_DAILY_DATA_FULFILLED,
	BrandFunnelCombinedData[]
>;

export const READ_BRAND_FUNNEL_DATA_BY_COHORT = 'READ_BRAND_FUNNEL_DATA_BY_COHORT';
export const READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED = 'READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED';
export type ReadBrandFunnelDataByCohortPayload = {
	id: string;
	from: string;
	to: string;
	brand: string;
	cohorts: string[];
};
export const BrandFunnelDataByCohortSchema: JSONSchema = {
	type: 'object',
};

export type ReadBrandFunnelDataByCohort = ReduxAction<
	typeof READ_BRAND_FUNNEL_DATA_BY_COHORT,
	ReadBrandFunnelDataByCohortPayload
>;
export type ReadBrandFunnelDataByCohortFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_DATA_BY_COHORT_FULFILLED,
	BrandFunnelCombinedData[]
>;

export const READ_BRAND_FUNNEL_COMBINED_DATA = 'READ_BRAND_FUNNEL_COMBINED_DATA';
export const READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED = 'READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED';
export type ReadBrandFunnelCombinedDataPayload = {
	id: string;
	from: string;
	to: string;
	cohorts: string[];
};
export const BrandFunnelCombinedDataSchema: JSONSchema = {
	type: 'object',
};

export type ReadBrandFunnelCombinedData = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMBINED_DATA,
	ReadBrandFunnelCombinedDataPayload
>;
export type ReadBrandFunnelCombinedDataFulfilled = ReduxAction<
	typeof READ_BRAND_FUNNEL_COMBINED_DATA_FULFILLED,
	BrandFunnelCombinedData
>;

export type BrandFunnelAction =
	| ReadBrandFunnelComparisonData
	| ReadBrandFunnelComparisonDataFulfilled
	| ReadBrandFunnelDailyData
	| ReadBrandFunnelDailyDataFulfilled
	| ReadBrandFunnelDataByCohort
	| ReadBrandFunnelDataByCohortFulfilled
	| ReadBrandFunnelCombinedData
	| ReadBrandFunnelCombinedDataFulfilled;

export type BrandFunnelReducer = ReduxReducer<SurveyAnalytics, BrandFunnelAction>;
