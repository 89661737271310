import {
	APPROVE,
	ApproveAction,
	ApproveActionSuccess,
	APPROVE_SUCCESS,
	Count,
	GetMessagesAction,
	GetMessagesActionSuccess,
	GetQuestionnaireCountAction,
	GetQuestionnaireCountActionSuccess,
	GET_MESSAGES,
	GET_MESSAGES_SUCCESS,
	GET_QUESTIONNAIRES_COUNT,
	GET_QUESTIONNAIRES_COUNT_SUCCESS,
	Message,
	PostMessageAction,
	PostMessageActionSuccess,
	PostMessagePayload,
	POST_MESSAGE,
	POST_MESSAGE_SUCCESS,
	REJECT,
	RejectAction,
	RejectActionSuccess,
	REJECT_SUCCESS,
	SetApprovalError,
	SET_APPROVAL_ERROR,
} from './types';

export const getMessagesAction = (id: string): GetMessagesAction => {
	return {
		type: GET_MESSAGES,
		payload: id,
	};
};

export const getMessagesActionSuccess = (payload: Message[]): GetMessagesActionSuccess => {
	return {
		type: GET_MESSAGES_SUCCESS,
		payload: payload,
	};
};

export const postMessageAction = (payload: PostMessagePayload): PostMessageAction => {
	return {
		type: POST_MESSAGE,
		payload: payload,
	};
};

export const postMessageActionSuccess = (payload: Message): PostMessageActionSuccess => {
	return {
		type: POST_MESSAGE_SUCCESS,
		payload: payload,
	};
};

export const approveAction = (payload: { type: string; userId: string }): ApproveAction => {
	return {
		type: APPROVE,
		payload,
	};
};

export const approveActionSuccess = (payload: string): ApproveActionSuccess => {
	return {
		type: APPROVE_SUCCESS,
		payload: payload,
	};
};

export const rejectAction = (payload: { type: string; userId: string }): RejectAction => {
	return {
		type: REJECT,
		payload,
	};
};

export const rejectActionSuccess = (payload: string): RejectActionSuccess => {
	return {
		type: REJECT_SUCCESS,
		payload: payload,
	};
};

export const getQuestionnaireCountAction = (brandId: string): GetQuestionnaireCountAction => {
	return {
		type: GET_QUESTIONNAIRES_COUNT,
		payload: brandId,
	};
};

export const getQuestionnaireCountActionSuccess = ({ count }: Count): GetQuestionnaireCountActionSuccess => {
	return {
		type: GET_QUESTIONNAIRES_COUNT_SUCCESS,
		payload: count,
	};
};

export const setApprovalError = (error: string): SetApprovalError => {
	return {
		type: SET_APPROVAL_ERROR,
		payload: error,
	};
};
