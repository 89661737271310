import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Tooltip, TooltipProps } from 'antd';

export const StyledGlobalTooltip = createGlobalStyle`
.styled-tooltip__overlay {
.ant-tooltip-inner {
border-radius: 4px;
background-color: rgba(0, 0, 0, 0.85);
font-size: 1.3513rem;
min-height: 3.6487rem;
min-width: fit-content;
}

.ant-tooltip-arrow-content,
.ant-tooltip-arrow {
width: 16px;
height: 16px;
}

.ant-tooltip-arrow-content {
background-color: rgba(0, 0, 0, 0.85);
box-shadow: none;
}
}

.styled-tooltip__overlay.ant-tooltip-placement-bottom,
.styled-tooltip__overlay.ant-tooltip-placement-bottomLeft,
.styled-tooltip__overlay.ant-tooltip-placement-bottomRight {
.ant-tooltip-arrow {
top: -8px;
}
.ant-tooltip-arrow-content {
transform: translateY(11px) rotate(45deg);
}
}

.styled-tooltip__overlay.ant-tooltip-placement-top,
.styled-tooltip__overlay.ant-tooltip-placement-topLeft,
.styled-tooltip__overlay.ant-tooltip-placement-topRight {
.ant-tooltip-arrow {
bottom: -8px;
}
.ant-tooltip-arrow-content {
transform: translateY(-11px) rotate(45deg);
}
}

.styled-tooltip__overlay.ant-tooltip-placement-right,
.styled-tooltip__overlay.ant-tooltip-placement-rightBottom,
.styled-tooltip__overlay.ant-tooltip-placement-rightTop {
.ant-tooltip-arrow {
left: -8px;
}
.ant-tooltip-arrow-content {
transform: translateX(11px) rotate(45deg);
}
}

.styled-tooltip__overlay.ant-tooltip-placement-left,
.styled-tooltip__overlay.ant-tooltip-placement-leftBottom,
.styled-tooltip__overlay.ant-tooltip-placement-leftTop {
.ant-tooltip-arrow {
right: -8px;
}
.ant-tooltip-arrow-content {
transform: translateX(-11px) rotate(45deg);
}
}
`;

export const StyledTooltip: FC<any> = (props) => (
	<Tooltip overlayClassName="styled-tooltip__overlay" destroyTooltipOnHide={true} {...props} />
);
