import {
	WorkspaceUserState,
	SET_WORKSPACES,
	GET_WORKSPACES,
	WorkspaceUserAction,
	SET_NOTIFICATION,
	SET_WORKSPACE_USER_ERROR,
	RESET_WORKSPACE_USER_ERROR,
} from './types';

const initialState: WorkspaceUserState = {
	workspaces: [],
	error: null,
	notification: null,
	loading: false,
};

export const workspaceUserReducer = (state = initialState, action: WorkspaceUserAction) => {
	switch (action.type) {
		case GET_WORKSPACES:
			return {
				...state,
				loading: true,
			};
		case SET_WORKSPACES:
			return {
				...state,
				workspaces: action.payload,
				loading: false,
			};
		case SET_NOTIFICATION:
			return {
				...state,
				loading: false,
				notification: action.payload,
			};
		case SET_WORKSPACE_USER_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case RESET_WORKSPACE_USER_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
};
