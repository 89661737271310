import {
	CREATE_BRAND_KEYWORDS_FULFILLED,
	READ_BRAND_KEYWORDS_FULFILLED,
	BrandKeywordAction,
	BrandKeywordReducer,
} from './types';

export const brandKeywordReducer: BrandKeywordReducer = (state, action: BrandKeywordAction) => {
	switch (action.type) {
		case READ_BRAND_KEYWORDS_FULFILLED:
			return {
				...state,
				keywords: action.payload,
			};
		case CREATE_BRAND_KEYWORDS_FULFILLED:
			return {
				...state,
				keywords: action.payload,
			};

		default:
			return state;
	}
};
