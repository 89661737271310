import { DeleteStroke } from '../types';
import { select } from 'redux-saga/effects';
import { MultipleOpinionScaleQuesState, SectionState } from '../../../../../shared/types/QuesTypes';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { questionSectionStrokesEndPoint } from '../consts';
import { setQuestion } from '../../sharedQuestions/actions';
import { set, updateAll } from 'shades';
import { omit, without } from 'ramda';

export function* removeStroke(action: DeleteStroke) {
	const {
		payload: { quesId, sectionId, strokeId },
	} = action;
	const { questions } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId]) {
		const question: MultipleOpinionScaleQuesState = questions[quesId];

		try {
			yield axios.delete(getApiEndPointUrl(questionSectionStrokesEndPoint, strokeId));
			const section = question.sections[sectionId];
			const strokeOrder = without([strokeId], section.strokeOrder);

			yield putEffect(
				setQuestion(
					question.id,
					updateAll<MultipleOpinionScaleQuesState>(
						set(
							'sections',
							sectionId
						)(
							updateAll<SectionState>(
								(sectionState) =>
									strokeOrder.reduce(
										(section, strokeId, index) => set('strokes', strokeId, 'orderPosition')(index + 1)(section),
										sectionState
									),
								set('strokeOrder')(strokeOrder),
								set('strokes')(omit([strokeId], section.strokes))
							)(section)
						)
					)(question)
				)
			);
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			yield axios.delete(getApiEndPointUrl(questionSectionStrokesEndPoint, strokeId));
			const section = question.sections[sectionId];
			const strokeOrder = without([strokeId], section.strokeOrder);

			questions[question?.questionnaireSectionId].questions[question.id] = updateAll<MultipleOpinionScaleQuesState>(
				set(
					'sections',
					sectionId
				)(
					updateAll<SectionState>(
						(sectionState) =>
							strokeOrder.reduce(
								(section, strokeId, index) => set('strokes', strokeId, 'orderPosition')(index + 1)(section),
								sectionState
							),
						set('strokeOrder')(strokeOrder),
						set('strokes')(omit([strokeId], section.strokes))
					)(section)
				)
			)(question);

			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	}
}
