import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { fulfill, pend, reject } from '../../promise/actions';
import {
	createNichesFulfilled,
	updateNichesFulfilled,
	readNichesFulfilled,
	deleteNichesFulfilled,
	createNiches,
	updateNiches,
	readNiches,
	deleteNiches,
	putNiches,
} from './actions';
import { nicheEndpoint } from './const';
import {
	CreateNichePayload,
	CreateNiches,
	CREATE_NICHES,
	ReadNiches,
	ReadNichesPayload,
	READ_NICHES,
	UpdateNiches,
	UpdateNichePayload,
	UPDATE_NICHEs,
	DELETE_NICHES,
	DeleteNiches,
	Niche,
	NichesSchema,
	PUT_NICHES,
} from './types';
import { getRidOfEscapeStrings } from './utils';

function createNicheAPI(niche: CreateNichePayload) {
	return axios.post(getApiEndPointUrl(nicheEndpoint), niche);
}
export function putNichesAPI(niche: CreateNichePayload[]) {
	return axios.put(getApiEndPointUrl(nicheEndpoint), niche);
}
function deleteNicheAPI(niche: Niche) {
	return axios.delete(getApiEndPointUrl(nicheEndpoint, niche.id));
}
function updateNicheAPI({ id, ...niche }: UpdateNichePayload) {
	return axios.patch(getApiEndPointUrl(nicheEndpoint, id), niche);
}
function readNichesAPI(payload: ReadNichesPayload) {
	return axios.get(getApiEndPointUrl(nicheEndpoint), { params: payload });
}

function* createNichesSaga(action: CreateNiches) {
	try {
		yield putEffect(pend(createNiches));
		const responses = yield Promise.all(action.payload.map(createNicheAPI));
		yield putEffect(
			createNichesFulfilled(
				validate(getRidOfEscapeStrings(responses.map((response: { data: any }) => response.data)), NichesSchema)
			)
		);
		yield putEffect(fulfill(createNiches));
	} catch (e) {
		yield putEffect(reject(createNiches, getApiError(e)));
	}
}
function* putNichesSaga(action: CreateNiches) {
	try {
		yield putEffect(pend(putNiches));
		const response = yield putNichesAPI(action.payload);
		yield putEffect(createNichesFulfilled(validate(getRidOfEscapeStrings(response.data), NichesSchema)));
		yield putEffect(fulfill(putNiches));
	} catch (e) {
		yield putEffect(reject(putNiches, getApiError(e)));
	}
}
function* updateNichesSaga(action: UpdateNiches) {
	try {
		yield putEffect(pend(updateNiches));
		const responses = yield Promise.all(action.payload.map(updateNicheAPI));
		yield putEffect(
			updateNichesFulfilled(
				validate(getRidOfEscapeStrings(responses.map((response: { data: any }) => response.data)), NichesSchema)
			)
		);
		yield putEffect(fulfill(updateNiches));
	} catch (e) {
		yield putEffect(reject(updateNiches, getApiError(e)));
	}
}
function* readNichesSaga(action: ReadNiches) {
	try {
		yield putEffect(pend(readNiches));
		const res = yield readNichesAPI(action.payload);
		yield putEffect(readNichesFulfilled(validate(getRidOfEscapeStrings(res.data), NichesSchema)));
		yield putEffect(fulfill(readNiches));
	} catch (e) {
		yield putEffect(reject(readNiches, getApiError(e)));
	}
}
function* deleteNichesSaga(action: DeleteNiches) {
	try {
		yield putEffect(pend(deleteNiches));
		yield Promise.all(action.payload.map(deleteNicheAPI));
		yield putEffect(deleteNichesFulfilled(action.payload));
		yield putEffect(fulfill(deleteNiches));
	} catch (e) {
		yield putEffect(reject(deleteNiches, getApiError(e)));
	}
}

function* getCreateNichesWatcher() {
	yield takeLeading(CREATE_NICHES, createNichesSaga);
}
function* getDeleteNichesWatcher() {
	yield takeLeading(DELETE_NICHES, deleteNichesSaga);
}
function* getReadNichesWatcher() {
	yield takeLeading(READ_NICHES, readNichesSaga);
}
function* getUpdateNichesWatcher() {
	yield takeLeading(UPDATE_NICHEs, updateNichesSaga);
}

function* getPutNichesWatcher() {
	yield takeLeading(PUT_NICHES, putNichesSaga);
}

export const nicheSagas = [
	getCreateNichesWatcher,
	getReadNichesWatcher,
	getUpdateNichesWatcher,
	getDeleteNichesWatcher,
	getPutNichesWatcher,
];
