import {
	MacroReducer,
	MacroAction,
	CREATE_MACRO_FULFILLED,
	UPDATE_MACRO_FULFILLED,
	READ_MACROS_FULFILLED,
} from './types';

export const macroReducer: MacroReducer = (state, action: MacroAction) => {
	switch (action.type) {
		case CREATE_MACRO_FULFILLED:
			return {
				...state,
				macros: [action.payload],
			};

		case UPDATE_MACRO_FULFILLED:
			let macros = state.macros.map((macro) => (macro.id === action.payload.id ? action.payload : macro));
			return {
				...state,
				macros,
			};

		case READ_MACROS_FULFILLED:
			return {
				...state,
				macros: action.payload,
			};

		default:
			return state;
	}
};
