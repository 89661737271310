import { SurveyBuilderState } from '../../shared/types';
import { select } from 'redux-saga/effects';
import { StoreState } from '../../../../index';
import { set } from 'shades';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { changeSectionInfo, setPopup, setQuestion, setSection } from '../actions';
import { SetDisplayLogic } from '../types';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { surveyQuestionsApiUrl, surveySectionOptionAssetsApiUrl } from './consts';
import { mapShowLogicToDisplayLogicRequest, mapSkipLogicToDisplayLogicRequest } from './utils';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { assign } from 'shared/utils/assign';
import { fulfill, pend, reject } from 'store/promise/actions';
import { getQuestionsWithoutFunctions } from '@utils/surveyBuilder';

export function* setDisplayLogic(action: SetDisplayLogic) {
	const { id, logic, type } = action.payload;
	const { questions }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
	const q = getQuestionsWithoutFunctions(questions);
	const question = type !== 'section' && set('logic', 'display', 'showIf')(logic)(q[id]);
	const isShowDisplayLogicApplied = question?.logic?.display?.showIf?.length !== 0;
	if (type === 'section') {
		// const section = set('logic', 'display', 'showIf')(logic)(
		// 	questions[id]
		// );
		questions[id].display.showIf = logic;
		yield putEffect(setSection(id, questions[id], false, false));
		try {
			yield putEffect(pend(changeSectionInfo));
			let updates = {
				id: id,
				display: {
					...mapShowLogicToDisplayLogicRequest(question.logic.display),
					...mapSkipLogicToDisplayLogicRequest(question.logic.display),
				},
			};
			const res = yield axios.patch(getApiEndPointUrl(surveySectionOptionAssetsApiUrl), updates);
			yield putEffect(setPopup(''));
			yield putEffect(fulfill(changeSectionInfo, { payload: { ...updates, res }, res }));
		} catch (e) {
			yield putEffect(reject(changeSectionInfo, getApiError(e)));
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		const nextQueId: any = Object?.keys(q)?.find(
			(key) => questions[key]?.orderPosition === question?.orderPosition + 1
		);
		//pagebreak true for next question if display-logic is enabled
		if (!!nextQueId && isShowDisplayLogicApplied) {
			yield putEffect(setQuestion(nextQueId, assign({ pageBreak: true }, q[nextQueId]), false));
		}
	}

	try {
		yield axios.patch(getApiEndPointUrl(surveyQuestionsApiUrl, question.id), {
			questionId: question.id,
			questionType: question.type,
			display: {
				...mapShowLogicToDisplayLogicRequest(question.logic.display),
				...mapSkipLogicToDisplayLogicRequest(question.logic.display),
			},
			//  display: mapShowLogicToDisplayLogicRequest(question.logic.display)// USED THIS ONCE BACKEND IS CHANGED
		});
		yield putEffect(
			setQuestion(id, assign({ pageBreak: isShowDisplayLogicApplied ? true : question?.pageBreak }, question))
		);
	} catch (e) {
		yield putEffect(setPopup(getApiError(e)));
	}
}
