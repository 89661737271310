import {
	FunnelAnalyzerStageReducer,
	FunnelAnalyzerStageAction,
	READ_FUNNEL_ANALYZER_STAGES_FULFILLED,
	UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED,
} from './types';

export const funnelAnalyzerStagesReducer: FunnelAnalyzerStageReducer = (state, action: FunnelAnalyzerStageAction) => {
	switch (action.type) {
		case READ_FUNNEL_ANALYZER_STAGES_FULFILLED:
		case UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED:
			return {
				...state,
				funnelAnalyzerStages: action.payload,
			};
		default:
			return state;
	}
};
