import { getWorkspaceEndPoint } from './const';
import { environment } from '../../environment';
import { AxiosResponse } from 'axios';

import axios from '@utils/axios';
import { GET_WORKSPACES, Workspace, Role } from './types';
import { getApiError } from '../../shared/utils/getApiError';
import { setWorkspaceUserAction, setNotificationAction } from './actions';
import { takeLeading } from 'redux-saga/effects';
import { putEffect } from '../../shared/utils/putEffect';
import { zipWith } from 'ramda';
import { PermissionRole } from '../../shared/enums/Permissions';
import { mapGetWorkspaceResponseToPayload } from './utils';

function getRolesRequest(id: string) {
	return axios.get(`${environment.apiUrl}roles?workspaceId=${id}`);
}

function getWorkspacesRequest() {
	return axios.get(`${environment.apiUrl}${getWorkspaceEndPoint}`);
}

function* getRoles(ids: string[]) {
	try {
		const data = yield Promise.all(ids.map(getRolesRequest));
		return data.map((res: AxiosResponse) => res.data);
	} catch (e) {
		yield putEffect(setNotificationAction(getApiError(e)));
	}
}

function* getWorkspaces() {
	try {
		const workspaces = mapGetWorkspaceResponseToPayload(yield getWorkspacesRequest());
		const roles = yield getRoles(workspaces.map((workspace) => workspace.id));
		const objs = zipWith(
			(workspace: Workspace, roles: Role[]) => ({
				...workspace,
				roles: roles.filter((role) => role.name !== PermissionRole.BrandOwner),
			}),
			workspaces,
			roles
		);
		yield putEffect(setWorkspaceUserAction(objs));
	} catch (e) {
		yield putEffect(setNotificationAction(getApiError(e)));
	}
}

function* getWorkspaceWatcher() {
	yield takeLeading(GET_WORKSPACES, getWorkspaces);
}

export const workspaceUserSagas = [getWorkspaceWatcher];
