import { ReduxAction } from '../../shared/types/ReduxAction';
import { ReduxReducer } from '../../shared/types/ReduxReducer';
import { Workspace } from '../workspace/types';
import { Role } from '../workspaceUsers/types';

export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_DASHBOARD_WORKSPACES = 'GET_DASHBOARD_WORKSPACES';
export const SET_DASHBOARD_WORKSPACES = 'SET_DASHBOARD_WORKSPACES';
export const GET_ROLES = 'GET_ROLES';
export const SET_ROLES = 'SET_ROLES';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const RENAME_WORKSPACE_REQUEST = 'RENAME_WORKSPACE_REQUEST';
export const RENAME_WORKSPACE = 'RENAME_WORKSPACE';
export const REMOVE_USER_FROM_WORKSPACES = 'REMOVE_USER_FROM_WORKSPACES';
export const DELETE_USER_FROM_WORKSPACES = 'DELETE_USER_FROM_WORKSPACES';
export const SET_WORKSPACE_DASHBOARD_ERROR = 'SET_WORKSPACE_DASHBOARD_ERROR';
export const SET_USER_DASHBOARD_ERROR = 'SET_USER_DASHBOARD_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const RENAME_WORKSPACE_ERROR = 'RENAME_WORKSPACE_ERROR';
export const ADD_NEW_USERS = 'ADD_NEW_USERS';
export const ADD_NEW_USERS_FULFILLED = 'ADD_NEW_USERS_FULFILLED';

export type UserProfileInformation = {
	companyName: string;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	phoneNumber: string | null;
	profilePicture: string | null;
};

export type WorkspaceUser = {
	id: string;
	role: Role;
};

export type UserObj = {
	user: UserProfileInformation;
	workspaceUsers: WorkspaceUser[];
};

export type WorkspaceObj = {
	workspace: Workspace;
	workspaceUsers: (WorkspaceUser & { user: UserProfileInformation })[];
};

export type UserWithRole = {
	user: UserProfileInformation;
	id: string;
	role: Role;
};

export type DashboardState = {
	users: UserObj[];
	totalUsers: number;
	workspaces: WorkspaceObj[];
	totalWorkspaces: number;
	workspaceRoles: Role[];
	loading: boolean;
	workspaceDashboardError: string | null;
	userDashboardError: string | null;
	renameWorkspaceError: string | null;
};

export type RenameWorkspacePayload = {
	workspaceId: string;
	newWorkspaceName: string;
};

export type RenameWorkspaceRequestPayload = RenameWorkspacePayload;

export type RenameWorkspaceRequestAction = ReduxAction<typeof RENAME_WORKSPACE_REQUEST, RenameWorkspaceRequestPayload>;
export type RenameWorkspaceAction = ReduxAction<typeof RENAME_WORKSPACE, RenameWorkspacePayload>;
export type RenameWorkspaceError = ReduxAction<typeof RENAME_WORKSPACE_ERROR, string | null>;

export type UpdateUserRoleObj = {
	workspaceRoleId: string;
	roleId: string;
};

export type UpdateUserAction = ReduxAction<typeof UPDATE_USER, UserWithRole[]>;

export type SetUserDashboardError = ReduxAction<typeof SET_USER_DASHBOARD_ERROR, string | null>;

export type SetWorkspaceDashboardError = ReduxAction<typeof SET_WORKSPACE_DASHBOARD_ERROR, string | null>;

export type DeleteUserFromWorkspaces = ReduxAction<typeof DELETE_USER_FROM_WORKSPACES, string>;

export type RemoveUserFromWorkspaces = ReduxAction<typeof REMOVE_USER_FROM_WORKSPACES, string>;

export type DeleteWorkspaceAction = ReduxAction<typeof DELETE_WORKSPACE, string>;

export type UpdateUserRolesPayload = UpdateUserRoleObj[];

export type UpdateUserRolesAction = ReduxAction<typeof UPDATE_ROLES, UpdateUserRolesPayload>;

export type SetUsersPayloaad = { items: UserObj[]; count: number };
export type SetWorkspacesPayload = { items: WorkspaceObj[]; count: number };

export type SetUsersAction = ReduxAction<typeof SET_USERS, SetUsersPayloaad>;
export type SetWorkspacesAction = ReduxAction<typeof SET_DASHBOARD_WORKSPACES, SetWorkspacesPayload>;

export type GetRolesAction = ReduxAction<typeof GET_ROLES, string[]>;

export type SetRolesPayload = Role[];
export type SetRolesAction = ReduxAction<typeof SET_ROLES, SetRolesPayload>;

export type GetUsersPayload = { offset?: number; limit?: number };
export type GetUsersAction = ReduxAction<typeof GET_USERS, GetUsersPayload>;

export type GetWorkspacesPayload = { offset?: number; limit?: number };
export type GetWorkspacesAction = ReduxAction<typeof GET_DASHBOARD_WORKSPACES, GetWorkspacesPayload>;

export type RequestObj = {
	email: string;
	roleId: string;
	workspaceId: string;
	firstName: string;
	lastName: string;
};
export type AddNewUserPayload = { data: RequestObj[]; url: string };
export type AddNewUsersAction = ReduxAction<typeof ADD_NEW_USERS, AddNewUserPayload>;
export type AddNewUsersFulfilled = ReduxAction<typeof ADD_NEW_USERS_FULFILLED, UserWithRole[]>;

export const SET_DASHBOARD_LOADING = 'SET_DASHBOARD_LOADING';
export type SetDashboardLoading = ReduxAction<typeof SET_DASHBOARD_LOADING, boolean>;

export type DashboardAction =
	| GetUsersAction
	| SetUsersAction
	| GetRolesAction
	| SetRolesAction
	| UpdateUserRolesAction
	| DeleteWorkspaceAction
	| RenameWorkspaceAction
	| RenameWorkspaceRequestAction
	| RenameWorkspaceError
	| RemoveUserFromWorkspaces
	| DeleteUserFromWorkspaces
	| SetWorkspaceDashboardError
	| SetUserDashboardError
	| UpdateUserAction
	| AddNewUsersAction
	| AddNewUsersFulfilled
	| SetDashboardLoading
	| GetWorkspacesAction
	| SetWorkspacesAction;
export type DasboardReducer = ReduxReducer<DashboardState, DashboardAction>;
