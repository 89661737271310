import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const UPDATE_FUNNEL_ANALYZER_STAGES = 'UPDATE_FUNNEL_ANALYZER_STAGES';
export const UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED = 'UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED';

export type FunnelAnalyzerStage = {
	operator: string;
	weight: number;
	quantity: string;
}[];
export type FunnelAnalyzerStages = {
	tofu: FunnelAnalyzerStage;
	mofu: FunnelAnalyzerStage;
	bofu: FunnelAnalyzerStage;
};

export const FunnelAnalyzerStagesSchema: JSONSchema = {
	anyOf: [{ type: 'object' }, { type: 'string' }],
};

export type UpdateFunnelAnalyzerStagesPayload = FunnelAnalyzerStages & { questionnaireId: string };

export type UpdateFunnelAnalyzerStages = ReduxAction<
	typeof UPDATE_FUNNEL_ANALYZER_STAGES,
	UpdateFunnelAnalyzerStagesPayload
>;
export type UpdateFunnelAnalyzerStagesFulfilled = ReduxAction<
	typeof UPDATE_FUNNEL_ANALYZER_STAGES_FULFILLED,
	FunnelAnalyzerStages
>;

export const READ_FUNNEL_ANALYZER_STAGES = 'READ_FUNNEL_ANALYZER_STAGES';
export const READ_FUNNEL_ANALYZER_STAGES_FULFILLED = 'READ_FUNNEL_ANALYZER_STAGES_FULFILLED';

export type ReadFunnelAnalyzerStagesPayload = {
	questionnaireId: string;
};

export type ReadFunnelAnalyzerStages = ReduxAction<typeof READ_FUNNEL_ANALYZER_STAGES, ReadFunnelAnalyzerStagesPayload>;
export type ReadFunnelAnalyzerStagesFulfilled = ReduxAction<
	typeof READ_FUNNEL_ANALYZER_STAGES_FULFILLED,
	FunnelAnalyzerStages
>;

export type FunnelAnalyzerStageAction =
	| ReadFunnelAnalyzerStages
	| ReadFunnelAnalyzerStagesFulfilled
	| UpdateFunnelAnalyzerStages
	| UpdateFunnelAnalyzerStagesFulfilled;

export type FunnelAnalyzerStageReducer = ReduxReducer<SurveyAnalytics, FunnelAnalyzerStageAction>;
