import { curryN, map, pickAll } from 'ramda';
import { SurveyBrand } from '../types';

type BrandResponse = {
	id: string;
	name: string;
	website: string;
	industry: string;
	brandKeywords: { id: string; keyword: string }[];
	brandAssets: { id: string; url: string }[];
	competitors: { id: string; name: string }[];
	products: { id: string; name: string; keywords: string[]; imageUrl: string }[];
};

const lazyPick = curryN(2, pickAll);
const mapTo = <T>(data: T[], props: string[]) => map<T, Partial<T>>(lazyPick(props), data);

export const mapBrandResponseToSurveyBrand = (brandResponse: BrandResponse): SurveyBrand => {
	return {
		...pickAll(['id', 'name', 'website', 'industry'], brandResponse),
		brandAssets: mapTo(brandResponse.brandAssets, ['id', 'url', 'assetId']),
		competitors: mapTo(brandResponse.competitors, ['id', 'name']),
		products: mapTo(brandResponse.products, ['id', 'name', 'keywords', 'imageUrl', 'assetId']),
		keywords: mapTo(brandResponse.brandKeywords, ['id', 'keyword']),
	} as SurveyBrand;
};
