import {
	LOGIN_USER,
	SET_TOKEN,
	SIGN_UP_USER,
	UserReducer,
	UserState,
	GET_USER_ROLES,
	GET_USER_ROLES_SUCCESS,
	LOGOUT_USER,
} from './types';

const initialUsersState: UserState = {
	authenticated: false,
	roles: [],
	isUserSignedUp: false,
};

export const userReducer: UserReducer = (state = initialUsersState, action) => {
	switch (action.type) {
		case GET_USER_ROLES:
		case LOGIN_USER: {
			return {
				...state,
			};
		}
		case SIGN_UP_USER: {
			return {
				...state,
				isUserSignedUp: true,
			};
		}
		case GET_USER_ROLES_SUCCESS:
			return {
				...state,
				roles: action.payload,
			};
		case SET_TOKEN:
			return {
				...state,
			};
		case LOGOUT_USER:
			return { ...state, authenticated: false };
		default:
			return state;
	}
};
