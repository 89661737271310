import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';
import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import { readCustomVariables, readCustomVariablesFulfilled } from './actions';
import { surveyEndPoint, CustomVariablesEndPoint } from './const';
import { ReadCustomVariables, CustomVariablesSchema, READ_CUSTOM_VARIABLES } from './types';
function readCustomVariablesAPI(id: string) {
	return axios.get(getApiEndPointUrl(surveyEndPoint, id, CustomVariablesEndPoint));
}

function* readCustomVariablesSaga(action: ReadCustomVariables) {
	try {
		yield putEffect(pend(readCustomVariables));
		const res = yield readCustomVariablesAPI(action.payload);

		yield putEffect(readCustomVariablesFulfilled(validate(res.data, CustomVariablesSchema)));
		yield putEffect(fulfill(readCustomVariables));
	} catch (e: any) {
		yield putEffect(reject(readCustomVariables, getApiError(e)));
	}
}

export const getCustomVariablesWatchers = [
	function* () {
		yield takeLeading(READ_CUSTOM_VARIABLES, readCustomVariablesSaga);
	},
];
