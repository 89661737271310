import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const UPDATE_COHORT_DEEP_DIVE_STAGES = 'UPDATE_COHORT_DEEP_DIVE_STAGES';
export const UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED = 'UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED';

export type CohortDeepDiveStage = {
	operator: string;
	weight: number;
	quantity: string;
}[];
export type CohortDeepDiveStages = {
	tofu: CohortDeepDiveStage;
	mofu: CohortDeepDiveStage;
	bofu: CohortDeepDiveStage;
};

export const CohortDeepDiveStagesSchema: JSONSchema = {
	anyOf: [{ type: 'object' }, { type: 'string' }],
};

export type UpdateCohortDeepDiveStagesPayload = CohortDeepDiveStages & { questionnaireId: string };

export type UpdateCohortDeepDiveStages = ReduxAction<
	typeof UPDATE_COHORT_DEEP_DIVE_STAGES,
	UpdateCohortDeepDiveStagesPayload
>;
export type UpdateCohortDeepDiveStagesFulfilled = ReduxAction<
	typeof UPDATE_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	CohortDeepDiveStages
>;

export const READ_COHORT_DEEP_DIVE_STAGES = 'READ_COHORT_DEEP_DIVE_STAGES';
export const READ_COHORT_DEEP_DIVE_STAGES_FULFILLED = 'READ_COHORT_DEEP_DIVE_STAGES_FULFILLED';

export type ReadCohortDeepDiveStagesPayload = {
	questionnaireId: string;
};

export type ReadCohortDeepDiveStages = ReduxAction<
	typeof READ_COHORT_DEEP_DIVE_STAGES,
	ReadCohortDeepDiveStagesPayload
>;
export type ReadCohortDeepDiveStagesFulfilled = ReduxAction<
	typeof READ_COHORT_DEEP_DIVE_STAGES_FULFILLED,
	CohortDeepDiveStages
>;

export type CohortDeepDiveStageAction =
	| ReadCohortDeepDiveStages
	| ReadCohortDeepDiveStagesFulfilled
	| UpdateCohortDeepDiveStages
	| UpdateCohortDeepDiveStagesFulfilled;

export type CohortDeepDiveStageReducer = ReduxReducer<SurveyAnalytics, CohortDeepDiveStageAction>;
