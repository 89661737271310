import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { TargetingState } from '../types';

export const READ_INTERESTS = 'READ_INTERESTS';
export const READ_INTERESTS_FULFILLED = 'READ_INTERESTS_FULFILLED';

export type ReadTargetingInterestsPayload = {
	query: string;
	limit: number;
};
export type ReadTargetingInterests = ReduxAction<typeof READ_INTERESTS, ReadTargetingInterestsPayload>;

export type ReadTargetingInterestsFulfilledPayload = any;
export type ReadTargetingInterestsFulfilled = ReduxAction<
	typeof READ_INTERESTS_FULFILLED,
	ReadTargetingInterestsFulfilledPayload
>;

export type TargetingInterestsAction = ReadTargetingInterests | ReadTargetingInterestsFulfilled;

export type TargetingInterestsReducer = ReduxReducer<TargetingState, TargetingInterestsAction>;
