import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { environment } from '../../../environment';
import { getApiError } from 'shared/utils/getApiError';
import { putEffect } from 'shared/utils/putEffect';
import { fulfill, pend, reject } from 'store/promise/actions';
import { select } from 'redux-saga/effects';
import { StoreState } from '../../index';
import {
	readGeneralAnalyticsAllReport,
	deleteGeneralAnalyticsReport,
	sendGeneralAnalyticsReportFilters,
	sendGeneralAnalyticsReportFulfilled,
	READ_GENERAL_ANALYTIC_ALL_REPORT,
	DELETE_GENERAL_ANALYTIC_REPORT,
	SEND_REPORT_FILTERS,
	UpdateReportPayload,
	deleteGeneralAnalyticsReportFulfilled,
	readGeneralAnalyticsAllReportFulfilled,
	GetReportPayload,
	DeleteReportPayload,
	readCurrentAnalyticsReport,
	readCurrentAnalyticsReportFulfilled,
	READ_CURRENT_ANALYTICS_REPORT,
	GetCurrentReportPayload,
} from './actions';
import { SurveyAnalytics } from '../types';
import { readGeneralAnalyticsFulfilled } from '../generalAnalytics/actions';
import { set } from 'shades';

export const postGeneralReportApiFilterEndPoint = `general-analytics`;

function postGeneralAnalyticsReportAPI(action: UpdateReportPayload) {
	return axios.post(`${environment.surveyAnalyticsApiUrl}general-analytics/report`, action);
}

function getGeneralAnalyticsAllReportAPI(action: GetReportPayload) {
	return axios.get(`${environment.surveyAnalyticsApiUrl}general-analytics/${action}/allReports`);
}

function getCurrentAnalyticsReportAPI(action: GetCurrentReportPayload) {
	const { questionnaireId } = action;
	return axios.get(`${environment.surveyAnalyticsApiUrl}general-analytics/report/${questionnaireId}`);
}

function deleteGeneralAnalyticsReportAPI(action: DeleteReportPayload) {
	return axios.delete(`${environment.surveyAnalyticsApiUrl}general-analytics/report/${action}`);
}

function* getGeneralAnalyticsAllReportSaga(action: ReturnType<typeof readGeneralAnalyticsAllReport>) {
	try {
		yield putEffect(pend(readGeneralAnalyticsAllReport));
		const res = yield getGeneralAnalyticsAllReportAPI(action.payload);
		yield putEffect(readGeneralAnalyticsAllReportFulfilled(res.data));
		yield putEffect(fulfill(readGeneralAnalyticsAllReport));
	} catch (e) {
		yield putEffect(reject(readGeneralAnalyticsAllReport, getApiError(e)));
	}
}

function* getCurrentAnalyticsReportSaga(action: ReturnType<typeof readCurrentAnalyticsReport>) {
	try {
		yield putEffect(pend(readCurrentAnalyticsReport));
		const res = yield getCurrentAnalyticsReportAPI(action.payload);
		yield putEffect(readCurrentAnalyticsReportFulfilled(res.data));
		yield putEffect(fulfill(readCurrentAnalyticsReport));
	} catch (e) {
		yield putEffect(reject(readCurrentAnalyticsReport, getApiError(e)));
	}
}

function* deleteGeneralAnalyticsReportSaga(action: ReturnType<typeof deleteGeneralAnalyticsReport>) {
	try {
		const { generalAnalyticsReports }: SurveyAnalytics = yield select((s: StoreState) => s.surveyAnalytics);
		yield putEffect(pend(deleteGeneralAnalyticsReport));
		yield deleteGeneralAnalyticsReportAPI(action.payload);
		const updatedRecords: UpdateReportPayload[] = generalAnalyticsReports.filter(
			(report) => report.id !== action.payload
		);
		yield putEffect(deleteGeneralAnalyticsReportFulfilled(updatedRecords));
		yield putEffect(fulfill(deleteGeneralAnalyticsReport));
	} catch (e) {
		yield putEffect(reject(deleteGeneralAnalyticsReport, getApiError(e)));
	}
}

function* postGeneralAnalyticsReportSaga(action: ReturnType<typeof sendGeneralAnalyticsReportFilters>) {
	try {
		const { generalAnalyticsReports, generalAnalytics }: SurveyAnalytics = yield select(
			(s: StoreState) => s.surveyAnalytics
		);
		yield putEffect(pend(sendGeneralAnalyticsReportFilters));
		const res = yield postGeneralAnalyticsReportAPI(action.payload);
		let updatedRecords: UpdateReportPayload[] = [];

		if (generalAnalyticsReports.findIndex((report) => report.id === action.payload.id) >= 0) {
			updatedRecords = generalAnalyticsReports.map((report) => (report.id === action.payload.id ? res.data : report));
		}
		if (generalAnalyticsReports.findIndex((report) => report.id === action.payload.id) < 0) {
			if (action.payload.id.length) {
				updatedRecords = generalAnalyticsReports.concat(action.payload);
			} else {
				updatedRecords = generalAnalyticsReports;
			}
		}

		if (action.payload.hasOwnProperty('denominator')) {
			yield putEffect(
				readGeneralAnalyticsFulfilled(set('denominator')(action.payload.denominator)(generalAnalytics[0]))
			);
		}

		yield putEffect(sendGeneralAnalyticsReportFulfilled(updatedRecords));
		yield putEffect(fulfill(sendGeneralAnalyticsReportFilters));
	} catch (e) {
		yield putEffect(reject(sendGeneralAnalyticsReportFilters, getApiError(e)));
	}
}

// function* postGeneralAnalyticsComparisonReportSaga(action: ReturnType<typeof sendGeneralAnalyticsReportComparison>) {
// 	try {
// 		const { generalAnalyticsReports }: SurveyAnalytics = yield select((s: StoreState) => s.surveyAnalytics);
// 		yield putEffect(pend(sendGeneralAnalyticsReportComparison));
// 		const res = yield postGeneralAnalyticsReportAPI(action.payload);
// 		let updatedRecords: UpdateReportPayload[] = [];
// 		if (generalAnalyticsReports.findIndex((report) => report.id === action.payload.id) >= 0) {
// 			updatedRecords = generalAnalyticsReports.map((report) => (report.id === action.payload.id ? res.data : report));
// 		}
// 		if (generalAnalyticsReports.findIndex((report) => report.id === action.payload.id) < 0) {
// 			updatedRecords = generalAnalyticsReports.concat(action.payload);
// 		}
// 		yield putEffect(sendGeneralAnalyticsComparisonReportFulfilled(updatedRecords));
// 		yield putEffect(fulfill(sendGeneralAnalyticsReportComparison));
// 	} catch (e) {
// 		yield putEffect(reject(sendGeneralAnalyticsReportComparison, getApiError(e)));
// 	}
// }

function* sendAnalyticsDataReportWatcher() {
	yield takeLeading(SEND_REPORT_FILTERS, postGeneralAnalyticsReportSaga);
}

// function* sendAnalyticsComparisonDataReportWatcher() {
// 	yield takeLeading(SEND_REPORT_FILTERS, postGeneralAnalyticsComparisonReportSaga);
// }

function* readAnalyticsDataAllReportWatcher() {
	yield takeLeading(READ_GENERAL_ANALYTIC_ALL_REPORT, getGeneralAnalyticsAllReportSaga);
}

function* readCurrentAnalyticsDataReportWatcher() {
	yield takeLeading(READ_CURRENT_ANALYTICS_REPORT, getCurrentAnalyticsReportSaga);
}

function* deleteAnalyticsDataReportWatcher() {
	yield takeLeading(DELETE_GENERAL_ANALYTIC_REPORT, deleteGeneralAnalyticsReportSaga);
}

export const generalAnalyticsReportWatcher = [
	sendAnalyticsDataReportWatcher,
	readAnalyticsDataAllReportWatcher,
	deleteAnalyticsDataReportWatcher,
	readCurrentAnalyticsDataReportWatcher,
	// sendAnalyticsComparisonDataReportWatcher,
];
