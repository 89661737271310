import { EditStroke } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setSurveyError } from '../../shared/actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { select } from 'redux-saga/effects';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { questionSectionStrokesEndPoint } from '../consts';
import { MultipleOpinionScaleQuesState } from '../../../../../shared/types/QuesTypes';
import { setQuestion } from '../../sharedQuestions/actions';
import { set } from 'shades';

export function* updateStrokeText(action: EditStroke) {
	const { quesId, sectionId, text, strokeId } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	if (!!questions[quesId]) {
		const question: MultipleOpinionScaleQuesState = questions[quesId];

		try {
			yield axios.patch(getApiEndPointUrl(questionSectionStrokesEndPoint, strokeId), { sectionId, text });
			yield putEffect(
				setQuestion(question.id, set('sections', sectionId, 'strokes', strokeId, 'text')(text)(question))
			);
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	} else {
		let question: any;
		for (const questionId in questions) {
			const section = questions[questionId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[quesId]) {
					question = section?.questions?.[quesId];
					break;
				}
			}
		}
		try {
			yield axios.patch(getApiEndPointUrl(questionSectionStrokesEndPoint, strokeId), { sectionId, text });

			questions[question?.questionnaireSectionId].questions[question.id] = set(
				'sections',
				sectionId,
				'strokes',
				strokeId,
				'text'
			)(text)(question);
			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			yield putEffect(setSurveyError(getApiError(e)));
		}
	}
}
