import styled, { css } from 'styled-components';
export const MixinText12 = css<{
	$color?: string;
}>`
	font-size: ${({ theme }) => theme.fonts.size.small};
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
	font-family: ${({ theme }) => theme.fonts.family.primary};
`;
export const MixinText14 = css<{
	$color?: string;
}>`
	font-size: ${({ theme }) => theme.fonts.size.base};
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText16 = css<{
	$color?: string;
	color?: string;
}>`
	font-size: ${({ theme }) => theme.fonts.size.big};
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme, $color, color }) => (!!color ? color : $color || theme.colors.primaryDark)};
`;
export const MixinText18 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	font-size: ${({ theme }) => theme.fonts.size.bigger};
	font-weight: 400;
	line-height: 3rem;
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinHeading18 = css<{
	$color?: string;
}>`
	font-size: ${({ theme }) => theme.fonts.size.bigger};
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	font-weight: 400;
	line-height: 3rem;
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText22 = css<{
	$color?: string;
}>`
	font-size: 2.2rem;
	line-height: 2.9rem;
	font-weight: 400;
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText20 = css<{
	$color?: string;
}>`
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 400;
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinHeading20 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	${MixinText20}
`;

export const MixinText21 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	font-size: ${({ theme }) => theme.fonts.size.evenBigger};
	line-height: 2.9rem;
	font-weight: 400;
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText24 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	font-size: ${({ theme }) => theme.fonts.size.lead};
	font-style: normal;
	font-weight: ${({ theme }) => theme.fonts.weight.bolder};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText28 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.primary};
	font-size: ${({ theme }) => theme.fonts.size.morelead};
	font-style: normal;
	line-height: 5.2rem;
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText30 = css<{
	$color?: string;
}>`
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	font-size: ${({ theme }) => theme.fonts.size.size30};
	font-style: normal;
	line-height: 5.2rem;
	font-weight: ${({ theme }) => theme.fonts.weight.base};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText35 = css<{
	$color?: string;
}>`
	font-weight: 400;
	font-size: ${({ theme }) => theme.fonts.size.massive};
	line-height: 6.4rem;
	letter-spacing: 0.05rem;
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText40 = css<{
	$color?: string;
}>`
	font-weight: 400;
	font-size: ${({ theme }) => theme.fonts.size.gigantic};
	line-height: 6.4rem;
	letter-spacing: 0.05rem;
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinText48 = css<{
	$color?: string;
}>`
	font-weight: 400;
	font-size: ${({ theme }) => theme.fonts.size.huge};
	line-height: 6.4rem;
	letter-spacing: 0.2rem;
	font-family: ${({ theme }) => theme.fonts.family.styledHeading};
	color: ${({ theme, $color }) => $color || theme.colors.primaryDark};
`;
export const MixinBoldText12 = css`
	${MixinText12}
	font-weight: 500;
`;
export const MixinBoldText14 = css`
	${MixinText14}
	font-weight: 500;
`;
export const MixinBoldText16 = css`
	${MixinText16}
	font-weight: 500;
`;
export const MixinBoldText18 = css`
	${MixinText18}
	font-weight: 500;
`;
export const MixinBoldText21 = css`
	${MixinText21}
	font-weight: 500;
`;
export const MixinBoldText20 = css`
	${MixinText20}
	font-weight: 500;
`;
export const MixinBoldText22 = css`
	${MixinText22}
	font-weight: 500;
`;
export const MixinBoldText24 = css`
	${MixinText24}
	font-weight: 500;
`;
export const MixinBoldText28 = css`
	${MixinText28}
	font-weight: 500;
`;
export const StyledText12 = styled.span<{
	$color?: string;
}>`
	${MixinText12};
	display: inline-block;
`;
export const StyledText14 = styled.span<{
	$color?: string;
	color?: string;
}>`
	${MixinText14};
	color: ${({ theme, color }) => !!color && color};
	display: inline-block;
`;
export const StyledText16 = styled.span<{
	$color?: string;
}>`
	${MixinText16};
	display: inline-block;
`;
export const StyledText18 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinText18};
`;

export const StyledText21 = styled.span<{
	$color?: string;
}>`
	${MixinText21};
	display: inline-block;
`;
export const StyledText20 = styled.span<{
	$color?: string;
}>`
	${MixinText20};
	display: inline-block;
`;
export const StyledText22 = styled.span<{
	$color?: string;
}>`
	${MixinText22};
	display: inline-block;
`;
export const StyledText24 = styled.span<{
	$color?: string;
}>`
	${MixinText24};
	display: inline-block;
`;

export const StyledText28 = styled.span<{
	$color?: string;
}>`
	${MixinText28};
	display: inline-block;
`;

export const StyledText30 = styled.span<{
	$color?: string;
}>`
	${MixinText30};
	display: inline-block;
`;
export const StyledText40 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinText40};
`;
export const StyledText48 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinText48};
`;

export const StyledText35 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinText35};
`;
export const StyledBoldText12 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinBoldText12};
`;
export const StyledBoldText14 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText14};
	display: inline-block;
`;
export const StyledBold700Text14 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText14};
	display: inline-block;
	font-weight: 700;
`;
export const StyledBoldText16 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText16};
	display: inline-block;
`;
export const StyledBold700Text16 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText16};
	display: inline-block;
	font-weight: 700;
`;

export const StyledBoldText18 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText18};
	display: inline-block;
`;

export const StyledBoldText20 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText20};
	display: inline-block;
`;

export const StyledBold700Text20 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText20};
	display: inline-block;
	font-weight: 700;
`;
export const StyledBoldText21 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText21};
	display: inline-block;
`;
export const StyledBoldText22 = styled.span<{
	$color?: string;
}>`
	${MixinBoldText22};
	display: inline-block;
`;

export const StyledBoldText24 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinBoldText24};
`;

export const StyledBoldText28 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinBoldText28};
`;
export const StyledHeading20 = styled.span<{
	$color?: string;
}>`
	${MixinHeading20};
	display: inline-block;
`;
export const StyledParagraph12 = styled.p<{
	$color?: string;
}>`
	${MixinText12};
	margin: 0;
`;
export const StyledParagraph14 = styled.p<{
	$color?: string;
}>`
	${MixinText14};
	margin: 0;
`;
export const StyledParagraph16 = styled.p<{
	$color?: string;
}>`
	${MixinText16};
	margin: 0;
`;
export const StyledHeading18 = styled.span<{
	$color?: string;
}>`
	display: inline-block;
	${MixinHeading18};
`;
