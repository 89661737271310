import { call, select } from 'redux-saga/effects';
import axios from '@utils/axios';
import { surveyQuestionOptionAssetsApiUrl } from '../../sharedQuestions/sagas/consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { get, set, updateAll } from 'shades';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { UploadOptionAsset } from '../types';
import { CreativeQuesState } from '../../../../../shared/types/QuesTypes';

export function* uploadOptionAsset(action: UploadOptionAsset) {
	const { questionId, optionId, file, url } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);

	if (!!questions[questionId]) {
		const question = questions[questionId];
		// Upload process is long this will set URL of file before the upload is completed (for better UX)
		yield putEffect(setQuestion(question.id, set('options', optionId, 'url')(url)(question)));

		if (get('options', optionId, 'assetId')(question)) {
			yield call(
				axios.delete,
				getApiEndPointUrl(surveyQuestionOptionAssetsApiUrl, get('options', optionId, 'assetId')(question) || '')
			);
		}

		try {
			const data = new FormData();
			if (url) {
				data.append('url', url);
			} else if (file) {
				data.append('assets', file);
			}
			data.append('optionId', optionId);
			const res = yield axios.post(getApiEndPointUrl(surveyQuestionOptionAssetsApiUrl, 'batch'), data);
			yield putEffect(
				setQuestion(
					question.id,
					updateAll<CreativeQuesState>(
						set('options', optionId, 'url')(get('data', 0, 'url')(res) as unknown as string),
						set('options', optionId, 'assetId')(get('data', 0, 'id')(res) as unknown as string)
					)(question)
				)
			);
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		let question: any;
		for (const quesId in questions) {
			const section = questions[quesId];
			if (section?.metaData && section?.questionnaireId.length > 0) {
				if (section?.questions?.[questionId]) {
					question = section?.questions?.[questionId];
					break;
				}
			}
		}
		questions[question?.questionnaireSectionId].questions[question.id] = set('options', optionId, 'url')(url)(question);
		yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));

		if (get('options', optionId, 'assetId')(question)) {
			yield call(
				axios.delete,
				getApiEndPointUrl(surveyQuestionOptionAssetsApiUrl, get('options', optionId, 'assetId')(question) || '')
			);
		}
		try {
			const data = new FormData();
			if (url) {
				data.append('url', url);
			} else if (file) {
				data.append('assets', file);
			}
			data.append('optionId', optionId);
			const res = yield axios.post(getApiEndPointUrl(surveyQuestionOptionAssetsApiUrl, 'batch'), data);
			questions[question?.questionnaireSectionId].questions[question.id] = updateAll<CreativeQuesState>(
				set('options', optionId, 'url')(get('data', 0, 'url')(res) as unknown as string),
				set('options', optionId, 'assetId')(get('data', 0, 'id')(res) as unknown as string)
			)(question);
			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
