import React from 'react';
import { intersection } from 'ramda';
import { useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { Role } from '../../store/auth/types';
import { Entity, PermissionAction, PermissionRole, permissions } from '../enums/Permissions';
import { useCurrentRoute } from 'react-navi';

export const hasPermissions = (
	roles: Role[] = [],
	requiredPermissions: { entity: Entity; actions: PermissionAction[]; workspaceId?: string }
): boolean => {
	const { entity, actions, workspaceId } = requiredPermissions;
	const selectedRoles = roles.filter((role) => !workspaceId || role.workspace.id === workspaceId);
	if (selectedRoles.length) {
		const userPermissionsList = selectedRoles.reduce((acc: string[], current) => {
			return [...acc, ...current.permissions.map((role) => role.action)];
		}, []);
		const allowedPermissionsList = selectedRoles.reduce((acc: string[], current) => {
			return [...acc, ...permissions[entity][current.name]];
		}, []);

		const common = intersection(allowedPermissionsList, userPermissionsList);
		return intersection(common, actions).length !== 0;
	}
	// below condition might not be needed anymore
	return isBrandOwner(roles);
};

export const isBrandOwner = (roles: Role[]): boolean => {
	if (roles.length === 0) return true;
	return roles.some((role) => role.name === PermissionRole.BrandOwner);
};

export const withPermissionsCheck = (
	Component: React.ComponentClass | React.FC,
	entity: Entity,
	actions: PermissionAction[]
): React.FC => {
	const WithPermissionsCheckWrapper: React.FC = (_props): JSX.Element => {
		const {
			lastChunk: { request },
		} = useCurrentRoute();
		const roles: Role[] = useSelector((state: StoreState) => state.user.roles);
		const workspaceId = request?.params.workspaceId;

		if (hasPermissions(roles, { entity, actions, workspaceId })) {
			return <Component {..._props} />;
		}
		return <></>;
	};
	return WithPermissionsCheckWrapper;
};
