import { getBrandFunnelWatchers } from './brandFunnel/sagas';
import { brandFunnelCompetitorSagas } from './brandFunnelCompetitors/sagas';
import { brandFunnelMetricSagas } from './brandFunnelMetrics/sagas';
import { cohortDeepDiveStageSagas } from './cohortDeepDiveStages/sagas';
import { funnelAnalyzerStageSagas } from './funnelAnalyzerStages/sagas';
import { getSurveyDataWatchers } from './generalAnalytics/sagas';
import { getVideoRecordingsWatchers } from './VideoRecordings/sagas';
import { generalAnalyticsReportWatcher } from './filters/sagas';
import { comparisonAnalyticsReportWatcher } from './compare/sagas';

export const surveyAnalyticsSagas = [
	...getSurveyDataWatchers,
	...getBrandFunnelWatchers,
	...brandFunnelMetricSagas,
	...funnelAnalyzerStageSagas,
	...cohortDeepDiveStageSagas,
	...brandFunnelCompetitorSagas,
	...getVideoRecordingsWatchers,
	...generalAnalyticsReportWatcher,
	...comparisonAnalyticsReportWatcher,
];
