import React, { ReactElement, ReactNode } from 'react';
import { StyledThickButton, StyledThickOutlinedButton } from '../Button/styled';
import { StyledFooter, StyledHaltButton, StyledLeftOutlined, StyledRightOutlined } from './styled';
import SaveOutlined from '@ant-design/icons/SaveOutlined';

export type HaltProceedFooterProps = {
	onProceed?: () => void; // ignored if proceedButtonFormId is passed
	onHalt?: () => void;
	onSecondaryProceed?: () => void;
	disabled?: boolean;
	proceedButton?: ReactElement | null | boolean;
	proceedIcon?: ReactElement | null; // ignored if proceedButton is passed
	proceedContent?: ReactNode; // ignored if proceedButton is passed
	secondaryProceedButton?: ReactElement | null | boolean;
	secondaryProceedIcon?: ReactElement | null; // ignored if secondaryProceedButton is passed
	secondaryProceedContent?: ReactNode; // ignored if proceedButton is passed
	haltButton?: ReactElement | null | boolean;
	haltIcon?: ReactElement | null; // ignored if haltButton is passed
	haltContent?: ReactNode; // ignored if haltButton is passed
	middle?: ReactElement;
	proceedButtonFormId?: string;
	className?: string;
	showSecondaryButton?: boolean;
	// displayProceedIcon?: boolean;
};
export const HaltProceedFooter = ({
	className,
	onProceed = () => {},
	onHalt = () => {},
	onSecondaryProceed = () => {},
	disabled = false,
	proceedButton,
	proceedIcon = <StyledRightOutlined />,
	proceedContent = 'Continue',
	// displayProceedIcon = true
	secondaryProceedButton,
	secondaryProceedIcon = <SaveOutlined className="mr-4" />,
	secondaryProceedContent = 'Save as Draft',
	haltButton,
	haltIcon = <StyledLeftOutlined />,
	haltContent = 'Reset',
	middle = <span />,
	proceedButtonFormId,
}: HaltProceedFooterProps): ReactElement => {
	const finalProceedButton =
		proceedButton === undefined ? (
			<StyledThickButton
				type="submit"
				form={proceedButtonFormId}
				disabled={disabled}
				onClick={!proceedButtonFormId ? onProceed : undefined}
			>
				{proceedContent}
				{proceedIcon}
			</StyledThickButton>
		) : (
			proceedButton // to center things null will be empty span
		);

	const finalHaltButton =
		haltButton === undefined ? (
			<StyledHaltButton onClick={onHalt} type="button">
				{haltIcon}
				{haltContent}
			</StyledHaltButton>
		) : (
			haltButton || <span />
		);
	const finalSecondaryProceedButton =
		secondaryProceedButton === true ? (
			<StyledThickOutlinedButton onClick={onSecondaryProceed} type="button" className="mr-3">
				{secondaryProceedIcon}
				{secondaryProceedContent}
			</StyledThickOutlinedButton>
		) : (
			secondaryProceedButton
		);
	return (
		<StyledFooter className={className}>
			{finalHaltButton}
			{middle} {/* Keeps button aligned even if one component is null*/}
			<span className="inline-flex">
				{finalSecondaryProceedButton}
				{finalProceedButton}
			</span>
		</StyledFooter>
	);
};
