export enum PermissionRole {
	BrandOwner = 'Brand Owner',
	Admin = 'Administrator',
	Editor = 'Editor',
	Viewer = 'Viewer',
}

export enum PermissionAction {
	View = 'view',
	Update = 'update',
	Delete = 'delete',
	Create = 'create',
	Approve = 'approve',
	Review = 'review',
}

export enum Entity {
	WorkspaceEntity = 'WorkspaceEntity',
	QuestionnaireEntity = 'QuestionnaireEntity',
	BrandEntity = 'BrandEntity',
	WorkspaceUserEntity = 'workspaceUserEntity',
	CampaignEntity = 'CampaignEntity',
}

export const permissions = {
	[Entity.WorkspaceEntity]: {
		[PermissionRole.BrandOwner]: [PermissionAction.Create, PermissionAction.Update, PermissionAction.Delete],
		[PermissionRole.Admin]: [],
		[PermissionRole.Editor]: [],
		[PermissionRole.Viewer]: [],
	},
	[Entity.BrandEntity]: {
		[PermissionRole.BrandOwner]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.Delete,
			PermissionAction.View,
		],
		[PermissionRole.Admin]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.Delete,
			PermissionAction.View,
		],
		[PermissionRole.Editor]: [PermissionAction.View],
		[PermissionRole.Viewer]: [PermissionAction.View],
	},
	[Entity.QuestionnaireEntity]: {
		[PermissionRole.BrandOwner]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.Delete,
			PermissionAction.View,
			PermissionAction.Review,
			PermissionAction.Approve,
		],
		[PermissionRole.Admin]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.View,
			PermissionAction.Review,
			PermissionAction.Approve,
			PermissionAction.Delete,
		],
		[PermissionRole.Editor]: [PermissionAction.Create, PermissionAction.Update, PermissionAction.View],
		[PermissionRole.Viewer]: [PermissionAction.View],
	},
	[Entity.WorkspaceUserEntity]: {
		[PermissionRole.BrandOwner]: [PermissionAction.Create, PermissionAction.Update, PermissionAction.Delete],
		[PermissionRole.Admin]: [PermissionAction.Create, PermissionAction.Update, PermissionAction.Delete],
		[PermissionRole.Editor]: [],
		[PermissionRole.Viewer]: [],
	},
	[Entity.CampaignEntity]: {
		[PermissionRole.BrandOwner]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.Delete,
			PermissionAction.View,
		],
		[PermissionRole.Admin]: [
			PermissionAction.Create,
			PermissionAction.Update,
			PermissionAction.Delete,
			PermissionAction.View,
		],
		[PermissionRole.Editor]: [PermissionAction.View],
		[PermissionRole.Viewer]: [PermissionAction.View],
	},
};
