import { ApproveEdit } from '../types';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { approveEdit, setPopup } from '../actions';
import { getApiError } from '../../../../../shared/utils/getApiError';
import axios from '@utils/axios';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { questionnairesEndPoint } from 'store/questionnaire/const';
import { fulfill, pend, reject } from 'store/promise/actions';

export function* handleApproveEdit(action: ApproveEdit) {
	try {
		yield putEffect(pend(approveEdit));
		yield axios.post(getApiEndPointUrl(questionnairesEndPoint, action.payload.surveyId, 'approveEdit'));
		yield putEffect(fulfill(approveEdit));
	} catch (e) {
		yield putEffect(reject(approveEdit, getApiError(e)));
		yield putEffect(setPopup(getApiError(e)));
	}
}
