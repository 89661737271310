import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { SurveyAnalytics } from '../types';
import { JSONSchema } from '../../../shared/utils/jsonschema';

export const READ_VIDEO_RECORDINGS = 'READ_VIDEO_RECORDINGS';
export const READ_VIDEO_RECORDINGS_FULFILLED = 'READ_VIDEO_RECORDINGS_FULFILLED';
export const UPDATE_VIDEO_RECORDINGS = 'UPDATE_VIDEO_RECORDINGS';
export const UPDATE_VIDEO_RECORDINGS_FULFILLED = 'UPDATE_VIDEO_RECORDINGS_FULFILLED';
export const UPDATE_VIDEO_MARKERS = 'UPDATE_VIDEO_MARKERS';
export const UPDATE_VIDEO_MARKERS_FULFILLED = 'UPDATE_VIDEO_MARKERS_FULFILLED';

type videoMarkers = {
	description: string;
	id: string;
	text: string;
	time: number;
	type: string;
	username?: string;
	profilePicture?: string;
}[];

export type VideoRecording = {
	responseId: string;
	comment: string;
	nicheId: string;
	video: {
		videoUrl: string;
		videoDuration: number;
		createdAt: string;
		camDuration?: number;
		camKey?: string;
		camUrl?: string;
		key?: string;
		videoMarkers?: videoMarkers;
	};
};
export type VideoRecordings = {
	metaData: { total: number };
	entities: VideoRecording[];
};
export type ReadVideoRecordingsPayload = {
	id: string;
	offset: number;
	limit: number;
	cohorts: string[];
};

export const VideoRecordingsSchema: JSONSchema = {
	type: 'object',
	properties: {
		metaData: { type: 'object', properties: { total: { type: 'number' } } },
		entities: { type: 'array', items: { type: 'object' } },
	},
};
export type UpdateVideoRecordingPayload = Omit<VideoRecording, 'video' | 'nicheId'> & { questionnaireId: string };
export type UpdateVideoMarkersPayload = { responseId: string; questionnaireId: string; videoMarkers: videoMarkers };

export type ReadVideoRecordings = ReduxAction<typeof READ_VIDEO_RECORDINGS, ReadVideoRecordingsPayload>;
export type ReadVideoRecordingsFulfilled = ReduxAction<typeof READ_VIDEO_RECORDINGS_FULFILLED, VideoRecordings>;
export type UpdateVideoRecordings = ReduxAction<typeof UPDATE_VIDEO_RECORDINGS, UpdateVideoRecordingPayload>;
export type UpdateVideoRecordingFulfilled = ReduxAction<
	typeof UPDATE_VIDEO_RECORDINGS_FULFILLED,
	UpdateVideoRecordingPayload
>;
export type UpdateVideoMarkers = ReduxAction<typeof UPDATE_VIDEO_MARKERS, UpdateVideoMarkersPayload>;
export type UpdateVideoMarkersFulfilled = ReduxAction<typeof UPDATE_VIDEO_MARKERS_FULFILLED, UpdateVideoMarkersPayload>;

export type VideoRecordingsAction =
	| ReadVideoRecordings
	| ReadVideoRecordingsFulfilled
	| UpdateVideoRecordings
	| UpdateVideoRecordingFulfilled
	| UpdateVideoMarkers
	| UpdateVideoMarkersFulfilled;

export type VideoRecordingsReducer = ReduxReducer<SurveyAnalytics, VideoRecordingsAction>;
