import {
	ReadBrandFunnelCompetitors,
	READ_BRAND_FUNNEL_COMPETITORS,
	BrandFunnelCompetitor,
	ReadBrandFunnelCompetitorsFulfilled,
	READ_BRAND_FUNNEL_COMPETITORS_FULFILLED,
	ReadBrandFunnelCompetitorsPayload,
} from './types';

export const readBrandFunnelCompetitors = (payload: ReadBrandFunnelCompetitorsPayload): ReadBrandFunnelCompetitors => ({
	type: READ_BRAND_FUNNEL_COMPETITORS,
	payload,
});

export const readBrandFunnelCompetitorsFulfilled = (
	payload: BrandFunnelCompetitor[]
): ReadBrandFunnelCompetitorsFulfilled => ({
	type: READ_BRAND_FUNNEL_COMPETITORS_FULFILLED,
	payload: payload,
});
