import {
	GET_RESEARCH_TEMPLATES,
	ResearchReducer,
	ResearchState,
	SET_RESEARCH_TEMPLATES,
	SET_RESEARCH_TEMPLATES_ERROR,
} from './types';
import { set, updateAll } from 'shades';

const initialState: ResearchState = {
	modules: [],
	templates: [],
	requestInProgress: false,
	error: '',
};

export const researchReducer: ResearchReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_RESEARCH_TEMPLATES:
			return updateAll<ResearchState>(set('requestInProgress')(true as boolean), set('error')('' as string))(state);
		case SET_RESEARCH_TEMPLATES:
			return updateAll<ResearchState>(
				set('requestInProgress')(false as boolean),
				set('error')('' as string),
				set('modules')(action.payload.modules),
				set('templates')(action.payload.templates)
			)(state);
		case SET_RESEARCH_TEMPLATES_ERROR:
			return updateAll<ResearchState>(set('requestInProgress')(false as boolean), set('error')(action.payload))(state);
		default:
			return state;
	}
};
