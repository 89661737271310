import { SurveyBuilderState, SurveyReducer } from '../shared/types';
import {
	CREATE_SURVEY,
	GET_SURVEY,
	SCHEDULE_SURVEY,
	SET_ACTIVE_SURVEY,
	SET_SURVEY,
	SET_SURVEY_INFO,
	SET_SURVEY_GAME,
	UPDATE_GLOBAL_SETTINGS,
	GET_PUBLIC_SURVEY,
	CREATE_MODERATE_SLOT,
	SET_SURVEY_DESIGN_THEME,
} from './types';
import { get, set, updateAll } from 'shades';
// import { assign } from '../../../../shared/utils/assign';
import { isGameSelected } from '../../../../shared/utils/surveyDesign';
import { QUES_TYPE } from '../../../../shared/consts/quesType';
import { insert, omit } from 'ramda';

export const surveyInfoReducer: SurveyReducer = (state, action) => {
	switch (action.type) {
		case CREATE_SURVEY:
		case GET_SURVEY:
		case GET_PUBLIC_SURVEY:
		case UPDATE_GLOBAL_SETTINGS:
		case CREATE_MODERATE_SLOT:
		case SET_SURVEY_INFO:
		case SCHEDULE_SURVEY: {
			return set('requestInProgress')(true)(state);
		}
		case SET_SURVEY_DESIGN_THEME: {
			let updatedData = updateAll<SurveyBuilderState>(
				set('surveyTemplateSetting')({
					...action.payload.surveyTemplateSetting,
				})
			)(state);
			return {
				...updatedData,
			};
		}
		case SET_SURVEY: {
			return {
				...state,
				...omit(['surveyId'], action.payload.questionnaire),
				requestInProgress: false,
			};
		}
		case SET_SURVEY_GAME: {
			const surveyId = action.payload.surveyId;
			const survey = JSON.parse(JSON.stringify(state.surveys));
			const game = action.payload.game;

			survey[surveyId].gamesSelected = isGameSelected(game);
			survey[surveyId].game = game;

			return set('surveys')(survey)(state);
		}
		case SET_ACTIVE_SURVEY: {
			const survey = get('surveys', action.payload.id)(state);
			if (!survey) return state;

			const hasWelcomeScreen = Object.values(survey.questions).some(({ type }) => type === QUES_TYPE.WELCOME_SCREEN);
			const hasVideoUploadQues = Object.values(survey.questions).some(
				({ type }) => type === QUES_TYPE.VIDEO_UPLOAD_QUES
			);
			const questionOrder = Object.entries(survey.questions).reduce(
				(order, [id, { orderPosition }]) => insert(orderPosition)(id, order),
				[] as string[]
			);
			return updateAll<SurveyBuilderState>(
				set('quesOrder')(questionOrder),
				set('hasWelcomeScreen')(hasWelcomeScreen),
				set('hasVideoUploadQues')(hasVideoUploadQues),
				set('questions')(survey.questions),
				set('moderatedQuestion')(survey?.moderatedTesting?.questions),
				set('surveyId')(action.payload.id),
				set('research')(survey.research.type),
				set('currQuesId')('')
			)(state.surveyId ? set('surveys', state.surveyId, 'questions')(state.questions)(state) : state);
		}
		default:
			return state;
	}
};
