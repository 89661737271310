import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import mixpanel from 'mixpanel-browser';

interface MixPanelContextType {
	eventProperties: Record<string, any>;
	updateInfo: (updates: Record<string, any>) => void;
	resetInfo: () => void;
	track: (eventName: string) => void;
}

const MixPanelContext = createContext({} as MixPanelContextType);

export const useMixpanel = () => useContext(MixPanelContext);

const MixPanelProvider = ({ children }) => {
	const [eventProperties, setEventProperties] = useState<Record<string, any>>({});

	const updateInfo = useCallback((updates: Record<string, any>) => {
		setEventProperties((prev) => ({ ...prev, ...updates }));
	}, []);

	const resetInfo = useCallback(() => setEventProperties({}), []);

	const track = useCallback(
		(eventName: string) => {
			mixpanel.track(eventName, eventProperties);
			resetInfo();
		},
		[resetInfo, eventProperties]
	);

	const value: MixPanelContextType = useMemo(
		() => ({
			eventProperties,
			setInfo: setEventProperties,
			updateInfo,
			resetInfo,
			track,
		}),
		[eventProperties, updateInfo, resetInfo, track]
	);

	return <MixPanelContext.Provider value={value}>{children}</MixPanelContext.Provider>;
};

export default MixPanelProvider;
