import axios from '@utils/axios';
import { takeLatest, takeLeading } from 'redux-saga/effects';
import {
	DeleteWorkspaceAction,
	DELETE_WORKSPACE,
	GetRolesAction,
	GET_ROLES,
	GET_USERS,
	RenameWorkspaceAction,
	RenameWorkspacePayload,
	UpdateUserRoleObj,
	UpdateUserRolesAction,
	UPDATE_ROLES,
	REMOVE_USER_FROM_WORKSPACES,
	RemoveUserFromWorkspaces,
	RENAME_WORKSPACE_REQUEST,
	AddNewUsersAction,
	ADD_NEW_USERS,
	RequestObj,
	GetUsersAction,
	GetWorkspacesAction,
	GetUsersPayload,
	GetWorkspacesPayload,
	GET_DASHBOARD_WORKSPACES,
	UserWithRole,
} from './types';
import {
	getUsersEndPoint,
	getRolesEndPoint,
	updateUserRolesEndPoint,
	renameWorkspaceEndPoint,
	removeUserFromWorkspacesEndPoint,
	postUserWorkspaceEndpoint,
	getWorkspacesEndPoint,
} from './consts';
import { environment } from '../../environment';
import { putEffect } from '../../shared/utils/putEffect';
import {
	setUsersAction,
	setRolesAction,
	deleteUserFromWorkspaces,
	setWorkspaceDashboardError,
	setUserDashboardError,
	updateUserAction,
	renameWorkspaceAction,
	setRenameWorkspaceErrorAction,
	addNewUsersFulfilled,
	addNewUsersAction,
	setWorkspacesAction,
	getWorkspacesAction,
} from './actions';
import { getWorkspaceEndPoint } from '../workspaceUsers/const';
import { getApiError } from '../../shared/utils/getApiError';
import { Role } from '../workspaceUsers/types';
import { mapRenameRequestResponseToPayload } from './utils';
import { redirect } from '../redirect/actions';
import { fulfill, pend, reject } from '../promise/actions';
import { getApiEndPointUrl } from '../../shared/utils/getApiEndpointUrl';

function removeUserFromWorkspacesRequest(userId: string) {
	return axios.delete(`${environment.apiUrl}${removeUserFromWorkspacesEndPoint}`, { params: { userId } });
}

function renameWorkspaceRequest({ workspaceId, newWorkspaceName }: RenameWorkspacePayload) {
	return axios.patch(`${environment.apiUrl}${renameWorkspaceEndPoint}/${workspaceId}`, { name: newWorkspaceName });
}

function deleteWorkspaceRequest(workspaceId: string) {
	return axios.delete(`${environment.apiUrl}${getWorkspaceEndPoint}/${workspaceId}`);
}

function getRolesRequest(workspaceId: string) {
	return axios.get(`${environment.apiUrl}${getRolesEndPoint}?workspaceId=${workspaceId}`);
}

function getUsersRequest(payload: GetUsersPayload) {
	return axios.get(`${environment.apiUrl}${getUsersEndPoint}`, {
		params: payload,
	});
}

function getWorkspacesRequest(payload: GetWorkspacesPayload) {
	return axios.get(`${environment.apiUrl}${getWorkspacesEndPoint}`, {
		params: payload,
	});
}

function updateUserRolesRequest({ workspaceRoleId, roleId }: UpdateUserRoleObj) {
	return axios.patch(`${getApiEndPointUrl(updateUserRolesEndPoint)}/${workspaceRoleId}`, {
		userDetails: { roleId },
		customVariables: {
			productUrl: window.location.origin,
		},
	});
}
function postUserWorkspaceRequest(requestObj: RequestObj[]) {
	return axios.post(getApiEndPointUrl(postUserWorkspaceEndpoint), {
		usersList: requestObj,
		customVariables: {
			productUrl: window.location.origin,
		},
	});
}

function* getUsersSaga(action: GetUsersAction) {
	try {
		const res = yield getUsersRequest(action.payload);
		yield putEffect(setUsersAction(res.data));
	} catch (e) {
		yield putEffect(setWorkspaceDashboardError(getApiError(e)));
	}
}

function* getWorkspacesSaga(action: GetWorkspacesAction) {
	try {
		yield putEffect(pend(getWorkspacesAction));
		const res = yield getWorkspacesRequest(action.payload);
		yield putEffect(setWorkspacesAction(res.data));
		yield putEffect(fulfill(getWorkspacesAction));
	} catch (e) {
		yield putEffect(setWorkspaceDashboardError(getApiError(e)));
		yield putEffect(reject(getWorkspacesAction, getApiError(e)));
	}
}

function* getRolesSaga(action: GetRolesAction) {
	try {
		const roles: Role[] = yield Promise.all(action.payload.map(getRolesRequest)).then((data) =>
			data.map((res) => res.data).flat()
		);
		yield putEffect(setRolesAction(roles));
	} catch (e) {
		yield putEffect(setUserDashboardError(getApiError(e)));
	}
}

function* updateRolesSaga(action: UpdateUserRolesAction) {
	try {
		const user: UserWithRole[] = yield Promise.all(action.payload.map(updateUserRolesRequest)).then((data) =>
			data.map((res) => res.data)
		);
		yield putEffect(updateUserAction(user));
	} catch (e) {
		yield putEffect(setWorkspaceDashboardError(getApiError(e)));
	}
}

function* deleteWorkspaceSaga(action: DeleteWorkspaceAction) {
	try {
		yield deleteWorkspaceRequest(action.payload);
	} catch (e) {
		yield putEffect(setWorkspaceDashboardError(getApiError(e)));
	}
}

function* renameWorkspaceSaga(action: RenameWorkspaceAction) {
	try {
		const res = yield renameWorkspaceRequest(action.payload);
		yield putEffect(renameWorkspaceAction(mapRenameRequestResponseToPayload(res.data)));
	} catch (e) {
		yield putEffect(setRenameWorkspaceErrorAction(getApiError(e)));
	}
}

function* removeUserFromWorkspaceSaga(action: RemoveUserFromWorkspaces) {
	try {
		yield removeUserFromWorkspacesRequest(action.payload); // TODO wait response yield removeUserFromWorkspacesRequest
		yield putEffect(deleteUserFromWorkspaces(action.payload));
	} catch (error) {
		yield putEffect(setUserDashboardError(getApiError(error)));
	}
}
function* addNewUsersToWorkspace(action: AddNewUsersAction) {
	try {
		yield putEffect(pend(addNewUsersAction));
		const res = yield postUserWorkspaceRequest(action.payload.data);
		if (action.payload.url) yield putEffect(redirect(action.payload.url));
		yield putEffect(addNewUsersFulfilled(res.data));
		yield putEffect(fulfill(addNewUsersAction));
	} catch (e) {
		yield putEffect(reject(addNewUsersAction, getApiError(e)));
	}
}

function* addUsersToWorkspaceWatcher() {
	yield takeLeading(ADD_NEW_USERS, addNewUsersToWorkspace);
}

function* renameWorkspaceWatcher() {
	yield takeLeading(RENAME_WORKSPACE_REQUEST, renameWorkspaceSaga);
}

function* getUsersWatcher() {
	yield takeLatest(GET_USERS, getUsersSaga);
}

function* getWorkspacesWatcher() {
	// latest becuase pagination needs it
	yield takeLatest(GET_DASHBOARD_WORKSPACES, getWorkspacesSaga);
}

function* getRolesWatcher() {
	yield takeLeading(GET_ROLES, getRolesSaga);
}

function* updateUsersWatcher() {
	yield takeLeading(UPDATE_ROLES, updateRolesSaga);
}

function* deleteWorkspaceWatcher() {
	yield takeLeading(DELETE_WORKSPACE, deleteWorkspaceSaga);
}

function* removeUserFromWorkspacesWatcher() {
	yield takeLeading(REMOVE_USER_FROM_WORKSPACES, removeUserFromWorkspaceSaga);
}

export const dashboardSagas = [
	getUsersWatcher,
	getRolesWatcher,
	updateUsersWatcher,
	deleteWorkspaceWatcher,
	renameWorkspaceWatcher,
	removeUserFromWorkspacesWatcher,
	addUsersToWorkspaceWatcher,
	getWorkspacesWatcher,
];
