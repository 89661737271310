import { EditPipeQuesOption } from '../types';
import { call, select } from 'redux-saga/effects';
import { CreativeQuesState, MultipleChoiceQuesState, OptionType } from '../../../../../shared/types/QuesTypes';
import axios from '@utils/axios';
import { surveyQuestionOptionAssetsApiUrl, surveyQuestionOptionsApiUrl } from '../../sharedQuestions/sagas/consts';
import { setPopup, setQuestion } from '../../sharedQuestions/actions';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { processMultipleChoiceQuestion } from '../../surveyInfo/sagas/utils';
import { deleteQuesOptionAsset, editPipeQuesOption } from '../actions';
import { get } from 'shades';
import { fulfill, pend, reject } from 'store/promise/actions';
import { assign } from 'shared/utils/assign';

export function* updatePipeChoiceOption(action: EditPipeQuesOption) {
	const { quesId, pipeId, pipeValue, optionType, optionId } = action.payload;
	const { questions } = yield select((s) => s.surveyBuilder);
	let question: any;
	try {
		if (!!questions[quesId]) {
			question = questions[quesId] as CreativeQuesState;
		} else {
			for (const questionId in questions) {
				const section = questions[questionId];
				if (section?.metaData && section?.questionnaireId.length > 0) {
					if (section?.questions?.[quesId]) {
						question = section?.questions?.[quesId] as CreativeQuesState;
						break;
					}
				}
			}
		}
		if (get('options', optionId, 'assetId')(question) && question.options[optionId].optionType === OptionType.Asset) {
			yield call(
				axios.delete,
				getApiEndPointUrl(surveyQuestionOptionAssetsApiUrl, get('options', optionId, 'assetId')(question) || '')
			);
		}

		yield putEffect(pend(editPipeQuesOption));
		yield putEffect(deleteQuesOptionAsset(optionId));
		const { data } = yield axios.patch(getApiEndPointUrl(surveyQuestionOptionsApiUrl, optionId), {
			questionId: question.id,
			pipeId,
			optionType,
			pipeValue,
		});
		const nextQueId: string =
			Object?.keys(questions)?.find((key) => questions[key]?.orderPosition === question?.orderPosition + 1) || '';
		// pagebreak true for next question if pipeId is available
		if (questions[nextQueId] !== undefined && !!pipeId) {
			yield putEffect(setQuestion(nextQueId, assign({ pageBreak: true }, questions[nextQueId]), false));
		}
		if (!!questions[quesId]) {
			yield putEffect(
				setQuestion(question.id, processMultipleChoiceQuestion(data, question as unknown as MultipleChoiceQuesState))
			);
		} else {
			questions[question?.questionnaireSectionId].questions[question.id] = processMultipleChoiceQuestion(
				data,
				question as unknown as MultipleChoiceQuesState
			);
			yield putEffect(setQuestion(question?.questionnaireSectionId, questions[question?.questionnaireSectionId]));
		}

		yield putEffect(fulfill(editPipeQuesOption));
	} catch (e) {
		yield putEffect(reject(editPipeQuesOption, getApiError(e)));
		yield putEffect(setPopup(getApiError(e)));
	}
}
