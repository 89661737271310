import axios from '@utils/axios';
import { takeLeading } from 'redux-saga/effects';
import { getAnalyticsApiEndPointUrl } from '../../../shared/utils/getApiEndpointUrl';
import { getApiError } from '../../../shared/utils/getApiError';
import { validate } from '../../../shared/utils/jsonschema';

import { putEffect } from '../../../shared/utils/putEffect';
import { pend, fulfill, reject } from '../../promise/actions';
import {
	readCohortDeepDiveStages,
	readCohortDeepDiveStagesFulfilled,
	updateCohortDeepDiveStages,
	updateCohortDeepDiveStagesFulfilled,
} from './actions';
import { questionnaireEndPoint, brandFunnelStagesEndPoint } from './const';
import {
	UpdateCohortDeepDiveStagesPayload,
	ReadCohortDeepDiveStagesPayload,
	UpdateCohortDeepDiveStages,
	ReadCohortDeepDiveStages,
	CohortDeepDiveStagesSchema,
	UPDATE_COHORT_DEEP_DIVE_STAGES,
	READ_COHORT_DEEP_DIVE_STAGES,
} from './types';

function updateCohortDeepDiveStagesAPI(brand: UpdateCohortDeepDiveStagesPayload) {
	const { questionnaireId, ...data } = brand;
	return axios.put(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelStagesEndPoint),
		data
	);
}

function readCohortDeepDiveStagesAPI(brand: ReadCohortDeepDiveStagesPayload) {
	return axios.get(
		getAnalyticsApiEndPointUrl(questionnaireEndPoint, brand.questionnaireId, brandFunnelStagesEndPoint),
		{
			params: brand,
		}
	);
}

function* updateCohortDeepDiveStageSaga(action: UpdateCohortDeepDiveStages) {
	try {
		yield putEffect(pend(updateCohortDeepDiveStages));
		yield updateCohortDeepDiveStagesAPI(action.payload);
		yield putEffect(updateCohortDeepDiveStagesFulfilled(action.payload));
		yield putEffect(fulfill(updateCohortDeepDiveStages));
	} catch (e) {
		yield putEffect(reject(updateCohortDeepDiveStages, getApiError(e)));
	}
}

function* readCohortDeepDiveStagesSaga(action: ReadCohortDeepDiveStages) {
	try {
		yield putEffect(pend(readCohortDeepDiveStages));
		const res = yield readCohortDeepDiveStagesAPI(action.payload);
		yield putEffect(readCohortDeepDiveStagesFulfilled(validate(res.data, CohortDeepDiveStagesSchema)));
		yield putEffect(fulfill(readCohortDeepDiveStages));
	} catch (e) {
		yield putEffect(reject(readCohortDeepDiveStages, getApiError(e)));
	}
}

export const cohortDeepDiveStageSagas = [
	function* getUpdateCohortDeepDiveStageWatcher() {
		yield takeLeading(UPDATE_COHORT_DEEP_DIVE_STAGES, updateCohortDeepDiveStageSaga);
	},
	function* getReadCohortDeepDiveStagesWatcher() {
		yield takeLeading(READ_COHORT_DEEP_DIVE_STAGES, readCohortDeepDiveStagesSaga);
	},
];
