import axios from '@utils/axios';
import { map, prop, sortBy, values } from 'ramda';

import { selectQues, setPopup } from '../actions';
import { select } from 'redux-saga/effects';
import { DeleteSurveySection } from '../types';
import { StoreState } from '../../../../index';
import { getCleanupActions } from '../../utils';
import { SurveyBuilderState } from '../../shared/types';
import { surveyQuestionsApiUrl, surveySectionDeletesApiUrl, surveySectionOptionAssetsApiUrl } from './consts';
import { putEffect } from '../../../../../shared/utils/putEffect';
import { getApiError } from '../../../../../shared/utils/getApiError';
import { getApiEndPointUrl } from '../../../../../shared/utils/getApiEndpointUrl';
import { getOnePreviousQuestioId } from './utils';

export function* handleRemoveSurveySection(action: DeleteSurveySection) {
	const { sectionId, moderated, foundQuestion } = action.payload;
	if (!!moderated) {
		try {
			yield axios.delete(getApiEndPointUrl(surveyQuestionsApiUrl, surveySectionDeletesApiUrl, sectionId));
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
	} else {
		try {
			const { questions }: SurveyBuilderState = yield select((s: StoreState) => s.surveyBuilder);
			const quesOrder: any = map(
				prop('id'),
				sortBy(
					prop('orderPosition'),
					values(questions)?.filter((que) => !que.parentQuestionId)
				)
			);
			const newSelectQuesId = getOnePreviousQuestioId(foundQuestion, questions);
			const affected = quesOrder.map((id) => questions[id]);
			const actions = getCleanupActions(sectionId, affected, questions);
			yield axios.delete(getApiEndPointUrl(surveyQuestionsApiUrl, surveySectionDeletesApiUrl, sectionId));
			// yield putEffect(selectQues(newSelectQuesId, true));

			for (const action of actions) {
				yield putEffect(action);
			}
			if (actions.length) {
				yield putEffect(setPopup('Section piping has been modified after deleting the question.'));
			}
		} catch (e) {
			yield putEffect(setPopup(getApiError(e)));
		}
	}
}
