import { ReduxAction } from '../../../shared/types/ReduxAction';
import { ReduxReducer } from '../../../shared/types/ReduxReducer';
import { JSONSchema } from '../../../shared/utils/jsonschema';
import { BrandState } from '../types';

export const DELETE_BRAND_COLORS = 'DELETE_BRAND_COLOR';
export const DELETE_BRAND_COLORS_FULFILLED = 'DELETE_BRAND_COLOR_FULFILLED';
export const CREATE_BRAND_COLORS = 'CREATE_BRAND_COLORS';
export const CREATE_BRAND_COLORS_FULFILLED = 'CREATE_BRAND_COLORS_FULFILLED';

export type BrandColor = {
	id: string;
	label: string;
	hexCode: string;
	brandId: string;
};

const BrandColorSchema: JSONSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		label: { type: 'string' },
		hexCode: { type: 'string' },
		brandId: { type: 'string' },
	},
};
export const BrandColorsSchema: JSONSchema = {
	type: 'array',
	items: BrandColorSchema,
};
export type CreateBrandColorPayload = Omit<BrandColor, 'id'>;

export type CreateBrandColors = ReduxAction<typeof CREATE_BRAND_COLORS, CreateBrandColorPayload[]>;
export type CreateBrandColorsFulfilled = ReduxAction<typeof CREATE_BRAND_COLORS_FULFILLED, BrandColor[]>;

export type DeleteBrandColors = ReduxAction<typeof DELETE_BRAND_COLORS, BrandColor[]>;
export type DeleteBrandColorsFulfilled = ReduxAction<typeof DELETE_BRAND_COLORS_FULFILLED, BrandColor[]>;
export const READ_BRAND_COLORS = 'READ_BRAND_COLORS';
export const READ_BRAND_COLORS_FULFILLED = 'READ_BRAND_COLORS_FULFILLED';

export type ReadBrandColorsPayload = {
	brandId: string;
};

export type ReadBrandColors = ReduxAction<typeof READ_BRAND_COLORS, ReadBrandColorsPayload>;
export type ReadBrandColorsFulfilled = ReduxAction<typeof READ_BRAND_COLORS_FULFILLED, BrandColor[]>;

export type BrandColorAction =
	| CreateBrandColors
	| CreateBrandColorsFulfilled
	| DeleteBrandColors
	| DeleteBrandColorsFulfilled
	| ReadBrandColors
	| ReadBrandColorsFulfilled;

export type BrandColorReducer = ReduxReducer<BrandState, BrandColorAction>;
