import { equals } from 'ramda';
import React from 'react';

type UseEffectParams = Parameters<typeof React.useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
type UseEffectReturn = ReturnType<typeof React.useEffect>;
export function useDeepCompareEffect(callback: EffectCallback, dependencies: DependencyList): UseEffectReturn {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}
export function useDeepCompareMemoize(value: DependencyList) {
	const ref = React.useRef<DependencyList>();
	const signalRef = React.useRef<number>(0);

	if (!equals(value, ref.current)) {
		ref.current = value;
		signalRef.current += 1;
	}

	return [signalRef.current];
}
