import { SurveyListItem } from './types';
import { getFormattedTime } from '../../../shared/utils/datetime';
import { QuestionnaireListResponse } from '../../../shared/types/SurveyResponses';
import { Research } from '../../../shared/enums/Research';

export const mapSurveyListResponseToItems = (item: QuestionnaireListResponse): SurveyListItem | any => ({
	surveyId: item.id,
	name: item.name || 'Untitled',
	description: item.description || 'No description provided',
	questionsNumber: item.questionNumber,
	startTime: getFormattedTime(item.startTime),
	endTime: getFormattedTime(item.endTime),
	createdAt: getFormattedTime(item.createdAt),
	approvalStatus: item.status,
	status: item.status,
	credits: item.surveys[0]?.credits || 0,
	totalRespondents: item.surveys[0]?.totalRespondents || 0,
	surveyCost: item.surveys[0]?.surveyCost || 0,
	research: item.surveys[0]?.research?.researchType || Research.None,
	paymentStatus: item.paymentStatus,
	pixelId: item.pixelId,
});
