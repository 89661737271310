import {
	CreateNichePayload,
	CreateNiches,
	CREATE_NICHES,
	Niche,
	CreateNichesFulfilled,
	CREATE_NICHES_FULFILLED,
	UpdateNichePayload,
	UpdateNiches,
	UPDATE_NICHEs,
	UpdateNichesFulfilled,
	UPDATE_NICHES_FULFILLED,
	ReadNichesPayload,
	ReadNiches,
	READ_NICHES,
	ReadNichesFulfilled,
	READ_NICHES_FULFILLED,
	DELETE_NICHES,
	DELETE_NICHES_FULFILLED,
	DeleteNiches,
	DeleteNichesFulfilled,
	PutNiches,
	PutNichesFulfilled,
	PUT_NICHES,
	PUT_NICHES_FULFILLED,
} from './types';

export const putNiches = (payload: CreateNichePayload[]): PutNiches => ({
	type: PUT_NICHES,
	payload,
});

export const putNichesFulfilled = (payload: Niche[]): PutNichesFulfilled => ({
	type: PUT_NICHES_FULFILLED,
	payload: payload,
});

export const createNiches = (payload: CreateNichePayload[]): CreateNiches => ({
	type: CREATE_NICHES,
	payload,
});

export const createNichesFulfilled = (payload: Niche[]): CreateNichesFulfilled => ({
	type: CREATE_NICHES_FULFILLED,
	payload: payload,
});

export const updateNiches = (payload: UpdateNichePayload[]): UpdateNiches => ({
	type: UPDATE_NICHEs,
	payload,
});

export const updateNichesFulfilled = (payload: Niche[]): UpdateNichesFulfilled => ({
	type: UPDATE_NICHES_FULFILLED,
	payload: payload,
});

export const readNiches = (payload: ReadNichesPayload): ReadNiches => ({
	type: READ_NICHES,
	payload,
});

export const readNichesFulfilled = (payload: Niche[]): ReadNichesFulfilled => ({
	type: READ_NICHES_FULFILLED,
	payload: payload,
});

export const deleteNiches = (payload: Niche[]): DeleteNiches => ({
	type: DELETE_NICHES,
	payload,
});

export const deleteNichesFulfilled = (payload: Niche[]): DeleteNichesFulfilled => ({
	type: DELETE_NICHES_FULFILLED,
	payload: payload,
});
